import { PlusIcon } from "@heroicons/react/solid";
import GlobalLayout from "layout/GlobalLayout";
import React from "react";
import { Outlet } from "react-router-dom";
import { menuItems } from "./MenuItems";

let init = JSON.parse(localStorage.getItem("init"));

export default function TaskSheetIndex() {
  return (
    <GlobalLayout
      mainTitle={"Task Sheet"}
      heading={"Task Sheet"}
      selectedMenu={menuItems[1]}
      breadCrumbs={[{ title: "Task Sheet" }]}
      MenuItems={menuItems}
    >
      <Outlet />
    </GlobalLayout>
  );
}
