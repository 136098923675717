import { useState, useEffect } from "react"
import DataGrid, { CONSTANTS as GridConstants } from "components/data-grid/DataGrid"
import customAxios from "middlewares/axios-interceptor/customAxios"


// FORM ENTITY
// public string Name { get; set; } = "Form Name";
// public string Description { get; set; }
// public string Header { get; set; } = "Form header";
// public string Footer { get; set; } = "Form footer";
// public string Payload { get; set; } = "Form Payload";
// public bool Sequential { get; set; } = true;
// public string Path { get; set; } = "Form/Path";
// public int? DepartmentId { get; set; }
// public DepartmentGroup Department { get; set; }
// public ICollection<RequestApprovalStage> ApprovalStages { get; set; }
// public ICollection<Form> Forms { get; set; }



const LeaveFormSettings = ({ setSelectedLeaveRequestForm }) => {
    let [isLoading, setIsLoading] = useState(true);
    let [data, setData] = useState([]);
    let [fdtDepartments, setFdtDepartments] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            customAxios
                .get("/department")
                .then((res) => {
                    setFdtDepartments(res)
                })
            refreshGridData()
        }, 0);
    }, [])


    //MOCKS

    let [mockData_forms, setMockData_forms] = useState(
        [
            {
                id: 1,
                name: "Leave Form",
                description: "General Leave Form / Parent Leave Forms",
                header: "",
                footer: "",
                payload: "",
                sequential: true,
                path: "",
                departmentId: null
            },
            {
                id: 2,
                name: "Senior Employee Leave Form",
                description: "Leave Form For Manegerial Department",
                header: "",
                footer: "",
                payload: "",
                sequential: true,
                path: "",
                departmentId: 5
            },
            {
                id: 3,
                name: "HR Leave Form",
                description: "Leave Form For HR Department",
                header: "",
                footer: "",
                payload: "",
                sequential: true,
                path: "",
                departmentId: 2
            },
            {
                id: 4,
                name: "Admin Leave Form",
                description: "Leave Form For Admin Department",
                header: "",
                footer: "",
                payload: "",
                sequential: true,
                path: "",
                departmentId: 3
            },
        ]
    );

    useEffect(() => {
        refreshGridData();
    }, [mockData_forms])


    //MOCKS



    const refreshGridData = (row) => {
        setIsLoading(true)
        setTimeout(() => {
            setData(mockData_forms)
            setSelectedLeaveRequestForm(mockData_forms[0])
            setIsLoading(false)
        }, 1000)
        // const groupType = "approval"
        // customAxios
        //     .get(`/group/type/${groupType}`)
        //     .then((res) => {
        //         setData(res);
        //         setIsLoading(false)
        //         setSelectedLeaveApprovalGroupId(res[0].id)
        //     });
    }





    const hideOnAdd = true;
    const hideOnEdit = true;


    const columns = [
        {
            accessor: "id",
            Header: "Id",
            hideOnAdd,
            hideOnEdit,
            formFieldProps:
            {
                // validation: (yup) => yup.string().required("Date is required"),
                // type: "dateSelect",
                // initialValue: () => dayjs().format()
            }
        },
        {
            accessor: "name",
            Header: "Name",
            formFieldProps:
            {
                validation: (yup) => yup.string().required("Name is required"),
            }
        },
        {
            accessor: "description",
            Header: "Description",
            formFieldProps:
            {
                // validation: (yup) => yup.string().required("Name is required"),
            }
        },
        {
            accessor: "sequential",
            Header: "Sequential",
            hideOnAdd,
            hideOnEdit,
            cellRenderFunction: (cell) => { return <div>{cell.value ? "Yes" : "No"}</div> },
            formFieldProps:
            {
                // validation: (yup) => yup.string().required("Name is required"),
            }
        },
        {
            accessor: "departmentId",
            Header: "Department",
            cellRenderFunction: (cell) => {
                return (
                    <div>
                        {
                            cell.value
                                ? fdtDepartments.find(x => x.id === cell.value)?.name ?? "Fkey Not Found"
                                : "-"
                        }
                    </div>
                )
            },
            formFieldProps:
            {
                type: "autoComplete",
                initialDataSource: async () => {
                    return fdtDepartments
                    // const res = await customAxios.get("/leave/types");
                    // const existingLeaveTypeIds = data.map(x => x.leaveTypeId) ?? [];
                    // return existingLeaveTypeIds
                    //     ? res?.filter(x => !existingLeaveTypeIds.includes(x.id))
                    //     : res
                },
                // validation: (yup) => yup.string().required("Name is required"),
                fieldProps: {
                    optionValue: "id",
                    nullable: false,
                    fieldDisplay: (x) => `${x.name}`,
                    optionDisplay: (x) =>
                        <div className="grid grid-cols-12 justify-center items-center gap-2">
                            <span className="col-span-1">{x.id}</span>
                            <span className="col-span-5">{x.name}</span>
                        </div>,
                }
            }
        }
    ];



    const onActionBegin = (args) => {
        if (args.requestType === GridConstants.GRID_ACTION_SAVE) {

            if (args.action === GridConstants.ACTION_ADD) {
                const payload =
                {
                    ...args.entity,
                    id: (data.at(-1)?.id ?? 0) + 1
                }
                console.log("SAVE >> ADD", payload)
                // customAxios
                //     .post("/group/create", payload)
                //     .then(() => { refreshGridData(); });

                setMockData_forms(prev => ([...prev, { ...payload }]))
            }

            else if (args.action === GridConstants.ACTION_UPDATE) {

                // const { policies: _, ...payload } = args.entity;
                const payload = { ...args.entity }
                console.log("SAVE >> UPDATE", payload)
                setMockData_forms(prev => {
                    const newArray = [...prev];
                    const indexOfUpdatedElement = newArray.findIndex(x => x.id === payload.id)
                    newArray[indexOfUpdatedElement] = { ...prev[indexOfUpdatedElement], ...payload }
                    return newArray;
                })

                // customAxios
                //     .put("/group/update", payload)
                //     .then(() => { refreshGridData(); });
            }

            else if (args.action === GridConstants.ACTION_DELETE) {
                console.log("SAVE >> DELETE", args.entity)
                const entityId = args.entity.id;
                setMockData_forms(prev => prev.filter(x => x.id !== entityId))
                // customAxios
                //     .delete(`/group/delete/${entityId}`)
                //     .then(() => { refreshGridData() })
            }

        } else if (args.requestType === GridConstants.GRID_ACTION_ROWSINGLECLICK) {
            console.log("ROW CLICK", args.entity)
            setSelectedLeaveRequestForm(args.entity)
        }
    }



    return (
        isLoading
            ? <div className="h-full w-full rounded-lg bg-slate-200 animate-pulse"></div>
            : <DataGrid
                header="Leave Request Forms"
                showHeader={true}
                columns={columns}
                testData={data}
                setData={setData}
                allowAdd={true}
                allowEdit={true}
                allowEditRowOnDoubleClick={true}
                allowDelete={true}
                confirmOnDelete={true}
                onActionBegin={onActionBegin}
            />
    )
}

export default LeaveFormSettings