import { useState, useEffect } from "react"
import DataGrid, { CONSTANTS as GridConstants } from "components/data-grid/DataGrid"
import customAxios from "middlewares/axios-interceptor/customAxios"


// APPROVAL STAGE ENTITY
// public int Index { get; set; }
// public Form Form { get; set; }
// public ApprovalGroup ApprovalGroup { get; set; }
// public int FormId { get; set; }
// public int ApprovalGroupId { get; set; }
// public PolicyGroup ApprovalStagePolicyGroup { get; set; }
// public int? ApprovalStagePolicyGroupId { get; set; }
// public int? MinimumRequiredApprovals { get; set; }



const LeaveApprovalStageSettings = ({ selectedLeaveRequestFormId, selectedLeaveRequestForm = undefined }) => {
    let [isLoading, setIsLoading] = useState(true);
    let [data, setData] = useState([]);
    let [fdtApprovalGroups, setFdtApprovalGroups] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            // API NOT READY
            // if(!selectedLeaveRequestForm) {
            //     customAxios
            //         .get(`/forms/${selectedLeaveRequestFormId}`)
            //         .then((res) => {
            //             setFdtApprovalGroups(res)
            //         })
            // }
            customAxios
                .get("/group/type/approval")
                .then((res) => {
                    setFdtApprovalGroups(res)
                })
            refreshGridData()
        }, 0);
    }, [])


    //MOCKS
    let idCtr = 0

    let [mockData_ApprovalStages, setMockData_ApprovalStages] = useState(
        [
            {
                id: ++idCtr,
                formId: 1,
                index: 0,
                approvalGroupId: 7,
                minimumRequiredApprovals: 1
            },
            {
                id: ++idCtr,
                formId: 1,
                index: 1,
                approvalGroupId: 8,
                minimumRequiredApprovals: 1
            },
            {
                id: ++idCtr,
                formId: 1,
                index: 2,
                approvalGroupId: 10,
                minimumRequiredApprovals: 1
            },
            {
                id: ++idCtr,
                formId: 2,
                index: 0,
                approvalGroupId: 7,
                minimumRequiredApprovals: 1
            },
            {
                id: ++idCtr,
                formId: 2,
                index: 1,
                approvalGroupId: 8,
                minimumRequiredApprovals: 1
            },
            {
                id: ++idCtr,
                formId: 2,
                index: 2,
                approvalGroupId: 10,
                minimumRequiredApprovals: 1
            },
        ]
    );

    useEffect(() => {
        refreshGridData();
    }, [mockData_ApprovalStages, selectedLeaveRequestFormId, selectedLeaveRequestForm])


    //MOCKS



    const refreshGridData = (row) => {
        setIsLoading(true)
        setTimeout(() => {
            setData(mockData_ApprovalStages.filter(x => x.formId === selectedLeaveRequestFormId))
            setIsLoading(false)
        }, 1000)
        // const groupType = "approval"
        // customAxios
        //     .get(`/group/type/${groupType}`)
        //     .then((res) => {
        //         setData(res);
        //         setIsLoading(false)
        //         setSelectedLeaveApprovalGroupId(res[0].id)
        //     });
    }





    const hideOnAdd = true;
    const hideOnEdit = true;


    const columns = [
        {
            accessor: "id",
            Header: "Id",
            hideOnAdd,
            hideOnEdit,
            formFieldProps:
            {
                // validation: (yup) => yup.string().required("Date is required"),
                // type: "dateSelect",
                // initialValue: () => dayjs().format()
            }
        },
        {
            accessor: "formId",
            Header: "FormId",
            hideOnAdd,
            hideOnEdit,
            formFieldProps:
            {
                // validation: (yup) => yup.string().required("Date is required"),
                // type: "dateSelect",
                // initialValue: () => dayjs().format()
            }
        },
        {
            accessor: "index",
            Header: "Index",
            formFieldProps:
            {
                validation: (yup) => yup.number().required("Index is required"),
                // type: "dateSelect",
                // initialValue: () => dayjs().format()
            }
        },
        {
            accessor: "approvalGroupId",
            Header: "Approval Group",
            cellRenderFunction: (cell) => {
                const fdtApprovalGroup = fdtApprovalGroups.find(x => x.id === cell.value);
                return (
                    <div className="whitespace-pre">
                        {
                            cell.value
                                ? `${cell.value}  -  ${fdtApprovalGroup?.name ?? `FKey Not Found`}`
                                : "-"
                        }
                    </div>
                )
            },
            formFieldProps:
            {
                validation: (yup) => yup.string().required("Must select an approval group to add to the approval stage"),
                type: "autoComplete",
                initialDataSource: () => fdtApprovalGroups,
                // initialValue: () => dayjs().format()
                fieldProps: {
                    label: "Approval Group",
                    optionValue: "id",
                    nullable: false,
                    fieldDisplay: (x) => `${x.name}`,
                    optionDisplay: (x) =>
                        <div className="grid grid-cols-12 justify-center items-center gap-2">
                            <span className="col-span-1">{x.id}</span>
                            <span className="col-span-5">{x.name}</span>
                        </div>,
                }
            }
        },
        {
            accessor: "minimumRequiredApprovals",
            Header: "Min Approvals",
            formFieldProps:
            {
                // validation: (yup) => yup.string().required("Date is required"),
                // type: "dateSelect",
                initialValue: () => 3
            }
        },
    ];



    const onActionBegin = (args) => {
        if (args.requestType === GridConstants.GRID_ACTION_SAVE) {

            if (args.action === GridConstants.ACTION_ADD) {
                const payload =
                {
                    ...args.entity,
                    formId: selectedLeaveRequestForm.id,
                    id: (data.at(-1)?.id ?? 0) + 1
                }
                console.log("SAVE >> ADD", payload)
                // customAxios
                //     .post("/group/create", payload)
                //     .then(() => { refreshGridData(); });

                setMockData_ApprovalStages(prev => { console.log("SAVE >> ADD", [...prev, { ...payload }]); return [...prev, { ...payload }] })
            }

            else if (args.action === GridConstants.ACTION_UPDATE) {

                // const { policies: _, ...payload } = args.entity;
                const payload = { ...args.entity }
                console.log("SAVE >> UPDATE", payload)
                setMockData_ApprovalStages(prev => {
                    const newArray = [...prev];
                    const indexOfUpdatedElement = newArray.findIndex(x => x.id === payload.id)
                    newArray[indexOfUpdatedElement] = { ...prev[indexOfUpdatedElement], ...payload }
                    return newArray;
                })

                // customAxios
                //     .put("/group/update", payload)
                //     .then(() => { refreshGridData(); });
            }

            else if (args.action === GridConstants.ACTION_DELETE) {
                console.log("SAVE >> DELETE", args.entity)
                const entityId = args.entity.id;
                setMockData_ApprovalStages(prev => prev.filter(x => x.id !== entityId))
                // customAxios
                //     .delete(`/group/delete/${entityId}`)
                //     .then(() => { refreshGridData() })
            }

            // } else if (args.requestType === GridConstants.GRID_ACTION_ROWSINGLECLICK) {
            //     console.log("ROW CLICK", args.entity)
            //     setSelectedLeaveApprovalGroupId(args.entity.id)
        }
    }



    return (
        isLoading
            ? <div className="h-full w-full rounded-lg bg-slate-200 animate-pulse"></div>
            : <DataGrid
                header="Leave Approval Stages"
                showHeader={true}
                columns={columns}
                testData={data}
                setData={setData}
                allowAdd={true}
                allowEdit={true}
                allowEditRowOnDoubleClick={true}
                allowDelete={true}
                confirmOnDelete={true}
                onActionBegin={onActionBegin}
            />
    )
}

export default LeaveApprovalStageSettings