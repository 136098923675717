import React, { Fragment, useEffect } from "react";
import { menuItems } from "../MenuItems";
import Widget from "components/widget/Widget";
import { useDispatch } from "react-redux";
import {
  setBreadCrumbs,
  setSelectedSubMenu,
  setSubHeader,
} from "features/navigation/NavigationSlice";

export default function TaskSheetOverview() {
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedSubMenu(menuItems[0]));
    dispatch(setSubHeader("Task Sheet"));
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "TaskS Sheet", link: "/TaskSheet" },
      ])
    );
  }, []);

  return <div className="grid grid-cols-4 gap-2">TaskSheet Overview</div>;
}
