import React from "react";
import { Tab } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setSelectedSubMenu, selectSelectedSubMenu } from "features/navigation/NavigationSlice";
import { MdFullscreen } from "react-icons/md";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SubSideMenu({ MenuItems, selectedMenu }) {
  let dispatch = useDispatch();
  let selectedSubMenu = useSelector(selectSelectedSubMenu);

  return (
    <div className="w-full h-full">
      <Tab.Group className={"h-full"}>
        <div>
          <Tab.List className={"space-y-2 bg-white h-full w-full p-2 shadow-md rounded-md"}>
            {MenuItems?.map((item, i) => (
              <Link to={item.path} key={i + 100} className="w-full">
                <Tab
                  // className={({ selected }) => {`style-0 ${selected ? "style-1" : "style-2"}`}}
                  className={
                    `flex flex-row justify-start rounded-md  text-md focus:outline-none w-full items-center space-x-4 pl-5 p-2  hover:cursor-pointer ",
                      ${selectedSubMenu === item ? "bg-orange-100 text-orange-600  " : "hover:bg-orange-50"}`
                  }
                  key={i + 700}
                >
                  <p className="text-sm font-medium">{item.title}</p>
                </Tab>
              </Link>
            ))}
          </Tab.List>
        </div>
      </Tab.Group>
    </div>
  );
}
