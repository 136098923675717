import React, { useEffect } from "react";
import customAxios from "../../../../../../middlewares/axios-interceptor/customAxios";
import EmployeeLeaveBalance from "widgets/employee/employee-card/EmployeeLeaveBalance";

export default function LeaveBalances({ id, empCode }) {
  let [leaveBalance, setLeaveBalance] = React.useState([]);
  let [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    customAxios.get(`/employee/profile/${empCode}`).then(
      (res) => {
        setLeaveBalance(
          res?.leaveBalance?.map((item) => {
            return {
              leaveType: item?.leaveType ?? "N/A",
              max: item?.max ?? "N/A",
              days: item?.balance ?? "N/A",
              percentage: (item?.balance / item?.max) * 100 ?? "N/A",
            };
          })
        );
        setLoading(false);
      },
      (err) => console.log(err)
    );

    // customAxios.get(`/leave?id=${EmployeeId}`).then(
    //   (res) => setLeaves(res.items),
    //   (err) => console.log(err)
    // );
  }, []);

  return (
    <div className="">
      <EmployeeLeaveBalance leaveBalanceData={leaveBalance} />
    </div>
  );
}
