import DataGrid from "components/data-grid/DataGrid";
import Widget from "components/widget/Widget";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "ui/buttons/SecondaryButton";

export default function EmployeeLoans() {
  let url = window.location.href;
  let navigate = useNavigate();

  let columns = [
    {
      Header: "Loan Type",
      accessor: "loanType",
    },
    {
      Header: "Start",
      accessor: "loanStartDate",
    },
    {
      Header: "End",
      accessor: "loanEndDate",
    },
    {
      Header: "Monthly Repayment",
      accessor: "monthlyRepayment",
    },
    {
      Header: "Rate",
      accessor: "rate",
    },
    {
      Header: "Amount",
      accessor: "loanAmount",
    },
    {
      Header: "Outstanding",
      accessor: "outstandingAmount",
    },
    {
      Header: "Repayments Overdue",
      accessor: "noOfRepaymentsOverdue",
    },
    {
      Header: "Status",
      accessor: "status",
    },
  ];

  let data = [
    // {
    //   loanType: "Home Loan",
    //   loanStartDate: moment(new Date("2021-01-02")).format("DD MMM YYYY"),
    //   loanEndDate: moment(new Date("2021-09-02")).format("DD MMM YYYY"),
    //   monthlyRepayment: "10000",
    //   rate: "10%",
    //   loanAmount: "100000",
    //   outstandingAmount: "100000",
    //   noOfRepaymentsOverdue: "0",
    //   overdueAmount: "0",
    //   status: "Active",
    // },
  ];

  function cellFunction(cell) {
    if (cell.column.id === "status") {
      return cell.value === "Active" ? (
        <div className="text-green-500">{cell.value}</div>
      ) : (
        <div className="text-red-500">{cell.value}</div>
      );
    }
    if (cell.column.id === "rate") {
      return cell.value;
    }
    if (cell.column.id === "monthlyRepayment") {
      return "MVR " + cell.value;
    }
    if (cell.column.id === "loanAmount") {
      return (
        <div className="">
          <div className="text-green-600">{cell.value}</div>
        </div>
      );
    }
    if (cell.column.id === "outstandingAmount") {
      return (
        <div className="">
          <div className="text-red-600">{"- " + cell.value}</div>
        </div>
      );
    }
    return cell?.value ?? "N" + "A";
  }

  return (
    <Widget
      heading={"Loans"}
      component={function () {
        return (
          <SecondaryButton>
            <button onClick={() => navigate(`AddLoan/Modal=true`)}>
              Add Loan
            </button>
          </SecondaryButton>
        );
      }}
    >
      <DataGrid
        showHeader={true}
        columns={columns}
        testData={data}
        cellFunction={cellFunction}
      />
    </Widget>
  );
}
