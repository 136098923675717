import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedEmployees: [
    {
      id: 1,
      name: "John Doe",
      empCode: "EMP001",
      identificationNumber: "123456789",
    },
  ],
  generatedAttendance: [],
};

export const AttendanceSlice = createSlice({
  name: "attendance",
  initialState,
  reducers: {
    setSelectedEmployees: (state, action) => {
      state.selectedEmployees = action.payload;
    },
    setGeneratedAttendance: (state, action) => {
      state.generatedAttendance = action.payload;
    },
  },
});

export const { setSelectedEmployees } = AttendanceSlice.actions;
export const { setGeneratedAttendance } = AttendanceSlice.actions;

// Selectors
export const selectSelectedEmployees = (state) =>
  state.attendance.selectedEmployees;
export const selectGeneratedAttendance = (state) =>
  state.attendance.generatedAttendance;

export default AttendanceSlice.reducer;
