import React, { useEffect, useState } from "react";
// import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
// import { Combobox } from "@headlessui/react";
// import { selectTab } from "../../features/employees/EmployeesSlice";
// import { useSelector } from "react-redux";
// import ComboBoxComponent from "../combo-box/ComboBoxComponent";
// import Widget from "components/widget/Widget";
// import PrimaryButton from "ui/buttons/PrimaryButton";
// import { useDropzone } from "react-dropzone";
// import DatePickerMain from "components/date-picker-v2/DatePickerMain";
// import moment from "moment";
// import { Link } from "react-router-dom";


import { Combobox } from "@headlessui/react";
import ComboBoxComponent from "../combo-box/ComboBoxComponent";


import DateSelectField from "./FormFieldComponents/DateSelectField";
// import DropDownField from "./FormFieldComponents/DropDownField";
import DropDownField from "./FormFieldComponents/FormGeneratorV2Components/DropDownField";
import AutoCompleteField from "./FormFieldComponents/FormGeneratorV2Components/AutoCompleteField";
import TextField from "./FormFieldComponents/FormGeneratorV2Components/TextField";

import Widget from "components/widget/Widget";

import { v4 as uuidv4 } from 'uuid';
import { Formik, useFormikContext, useField, Field, FieldArray, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import customAxios from "middlewares/axios-interceptor/customAxios";
import DatePickerMain from "components/date-picker-v2/DatePickerMain";
import moment from "moment";
import { fi } from "date-fns/locale";
import { FGV2Context } from "./FormFieldComponents/FormGeneratorV2Components/FGV2Context";


async function fetchDepartmentDesignations(departmentId) {
  const route = `/department/${departmentId}/designations`
  console.log(route)
  const designations = await customAxios
    .get(route)

  return designations;
}



const _fieldsReduce = (fields, fieldStyling) => {
  return fields.reduce(
    (accu, field, i) => {

      if (i !== 0) {
        if (!fieldStyling.isCellSkipAlternate || (fieldStyling.isCellSkipAlternate && i % 2 !== 0)) {
          const cellSkip = field.styling?.cellSkip ?? fieldStyling.cellSkip;
          accu.push(Array.from({ length: cellSkip }, () => <div></div>))
        }
      }

      let renderFrag;

      if (field.type === "text") {
        renderFrag = <TextField name={field.name} label={field.label} styling={fieldStyling} {...field.fieldProps} />
      }
      else if (field.type === "dropdown") {
        renderFrag = <DropDownField name={field.name} styling={fieldStyling} {...field.fieldProps} />
      }
      else if (field.type === "autoComplete") {
        renderFrag = <AutoCompleteField name={field.name} styling={fieldStyling} {...field.fieldProps} />
      }
      else if (field.type === "dateSelect") {
        renderFrag = <DateSelectField name={field.name} styling={fieldStyling} {...field.fieldProps} />
      }
      else {
        renderFrag = <TextField name={field.name} label={field.label} styling={fieldStyling} {...field.fieldProps} />
      }

      accu.push(renderFrag);
      return accu;
    },
    []
  )
}




function FormGeneratorV2({
  height = "h-full",
  isError,
  ErrorMsg,
  setSelectedValues,
  buttonSubmit,
  onSubmit,
  header,
  subHeader,
  fields,
  sections,
  styling,
  submitButtonText
}) {

  // INITIALIZING FORM PARAMS AND SETTING DEFAULTS
  fields ??= []
  sections ??= []
  submitButtonText ??= "Submit"
  styling ??=
  {
    // layout: "grid grid-cols-12 gap-2",
    field: {
      container: "col-span-12",
      cellSkip: 0,
      isCellSkipAlternate: true

      // label: "pl-1 font-bold",
      // input: "rounded-lg",
      // error: "text-red-500"
    }

  }

  // let [constructedValidationSchema, setConstructedValidationSchema] = useState();
  let [formDataSources, setFormDataSources] = useState({});

  const setFormFieldDataSources = (fieldName, value) =>
    setFormDataSources(prev => ({ ...prev, [fieldName]: value }))

  // let [formGeneratorContext, setFormGeneratorContext] = useState({
  //   formDataSources,
  //   setFormFieldDataSource
  // });


  // useEffect(() => {
  //   setConstructedInitialValues(fields.reduce((accu, next) => {
  //     accu[next.name] = next.initialValue ?? ""; return accu;
  //   }, {}))
  //   setConstructedValidationSchema(
  //     Yup.object(
  //       fields.reduce((accu, next) => { typeof (next.validation) === "function" && (accu[next.name] = next.validation(Yup)); return accu; }, {})
  //     )
  //   );
  //   // console.log("Form Values", formData);
  //   // console.log("Form Initial Values", initialValues);
  // }, []);






  // WOW REALLY NEED TO FIGURE HOW JS ASYNC WORKS AS WELL AS HOW THAT INTERACTS WITH REACT
  useEffect(() => {
    const fetchData = async () => {
      fields.forEach(
        async next => {
          typeof (next.initialDataSource) === "function" && setFormFieldDataSources(next.name, await next.initialDataSource());
        }
      )
    }
    // const fetchData = async () => {
    //   return fields.reduce(async (accu, next) => {
    //     typeof (next.initialDataSource) === "function" && (accu[next.name] = await next.initialDataSource()); return accu;
    //   }, {})
    // }

    const initialDataSoruces = fetchData()
    console.log("INITIAL DATASOURCES", initialDataSoruces)
  }, [])


  const constructedInitialValues =
    fields.reduce((accu, next) => {
      accu[next.name] = typeof (next.initialValue) === "function" ? next.initialValue() : ""; return accu;
    }, {})
  const constructedValidationSchema =
    Yup.object(
      fields.reduce((accu, next) => {
        typeof (next.validation) === "function" && (accu[next.name] = next.validation(Yup)); return accu;
      }, {})
    )

  // const initialDataSoruces = fields.reduce(async (accu, next) => {
  //   console.log(">>>>>>>>>>>>>>>>>>>>>>>>>", next.name, next)
  //   typeof (next.initialDataSource) === "function" && (accu[next.name] = await next.initialDataSource()); return accu;
  // }, {})
  // console.log("INITIAL DATASOURCES", initialDataSoruces)


  // setFormDataSources()
  // console.log("FORM INITED DATASOURCES", formDataSources)

  return (
    // <V2_Context.Provider value={formGeneratorContext}>
    <FGV2Context.Provider value={{ formDataSources, setFormFieldDataSources }}>
      {/* // To rerender the Formik component when initalvalues  */}
      {/* <div key={uuidv4()}> */}
      <div>
        <div className="text-xl font-bold">{header}</div>
        <div className="text-sm text-gray-500">{subHeader}</div>
        <div className="mt-5">
          <Formik
            initialValues={constructedInitialValues}
            validationSchema={constructedValidationSchema}
            onSubmit={
              (values, { setSubmitting }) => {
                onSubmit
                  ? onSubmit(values, { setSubmitting })
                  : setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    setSubmitting(false);
                  }, 400);
              }}
          >
            {
              (props) =>
                <Form>

                  {/* GENERATING FIELDS PROP */}
                  {/* <div className={`${styling.layout ?? "grid grid-cols-12 gap-2"}`}> */}
                  <div className={"grid grid-cols-12 gap-2"}>
                    <div className="col-span-12">
                      <div className={"grid grid-cols-12 gap-2"}>
                        {
                          _fieldsReduce(fields, styling.field)
                        }
                      </div>
                    </div>


                    {/* GENERATING SECTIONS PROP */}
                    {
                      sections.reduce(
                        (accu, section, i) => {

                          accu.push(
                            <section className="col-span-6">
                              <Widget
                                heading={section.header ?? "N/A"}
                                subHeading={section.subHeader ?? ""}
                              >
                                {/* <div className="text-lg font-bold">{}</div>
                              <div className="text-md font-semibold text-gray-500">{}</div> */}
                                {/* <div className={`${section.styling.layout ?? "grid grid-cols-12 gap-2"}`}> */}
                                <div className={"grid grid-cols-12 gap-2"}>
                                  {
                                    // GENERATING SECTION FIELDS
                                    _fieldsReduce(section.fields, section.styling.field ?? styling.field)
                                    // section.fields.reduce(

                                    //   (accu, field, i) => {
                                    //     const localStyling = section.styling ?? styling;

                                    //     if (i !== 0) {
                                    //       if (!localStyling.field.isCellSkipAlternate || (localStyling.field.isCellSkipAlternate && i % 2 !== 0)) {
                                    //         const cellSkip = field.styling?.cellSkip ?? localStyling.field.cellSkip;
                                    //         accu.push(Array.from({ length: cellSkip }, () => <div></div>))
                                    //       }
                                    //     }


                                    //     if (field.type === "text") {
                                    //       accu.push(<TextField name={field.name} label={field.label} styling={localStyling.field} {...field.fieldProps} />)
                                    //     }
                                    //     else if (field.type === "dropdown") {
                                    //       accu.push(<DropDownField name={field.name} styling={localStyling.field} {...field.fieldProps} />)
                                    //     }
                                    //     else if (field.type === "autoComplete") {
                                    //       accu.push(<AutoCompleteField name={field.name} styling={styling.field} {...field.fieldProps} />)
                                    //     }
                                    //     else if (field.type === "dateSelect") {
                                    //       accu.push(<DateSelectField name={field.name} styling={localStyling.field} {...field.fieldProps} />)
                                    //     }
                                    //     else {
                                    //       console.log("TX Dependents", field.fieldProps.dependents)
                                    //       accu.push(<TextField name={field.name} label={field.label} styling={localStyling.field} {...field.fieldProps} />)
                                    //     }

                                    //     return accu;
                                    //   },
                                    //   []
                                    // )
                                  }
                                </div>
                              </Widget>
                            </section>
                          )

                          return accu;

                        },
                        []
                      )
                    }
                  </div>


                  <div className="mt-5">
                    <button
                      type="submit"
                      className="rounded-2xl bg-orange-500 px-5 py-2 font-semibold text-white"
                    >
                      {submitButtonText}
                    </button>
                  </div>
                </Form >
            }
          </Formik>
        </div>
      </div>
      {/* </div> */}

    </FGV2Context.Provider>
  );
}

export default FormGeneratorV2;
