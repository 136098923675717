import Widget from "components/widget/Widget";
import {
  selectRefreshTrigger,
  setBreadCrumbs,
} from "features/navigation/NavigationSlice";
import customAxios from "middlewares/axios-interceptor/customAxios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { employeeMapper } from "pages/attendance/timesheet/EmployeeMapper";
import DataGrid from "components/data-grid/DataGrid";
import moment from "moment";
import { addHours } from "date-fns";
import AddTaskForm from "./components/AddTask";
import { selectTaskSheet } from "features/tasksheet/auth/tasksheet.selector";
import { getTSHRId } from "pages/attendance/timesheet/HelperFunctions";
import format from "date-fns-tz/format";
import { getTaskSheetIdFromHrId } from "./HelperFunctions";

const USERNAME = "9494";
const PASSWORD = "236FE197-7CC5-41F2-A01C-9EB36D8B7D38";

const auth = {
  username: USERNAME,
  password: PASSWORD,
};

export default function ViewEmployeeTimesheet({}) {
  console.log(employeeMapper)
  let { Name, EmployeeId } = useParams();
  let dispatch = useDispatch();
  let refreshTrigger = useSelector(selectRefreshTrigger);
  let [data, setData] = React.useState([]);
  let date = moment(new Date()).format("MM-DD-YY");
  let [taskAdded, setTaskAdded] = useState({})
  const isLoggedIn = useSelector(selectTaskSheet).isLoggedIn

  let TaskSheetEmployeeId = getTaskSheetIdFromHrId(EmployeeId)

  React.useEffect(() => {
    customAxios
      .get(
        `https://task.echesconsultancy.com:8088/repemptimesheet/` +
          EmployeeId +
          "/" +
          date,
        {
          auth: auth,
        }
      )
      .then((res) => {
        setData(res);
      });

    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Employees", link: "/employees/all" },
        { name: `${Name}`, link: `` },
        { name: "Timesheet", link: `` },
      ])
    );
  }, [taskAdded]);

  let array = [];
  data.map((d) => array.push(d.timeSpent));

  let total = addTimes(array);

  function addTimes(times) {
    let duration = 0;
    times.forEach((element) => {
      let [hours, minutes] = element.split(":");
      duration += parseInt(hours) * 60 + parseInt(minutes);
    });
    let hours = Math.floor(duration / 60);
    let minutes = duration % 60;
    return `${hours}:${minutes}`;
  }

  let columns = [
    {
      Header: "Task Name",
      accessor: "task",
    },

    {
      Header: "Activity",
      accessor: "activity",
    },
    {
      Header: "Remarks",
      accessor: "remarks",
    },
    {
      Header: "Customer",
      accessor: "customer",
    },

    {
      Header: "Started On",
      accessor: "startTime",
    },
    {
      Header: "Ended On",
      accessor: "endTime",
    },
    // {
    //   Header: "Completed On",
    //   accessor: "endDate",
    // },
    {
      Header: "Time spent",
      accessor: "timeSpent",
    },
    {
      Header: "Due Date",
      accessor: "dueDate",
    },
  ];

  let cellFunction = (cell) => {
    if (cell.column.id == "dueDate") {
      return moment(cell.value).format("DD MMM YYYY");
    }
    if (cell.column.id == "startDate") {
      return moment(cell.value).format("DD MMM YYYY");
    }
    if (cell.column.id == "timeSpent") {
      return moment(cell.value, "HH:mm:ss").format("H [hrs] m [mins]");
    }
    if (cell.column.id == "startTime" || cell.column.id == "endTime") {
      return format(new Date(cell.value), "HH:mm");
    }
    if (cell.column.id == "remarks") {
      return cell.value == null ? "N/A" : cell.value;
    }
    return cell.value;
  };

  const handleTaskAdded = (x) => setTaskAdded(x)

  return (
    <div className="grid grid-cols-4 grid-rows-2 overflow-auto h-full pb-10  gap-3 ">
      <div className="col-span-2 row-span-1">
        <Widget heading="Employee Timesheet today" className="bg-red-500">
          {data.length == 0 ? (
            <div className="text-center text-gray-500">No data found</div>
          ) : (
            <>
              <div className="flex flex-end w-full justify-start">
                <p className="font-medium text-sm text-slate-500">
                  Total Time Spent:
                  <span className="ml-2 text-orange-600 p-1 px-2 rounded-md  text-sm">
                    {moment(total, "H:mm").format("H [hrs] m [mins]")}
                  </span>
                </p>
              </div>

              <DataGrid
                showHeader={true}
                testData={data}
                columns={columns}
                cellFunction={cellFunction}
              />
            </>
          )}
        </Widget>
      </div>
      <div>
        <Widget
          heading={isLoggedIn ? `Add Timesheet for ${Name}` : `Log in to add timesheet for ${Name}`}
        >
            <AddTaskForm onTaskAdded={handleTaskAdded}/>
        </Widget>
      </div>
      <Outlet />
    </div>
  );
}
