export const columns = [
  // {
  //   Header: "ID",
  //   accessor: "id",
  // },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Department",
    accessor: "department",
  },
];
