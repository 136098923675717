import { selectBreadCrumbs } from "features/navigation/NavigationSlice";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function BreadCrumbs() {
  let BreadCrumbs = useSelector(selectBreadCrumbs);
  return (
    // <div className="text-slate-800 flex space-x-3 h-6 text-sm justify-start items-center">
    <div className="text-slate-800 flex h-6 space-x-1 text-sm justify-start items-center">
      {BreadCrumbs?.map((b, i) => {
        return (
          <div
            key={i}
            className="flex flex-row space-x-1 justify-start items-center"
          >
            {i === 0 ? null : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            )}
            <Link to={b.link}>
              <span className="group">
                <span className="group-hover:font-semibold transition-all duration-100">
                  {b.name}
                </span>
                <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 border-b-2 border-black"></span>
              </span>
            </Link>
          </div>
        );
      })}
    </div>
  );
}
