import { setSelectedEmployee } from "features/employees/EmployeesSlice";
import {
  setBreadCrumbs,
  setSelectedSubMenu,
  setNavigationButtons,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import TabLayout from "layout/TabLayout";
import customAxios from "middlewares/axios-interceptor/customAxios";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import SecondaryButton from "ui/buttons/SecondaryButton";
import { menuItems } from "./../MenuItems";


let tabItems = [
  {
    title: "All",
    path: "Type=All",
  },
  {
    title: "Active",
    path: "Type=Active",
  },
  {
    title: "Pending",
    path: "Type=Pending",
  },
  {
    title: "Completed",
    path: "Type=Completed",
  },
];
export default function TaskSheetTasks() {
  let dispatch = useDispatch();
  let { Name, EmployeeId } = useParams();
  let [empData, setEmpData] = React.useState({});

  React.useEffect(() => {
    dispatch(setSelectedSubMenu(menuItems[1]))
    dispatch(setSubHeader(Name));
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Employees", link: "/employees/all" },
        { name: `View ${Name}`, link: `` },
      ])
    );
    customAxios.get(`/employee/profile/${EmployeeId}`).then(
      (res) => dispatch(setSelectedEmployee(res)),
      (err) => console.log(err)
    );
  }, []);

  return <TabLayout rootPath={`/TaskSheet/Tasks/`} tabItems={tabItems} />;
}
