import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import MenuItems from "../MenuItems"
import Widget from "components/widget/Widget"
import {
    setBreadCrumbs,
    setSelectedSubMenu,
    setSubHeader,
} from "features/navigation/NavigationSlice";

import customAxios from "middlewares/axios-interceptor/customAxios"

import LeaveTypeSettings from "./leave-type-settings/LeavesTypeSettings"
import LeaveGroupSetting from "./leave-type-settings/LeaveGroupSettings"
import LeaveGroupLeaveTypeSettings from "./leave-type-settings/LeaveGroupLeaveTypeSettings"
import LeaveGroupEmployeeSettings from "./leave-type-settings/LeaveGroupEmployeeSettings"
import LeaveApprovalGroupSettings from "./leave-type-settings/LeaveApprovalGroupSettings"
import LeaveApprovalGroupEmployeeSettings from "./leave-type-settings/LeaveApprovalGroupEmployeeSettings"
import LeaveFormSettings from "./leave-type-settings/LeaveFormSettings";
import LeaveApprovalStageSettings from "./leave-type-settings/LeaveApprovalStageSettings";

import PageSection from "components/page-section/PageSection.tsx";

const CardHeaderVariant = ({
    headerText,
    subHeaderText,
    headerStyling = "text-slate-800 font-bold text-base",
    subHeaderStyling = "text-sm text-gray-400 whitespace-pre-wrap"
}) => {
    return (
        <div className="flex justify-between w-full">
            <div className={headerStyling}>{headerText}</div>
            <div className={subHeaderStyling}>{subHeaderText}</div>
        </div>
    )
}

// const PageSection = ({
//     header = "Section Header",
//     className = "max-height-[50%] w-full border-2 border-solid border-slate-400 rounded-xl p-4 pt-6 relative mt-6",
//     children }) => {
//     return (
//         <div className={className}>
//             <h3 className="absolute top-[-1rem] text-lg w-fit px-2 font-semibold bg-slate-200">
//                 {header}
//             </h3>
//             {/* <PageHDivder /> */}
//             <div className=" h-full overflow-hidden">
//                 {children}
//             </div>
//         </div>
//     )
// }



const PageHDivder = () => {
    return (
        <div className="w-full my-2 border-b-2 border-solid border-slate-400"></div>
    )
}


const LeaveSetting = () => {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setSelectedSubMenu(MenuItems.find(x => x.title === "Leave Settings")))
        dispatch(
            setBreadCrumbs([
                { name: "Home", link: "/" },
                { name: "Settings", link: "" },
                { name: "Leave Settings", link: "/Settings/Leaves" },
            ])
        );
    })



    let [selectedLeaveGroup, setSelectedLeaveGroup] = useState()
    let [selectedLeaveApprovalGroup, setSelectedLeaveApprovalGroup] = useState()
    let [selectedLeaveRequestForm, setSelectedLeaveRequestForm] = useState()

    // let [leaveTypes, setLeaveTypes] = useState([]);


    // useEffect(
    //     () => {
    //         customAxios.get("/leave/types").then((res) => {
    //             setLeaveTypes(res);
    //         });
    //     }
    //     , [])


    const sectionHeights = "h-[35rem]"




    return (
        <div className="flex flex-col gap-y-3 h-fit w-full 2xl:max-w-7xl px-3 pb-3">
            {/* <Widget
                heading={"Leave Settings"}
            > */}

            <PageSection header="Leave Type Settings" heightTwClass={sectionHeights}>
                <Widget
                    heading={"Leave Types"}
                >
                    <LeaveTypeSettings />
                </Widget>
            </PageSection>

            {/* <PageHDivder /> */}

            <PageSection header="Leave Group Settings" heightTwClass={sectionHeights}>
                <div className="flex h-full w-full gap-5">
                    <Widget
                        heading={"Leave Groups"}
                    >
                        <LeaveGroupSetting
                            setSelectedLeaveGroup={setSelectedLeaveGroup}
                        />
                    </Widget>

                    <Widget
                        headerRenderProp={
                            (widgetProperties) =>
                                <CardHeaderVariant
                                    headerText={"Leave Group Leave Types"}
                                    subHeaderText={selectedLeaveGroup && `[  ${selectedLeaveGroup.name}  |  Id: ${selectedLeaveGroup.id}  ]`}
                                />
                        }
                    >
                        <LeaveGroupLeaveTypeSettings
                            selectedLeaveGroupId={selectedLeaveGroup?.id}
                        />
                    </Widget>
                </div>
            </PageSection>
            {/* <PageHDivder /> */}
            <PageSection header="Leave Approval Settings" heightTwClass="h-fit">
                <div className="flex flex-col h-full w-full overflow-auto gap-5">
                    <PageSection header="Leave Applicaton Form Settings" heightTwClass={sectionHeights}>
                        <div className="flex h-full w-full gap-5 overflow-auto">
                            <Widget
                                heading={"Leave Application Forms"}
                            >
                                <LeaveFormSettings
                                    setSelectedLeaveRequestForm={setSelectedLeaveRequestForm}
                                />
                            </Widget>
                            <Widget
                                headerRenderProp={
                                    (widgetProperties) =>
                                        <CardHeaderVariant
                                            headerText={"Leave Approval Stages"}
                                            subHeaderText={selectedLeaveRequestForm && `[  ${selectedLeaveRequestForm.name}  |  Id: ${selectedLeaveRequestForm.id}  ]`}
                                        />
                                }
                            >
                                <LeaveApprovalStageSettings
                                    selectedLeaveRequestFormId={selectedLeaveRequestForm?.id}
                                    selectedLeaveRequestForm={selectedLeaveRequestForm}
                                />
                            </Widget>
                        </div>
                    </PageSection>
                    <PageSection header="Leave Approval Group Settings" heightTwClass={sectionHeights}>
                        <div className="flex h-full w-full gap-5">
                            <Widget
                                heading={"Leave Approval Groups"}
                            >
                                <LeaveApprovalGroupSettings
                                    setSelectedLeaveApprovalGroup={setSelectedLeaveApprovalGroup}
                                />
                            </Widget>
                            <Widget
                                heading={"Leave Approval Group Employees"}
                                headerRenderProp={
                                    (widgetProperties) =>
                                        <CardHeaderVariant
                                            headerText={"Leave Approval Stages"}
                                            subHeaderText={selectedLeaveApprovalGroup && `[  ${selectedLeaveApprovalGroup.name}  |  Id: ${selectedLeaveApprovalGroup.id}  ]`}
                                        />
                                }
                            >
                                <LeaveApprovalGroupEmployeeSettings
                                    selectedLeaveGroup={selectedLeaveApprovalGroup}
                                />
                            </Widget>
                        </div>
                    </PageSection>
                </div>
            </PageSection>

        </div >
    )
}

export default LeaveSetting