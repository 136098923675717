export const columns = [
  // {
  //   Header: "ID",
  //   accessor: "id",
  // },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Employee Id",
    accessor: "empCode",
  },
  {
    Header: "NIC / Passport No",
    accessor: "identificationNumber",
  },
  {
    Header: "Country",
    accessor: "nationality",
  },
  {
    Header: "Designation",
    accessor: "designation",
  },
  {
    Header: "Department",
    accessor: "department",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Employee Type",
    accessor: "empType",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Date Joined",
    accessor: "dateJoined",
  },
];
