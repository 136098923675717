import React from "react";
import FormGenerator from "components/form-generator/FormGenerator";
import customAxios from "middlewares/axios-interceptor/customAxios";
import * as Yup from "yup";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";

let variables = JSON.parse(localStorage.getItem("variables"));

export default function AddEducation() {
  let { EmployeeId } = useParams();
  let navigate = useNavigate();

  const FormData = {
    contents: [
      {
        heading: "Add a new Qualification",
        description: null,
        function: () => {
          return <div></div>;
        },
        fields: [
          {
            name: "university",
            displayName: "University",
            type: "field",
            required: true,
          },

          {
            name: "country",
            displayName: "Country",
            type: "field",
            required: true,
          },

          {
            name: "awardType",
            displayName: "Award Type",
            type: "comboBox",
            required: true,
            values: [
              { id: 1, name: "Certificate" },
              { id: 2, name: "Diploma" },
              { id: 3, name: "Degree" },
              { id: 4, name: "Masters" },
              { id: 5, name: "PhD" },
            ],
            value: (formik) => {
              return formik.values.awardType;
            },
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },
          {
            name: "fieldOfStudy",
            displayName: "Field of Study",
            type: "field",
            required: true,
          },
          {
            name: "startDate",
            displayName: "Start Date",
            type: "dateSelect",
            required: true,
            value: (formik) => {
              return formik.values.startDate;
            },
          },
          {
            name: "endDate",
            displayName: "End Date",
            type: "dateSelect",
            required: true,
            value: (formik) => {
              return formik.values.endDate;
            },
          },
          {
            name: "programmeName",
            displayName: "Programme Name",
            type: "field",
            required: true,
          },
          {
            name: "grade",
            displayName: "Grade",
            type: "field",
            required: true,
          },
          {
            name: "isHighest",
            displayName: "Is Highest",
            type: "checkBox",
            required: true,
          },
        ],
      },
    ],
    initialValues: {
      university: "",
    },
    validationSchema: Yup.object().shape({
      university: Yup.string().required("University is required"),
    }),
    onSubmit: async (
      values,
      { setSubmitting, setErrors, setStatus, resetForm, handleSubmit }
    ) => {
      const data = {
        university: values.university,
        country: values.country,
        awardType: values.awardType.name,
        fieldOfStudy: values.fieldOfStudy,
        startDate: moment(values.startDate).format("YYYY-MM-DD"),
        endDate: moment(values.endDate).format("YYYY-MM-DD"),
        programmeName: values.programmeName,
        grade: values.grade,
        isHighest: values.isHighest,
      };
      customAxios
        .post(
          `https://api.echeshr.com/api/v2/employee/${EmployeeId}/education`,
          [data]
        )
        .then((res) => navigate(-1));
    },
  };

  return <FormGenerator formData={FormData} />;
}
