import {
  setBreadCrumbs,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import customAxios from "middlewares/axios-interceptor/customAxios";
import React from "react";
import { useDispatch } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import EmployeeDocumentExpiry from "widgets/employee/employee-card/EmployeeDocumentExpiry";
import EmployeeDocuments from "widgets/employee/employee-card/EmployeeDocuments";
import EmployeeLoanRepaymentHistory from "widgets/loans/EmployeeLoanRepaymentHistory";
import EmployeeLoans from "widgets/loans/EmployeeLoans";

export default function ViewEmployeeLoans() {
  let { Name, EmployeeId, Modal } = useParams();
  let [loading, setLoading] = React.useState(false);
  let [documents, setDocuments] = React.useState([]);

  let dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      setSubHeader(Modal ? "Add Loan" : Name),
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Employees", link: "/employees/all" },
        { name: `${Name}`, link: `` },
        { name: "Loans", link: `` },
      ])
    );
  }, [Modal]);

  return (
    <>
      {!Modal ? (
        <div className="grid grid-cols-4 overflow-auto h-[80vh] pb-10  gap-2 ">
          <div className="col-span-3">
            <EmployeeLoans />
          </div>
          <div className="col-span-1 grid grid-rows-3 gap-y-2">
            <div className="row-span-2">
              <EmployeeLoanRepaymentHistory />
            </div>
            <EmployeeDocumentExpiry Data={documents} />
          </div>
        </div>
      ) : (
        <Outlet className="w-full" />
      )}
    </>
  );
}
