import { setBreadCrumbs } from "features/navigation/NavigationSlice";
import customAxios from "middlewares/axios-interceptor/customAxios";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import EmployeeJobHistory from "widgets/employee/employee-card/EmployeeJobHistory";

export default function ViewEmployeeJobs() {
  let { Name, EmployeeId } = useParams();
  let [jobs, setJobs] = React.useState([]);
  let [loading, setLoading] = React.useState(true);
  let dispatch = useDispatch();

  React.useEffect(() => {
    customAxios.get(`/employee/profile/${EmployeeId}`).then(
      (res) => {
        setJobs(
          res.employments.map((e) => {
            return {
              department: e.department ?? "N/A",
              designation: e.designation ?? "N/A",
              placeOfWork: e.placeOfWork ?? "N/A",
              startDate: moment(e.startDate).format("DD MMMM YYYY") ?? "N/A",
              endDate: moment(e.endDate).format("DD MMMM YYYY") ?? "N/A",
            };
          })
        );
        setLoading(false);
      },
      (err) => console.log(err)
    );
  }, []);

  React.useEffect(() => {
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Employees", link: "/employees/all" },
        { name: `${Name}`, link: `` },
        { name: "Jobs", link: `` },
      ])
    );
  }, []);

  return (
    <div className="grid grid-cols-4 overflow-auto h-[75vh] pb-10  gap-2 ">
      <div className="col-span-3">
        <EmployeeJobHistory Data={jobs} />
      </div>
    </div>
  );
}
