import DataGrid from "components/data-grid/DataGrid";
import Widget from "components/widget/Widget";
import React from "react";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "ui/buttons/SecondaryButton";

export default function EmployeeDocuments({ Data }) {
  let url = window.location.pathname;
  let navigate = useNavigate();
  return (
    <Widget
      heading={"Employee Documents"}
      component={function () {
        return (
          <div className="flex flex-col space-y-3">
            <SecondaryButton>
              <button
                onClick={() => {
                  navigate(url + "/AddDocument/Modal=true");
                }}
                className="text-orange-600 "
              >
                Add Document
              </button>
            </SecondaryButton>
          </div>
        );
      }}
    >
      <DataGrid
        columns={[
          {
            Header: "Document Type",
            accessor: "documentType",
          },
          {
            Header: "Document Number",
            accessor: "documentNumber",
          },
          {
            Header: "Expiry Date",
            accessor: "expiryDate",
          },
          {
            Header: "Issue Date",
            accessor: "issueDate",
          },
          {
            Header: "Issue Country",
            accessor: "issueCountry",
          },
          {
            Header: "Issued By",
            accessor: "issuedBy",
          },
          {
            Header: "Attachment",
            accessor: "attachment",
          },
        ]}
        testData={Data}
        cellFunction={(cell) => {
          if (cell.column.Header === "Attachment") {
            return (
              <a
                href
                onClick={(e) => {
                  e.preventDefault();
                  window.open(cell.value);
                }}
              >
                View
              </a>
            );
          }

          return cell.value;
        }}
        showHeader={true}
      />
    </Widget>
  );
}
