import { useState, useEffect } from "react"
import DataGrid, { CONSTANTS as GridConstants } from "components/data-grid/DataGrid"
import customAxios from "middlewares/axios-interceptor/customAxios"


const LeaveGroupLeaveTypeSettings = ({ selectedLeaveGroupId, selectedLeaveGroup, leaveTypes }) => {
    let [isLoading, setIsLoading] = useState(true);
    let [data, setData] = useState([]);
    let [_selectedLeaveGroup, set_SelectedLeaveGroup] = useState(selectedLeaveGroup ?? {});
    let [fdtLeaveTypes, setFdtLeaveTypes] = useState([]);

    const refreshGridData = (row) => {
        setIsLoading(true)
        if (selectedLeaveGroupId) {
            customAxios.get(`/group/${selectedLeaveGroupId}`)
                .then((res) => {
                    set_SelectedLeaveGroup(res)
                    const mappedData = res?.leaveTypeGroups.map(x => ({ ...x, name: fdtLeaveTypes.find(y => y.id === x.leaveTypeId)?.name }))
                    setData(mappedData ?? []);
                    setIsLoading(false)
                });
        }
    }

    useEffect(() => {
        customAxios
            .get("/leave/types")
            .then((res) => {
                setFdtLeaveTypes(res);
            });
    }, [])

    useEffect(() => {
        setTimeout(() => {
            refreshGridData()
        }, 0);
    }, [selectedLeaveGroupId, fdtLeaveTypes])


    const hideOnAdd = true;
    const hideOnEdit = true;


    const columns = [
        {
            accessor: "leaveGroupId",
            Header: "LeaveGroupId",
            hideOnAdd: true,
            hideOnEdit: true,
            formFieldProps:
            {
                // validation: (yup) => yup.string().required("Date is required"),
                // type: "dateSelect",
                // initialValue: () => dayjs().format()
            }
        },
        {
            accessor: "leaveTypeId",
            Header: "LeaveTypeId",
            formFieldProps:
            {
                displayName: "Leave Type",
                type: "autoComplete",
                validation: (yup) => yup.number().required("Leave Type to add is required"),
                initialDataSource: async () => {
                    const res = await customAxios.get("/leave/types");
                    const existingLeaveTypeIds = data.map(x => x.leaveTypeId) ?? [];
                    return existingLeaveTypeIds
                        ? res?.filter(x => !existingLeaveTypeIds.includes(x.id))
                        : res
                },
                fieldProps: {
                    label: "Leave Type",
                    optionValue: "id",
                    nullable: false,
                    fieldDisplay: (x) => `${x.name}`,
                    optionDisplay: (x) =>
                        <div className="grid grid-cols-12 justify-center items-center gap-2">
                            <span className="col-span-1">{x.id}</span>
                            <span className="col-span-5">{x.name}</span>
                        </div>,
                    // onBlur:
                    //   ({ fieldValueOnFocus, fieldName, formValues, setFormFieldValue, formDataSources, setFormFieldDataSources }) => {
                    //     // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>TRACER>>>>>>>>>>", formDataSources)
                    //     // fetchDataSource(setInternalDataSource)
                    //     if (fieldValueOnFocus !== formValues[fieldName]) {
                    //       const route = `/employee/shifts/${formValues["employeeId"]}`
                    //       console.log(route)
                    //       customAxios
                    //         .get(route)
                    //         .then((res) => {
                    //           setFormFieldValue("start", res[0].startOn)
                    //           setFormFieldValue("end", res[0].endOn)
                    //           setFormFieldValue("checkIn", res[0].startOn)
                    //           setFormFieldValue("checkOut", res[0].endOn)
                    //           console.log(res)
                    //         })
                    //     }
                    //   }
                    // <div className="w-full">{`${x.id} >> ${x.name}`}</div>,
                }
            }
        },
        {
            accessor: "name",
            Header: "Leave Type Name",
            hideOnAdd: true,
            hideOnEdit: true,
            formFieldProps:
            {
            }
        },

    ];



    const onActionBegin = (args) => {
        // console.log("ACTION BEGIN", args)
        if (args.requestType === GridConstants.GRID_ACTION_SAVE) {

            const updateLeaveGroupPayload =
            {
                ..._selectedLeaveGroup,
                policies: null,
                leaveTypeGroups: null,
                leaveTypes: _selectedLeaveGroup.leaveTypeGroups.map(x => x.leaveTypeId)
            }

            const leaveTypeId = args.entity.leaveTypeId;

            if (args.action === GridConstants.ACTION_ADD) {

                updateLeaveGroupPayload.leaveTypes.push(leaveTypeId)

            } else if (args.action === GridConstants.ACTION_DELETE) {

                const indexToRemove = updateLeaveGroupPayload.leaveTypes.indexOf(leaveTypeId);
                updateLeaveGroupPayload.leaveTypes.splice(indexToRemove, 1);

            }

            console.log("SAVE >> ADD || DELETE", updateLeaveGroupPayload)

            customAxios
                .put("/group/update", updateLeaveGroupPayload)
                .then(() => { refreshGridData(); });
        }
    }



    return (
        isLoading
            ? <div className="h-full w-full rounded-lg bg-slate-200 animate-pulse"></div>
            : <DataGrid
                header="Leave Groups"
                showHeader={true}
                cellFunction={cell => cell.render("Cell")}
                columns={columns}
                testData={data}
                setData={setData}
                allowAdd={true}
                // allowEdit={true}
                // allowEditRowOnDoubleClick={true}
                allowDelete={true}
                confirmOnDelete={true}
                onActionBegin={onActionBegin}
            />
    )
}

export default LeaveGroupLeaveTypeSettings