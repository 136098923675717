import FormCreator from "components/form-creator/FormCreator";
import * as Yup from "yup";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectGeneralData, setGeneralData } from "features/groups/GroupsSlice";

export default function GeneralInformation() {
  let dispatch = useDispatch();
  let generalData = useSelector(selectGeneralData);
  const initialValues = {
    name: generalData?.name || "",
    description: generalData?.description || "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  });

  const onSubmit = (values) => {
    dispatch(setGeneralData(values));
  };

  const data = [
    {
      heading: "General Information",
      fields: [
        {
          name: "name",
          type: "text",
          placeholder: "Name",
          value: "name",
        },
        {
          name: "description",
          type: "text",
          component: "textarea",
          placeholder: "Description",
          value: "description",
        },
      ],
    },
  ];

  return (
    <FormCreator
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      data={data}
    />
  );
}
