import React from "react";
import { columns } from "./columns";
import moment from "moment";
import DataTable from "../../../../../../components/data-grid/DataGrid";
import customAxios from "../../../../../../middlewares/axios-interceptor/customAxios";
import { useParams } from "react-router-dom";

let init = JSON.parse(localStorage.getItem("init"));
let leaveTypes = init?.TypeLists?.filter((item) => item.type === 5);
let employees = JSON.parse(localStorage.getItem("employees"));

export default function ConflictingLeave({ LeaveId }) {
  let [rowData, setRowData] = React.useState();
  let [data, setData] = React.useState([]);
  let [loading, setLoading] = React.useState(true);
  let { id } = useParams();
  console.log(id);
  React.useEffect(() => {
    customAxios
      .get(`/leave/get-conflicts/${id}`)
      .then((res) => arrangeData(res));
  }, []);

  async function arrangeData(items) {
    let tempData = [];
    console.log(items);
    items?.map((d) => {
      tempData.push({
        id: d.id ?? "N/A",
        employeeId: d.employeeId ?? "N/A",
        name: d.employee?.name ?? "N/A",
        leaveType: d.leaveType?.name ?? "N/A",
        startDate: moment(d.from ?? new Date()).format("DD MMMM yyyy"),
        endDate: moment(d.to ?? new Date()).format("DD MMMM yyyy"),
        effectiveQty: d.effectiveQty,
        remarks: d.remarks,
        status: d.status,
        createdAt: moment(d.createdAt ?? new Date()).format("DD MMMM yyyy"),
      });
    });

    localStorage.setItem("employees", JSON.stringify(tempData));

    setData(tempData);
    setLoading(false);
  }

  function cellFunction(cell) {
    console.log(cell);
    if (cell.column.id == "remarks") {
      return <div className="w-[200px] text-limit">{cell.value}</div>;
    } else if (cell.column.id == "status") {
      return (
        <div className="flex justify-start items-center text-center ">
          <p
            style={{ backgroundColor: cell.value == "approved" ? "#22c55e" : cell.value == "rejected" ? "#f52c2c" : cell.value == "closed" ? "#808080" : "#f59d40" }}
            className="py-0.4 w-20 px-5 rounded-full text-white flex items-center justify-center"
          >
            {cell.value ?? "N/A"}
          </p>
        </div>
      );
    } else if (cell.column.id == "documents") {
      return (
        <div className="flex flex-col space-y-1 justify-start">
          {cell.value?.map((document, i) => (
            <div key={i}>
              <div className="text-orange-800 hover:underline cursor-pointer">
                {document}
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return cell.render("Cell");
    }
  }

  return (
    <div className="h-full ">
      {loading ? (
        <p>Loading</p>
      ) : (
        <div className="h-full">
          {data.length > 0 ? (
            <DataTable
              showHeader={true}
              cellFunction={cellFunction}
              columns={columns}
              testData={data}
              height={"h-[84vh]"}
            />
          ) : (
            <div className="flex flex-col justify-center h-full items-center text-sm ">
              <img
                src="https://cdn.dribbble.com/users/9734/screenshots/3751250/illi-inbox-zero2.png"
                className="h-32 w-40"
              />
              <p>No conflicting leaves</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
