const columns = [
    {
        Header: "Id",
        accessor: "id",
        hideOnAdd: true,
        hideOnEdit: true
    },
    {
        Header: "DepartmentId",
        accessor: "departmentId",
        formFieldProps:
        {
            displayName: "Department",
            required: true,
            validation: (yup) => yup.object().required("Department is required"),
            type: "comboBox",
            values: async (customAxios) => { const res = await customAxios.get("/department"); return res; },
            // values: () => { return [] },
            // value: (formik) => {
            //     return formik.values.departmentId.id;
            // },
            options: (item) => {
                return (
                    <div className="grid grid-cols-12 justify-center items-center gap-2">
                        <span className="col-span-1">{item.id}</span>
                        <span className="col-span-5">{item.name}</span>
                        <span className="col-span-6 ">
                            {item.designation ?? "N/A"}
                        </span>
                    </div>
                );
            }
        }
    },
    {
        Header: "Department",
        accessor: "department",
        hideOnAdd: true,
        hideOnEdit: true
    },
    {
        Header: "Name",
        accessor: "name",
        formFieldProps:
        {
            validation: (yup) => yup.string().required("Name is required"),
            required: true
        }
    },
    // {
    //     Header: "First Name",
    //     accessor: "firstName",
    //     // validation: (yup) => yup.string().required("Name is required").min(5, "Must be at least 5 charaters long"),

    // },
]

export default columns