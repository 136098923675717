export const menuItems = [
  {
    title: "Overview",
    path: "/Groups/Overview",
  },
  {
    title: "All Groups",
    path: "/Groups/All",
  },
  {
    title: "Departments",
    path: "/Groups/Department",
  },
  {
    title: "Employee Groups",
    path: "/Groups/Employee",
  },
  {
    title: "Division Groups",
    path: "/Groups/Division",
  },
];
