import React, { Fragment, useEffect, useState } from "react";
import { PlusIcon, XIcon } from "@heroicons/react/solid";
import { columns } from "./columns";
import DataGrid, { CONSTANTS as GridConstants } from "../../../components/data-grid/DataGrid";
import customAxios from "../../../middlewares/axios-interceptor/customAxios";
import GlobalLayout from "layout/GlobalLayout";
import DropDownButton from "ui/buttons/DropDownButton";
import {
  setBreadCrumbs,
  setSelectedSubMenu,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import { useDispatch } from "react-redux";
import { menuItems } from "./../MenuItems";
import Widget from "components/widget/Widget";
import PrimaryButton from "ui/buttons/PrimaryButton";
import FormGenerator from "components/form-generator/FormGenerator";
import { setOpen } from "features/notifications/NotificationSlice";
import SideDrawer from "components/side-drawer/SideDrawer";
import { v4 as uuidv4 } from 'uuid';
import Addresses from "pages/employees/add-employee/components/Addresses";
import * as Yup from "yup";


export default function AttendanceSignInLogs() {

  let [isLoading, setIsLoading] = useState(true);
  let [data, setData] = React.useState([]);

  const refreshGridData = (row) => {
    setIsLoading(true)
    customAxios.get("/attendance?Page=1&Size=100&Id=54&From=2023-01-01&To=2023-01-31&IsSortAscending=true&SortBy=").then((res) => {
      // console.log("ATTENDANCE FETCH", res.items)
      setData(res.items);
      setIsLoading(false)
    });
  }


  useEffect(() => { refreshGridData() }, [])

  const onActionBegin = (args) => {

    console.log("ONACTIONBEGIN EXT");
    if (args.requestType === GridConstants.GRID_ACTION_SAVE) {

      if (args.action === GridConstants.ACTION_ADD) {
        console.log("ATTENDANCE ADD VALUES", args.entity)
        // const payload =
        // {
        //   ...args.entity,
        //   type: "Department"
        // }
        // console.log("SAVE>ADD", payload)
        // customAxios
        //   .post("/group/create", payload)
        //   .then(() => { refreshGridData(); });

      }

      else if (args.action === GridConstants.ACTION_UPDATE) {

        console.log("DATA GRID EDIT SUBMIT");
        // const payload =
        // {
        //   ...args.entity
        // }

        // console.log("SAVE>UPDATE", payload)
        // // const { owner: _, ...refinedPayload } = payload;

        // console.log(payload);
        // customAxios
        //   .put("/group/update", payload)
        //   .then(() => {
        //     customAxios.get("/group/type/department").then((res) => {
        //       setData(res);
        //     });
        //   });

      }

      else if (args.action === GridConstants.ACTION_DELETE) {

        console.log("SAVE>DELETE")
        //   const entityId = args.entity.id;
        //   customAxios
        //     // .delete("/group/delete", { data: payload })
        //     .delete(`/group/delete/${entityId}`)
        //     .then(() => {
        //       customAxios.get("/group/type/department")
        //         .then((res) => {
        //           setData(res);
        //         });
        //     });

        // }

      }

    }
  }


  return (
    <div className="h-full">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="flex flex-col h-full w-full">
          <Widget heading={"Attendance Logs"}>
            <DataGrid
              header="Attendance Logs"
              showHeader={true}
              cellFunction={cell => cell.render("Cell")}
              columns={columns}
              testData={data}
              setData={setData}
              allowAdd={true}
              allowEdit={true}
              allowEditRowOnDoubleClick={true}
              allowDelete={true}
              confirmOnDelete={true}
              onActionBegin={onActionBegin}
            />
          </Widget>
        </div>
      )}
    </div>
  );
}

