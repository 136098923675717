import Widget from "components/widget/Widget";
import {
  selectRefreshTrigger,
  setBreadCrumbs,
} from "features/navigation/NavigationSlice";
import customAxios from "middlewares/axios-interceptor/customAxios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import EmployeeAddresses from "widgets/employee/employee-card/EmployeeAddresses";
import EmployeeContacts from "widgets/employee/employee-card/EmployeeContacts";
import EmployeeEducation from "widgets/employee/employee-card/EmployeeEducation";
import EmployeeExperience from "widgets/employee/employee-card/EmployeeExperience";
import EmployeePersonalInformation from "widgets/employee/employee-card/EmployeePersonalInformation";
import moment from "moment";

export default function ViewEmployeePersonal() {
  let { Name, EmployeeId } = useParams();
  let [loading, setLoading] = React.useState(false);
  let [employeePersonalData, setEmployeePersonalData] = React.useState({});
  let [employeeEducation, setEmployeeEducation] = React.useState([]);
  let [employeeContacts, setEmployeeContacts] = React.useState([]);
  let [employeeAddresses, setEmployeeAddresses] = React.useState([]);
  let [employeeExperience, setEmployeeExperience] = React.useState([]);
  let dispatch = useDispatch();
  let refreshTrigger = useSelector(selectRefreshTrigger);
  let [trigger, setTrigger] = React.useState(refreshTrigger);

  React.useEffect(() => {
    customAxios.get(`/employee/profile/${EmployeeId}`).then(
      (res) => {
        setEmployeePersonalData({
          fullName: res?.title + ". " + res?.name ?? `N/A`,
          firstName: res?.firstName ?? "N/A",
          lastName: res?.lastName ?? "N/A",
          middleName: res?.middleName ?? "N/A",
          iDCardNumber: res?.idNumber ?? "N/A",
          nationality: res?.nationality ?? "N/A",
          gender: res.gender.value ?? "N/A",
          maritalStatus: res.maritalStatus ?? "N/A",
          dateOfBirth: moment(res?.dateOfBirth).format("DD MMMM YYYY") ?? "N/A",
        });

        setEmployeeEducation(
          res.educations.map((e) => {
            return {
              awardType: e.awardType ?? "N/A",
              programmeName: e.programmeName ?? "N/A",
              fieldOfStudy: e.fieldOfStudy ?? "N/A",
              grade: e.grade ?? "N/A",
              university: e.university ?? "N/A",
              location: e.country ?? "N/A",
              startDate: moment(e.startDate).format("DD MMMM YYYY") ?? "N/A",
              endDate: moment(e.endDate).format("DD MMMM YYYY") ?? "N/A",
            };
          })
        );

        setEmployeeContacts(res.contacts);

        setEmployeeAddresses(
          res.addresses.map((e) => {
            return {
              addressType: e.addressType ?? "N/A",
              apartmentNumber: e.appartmentNo ?? "N/A",
              block: e.block ?? "N/A",
              building: e.bulding ?? "N/A",
              street: e.street ?? "N/A",
              city: e.city ?? "N/A",
              country: e.country ?? "N/A",
              postalCode: e.postalCode ?? "N/A",
              isDefaut: e.isDefault ?? "N/A",
            };
          })
        );

        setEmployeeExperience(
          res.experiences.map((e) => {
            return {
              companyName: e.companyName ?? "N/A",
              employmentTypes: e.employmentType ?? "N/A",
              title: e.title ?? "N/A",
              location: e.location ?? "N/A",
              startDate: moment(e.startDate).format("DD MMMM YYYY") ?? "N/A",
              endDate: moment(e.endDate).format("DD MMMM YYYY") ?? "N/A",
            };
          })
        );

        setLoading(false);
      },
      (err) => console.log(err)
    );
  }, [trigger, refreshTrigger]);

  React.useEffect(() => {
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Employees", link: "/employees/all" },
        { name: `${Name}`, link: `` },
        { name: "Personal", link: `` },
      ])
    );
  }, []);

  return (
    <div className="grid grid-cols-4 overflow-auto h-[75vh] pb-10  gap-3 ">
      <div className="col-span-4">
        {loading ? (
          <div className="h-96 w-full flex justify-center items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        ) : (
          <EmployeePersonalInformation Data={employeePersonalData} />
        )}
      </div>
      <div className="col-span-4">
        <EmployeeAddresses Data={employeeAddresses} />
      </div>
      <div className="col-span-4">
        <EmployeeContacts contacts={employeeContacts} />
      </div>
      <div className="col-span-4">
        <EmployeeEducation education={employeeEducation} />
      </div>
      <div className="col-span-4">
        <EmployeeExperience Experience={employeeExperience} />
      </div>
      <Outlet />
    </div>
  );
}
