import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";

export default function DropDownButton({
  items,
  title,
  icon,
  className,
  setSelected,
  path,
}) {
  return (
    <div>
      <Menu>
        <div>
          <Menu.Button className="px-4 p-1.5 w-fit text-sm border border-slate-300 flex flex-row space-x-3 items-center justify-center text-slate-50 bg-orange-400 font-semibold rounded-xl shadow-md hover:brightness-95">
            <div className="">{icon}</div>
            <p>{title}</p>
            <ChevronDownIcon
              className="ml-2 -mr-1 h-4 w-4"
              aria-hidden="true"
            />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute rounded-md mt-1 p-3 z-50 right-3  w-56  origin-bottom-right divide-y divide-gray-100  bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {items?.map((item, i) => (
                <Menu.Item key={i}>
                  {({ active }) => (
                    <Link
                      onClick={() => {
                        setSelected(item);
                      }}
                      to={`${path}${item.name}`}
                      className={`${
                        active
                          ? "bg-orange-100 rounded-md text-black"
                          : "text-gray-900"
                      } group flex w-full items-center px-2 py-2 text-xs`}
                    >
                      {item.name ?? "N/A"}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </div>
      </Menu>
    </div>
  );
}
