import Widget from "components/widget/Widget"
import { useEffect, useState } from "react"
import AssignAllowancesForm from "./AssignAllowanceForm"
import customAxios from "middlewares/axios-interceptor/customAxios"
import { useDispatch } from "react-redux"
import { addNotification } from "features/notifications/NotificationSlice"
import FormWrapper from "pages/employees/view-employee/views/timesheet/components/FormWrapper"
import { setSubHeader } from "features/navigation/NavigationSlice"

const defaultFormFields = {
    // hidden
    IsShownInPaySlip: 1,
    IsTaxApplicable: 0,
    State: 0,
    // not hidden
    Employee: -1,
    Allowance: -1,
    Amount: -1,
    Description: "",
    Currency: 462,
    StartDate: null,
    EndDate: null
}

const AssignAllowances = () => {
    const [formFields, setFormFields] = useState(defaultFormFields)
    const [errorMessage, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setSubHeader("Assign Allowance"))
    }, [])

    const handleFormChange = (event) => {
        setFormFields({
            ...formFields,
            [event.target.name]: event.target.value
        })
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        console.log(formFields)
        setLoading(true)
        const res = await customAxios.post("/some-endpoint", formFields)
        const hasError = res?.response?.status ?? ""

        if(hasError)
        {
            let errorMessage = res.message
            setErrorMessage(errorMessage)
        }
        else
        {
            dispatch(addNotification({
                id: 1,
                heading: `Allowance Assigned Successfully`
            }))
        }

        setLoading(false)
    }   

    return (
        <div className="w-full min-h-96 max-h-full relative overflow-y-auto">
            <Widget>
                <FormWrapper loading={loading} errorMessage={errorMessage}>
                    <AssignAllowancesForm onChange={handleFormChange} onSubmit={handleSubmit} />
                </FormWrapper>
            </Widget>
        </div>
    )
}

export default AssignAllowances