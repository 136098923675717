const MenuItems = [
  {
    title: "Employees Settings",
    path: "/Settings/Employees",
  },
  {
    title: "Designation Settings",
    path: "/Settings/Designations",
  },
  {
    title: "Attendance Settings",
    path: "/Settings/Attendance",
  },
  {
    title: "Leave Settings",
    path: "/Settings/Leave",
  },
  {
    title: "Payroll Settings",
    path: "/Settings/Payroll",
  },
  {
    title: "Group Settings",
    path: "/Settings/Group",
  },
  {
    title: "Reports Settings",
    path: "/Settings/Reports",
  },
  {
    title: "Calendar Settings",
    path: "/Settings/Calendar",
  },
];

export default MenuItems;


// const MenuItems = [
//   {
//     name: null,
//     menuItems: [
//       {
//         name: "Overview",
//         selected: true,
//         route: "/settings/main",
//       },
//       ,
//     ],
//   },
//   {
//     name: "Leave Settings",
//     menuItems: [
//       {
//         name: "Leave Types",
//         selected: true,
//         route: "/settings/leaves/leave-types",
//       },
//       {
//         name: "Leave Groups",
//         selected: true,
//         route: "/settings/leaves/leave-groups",
//       },
//       ,
//     ],
//   },
//   {
//     name: "Approval Settings",
//     menuItems: [
//       {
//         name: "Approval Groups",
//         selected: true,
//         route: "/settings/approvals/groups",
//       },
//       {
//         name: "Approval Stages",
//         selected: true,
//         route: "/settigs/approvals/stages",
//       },
//     ],
//   },
//   {
//     name: "Calendar Settings",
//     menuItems: [
//       {
//         name: "Calendars",
//         selected: true,
//         route: "/leaves/calendar/main",
//       },
//       // {
//       //   name: "Balance Adjustment",
//       //   selected: true,
//       //   route: null,
//       // },
//     ],
//   },
// ];