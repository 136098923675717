import { XIcon } from "@heroicons/react/solid";
import React from "react";
import { Link } from "react-router-dom";
import BreadCrumbs from "../navigation/bread-crumbs/BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { Tab } from "@headlessui/react";
import NavigationBar from "../navigation/nav-bar/NavigationBar";
import GlobalCard from "../../ui/GlobalCard";
import Header from "ui/Typography/Header";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ModalLayout({
  stages,
  selectTab,
  setSelectedTab,
  heading,
  component,
  width,
  onClose,
  children,
  setIsOpen,
}) {
  let dispatch = useDispatch();

  let selectedTab;

  React.useEffect(() => {
    if (stages) {
      dispatch(setSelectedTab(stages[0]));
    }
  }, []);

  return (
    <>
      <div
        className={`${width ?? "w-screen"}  h-full  flex flex-col space-y-3`}
      >
        <div className="h-[82vh] overflow-auto">{children}</div>
      </div>
    </>
  );
}
