import { getTSHRId } from "./HelperFunctions";

export const employeeMapper = [
  // Eches
  {
    taskName: "Afnan",
    taskId: 27,
    hrName: "Mohamed Afnan",
    hrId: 57,
    tenant: "Eches",
  },
  {
    taskName: "Ameel",
    taskId: 29,
    hrName: "Ameel Ismail",
    hrId: 61,
    tenant: "Eches",
  },
  {
    taskName: "Anaan",
    taskId: 15,
    hrName: "Fathimath Anaan",
    hrId: 37,
    tenant: "Eches",
  },
  {
    taskName: "Arusham",
    taskId: 26,
    hrName: "Mohamed Arusham Hussain",
    hrId: 56,
    tenant: "Eches",
  },
  {
    taskName: "Azuhaan",
    taskId: 32,
    hrName: "Ismail Azuhaan",
    hrId: 62,
    tenant: "Eches",
  },
  {
    taskName: "Bob",
    taskId: 24,
    hrName: "Mohamed Abdulla",
    hrId: 50,
    tenant: "Eches",
  },
  {
    taskName: "Falaah",
    taskId: 12,
    hrName: "Hussain Falaah",
    hrId: 40,
    tenant: "Eches",
  },
  {
    taskName: "Hanaan",
    taskId: 16,
    hrName: "Aminath Hanan Shafeeu",
    hrId: 41,
    tenant: "Eches",
  },
  {
    taskName: "Ibbe",
    taskId: 6,
    hrName: "Ibrahim Rasheed",
    hrId: 35,
    tenant: "Eches",
  },
  {
    taskName: "Inaan",
    taskId: 31,
    hrName: "Ali Inaan",
    hrId: 60,
    tenant: "Eches",
  },
  {
    taskName: "Maahil",
    taskId: 13,
    hrName: "Maahil Mohamed",
    hrId: 36,
    tenant: "Eches",
  },
  {
    taskName: "Maan",
    taskId: 25,
    hrName: "Mohamed Maan Faheem",
    hrId: 52,
    tenant: "Eches",
  },
  {
    taskName: "Mode",
    taskId: 4,
    hrName: "Mohamed Hussain",
    hrId: 33,
    tenant: "Eches",
  },
  {
    taskName: "Muzuhir",
    taskId: 28,
    hrName: "Ali Muzuhir",
    hrId: 58,
    tenant: "Eches",
  },
  {
    taskName: "Neesha",
    taskId: 17,
    hrName: "Aminath Neesha",
    hrId: 49,
    tenant: "Eches",
  },
  {
    taskName: "Nihan",
    taskId: 21,
    hrName: "Nihan Jr Ali",
    hrId: 54,
    tenant: "Eches",
  },
  {
    taskName: "Phaax",
    taskId: 27,
    hrName: "Asiyath Fazila",
    hrId: 34,
    tenant: "Eches",
  },
  {
    taskName: "Rafhan",
    taskId: 23,
    hrName: "Rafhaan Ahmed Fayaz",
    hrId: 55,
    tenant: "Eches",
  },
  {
    taskName: "Saalif",
    taskId: 30,
    hrName: "Saalif Mohamed Rifath",
    hrId: 59,
    tenant: "Eches",
  },
  {
    taskName: "Shifau",
    taskId: 18,
    hrName: "Ibrahim Shifau",
    hrId: 43,
    tenant: "Eches",
  },
  {
    taskName: "Shihab",
    taskId: 3,
    hrName: "Hussain Shihab",
    hrId: 32,
    tenant: "Eches",
  },
  {
    taskName: "Siyah",
    taskId: 19,
    hrName: "Mohamed Siyah",
    hrId: 44,
    tenant: "Eches",
  },
  {
    taskName: "Sumana",
    taskId: 14,
    hrName: "Fathimath Sumaana",
    hrId: 38,
    tenant: "Eches",
  },

  // Clay
  {
    taskName: "Mua",
    taskId: 3,
    hrName: "Ahmed",
    hrId: 14,
    tenant: "Clay",
  },
];
