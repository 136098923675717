import PieChart from "components/charts/pie-chart/PieChart"
import { useState, useEffect } from "react"
import customAxios from "middlewares/axios-interceptor/customAxios"
import isSameYear from "date-fns/isSameYear"
import { endOfDay, isSameDay, startOfDay, subDays } from "date-fns"
import isWithinInterval from "date-fns/isWithinInterval"

  export default function LeavesPerType ({leaveTodayFunc}) {
    const [leaves, setLeaves] = useState([])
    const [leaveTypes, setLeaveTypes] = useState([])

    useEffect(() => {
      customAxios.get("/leave")
      .then((res) => {
        let leaveItems = res.items;
        setLeaves(leaveItems)
      });
      customAxios.get("/leave/types")
      .then((res) => {
        setLeaveTypes(res)
      })
    }, [])

    let leaveTypeCounts = {}

    let onLeaveToday = [];

    for(let i = 0; i < leaves.length; i++)
    {
        let leaf = leaves[i]
        let leaveStartDay = new Date(leaf.from);
        let leaveEndDay = new Date(leaf.to);

        if(!isSameYear(leaveStartDay, new Date()))
        {
          continue;
        }

        let today = new Date()
        let leaveStart = startOfDay(leaveStartDay)
        let leaveEnd = endOfDay(leaveEndDay)

        if(isWithinInterval (today, {start: leaveStart, end: leaveEnd} ) ) 
        {
          onLeaveToday.push(leaf)
        }

        if(leaveTypeCounts.hasOwnProperty(leaf.leaveType.name))
        {
            leaveTypeCounts[leaf.leaveType.name]++;
        }
        else
        {
            leaveTypeCounts[leaf.leaveType.name] = 1;
        }
    }

    leaveTodayFunc(onLeaveToday)

    const data = {
        labels: Object.keys(leaveTypeCounts),
        datasets: [
            {
                data: Object.values(leaveTypeCounts),
            }
        ]
    }

    return (
        <PieChart labels={data.labels} datasets={data.datasets} />
    )
  }