import customAxios from "middlewares/axios-interceptor/customAxios";
import moment from "moment";
import React, { RefObject, useEffect, useMemo, useRef, useState } from "react";
export function getWeekDays() {
  return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
}

export function getMonths() {
  return [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
}

type CalendarDay = {
  day: number;
  month: number;
  year: number;
  weekDay: number;
  date: Date;
  schedules: Schedule[];
  ref: RefObject<HTMLElement>;
};

export function useOnScreen(ref: RefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false);
  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting && entry.intersectionRatio === 1) {
          setIntersecting(true);
          console.log(ref.current.id);
          ref.current.style.backgroundColor = "#FF00CC !important";
        }
      }),
    [ref]
  );

  useEffect(() => {
    observer.observe(ref.current);
    const header = document.getElementById("header");

    return () => observer.disconnect();
  }, []);

  return isIntersecting;
}

export default function Calendar() {
  let [scheduleTypes, setScheduleTypes] = useState([] as ScheduleType[]);
  let [filterScheduleTypes, setFilterScheduleTypes] = useState([0] as number[]);
  let [calendar, setCalendar] = useState([] as CalendarDay[]);
  let [addEvent, setAddEvent] = useState(false);
  let [selectedDate, setSelectedDate] = useState(null);
  let [months, setMonths] = useState([] as string[]);
  let [currentMonth, setCurrentMonth] = useState("January");
  let [loading, setLoading] = useState(true);
  let currentRef = null;
  let monthRefs = useRef([]);

  var today = new Date();
  var firstDayOfTheYear = new Date(today.getFullYear(), 0, 1);
  var lastDayOfTheYear = new Date(today.getFullYear(), 11, 31);

  const getDaysInYear = () => {
    return Math.floor(
      (lastDayOfTheYear.getTime() - firstDayOfTheYear.getTime()) /
        (1000 * 3600 * 24)
    );
  };

  const GoToDate = (date: Date) => {
    const container = document.getElementById("calendar");
    date = new Date(date.toLocaleDateString());
    const month = date.getMonth();
    const year = date.getFullYear();
    const identifier = months[month] + " " + year;
    var element = document.getElementById(identifier);
    const headerHeight = document.getElementById("header")?.clientHeight;

    if (element) {
      container.scrollTo({
        top: element.offsetTop - headerHeight * 2,
        behavior: "smooth",
      });
      setCurrentMonth(months[date.getMonth()]);
    }
  };

  const filterScheduleType = (schedule: ScheduleType) => {
    if (filterScheduleTypes.includes(schedule.id)) {
      setFilterScheduleTypes(
        filterScheduleTypes.filter((x) => x !== schedule.id)
      );
    } else {
      setFilterScheduleTypes([...filterScheduleTypes, schedule.id]);
    }
  };

  const AddEvent = () => {
    return (
      <div className="absolute p-2 bg-white  z-[99] w-[40vw] shadow-2xl aspect-video bg-whit rounded-xl border ">
        <div className="flex justify-between border-b w-full p-2">
          <h1 className="text-lg ">
            Add event - {moment(selectedDate).format("YYYY-MM-DD, dddd")}
          </h1>
          <button
            onClick={() => {
              setAddEvent(false);
              setSelectedDate(null);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    );
  };

  const DayTile = (day?: CalendarDay) => {
    const isHoliday = day.schedules.findIndex((x) => x.isWorkingDay) !== -1;

    function viewEvent(e: any): void {
      e.preventDefault(); // prevent the default behaviour when right clicked
      throw new Error("Function not implemented.");
    }

    return (
      <div
        onBlur={() => {
          setAddEvent(false);
        }}
        onClick={() => {
          setAddEvent(true);
          setSelectedDate(day.date);
        }}
        id={day.day === 1 ? months[day.month] + " " + day.year : ""}
        className={`aspect-[4/3] w-full rounded-xl -md cursor-pointer border relative ${
          day.month % 2 !== 0
            ? "bg-gray-100 hover:bg-gray-200 "
            : "bg-white hover:bg-gray-50"
        } ${isHoliday ? "" : "bg-red-200 border-red-50 hover:bg-red-100"} ${
          selectedDate === day.date ? "bg-gray-500 text-white" : ""
        } ${
          day.date.toLocaleDateString() === today.toLocaleDateString()
            ? "bg-orange-500 hover:bg-orange-400 text-white"
            : ""
        }`}
      >
        <div className="flex flex-col">
          <div className="px-4 pt-4">
            <div className="text-xs text-gray-500">{months[day.month]}</div>
            <div className="font-bold text-3xl"> {day.day}</div>
          </div>

          <div className="flex flex-end justify-end h-full mt-auto px-4 overflow-hidden whitespace-nowrap flex-col ">
            {day.schedules.map((schedule) => {
              return filterScheduleTypes?.includes(schedule.type?.id) ||
                schedule.type === null ? (
                <div
                  style={{
                    color: schedule.type?.color,
                  }}
                  className="flex flex-row flex-nowrap space-x-2 "
                >
                  <div className={`flex flex-nowrap text-xs flex-col`}>
                    <div>{schedule.title}</div>
                  </div>
                </div>
              ) : (
                <div></div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  //Generate all days from 1st Jan to 31st Dec
  const generateCalendar = (schedules: Schedule[]) => {
    let calendar: CalendarDay[] = [];

    //If first day of the year is not Sunday, add empty days

    for (let i = 0; i <= getDaysInYear(); i++) {
      let date = new Date(
        firstDayOfTheYear.getTime() + i * 24 * 60 * 60 * 1000
      );

      //Get schedules for the day
      let schedulesForTheDay = schedules.filter((schedule) => {
        let scheduleDate = new Date(schedule.dateTime);
        return (
          scheduleDate.getDate() === date.getDate() &&
          scheduleDate.getMonth() === date.getMonth() &&
          scheduleDate.getFullYear() === date.getFullYear()
        );
      });

      const calendarDay: CalendarDay = {
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
        weekDay: date.getDay(),
        date: date,
        schedules: schedulesForTheDay,
        ref: React.createRef(),
      };
      calendar.push(calendarDay);
    }

    setCalendar(calendar);
    return calendar;
  };

  async function fetchSchedules() {
    const response = (await customAxios(
      `/calendar?from=${today.getFullYear()}-01-01&to=${today.getFullYear()}-12-31`
    )) as Schedule[];
    setMonths(getMonths);
    const calendar = generateCalendar(response);
    setLoading(false);
  }

  async function fetchScheduleTypes() {
    const response = (await customAxios(
      `/calendar/scheduleTypes`
    )) as ScheduleType[];

    const scheduleTypes = response.filter(
      (x) => x.isVisibleInCalendar === true
    );
    setScheduleTypes(scheduleTypes);
    setFilterScheduleTypes(scheduleTypes.map((x) => x.id));
  }

  const nextMonth = () => {
    const currentIndex = months.indexOf(currentMonth);
    const current = new Date(today.getFullYear(), currentIndex + 1, 1);
    GoToDate(current);
  };

  const previousMonth = () => {
    const currentIndex = months.indexOf(currentMonth);
    const current = new Date(today.getFullYear(), currentIndex - 1, 1);

    GoToDate(current);
  };

  //Async useEffect

  useEffect(() => {
    fetchSchedules();
    fetchScheduleTypes();
  }, []);

  //Watch intersection of months

  useEffect(() => {
    if (calendar.length > 0) {
      const month = calendar.find((x) => x.day === 1);
    }

    GoToDate(today);
  }, [calendar]);

  const Shimmer = () => {
    return Array(31)
      .fill(0)
      .map((_, i) => <div></div>);
  };

  const Month = ({ month }) => {
    const ref = useRef(month);
    const isOnScreen = useOnScreen(ref);

    if (isOnScreen) {
      const _currentMonth = months.findIndex((x) => x === months[month]);

      if (_currentMonth == -1) {
        setCurrentMonth(months[0]);
      } else {
        setCurrentMonth(months[month - 1]);
      }
    }

    useEffect(() => {
      if (months[month] === currentMonth) {
        currentRef = ref;
      }
    }, []);

    return <div ref={ref} id={months[month]} />;
  };

  return (
    <div id="calendar" className="h-full overflow-auto flex  flex-col w-full">
      <div
        id="header"
        className="pl-4 sticky top-0 z-20 bg-white rounded-xl shadow-lg "
      >
        <div className="h-14 flex justify-between items-center">
          <div className="flex ">
            <button onClick={() => previousMonth()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4.5 15.75l7.5-7.5 7.5 7.5"
                />
              </svg>
            </button>
            <div className="flex justify-center">
              <div className="font-bold text-center w-56 text-2xl">
                {currentMonth}
                <span className="text-gray-500 pt-1">
                  {" "}
                  {today.getFullYear()}
                </span>
              </div>

              <button onClick={() => nextMonth()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="scheduleTypes flex cursor-pointer space-x-2">
            {scheduleTypes.map((scheduleType) => (
              <div
                onClick={() => filterScheduleType(scheduleType)}
                className="flex hover:bg-gray-200 p-1 rounded-sm text-xs flex-row items-center"
              >
                <div
                  style={{
                    backgroundColor: filterScheduleTypes.includes(
                      scheduleType.id
                    )
                      ? scheduleType.color
                      : "gray",
                  }}
                  className="w-4 h-4 rounded-full mr-2"
                ></div>
                <div>{scheduleType.name}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex  flex-row w-full">
          {getWeekDays().map((weekDay) => (
            <div
              className={` flex flex-col w-full justify-center items-start  h-full border-b border-gray-200`}
            >
              {weekDay}
            </div>
          ))}
        </div>
      </div>
      <div className="grid pb-[100px]  grid-cols-7 gap-1 m-1 h-full">
        {loading
          ? Array(31).map((_, i) => (
              <DayTile
                ref={null}
                key={i}
                date={new Date()}
                day={0}
                month={0}
                schedules={[]}
                weekDay={0}
                year={new Date().getFullYear()}
              />
            ))
          : calendar.map((day, index) => (
              <div>
                {day.day === 1 && <Month month={day.month} />}
                <DayTile
                  ref={day.ref}
                  date={day.date}
                  day={day.day}
                  month={day.month}
                  year={day.year}
                  schedules={day.schedules}
                  weekDay={day.weekDay}
                  key={index}
                />
              </div>
            ))}
      </div>
      {addEvent ? (
        <div className="w-full h-full  flex items-center justify-center z-20  inset-0 absolute  bg-black/20">
          <AddEvent />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
