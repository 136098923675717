import Widget from "components/widget/Widget";
import {
  setBreadCrumbs,
  setNavigationButtons,
} from "features/navigation/NavigationSlice";
import customAxios from "middlewares/axios-interceptor/customAxios";
import DocumentStatus from "pages/employees/view/views/overview/components/DocumentStatusCard";
import React from "react";
import { useDispatch } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import EmployeeAttendanceChart from "widgets/employee/employee-card/EmployeeAttendanceChart";
import EmployeeCard from "widgets/employee/employee-card/EmployeeCard";
import EmployeeDetails from "widgets/employee/employee-card/EmployeeDetails";
import EmployeeDocumentStatus from "widgets/employee/employee-card/EmployeeDocumentStatus";
import EmployeeLeaveBalance from "widgets/employee/employee-card/EmployeeLeaveBalance";
import moment from "moment";
import PrimaryButton from "ui/buttons/PrimaryButton";
import EmployeeLeaveStatuses from "widgets/employee/employee-card/EmployeeLeaveStatuses";

export default function ViewEmployeeOverview() {
  let { Name, EmployeeId } = useParams();
  let [employeeCardData, setEmployeeCardData] = React.useState(null);
  let [EmployeeDetailsData, setEmployeeDetailsData] = React.useState(null);
  let [leaveBalance, setLeaveBalance] = React.useState([]);
  let [leaves, setLeaves] = React.useState([]);
  let [loading, setLoading] = React.useState(true);
  let dispatch = useDispatch();

  React.useEffect(() => {
    customAxios.get(`/employee/profile/${EmployeeId}`).then(
      (res) => {
        setEmployeeCardData({
          profilePicture:
            res?.avatar ?? "https://www.mydthpay.com/img/review.png",
          name: res?.title + ". " + res?.name ?? `N/A`,
          designation: res?.position?.name ?? "N/A",
          department: res?.position?.department ?? "N/A",
          joinedDate: moment(res.dateJoined).format("DD MMM YYYY") ?? "N/A",
          status: res.status ?? "N/A",
        });
        setEmployeeDetailsData({
          employeeType: res?.employeeType ?? "N/A",
          designation: res?.position?.name ?? "N/A",
          department: res?.position?.department ?? "N/A",
          division: res?.division?.name ?? "N/A",
          employeeCode: res?.employeeCode ?? "N/A",
          reportsTo: res?.reportsTo?.name ?? "N/A",
          joiningDate: moment(res.joinedDate).format("DD MMM YYYY") ?? "N/A",
          payGroup: res?.payGroup?.name ?? "N/A",
          leaveGroup: res?.leaveGroup?.name ?? "N/A",
        });
        setLeaveBalance(
          res?.leaveBalance?.map((item) => {
            return {
              leaveType: item?.leaveType ?? "N/A",
              max: item?.max ?? "N/A",
              days: item?.balance ?? "N/A",
              percentage: (item?.balance / item?.max) * 100 ?? "N/A",
            };
          })
        );

        setLoading(false);
      },
      (err) => console.log(err)
    );

    customAxios.get(`/leave?id=${EmployeeId}`).then(
      (res) => setLeaves(res.items),
      (err) => console.log(err)
    );
  }, []);

  React.useEffect(() => {
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Employees", link: "/employees/all" },
        { name: `${Name}`, link: `` },
        { name: "Overview", link: `` },
      ])
    );
  }, []);

  return (
    <div className="grid grid-cols-4 overflow-auto h-full   gap-3 ">
      <div className="col-span-1">
        {loading ? (
          <div className="h-96 w-full flex justify-center items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        ) : (
          <EmployeeCard data={employeeCardData} />
        )}
      </div>
      <div className="col-span-2">
        {loading ? (
          <div className="h-96 w-full flex justify-center items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        ) : (
          <EmployeeDetails Data={EmployeeDetailsData} />
        )}
      </div>
      <div className="col-span-1">
        {loading ? (
          <div className="h-96 w-full flex justify-center items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        ) : (
          <EmployeeLeaveBalance leaveBalanceData={leaveBalance} />
        )}
      </div>
      <EmployeeLeaveStatuses data={leaves} />
      <Outlet />
      {/* <div className="col-span-3">
        <EmployeeAttendanceChart />
      </div>
      <div className="col-span-1">
        <div className="grid grid-rows-2 gap-3">
          <EmployeeDocumentStatus />
          <EmployeeDocumentStatus />
          <EmployeeDocumentStatus />
        </div>
      </div> */}
      {/* <div className="col-span-1">
        <Widget heading={"Payroll Status"} />
      </div> */}
      {/* <div className="col-span-2">
        <Widget heading={"Benefits Overview"} />
      </div>
      <div className="col-span-1">
        <Widget heading={"Appraisal Status"} />
      </div> */}
    </div>
  );
}
