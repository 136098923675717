import DataGrid from "components/data-grid/DataGrid";
import Widget from "components/widget/Widget";
import { startOfYear } from "date-fns";
import {
  setBreadCrumbs,
  setSelectedSubMenu,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import { menuItems } from "./../MenuItems";
import customAxios from "middlewares/axios-interceptor/customAxios";
import moment from "moment";
import DataTable from "pages/employees/view/views/leaves/data-grid/DataGrid";
import React from "react";
import { useDispatch } from "react-redux";


export default function AttendanceSignInLogs() {
  let [data, setData] = React.useState([]);
  let [rowData, setowData] = React.useState([]);
  let [loading, setLoading] = React.useState(false);
  let from = startOfYear(new Date()).toISOString();
  let to = new Date();

  //add one day
  to.setDate(to.getDate() + 1).toLocaleString();

  from = moment(from).format("YYYY-MM-DD");
  to = moment(to).format("YYYY-MM-DD");

  let dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setSelectedSubMenu(menuItems[2]))
    customAxios
      .get(`/attendance?From=${from}&To=${to}`)
      .then((res) => {
        setData(res.items);
        arrangeRowData(res.items);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    dispatch(setSubHeader("Attendance"));
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Attendance", link: "/Attendance/overview" },
        { name: "Sign In Logs", link: "" },
      ])
    );
  }, []);

  function arrangeRowData(data) {
    data.map((item) => {
      let temp = {
        employee: item.employee,
        designation: item.employee?.position?.name,
        department: item.employee?.department ?? "N/A",
        date: item.date,
        checkIn: item.checkIn,
        checkOut: item.checkOut,
        dayType: item?.scheduleType?.name,
        start: item?.start,
        end: item?.end,
      };
      rowData.push(temp);
    });
  }

  function cellFunction(cell) {
    if (cell.column.id === "employee") {
      return (
        <div className="flex items-center w-34">
          <img
            src={
              cell.row.original.employee?.avatar
                ? cell.row.original.employee?.avatar
                : "https://www.mydthpay.com/img/review.png"
            }
            alt="avatar"
            className="w-8 h-8 rounded-full mr-2"
          />
          <div className="text-sm text-start">
            <p className="font-semibold">{cell.row.original.employee?.name}</p>
            <p className="text-xs text-gray-500">
              {cell.row.original.employee?.department ?? "N/A"} -{" "}
              {cell.row.original.employee?.position?.name ?? "N/A"} -{" "}
              {cell.row.original.employee?.id}
            </p>
          </div>
        </div>
      );
    }
    if (cell.column.id === "date") {
      return (
        <div className="flex text-center items-center w-34">
          <p className="text-center w-full">
            {moment(cell.row.original.date).format("DD MMM YYYY")}
          </p>
        </div>
      );
    }
    return cell.render("Cell");
  }

  return (
    <Widget heading={"Employee Sign-in Logs"}>
      <DataGrid
        showHeader={true}
        columns={[
          {
            Header: "Employee",
            accessor: "employee",
          },
          {
            Header: "Check In",
            accessor: "checkIn",
          },
          {
            Header: "Check Out",
            accessor: "checkOut",
          },
          {
            Header: "Day Type",
            accessor: "dayType",
          },
          {
            Header: "Start",
            accessor: "start",
          },
          {
            Header: "End",
            accessor: "end",
          },
          {
            Header: "Date",
            accessor: "date",
          },
        ]}
        testData={rowData}
        cellFunction={cellFunction}
        loading={loading}
        alignRow="left"
      />
    </Widget>
  );
}
