import { taskAxios } from "pages/attendance/timesheet/taskAxios/taskAxios"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import SelectTasksDropdown from "./SelectTasksDropdown"
import DateRangePickerMain from "components/date-picker-v2/DateRangePicker"
import Remarks from "./Remarks"
import TimeRangePicker from "components/time-picker/TimeRangePicker"
import SubmitButton from "./SubmitButton"
import { 
    handleImmediateFormErrors, 
    handleFormErrorsOnSubmit, 
    defaultFormFields,
    changeTimeOfDateObject,
    logInToTaskSheet,
    defaultLoginFormFields,
    prepareFormFields
} from "../HelperFunctions"
import ErrorMessageView from "./ErrorMessageView"
import FormWrapper from "./FormWrapper"
import { employeeMapper } from "pages/attendance/timesheet/EmployeeMapper"
import { useDispatch, useSelector } from "react-redux"
import { selectTaskSheet } from "features/tasksheet/auth/tasksheet.selector"
import { dispatchLogInToTaskSheet } from "features/tasksheet/auth/tasksheet.actions"
import TaskSheetLogInForm from "./TaskSheetLogInForm"
import { getTaskSheetIdFromHrId } from "../HelperFunctions"

const AddTaskForm = ({onTaskAdded}) => {
    const dispatch = useDispatch()
    const { EmployeeId } = useParams()
    const [tasks, setTasks] = useState([])
    const [taskId, setTaskId] = useState(1)
    const [formFields, setFormFields] = useState(defaultFormFields)
    const [logInFormFields, setLogInFormFields] = useState(defaultLoginFormFields)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [disableSubmit, setDisableSubmit] = useState(false)
    const [isLoggedInToTaskSheet, setIsLoggedInToTaskSheet] = useState(useSelector(selectTaskSheet).isLoggedIn)
    const taskSheetUserName = useSelector(selectTaskSheet).userId
    const taskSheetPassword = useSelector(selectTaskSheet).password
    const auth = {
        username: taskSheetUserName,
        password: taskSheetPassword
    }

    useEffect(() => {
        taskAxios.get(`/repmytasks/${taskId}/${EmployeeId}`, {auth: auth})
        .then(res => setTasks(res ?? []))
    }, [])

    const handleTaskChange = AsigneeId => setFormFields({...formFields, AsigneeId})
    const handleFDateChange = StartTime => setFormFields({...formFields, StartTime})
    const handleEDateChange = EndTime => setFormFields({...formFields, EndTime})
    const handleStartTimeChange = TimeStart => setFormFields({...formFields, TimeStart})
    const handleEndTimeChange = TimeEnd => setFormFields({...formFields, TimeEnd})
    const handleRemarksChange = Remarks => setFormFields({...formFields, Remarks})
    const resetFormFields = () => setFormFields(defaultFormFields)
    const handleLogInFormChange = (event) => {setLogInFormFields({
        ...logInFormFields,
        [event.target.name]: event.target.value
    })}
    const handleTaskSheetLogin = async (event) => {
        event.preventDefault()
        setLoading(true)
        const res = await logInToTaskSheet(logInFormFields)
        const {status, userId, token, msg} = res.response.data
        if(status === true)
        {
            setErrorMessage("")
            setIsLoggedInToTaskSheet(true)
            dispatch(dispatchLogInToTaskSheet(userId, token))
        }
        else
        {
            setErrorMessage(msg ?? "Error Logging In")
        }
        setLoading(false)
    }

    const getAsigneeIdFromActivityId = async (activityId, taskUserId) => {
        let res = await taskAxios.get("/asigneesofactivity", {
            params: {
                Id: activityId
            },
            auth: auth
        })
        return res.find(x => x.assigendToId == taskUserId).id
    }

    const handleSubmit = async () => {
        const error = handleFormErrorsOnSubmit(formFields)
        if(!error)
        {
            try {
                setLoading(true)
                const asigneeId = await getAsigneeIdFromActivityId(formFields.AsigneeId.activityId, taskSheetUserName)
                let submissionFormFields = prepareFormFields(formFields, asigneeId)
                taskAxios.post("/timesheet/", submissionFormFields, {auth: auth})
                .then((_) => {
                    onTaskAdded({})
                    setLoading(false)
                })
                .catch((err) => {
                    setLoading(false)
                    setErrorMessage(err.message)
                })
            } 
            catch (err) {
                setLoading(false)
                setErrorMessage(err.message ?? "Error")
            }
        }
        else
        {
            setErrorMessage(error)
        }
    }

    useEffect(() => {
        const error = handleImmediateFormErrors(formFields)
        setErrorMessage(error)
    }, [formFields])

    return (
        <FormWrapper 
            loading={loading}
        >
            {
                errorMessage && <ErrorMessageView errorMessage={errorMessage} />
            }
            {
                isLoggedInToTaskSheet ? (
                    <>
                        <SelectTasksDropdown 
                            onTaskChange={handleTaskChange} 
                            tasks={tasks} 
                        />
                        <DateRangePickerMain 
                            fDateHeading="Start Date"
                            eDateHeading="End Date"
                            onFDateChange={handleFDateChange}
                            onEDateChange={handleEDateChange} 
                        />
                        <TimeRangePicker
                            onStartTimeChange={handleStartTimeChange}
                            onEndTimeChange={handleEndTimeChange}
                            startTimeTitle="Start Time"
                            endTimeTitle="End Time"
                        />
                        <Remarks onRemarksChange={handleRemarksChange} />
                        {
                            !errorMessage && <SubmitButton disabled={disableSubmit} onClick={handleSubmit} />
                        }
                    </>
                ) : (
                    <TaskSheetLogInForm onSubmit={handleTaskSheetLogin} onChange={handleLogInFormChange} />
                )
            }
        </FormWrapper>
    )
}

export default AddTaskForm  