import Widget from "components/widget/Widget";
import { useSelector } from "react-redux";
import React from "react";
import { selectEmployeeLoans } from "features/employees/EmployeesSlice";
import customAxios from "middlewares/axios-interceptor/customAxios";
import DataGrid from "components/data-grid/DataGrid";
import moment from "moment";

export default function PayStructure() {
  let loan = useSelector(selectEmployeeLoans);
  let [rowData, setRowData] = React.useState([]);

  React.useEffect(() => {
    if (loan !== null) {
      let data = {
        PrincipalAmount: loan.PrincipalAmount,
        Rate: loan.Rate,
        NumberOfInstallments: loan.loanDuration,
        StartDate: loan.Start,
        RecurranceDayOfMonth: 28,
        RecurranceMonthlyInterval: 1,
      };
      customAxios
        .post("/employeefinance/create-payment-plan", data)
        .then((res) => {
          generateRowData(res);
        });
    }
  }, [loan]);

  function generateRowData(data) {
    let temp = [];
    data.map((item, index) => {
      let obj = {
        id: index + 1,
        dueDate: moment(item.dateDue).format("DD/MM/YYYY"),
        paymentDue: item.amountDue,
        interest: item.interest ?? "N/A",
      };
      temp.push(obj);
    });
    setRowData(temp);
  }

  function cellFunction(row) {
    return (
      <>
        <div className="flex items-center">
          <div className="flex flex-col">
            <span className="text-sm font-medium">{row.value}</span>
          </div>
        </div>
      </>
    );
  }

  return (
    <Widget
      heading={"Amortization Schedule / Payment Plan"}
      subHeading={"The due amount should be paid upon the agreed date"}
    >
      <DataGrid
        showHeader={true}
        columns={[
          {
            Header: "No",
            accessor: "id",
          },
          {
            Header: "Due Date",
            accessor: "dueDate",
          },
          {
            Header: "Payment Due",
            accessor: "paymentDue",
          },
          {
            Header: "Interest",
            accessor: "interest",
          },
          {
            Header: "Principal",
            accessor: "principal",
          },
          {
            Header: "Balance",
            accessor: "balance",
          },
        ]}
        testData={rowData}
        cellFunction={cellFunction}
      />
    </Widget>
  );
}
