import React from "react";

export default function PrimaryButton({ title, type, onClick, disabled, className }) {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      style={{
        cursor: disabled ? "not-allowed" : "pointer",
        backgroundColor: disabled ? "rgb(251 146 60 / 0.5)" : "rgb(251 146 60 / 1)",
      }}
      className={`px-6 p-1.5 w-fit text-sm border border-slate-300flex items-center justify-center text-slate-50 font-semibold rounded-xl shadow-md hover:brightness-95 ${className}`}
    >
      {title}
    </button>
  );
}
