import ModalLayout from "components/layout/ModalLayout";
import Modal from "components/modal/Modal";
import { addNotification } from "features/notifications/NotificationSlice";
import React from "react";
import * as Yup from "yup";
import FormGenerator from "components/form-generator/FormGenerator";
import customAxios from "middlewares/axios-interceptor/customAxios";
import { useParams } from "react-router-dom";
import moment from "moment/moment";

let countries = JSON.parse(localStorage.getItem("countries"));
let variables = JSON.parse(localStorage.getItem("variables"));
let genders = variables?.filter((x) => x.key == "Gender");
let suffix = variables?.filter((x) => x.key == "Title");
let MaritalStatuses = variables?.filter((x) => x.key == "MaritalStatus");
let Banks = variables?.filter((x) => x.key == "Bank");

export default function AddEmployeeDocument() {
  let { EmployeeId } = useParams();
  const formData = {
    contents: [
      {
        heading: "Add Document",
        description: null,
        function: () => {
          return <div></div>;
        },
        fields: [
          {
            name: "docNumber",
            displayName: "Document Number",
            type: "field",
            inputType: "text",
          },
          {
            name: "issuedCountry",
            displayName: "Issued Country",
            type: "field",
            inputType: "text",
          },

          {
            name: "issuedBy",
            displayName: "Issued by",
            type: "field",
            inputType: "text",
          },
          {
            name: "issuedOn",
            displayName: "Issued on",
            type: "dateSelect",
            default: new Date(),
            inputType: "text",
            value: (formik) => {
              return formik.values.issuedOn;
            },
          },
          {
            name: "expiresOn",
            displayName: "Expires on",
            type: "dateSelect",
            default: new Date(),
            inputType: "text",
            value: (formik) => {
              return formik.values.expiresOn;
            },
          },
          {
            name: "remarks",
            displayName: "Remarks",
            type: "textArea",
            inputType: "text",
          },
          {
            name: "documents",
            displayName: "Document",
            type: "fileUpload",
            inputType: "text",
          },
        ],
      },
    ],
    initialValues: {
      docNumber: "",
    },
    validationSchema: Yup.object().shape({
      docNumber: Yup.string().required("Document Number is required"),
    }),
    onSubmit: async (
      values,
      { setSubmitting, setErrors, setStatus, resetForm, handleSubmit }
    ) => {
      // change JSON data to FormData

      console.log(values);
      let formData = new FormData();
      formData.append("model[0].attachments", values.documents[0]);
      formData.append("model[0].documentNumber", values.documentNumber);
      formData.append("model[0].country", values.issuedCountry);
      formData.append("model[0].issuedBy", values.issuedBy);
      formData.append(
        "model[0].issuedOn",
        moment(new Date(values.issuedOn)).format("YYYY-MM-DD")
      );
      formData.append(
        "model[0].expiresOn",
        moment(new Date(values.expiresOn)).format("YYYY-MM-DD")
      );
      formData.append("model[0].remarks", values.remarks);

      customAxios
        .post(
          process.env.NODE_ENV === "production"
            ? `https://api.echeshr.com/api/v2/employee/`
            : "http://localhost/api/v2/employee/" + EmployeeId + `/documents`,
          formData
        )
        .then((res) => {
          if (res) {
            addNotification({
              type: "success",
              message: "Document Added Successfully",
            });
          }
        });

      setSubmitting(false);
    },
  };
  return (
    <Modal>
      <ModalLayout heading={"Edit Employee Details"} width={"w-[33vw]"}>
        <FormGenerator buttonSubmit={{ title: "Submit" }} formData={formData} />
      </ModalLayout>
    </Modal>
  );
}
