import { TASKSHEET_TYPES } from "./tasksheet.types"

const TASKSHEET_INITIAL_STATE = {
    userId: "9494",
    password: "236FE197-7CC5-41F2-A01C-9EB36D8B7D38",
    isLoggedIn: false,
}

export const tasksheetReducer = ( state = TASKSHEET_INITIAL_STATE, action = {} ) => {
    const { type, payload } =  action
    switch(type)
    {
        case TASKSHEET_TYPES.LOG_IN:
            return {
                ...state,
                userId: payload.userId,
                password: payload.password,
                isLoggedIn: true
            }
        default:
            return state
    }
}