import React from "react";
import FormGenerator from "components/form-generator/FormGenerator";
import customAxios from "middlewares/axios-interceptor/customAxios";
import * as Yup from "yup";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setRefreshTrigger } from "features/navigation/NavigationSlice";

let variables = JSON.parse(localStorage.getItem("variables"));

export default function AddContacts() {
  let dispatch = useDispatch();
  let { EmployeeId } = useParams();
  let navigate = useNavigate();
  let [isError, setIsError] = React.useState(false);
  let [errorMessage, setErrorMessage] = React.useState("");

  const FormData = {
    contents: [
      {
        heading: "Contact Information",
        description:
          "Multiple Contacts can be added for an employee. The contact types can be set in settings please click on the link below to add more contact types",
        function: () => {
          return <div></div>;
        },
        fields: [
          {
            name: "contactType",
            displayName: "Contact Type",
            type: "comboBox",
            value: (formik) => {
              return formik.values.contactType;
            },
            values: [
              { id: 1, name: "Emergency" },
              { id: 2, name: "Personal" },
              { id: 3, name: "Work" },
            ],
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },

          {
            name: "email",
            displayName: "Email",
            type: "field",
            inputType: "text",
            required: false,
          },
          {
            name: "mobile",
            displayName: "Mobile",
            type: "field",
            inputType: "text",
            required: false,
          },
          {
            name: "phone",
            displayName: "Phone",
            type: "field",
            inputType: "text",
            required: false,
          },
          {
            name: "extension",
            displayName: "Extension",
            type: "field",
            inputType: "text",
            required: false,
          },
          {
            name: "isDefault",
            displayName: "Make Default",
            type: "checkbox",
            inputType: null,
            value: (formik) => {
              return formik.values.isDefault;
            },
            required: false,
          },
        ],
      },
      // {
      //   heading: "Relationship to Employee",
      //   description:
      //     "This is only required to be filled for emergency contacts",
      //   function: (formik) => {
      //     if (formik.values.contactType) {
      //       return <div></div>;
      //     }
      //   },
      //   fields: [
      //     {
      //       name: "name",
      //       displayName: "Name",
      //       type: "field",
      //       inputType: "text",
      //     },
      //     {
      //       name: "relationship",
      //       displayName: "Relationship",
      //       type: "field",
      //       inputType: "text",
      //     },
      //   ],
      // },
    ],
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid Email Format"),
    }),
    onSubmit: async (
      values,
      { setSubmitting, setErrors, setStatus, resetForm, handleSubmit }
    ) => {
      let data = {
        phone: values.phone,
        mobile: values.mobile,
        email: values.email,
        extension: values.extension,
      };

      let dataArray = [];
      Object.keys(data).forEach((key) => {
        dataArray.push({
          type: values.contactType.name,
          contactType: key,
          value: values[key],
          isDefault: values.isDefault,
        });
      });

      customAxios
        .post(
          `https://api.echeshr.com/api/v2/employee/${EmployeeId}/contacts`,
          dataArray
        )
        .then(
          (res) => {
            dispatch(setRefreshTrigger());
            navigate(-1);
          },
          (err) => {
            setIsError(true);
            setErrorMessage(err.response.data);
          }
        );
    },
  };

  return (
    <FormGenerator
      ErrorMsg={errorMessage}
      isError={isError}
      formData={FormData}
    />
  );
}
