const MenuItems = [
  {
    name: null,
    menuItems: [
      {
        name: "Home",
        selected: true,
        route: "/",
        icon: "https://cdn-icons-png.flaticon.com/128/609/609803.png",
      },
      {
        name: "Dashboards",
        selected: true,
        route: "/",
        icon: "https://www.svgrepo.com/show/218829/dashboard.svg",
      },
      {
        name: "Employees",
        route: "/employees/all",
        icon: "https://cdn-icons-png.flaticon.com/128/912/912216.png",
      },
      {
        name: "Groups",
        route: "/groups/all",
        icon: "https://cdn-icons-png.flaticon.com/128/476/476863.png",
      },
      {
        name: "Attendance",
        route: "/attendance/manage/attendance",
        icon: "https://cdn-icons-png.flaticon.com/128/1642/1642767.png",
      },
      {
        name: "Leaves",
        route: "/leaves/main",
        icon: "https://cdn-icons-png.flaticon.com/128/3387/3387285.png",
      },
      {
        name: "Approval",
        route: "/approval/leaves",
        icon: "https://cdn-icons-png.flaticon.com/128/2954/2954893.png",
      },
      {
        name: "Payroll",
        route: "/employees/payroll",
        icon: "https://cdn-icons-png.flaticon.com/128/2914/2914914.png",
      },
      {
        name: "Appraisal",
        route: "/employees/payroll",
        icon: "https://cdn-icons-png.flaticon.com/512/3041/3041259.png",
      },
      {
        name: "Certification and Training",
        route: "/employees/payroll",
        icon: "https://cdn-icons-png.flaticon.com/512/1989/1989958.png",
      },
      {
        name: "Recruitment",
        route: "/employees/payroll",
        icon: "https://cdn-icons-png.flaticon.com/512/2275/2275248.png",
      },
      {
        name: "Requests",
        route: "/employees/payroll",
        icon: "https://cdn-icons-png.flaticon.com/128/8765/8765386.png",
      },
      {
        name: "Facility",
        route: "/documents/overview",
        icon: "https://cdn-icons-png.flaticon.com/512/2238/2238338.png",
      },
      {
        name: "Planning",
        route: "/documents/overview",
        icon: "https://cdn-icons-png.flaticon.com/512/1055/1055648.png",
      },
      {
        name: "Benefits Management",
        route: "/documents/overview",
        icon: "https://cdn-icons-png.flaticon.com/512/2191/2191179.png",
      },
      {
        name: "Assets Management",
        route: "/documents/overview",
        icon: "https://cdn-icons-png.flaticon.com/512/1907/1907649.png",
      },
      {
        name: "Reports",
        route: "/documents/overview",
        icon: "https://cdn-icons-png.flaticon.com/512/1055/1055644.png",
      },
    ],
  },
];

export default MenuItems;
