import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import App from "../App";
import Auth from "../pages/auth/index";
import Login from "../pages/auth/login/Login";
import Home from "../pages/home/Home";
// Employee
import Employees from "../pages/employees/index";
import AllEmployees from "../pages/employees/all-employees/AllEmployees";

import AddEmployee from "../pages/employees/add-employee/AddEmployee.js";
// Attendance

// Leaves
import Leaves from "../pages/leaves/index";
import LeaveOverview from "../pages/leaves/overview/LeaveOverview";
// Approval
import Approval from "../pages/leaves/index";
import ApprovalOverview from "../pages/approval/overview/ApprovalOverview";
import ApprovalLeaves from "../pages/approval/leaves/ApprovalLeaves";
import Overview from "../pages/employees/overview/Overview";
import NoPageFound from "../pages/static/no-page/NoPageFound";
// Settings
import Settings from "../pages/settings/index";
import EmployeesSettings from "pages/settings/employees-settings/EmployeesSettings";
// Calendar
import Calendar from "../pages/calendar/Calendar";
import Schedules from "../pages/calendar/schedules/Schedules";
// Group
import Group from "../pages/groups/index";
import AllGroups from "../pages/groups/all-groups/AllGroups";
// import AddGroup from "../pages/groups/all-groups/modals/add-group/AddGroup";
import ApprovalStages from "../pages/leaves/leaves/view-leave/components/approval-stages/ApprovalStages";
import ViewLeave from "../pages/leaves/leaves/view-leave/ViewLeave";
import AllApprovals from "../pages/approval/all-approvals/AllApprovals";
import AttendanceIndex from "../pages/attendance";
import GroupsOverview from "pages/groups/overview/GroupsOverview";
import GroupDepartment from "pages/groups/department/GroupDepartment";
import AddGroup from "pages/groups/add-group/AddGroup";
import AllLeaves from "pages/leaves/all-leaves/AllLeaves";
import AddLeave from "pages/leaves/add-leave/AddLeave";
import ViewEmployee from "pages/employees/view-employee/ViewEmployee";
import ViewEmployeeOverview from "pages/employees/view-employee/views/overview/ViewEmployeeOverview";
import AllAttendance from "pages/attendance/all-attendance/AllAttendance";
import AttendanceLogs from "pages/attendance/attendance-logs/AttendanceLogs";
import AttendanceOverview from "pages/attendance/overview/AttendanceOverview";
import AttendanceTimesheet from "pages/attendance/timesheet/AttendanceTimesheet";
import ViewEmployeePersonal from "pages/employees/view-employee/views/personal/ViewEmployeePersonal";
import GenerateAttendance from "pages/attendance/generate-attendance/GenerateAttendance";
import ViewEmmployeeAttendance from "pages/employees/view-employee/views/attendance/ViewEmployeeAttendance";
import ViewEmployeeLeaves from "pages/employees/view-employee/views/leaves/ViewEmployeeLeaves";
import EditEmployeeDetails from "pages/employees/view-employee/views/overview/modals/EditEmployeeDetails";
import EditEmployeePersonalInfo from "pages/employees/view-employee/views/personal/modals/EditEmployeePersonalInfo.tsx";
import EmployeePersonalAddModal from "pages/employees/view-employee/views/personal/modals/add-modal/EmployeePersonalAddModal";
import ViewEmployeeDocuments from "pages/employees/view-employee/views/documents/ViewEmployeeDocuments";
import ViewEmployeeJobs from "pages/employees/view-employee/views/jobs/ViewEmployeeJobs";
import ViewEmployeePayroll from "pages/employees/view-employee/views/payroll/ViewEmployeePayroll";
import Approvals from "pages/approval";
import AddEmployeeDocument from "pages/employees/view-employee/views/documents/modals/AddEmployeeDocument";
import EmployeeResetPassword from "pages/employees/view-employee/modals/EmployeeResetPassword";
import PayrollIndex from "pages/payroll";
import PayrollOverview from "pages/payroll/overview/PayrollOverview";
import GeneratePayroll from "pages/payroll/generate-payroll/GeneratePayroll";
import ViewEmployeeLoans from "pages/employees/view-employee/views/loans/ViewEmployeeLoans";
import AddEmployeeLoans from "pages/employees/view-employee/views/loans/add-loan/AddEmployeeLoans";
import ViewEmployeeTimesheet from "pages/employees/view-employee/views/timesheet/ViewEmployeeTimesheet";
import ViewEmployeeTaskSheet from "pages/employees/view-employee/views/tasksheet/ViewEmployeeTaskSheet";
import TaskSheetIndex from "pages/task-sheet";
import TaskSheetOverview from "pages/task-sheet/overview/TaskSheetOverview";
import TaskSheetTasks from "pages/task-sheet/tasks/TaskSheetTasks";
import Tasks from "pages/task-sheet/tasks/views/Tasks";
import AttendanceSignInLogs from "pages/attendance/sign-in-logs/AttendanceSignInLogs";
import ViewAttendance from "pages/attendance/view-attendance/ViewAttendance";
import EchesCalendar from "pages/calendar/overview/EchesCalendar.tsx";
import InactiveEmployees from "../pages/employees/inactive-employees/InactiveEmployees";
import Designations from "../pages/employees/designations/Designations";
import ModalRegisterer from "modals/ModalRegisterer";
import Onboarding from "pages/employees/onboarding/Onboarding";
import AddAllowances from "pages/payroll/allowances/AddAllowances";
import AssignAllowances from "pages/payroll/allowances/AssignAllowance";
import UpdateAllowance from "pages/payroll/allowances/UpdateAllowance";
import AttendanceSettings from "pages/settings/attendace-settings/AttendanceSettings";
import LeaveSetting from "pages/settings/leaves-settings/LeaveSettings";
import PayrollSettings from "pages/settings/payroll-settings/PayrollSettings";
import GroupSettings from "pages/settings/group-settings/GroupSettings";
import ReportsSettings from "pages/settings/reports-settings/ReportsSettings";
import CalendarSettings from "pages/settings/calendar-settings/CalendarSettings";
import DesignationSettings from "pages/settings/designation-settings/DesignationSettings";
import Snapshot from "pages/task-sheet/Snapshot";

export const PageRoutes = () => {
  let location = useLocation();

  return (
    <Routes location={location} key={location.pathname}>
      {/* Auth Routes */}

      <Route path="auth/" element={<Auth />}>
        <Route path="login" element={<Login />} />
      </Route>
      {/* Global Modal route */}
      <Route
        path="modal/:modalIdentifier&:size"
        element={<ModalRegisterer />}
      />
      {/* Home Routes */}
      <Route path="/" element={<Home />}></Route>
      {/* Employees Routes */}
      <Route path="employees/" element={<Employees />}>
        <Route path="overview" element={<Overview />} />
        <Route path="Onboarding/:step" element={<AddEmployee />} />
        <Route path="Designations" element={<Designations />} />
        <Route path="all" element={<AllEmployees />} />
        <Route path="inactive-employees" element={<InactiveEmployees />} />
        <Route path="add" element={<AddEmployee />} />
        {/* Employee View  */}
        <Route
          path="view/EmployeeId=:EmployeeId&Name=:Name/"
          element={<ViewEmployee />}
        >
          <Route path="ResetPassword" element={<EmployeeResetPassword />} />
          <Route path="overview/" element={<ViewEmployeeOverview />}>
            <Route
              path="EditEmployeeDetails/Modal=:Modal"
              element={<EditEmployeeDetails />}
            />
          </Route>
          <Route path="Personal/" element={<ViewEmployeePersonal />}>
            <Route
              path="EditPersonalInfo/Modal=:Modal"
              element={<EditEmployeePersonalInfo />}
            />
            <Route
              path="Add/Type=:AddModalType&Modal=:Modal"
              element={<EmployeePersonalAddModal />}
            />
          </Route>
          <Route path="Attendance" element={<ViewEmmployeeAttendance />} />
          <Route path="Leaves" element={<ViewEmployeeLeaves />} />
          <Route path="documents" element={<ViewEmployeeDocuments />}>
            <Route
              path="AddDocument/Modal=:Modal"
              element={<AddEmployeeDocument />}
            />
          </Route>
          <Route path="jobs" element={<ViewEmployeeJobs />} />
          <Route path="payroll" element={<ViewEmployeePayroll />} />
          <Route path="loans/" element={<ViewEmployeeLoans />}>
            <Route path="AddLoan/Modal=:Modal" element={<AddEmployeeLoans />} />
          </Route>
          <Route path="Timesheet" element={<ViewEmployeeTimesheet />}></Route>
          <Route path="TaskSheet" element={<ViewEmployeeTaskSheet />}></Route>
        </Route>
      </Route>
      {/* Attendance */}
      <Route path="Attendance/" element={<AttendanceIndex />}>
        <Route path="Overview" element={<AttendanceOverview />} />
        <Route path="All" element={<AllAttendance />}></Route>
        <Route path="AttendanceLogs" element={<AttendanceLogs />}></Route>
        <Route path="Timesheet" element={<AttendanceTimesheet />} />
        <Route
          path="GenerateAttendance/Modal=:Modal"
          element={<GenerateAttendance />}
        />
        <Route path="SignInLogs" element={<AttendanceSignInLogs />} />
        <Route
          path="ViewAttendanceOn/date=:date&EmployeeId=:EmployeeId"
          element={<ViewAttendance />}
        />
      </Route>
      {/* Leaves */}
      <Route path="leaves/" element={<Leaves />}>
        <Route path="overview" element={<LeaveOverview />} />
        <Route path="all" element={<AllLeaves />} />
        <Route
          path="AddLeave/Modal=:Modal&LeaveType=:LeaveType"
          element={<AddLeave />}
        />
        <Route path="view/id=:id" element={<ViewLeave />}></Route>
        <Route path="add" element={<AddLeave />}></Route>
      </Route>
      {/* Approval */}
      <Route path="Approvals/" element={<Approvals />}>
        <Route path="Overview" element={<ApprovalOverview />} />
        <Route path="All" element={<AllApprovals />} />
        <Route path="Leaves" element={<ApprovalLeaves />} />
      </Route>
      {/* Documents */}
      <Route path="documents/" element={<Approval />}>
        <Route path="overview" element={<ApprovalOverview />} />
      </Route>
      {/* Settings */}
      <Route path="settings/" element={<Settings />}>
        <Route path="Employees" element={<EmployeesSettings />} />
        <Route path="Designations" element={<DesignationSettings />} />
        <Route path="Attendance" element={<AttendanceSettings />} />
        <Route path="Leave" element={<LeaveSetting />} />
        <Route path="Payroll" element={<PayrollSettings />} />
        <Route path="Group" element={<GroupSettings />} />
        <Route path="Reports" element={<ReportsSettings />} />
        <Route path="Calendar" element={<CalendarSettings />} />
      </Route>
      <Route path="calendar" element={<Calendar />}>
        <Route path="overview" element={<EchesCalendar />} />
        <Route path="schedules" element={<Schedules />} />
      </Route>
      <Route path="*" element={<NoPageFound />} />

      {/* Groups */}
      <Route path="Groups/" element={<Group />}>
        <Route path="Overview" element={<GroupsOverview />} />
        <Route path="All" element={<AllGroups />} />
        {/* <Route  path="add" element={<AddGroup />} />
            <Route  path="view/id=:id" element={<ViewGroup />}></Route> */}
        <Route path="Department" element={<GroupDepartment />} />
        <Route
          path="AddGroup/Modal=:Modal&groupType=:groupType"
          element={<AddGroup />}
        ></Route>
      </Route>
      {/* Payroll */}
      <Route path="Payroll/" element={<PayrollIndex />}>
        <Route path="Overview" element={<PayrollOverview />} />
        <Route
          path="GeneratePayroll/Modal=:Modal"
          element={<GeneratePayroll />}
        />
        <Route path="add-allowance" element={<AddAllowances />} />
        <Route path="assign-allowance" element={<AssignAllowances />} />
        <Route path="update-allowance" element={<UpdateAllowance />} />
      </Route>
      {/* Task Sheet routes */}
      <Route path="TaskSheet/" element={<TaskSheetIndex />}>
        <Route path="Overview" element={<TaskSheetOverview />} />
        <Route path="Tasks/" element={<TaskSheetTasks />}>
          <Route path="Type=:Type" element={<Tasks />} />
        </Route>
        <Route path="Timesheet" element={<AttendanceTimesheet />} />
        <Route path="Snapshots" element={<Snapshot />} />
      </Route>
    </Routes>
  );
};
