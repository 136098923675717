export const menuItems = [
  {
    title: "Overview",
    path: "/Employees/Overview",
  },
  {
    title: "Onboarding",
    path: "/Employees/Onboarding/Personal Info",
  },
  {
    title: "Designations",
    path: "/Employees/Designations",
  },
  {
    title: "Active Employees",
    path: "/Employees/All",
  },
  {
    title: "Inactive Employees",
    path: "/Employees/inactive-employees",
  },
];
