import { ClockIcon } from "@heroicons/react/outline"

const TimePicker = ({title = "", onTimeChange = () => null}) => {
    return (
        <div>
            <div className="mb-1 text-xs">{title}</div>
            <input 
                type="time" 
                onChange={e => onTimeChange(e.target.value)} 
                name="time"
                className=" p-0.5 px-2 border border-gray-200 w-fit cursor-pointer h-8 text-xs rounded-lg flex justify-between items-center flex-row space-x-3 bg-white font-semibold hover:brightness-95"
            />
        </div>
    )
}

export default TimePicker