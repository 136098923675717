import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import OfficeAnimation from "assets/lotties/office.json";
import customAxios from "../../../middlewares/axios-interceptor/customAxios";
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import jwtDecode from "jwt-decode";
import Axios from "axios";
import axios from "axios";
import Lottie from "react-lottie-player";

export default function Login() {
  let navigate = useNavigate();
  const [submitted, setSubmitted] = React.useState(false);
  const [tokenData, setTokenData] = React.useState();
  const [error, setError] = React.useState();
  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    setSubmitted(true);
    let data = {
      companyId: values.tenantId,
      username: values.email,
      password: values.password,
      client_id: "e7bc573b-380b-4c9c-8698-4de65c995c52",
      grant_type: "password",
    };
    //Get token from Eches Auth API
    const tokenResponse = await customAxios
      .post("https://auth.echeshr.com/token", data)
      .catch((err) => {
        console.log(err);
        setError(err);
      });
    //Authorize received token from EchesHr API using fetch
    const authResponse = await axios
      .post(
        // process.env.NODE_ENV === "production"
        //   ? "https://api.echeshr.com/v1/authorize"
        //   : "http://localhost/v1/authorize",
        "https://api.echeshr.com/v1/authorize",
        {},
        {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        }
      )
      .catch((err) => {
        console.log(err);
        setError(err);
        setSubmitted(false);
      });

    if (authResponse.status === 200) {
      console.log(authResponse.data?.access_token);
      localStorage.setItem("token", authResponse.data?.access_token);
      localStorage.setItem(
        "tokenData",
        JSON.stringify(jwtDecode(authResponse?.data?.access_token))
      );
      customAxios.get("/app/init").then((res) => {
        localStorage.setItem("init", JSON.stringify(res));
      });

      customAxios.get("/app/variables").then((res) => {
        localStorage.setItem("variables", JSON.stringify(res));
      });
      customAxios.get("/group").then((res) => {
        localStorage.setItem("groups", JSON.stringify(res));
      });
      navigate("/");
    }

    resetForm();
  };

  React.useEffect(() => {
    // checkAuthStatus();
  }, []);

  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center bg-gradient-to-t from-slate-400 via-orange-500 to-slate-300 ">
      <div className=" flex w-[30vw]  justify-center items-center ">
        <div className="max-w-lg flex flex-col space-y-5 justify-center   p-5 shadow-xl rounded-xl bg-white py-10">
          <img
            class="mx-auto h-12 mb-2  w-auto animate-rotate"
            src="https://tailwindui.com/img/logos/mark.svg?color=orange&shade=600"
            alt="Your Company"
          />
          <h1 className="text-2xl font-semibold text-center">
            Welcome to EchesHR
          </h1>
          {/* Lottie files */}
          <div className="flex justify-center items-center">
            <Lottie
              loop
              animationData={OfficeAnimation}
              play
              style={{ width: 200, height: 200 }}
            />
          </div>

          <h5 className="text-sm text-gray-600 text-center">
            Please enter the following information to login
          </h5>
          {/* Error and Success Server Message */}
          {submitted && error && (
            <div className="w-full pb-2">
              {/* Error and Success Server Message */}
              {error && (
                <div
                  className={`w-full ${
                    error == null ? "bg-success" : "bg-red-100"
                  } p-2 rounded-xl`}
                >
                  <div className="flex flex-row space-x-3 justify-start items-center">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-10 h-10 text-red-800"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                    <div className="flex-col justify-start items-start text-left">
                      <h1 className="text-xs font-medium">
                        {error ? "Error" : "Successful"}
                      </h1>
                      <div className="text-xs text-grey-900">{error}</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <FormikProvider value={formik}>
            <Form className="flex flex-col space-y-5 w-[23vw]">
              <div className="flex flex-col space-y-3 px-10">
                {/* <div className="flex flex-col space-y-1 w-1/3">
                  <label style={{ fontSize: 10 }}>Tenant ID</label>
                  <Field
                    name="tenantId"
                    type="text"
                    className="form-control text-md focus:outline-none  border p-1 px-2 border-gray-200 "
                  />
                  <ErrorMessage
                    name="tenantId"
                    component="div"
                    className="text-red-600 text-md"
                  />
                </div> */}
                <div className="flex flex-col space-y-2 w-full">
                  <label style={{ fontSize: 10 }}>Email</label>
                  <Field
                    name="email"
                    type="text"
                    className="form-control text-sm focus:outline-none rounded-md my-1  border p-1 px-2 border-gray-200 "
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-600 text-sm capitalize"
                  />
                </div>
                <div className="flex flex-col space-y-2 w-full">
                  <label style={{ fontSize: 10 }}>Password</label>
                  <Field
                    name="password"
                    type="password"
                    className="form-control text-sm focus:outline-none rounded-md my-1  border p-1 px-2 border-gray-200 "
                  />
                  {/* show password */}

                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-red-600 text-sm capitalize"
                  />
                </div>
              </div>
              <div className="flex items-center justify-center">
                {submitted ? (
                  <button
                    type="button"
                    className=" flex flex-row justify-between text-orange-600 text-md font-medium p-2 px-6 rounded-md cursor-not-allowed"
                  >
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-orange-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v1a7 7 0 00-7 7h1z"
                      ></path>
                    </svg>
                    Signing you in...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="bg-orange-600 text-white text-sm font-medium p-2 px-6 rounded-md hover:bg-orange-800"
                  >
                    Login
                  </button>
                )}
              </div>
            </Form>
          </FormikProvider>
        </div>
      </div>
      <p className="text-md text-white fixed bottom-10">
        If you face any technical difficulties please contact 9993529 or visit
        our chat services here
      </p>
    </div>
  );
}
