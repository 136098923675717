import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Addresses from "pages/employees/add-employee/components/Addresses";
import Documents from "pages/employees/add-employee/components/Documents";
import Education from "pages/employees/add-employee/components/Education";
import EmploymentDetails from "pages/employees/add-employee/components/EmploymentDetails";
import Experience from "pages/employees/add-employee/components/Experience";
import PersonalInfo from "pages/employees/add-employee/components/PersonalInfo";
import { ReviewAndCreate } from "pages/employees/add-employee/components/ReviewAndCreate";

const initialState = {
  allEmployees: [],
  status: "",
  selectedEmployee: null,
  employeeLoans: null,

  addEmployee: {
    steps: [
      {
        index: 1,
        name: "Personal Info",
        component: <PersonalInfo />,
      },
      {
        index: 2,
        name: "Employment Details",
        component: <EmploymentDetails />,
      },
      {
        index: 3,
        name: "Address",
        component: <Addresses />,
      },

      {
        index: 5,
        name: "Documents",
        component: <Documents />,
      },
      {
        index: 6,
        name: "Education",
        component: <Education />,
      },
      {
        index: 7,
        name: "Experience",
        component: <Experience />,
      },
      {
        index: 8,
        name: "Review",
        component: <ReviewAndCreate />,
      },
    ],
    selectedTab: null,
    designations: [],
    personal: {
      errorStatus: false,
      error: null,
      data: null,
    },
    employment: {
      errorStatus: false,
      error: null,
      data: null,
    },
    contacts: {
      errorStatus: false,
      error: null,
      data: [],
    },
    addresses: {
      errorStatus: false,
      error: null,
      data: [],
    },
    documents: {
      errorStatus: false,
      error: null,
      data: [],
    },
    education: {
      errorStatus: false,
      error: null,
      data: [],
    },
    experience: {
      errorStatus: false,
      error: null,
      data: [],
    },
  },
};

// export const fetchEmployees = createAsyncThunk(
//   "employees/fetchEmployees",
//   async () => {
//     const response = await customAxios.get("/employee");
//     return response;
//   }
// );

export const EmployeesSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    // Set Selected Tab
    setSelectedTab: (state, action) => {
      state.addEmployeee.selectedTab = action.payload;
    },
    // set designations
    setDesignations: (state, action) => {
      state.addEmployee.designations = action.payload;
    },
    // Personal
    addPersonal: (state, action) => {
      state.addEmployee.personal.data = action.payload;
    },
    removeBasic: (state, action) => {
      state.pop();
    },
    // Employment
    addEmployment: (state, action) => {
      state.addEmployee.employment.data = action.payload;
    },
    // Contacts
    addContacts: (state, action) => {
      state.contacts.data.push(action.payload);
    },
    // delete Contact
    deleteContact: (state, action) => {
      let deletingContact = action.payload;
      state.addEmployee.contacts.data = state.addEmployee.contacts.data.filter(
        (contact) => {
          return contact.type.id !== deletingContact.type.id;
        }
      );
    },
    // Addresses
    addAddresses: (state, action) => {
      // check if address type already exists
      let addressType = action.payload.type.id;
      let addressExists = state.addEmployee.addresses.data.filter((address) => {
        return address.type.id === addressType;
      });
      if (addressExists.length > 0) {
        return;
      }
      state.addEmployee.addresses.data.push(action.payload);
    },
    // delete Address
    deleteAddress: (state, action) => {
      let deletingAddress = action.payload;
      state.addEmployee.addresses.data =
        state.addEmployee.addresses.data.filter((address) => {
          return address.type.id !== deletingAddress.type.id;
        });
    },
    // Addresses
    addDocuments: (state, action) => {
      state.documents.data.push(action.payload);
    },
    // delete Document
    deleteDocument: (state, action) => {
      let deletingDocument = action.payload;
      state.addEmployee.documents.data =
        state.addEmployee.documents.data.filter((document) => {
          return document.type.id !== deletingDocument.type.id;
        });
    },

    setSelectedEmployee: (state, action) => {
      state.selectedEmployee = action.payload;
    },
    setEmployeeLoans: (state, action) => {
      state.employeeLoans = action.payload;
    },
  },
  // extraReducers(builder) {
  //   builder
  //     .addCase(fetchEmployees.pending, (state, action) => {
  //       state.status = "loading";
  //     })
  //     .addCase(fetchEmployees.fulfilled, (state, action) => {
  //       state.status = "succeeded";
  //       state.allEmployees = action.payload;
  //     })
  //     .addCase(fetchEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});

export const {
  setSelectedTab,
  addPersonal,
  removeBasic,
  addContacts,
  deleteContact,
  addAddresses,
  deleteAddress,
  addDocuments,
  deleteDocument,
  setSelectedEmployee,
  setEmployeeLoans,
  setDesignations,
  addEmployment,
} = EmployeesSlice.actions;

// Selectors
export const selectEmployees = (state) => state.employees
export const selectStatus = (state) => state.employees.status;
export const selectTab = (state) => state.employees.selectedTab;
export const selectEmployee = (state) => state.employees.selectedEmployee;
export const selectAllEmployees = (state) => state.employees.allEmployees;
export const selectEmployment = (state) =>
  state.employees.addEmployee.employment;
export const selectPersonal = (state) => state.employees.addEmployee.personal;
export const selectContacts = (state) => state.employees.addEmployee.contacts;
export const selectAddresses = (state) => state.employees.addEmployee.addresses;
export const selectDocuments = (state) => state.employees.addEmployee.documents;
export const selectBasics = (state) => state.employees.addEmployee.basic;
export const selectEducation = (state) => state.employees.addEmployee.education;
export const selectExperience = (state) =>
  state.employees.addEmployee.experience;
export const selectEmployeeLoans = (state) => state.employees.employeeLoans;
export const selectDesignations = (state) =>
  state.employees.addEmployee.designations;

export default EmployeesSlice.reducer;
