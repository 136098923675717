import DataGrid from "components/data-grid/DataGrid";
import Widget from "components/widget/Widget";
import {
  setBreadCrumbs,
  setSelectedMenu,
  setSelectedSubMenu,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import customAxios from "middlewares/axios-interceptor/customAxios";
import React from "react";
import { useDispatch } from "react-redux";
import { menuItems } from "../MenuItems";
import { columns } from "./columns";
import moment from "moment";

export default function AllLeaves() {
  let [selectedRow, setSelectedRow] = React.useState(null);
  let [data, setData] = React.useState([]);
  let [paginationData, setPaginationData] = React.useState({});
  let [loading, setLoading] = React.useState(false);
  let dispatch = useDispatch();

  React.useEffect(() => {
    customAxios.get("/leave").then((res) => {
      setPaginationData({
        count: res.count,
        hasNexPage: res.hasNextPage,
        hasPrevPage: res.hasPrevPage,
        pageIndex: res.pageIndex,
        pageSize: res.pageSize,
        totalPages: res.totalPages,
      });
      arrangeData(res.items);
    });
  }, []);

  async function arrangeData(items) {
    let tempData = [];
    items.map((d) => {
      tempData.push({
        id: d.id,
        name: d.employee?.name,
        empCode: d.employeeId,
        leaveTypeId: d.leaveTypeId,
        leaveType: d.leaveType?.name,
        startDate: moment(d.from).format("D MMMM yyyy"),
        endDate: moment(d.to).format("D MMMM yyyy"),
        effectiveQty: d.workingDays,
        totalDays: d.totalDays,
        remarks: d.remarks,
        status: d.status ?? "Pending",
        documents: [],
        createdAt: moment(d.createdAt).format("D MMMM yyyy"),
      });
    });
    // console.log(tempData);
    setData(tempData);
    setLoading(false);
  }

  React.useEffect(() => {
    // dispatch(setSelectedMenu(menuItems[1]));
    dispatch(setSelectedSubMenu(menuItems[1]));
    dispatch(setSubHeader("All Leaves"));
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Leaves", link: "/Leaves" },
      ])
    );
  }, []);

  function cellFunction(cell) {
    if (cell.column.id == "remarks") {
      return <div className="w-[200px] text-limit">{cell.value}</div>;
    } else if (cell.column.id == "status") {
      return (
        <div className="flex justify-start items-center text-center ">
          <p
            style={{
              color:
                cell.value == "approved"
                  ? "#22c55e"
                  : cell.value == "rejected"
                    ? "#f52c2c"
                    : cell.value == "closed"
                      ? "#808080"
                      : "#f59d40",
            }}
            className="py-0.4 w-20  rounded-full font-bold capitalize flex items-start justify-start"
          >
            {cell.value ?? "N/A"}
          </p>
        </div>
      );
    } else if (cell.column.id == "documents") {
      return (
        <div className="flex flex-col space-y-1 justify-start">
          {cell.value?.map((document, i) => (
            <div key={i}>
              <div className="text-orange-800 hover:underline cursor-pointer">
                {document.name}
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return cell.render("Cell");
    }
  }

  return (
    <div className="h-full">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Widget heading={"All Leaves"}>
          <DataGrid
            showHeader={true}
            cellFunction={cellFunction}
            columns={columns}
            testData={data}
            selectable={true}
            pagination={true}
            path={"/Leaves/View/id="}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
          />
        </Widget>
      )}
    </div>
  );
}
