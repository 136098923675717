import Widget from "components/widget/Widget";
import React from "react";
import SecondaryButton from "ui/buttons/SecondaryButton";
import { useNavigate, useParams } from "react-router-dom";
import customAxios from "../../../middlewares/axios-interceptor/customAxios";

export default function EmployeePersonalInformation({ Data }) {
  let navigate = useNavigate();
  let url = window.location.pathname;
  let { EmployeeId } = useParams();

  function loadPersonalInfo() {
    customAxios.get("/api/employees/" + EmployeeId).then((res) => {
      Data.initialValues = res;
    });
  }

  return (
    <Widget
      heading={"Personal Information"}
      // component={function () {
      //   return (
      //     <div className="flex flex-col space-y-3">
      //       <SecondaryButton>
      //         <button
      //           onClick={() => navigate(url + "/EditPersonalInfo/Modal=true")}
      //           className="text-orange-600"
      //         >
      //           Edit Personal Information
      //         </button>
      //       </SecondaryButton>
      //     </div>
      //   );
      // }}
    >
      <div className="p-3">
        <div className="grid grid-rows-3 grid-flow-col gap-4 text-sm">
          {Object.keys(Data).map((key, index) => {
            return (
              <div className="row-span-1 flex flex-col space-y-1.5">
                <div className="capitalize text-xs">
                  <p>
                    {key
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                  </p>
                </div>
                <div className=" font-semibold">
                  <p>{Data[key]}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Widget>
  );
}
