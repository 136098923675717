import Widget from "components/widget/Widget";
import {
  selectGeneralData,
  selectSelectedEmployees,
} from "features/groups/GroupsSlice";
import customAxios from "middlewares/axios-interceptor/customAxios";
import React from "react";
import { useSelector } from "react-redux";
import SecondaryButton from "ui/buttons/SecondaryButton";

export default function ReviewAndSubmit() {
  let generalInfo = useSelector(selectGeneralData);
  let selectedEmployees = useSelector(selectSelectedEmployees);
  let [submitted, setSubmitted] = React.useState(false);

  function onSubmit() {
    setSubmitted(true);
    let data = {
      name: generalInfo.name,
      description: generalInfo.description,
      employees: selectedEmployees.map((employee) => employee.id),
    };
    customAxios.post("/group/create", data).then(
      (res) => {
        console.log(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  return (
    <div className="flex flex-col space-y-2">
      <Widget heading="Review & Submit">
        <div className="grid grid-cols-2 gap-2">
          <Widget secondary={true} heading="General Information">
            <div className="flex flex-col space-y-2 text-sm">
              <div className="flex flex-row space-x-2">
                <div className="w-1/3 text-slate-600">Group Name</div>
                <div className="w-2/3 font-medium">{generalInfo?.name}</div>
              </div>
              <div className="flex flex-row space-x-2">
                <div className="w-1/3 text-slate-600">Description</div>
                <div className="w-2/3 font-medium">
                  {generalInfo?.description}
                </div>
              </div>
            </div>
          </Widget>
          <Widget secondary={true} heading="Selected Employees">
            <div className="flex flex-col justify-between space-y-2 text-sm">
              <div className="flex flex-row text-sm font-medium  space-x-2">
                {selectedEmployees ? selectedEmployees[0].name : "N/A"} and{" "}
                {selectedEmployees?.length - 1} others selected
              </div>
            </div>
          </Widget>
        </div>
      </Widget>
      <div>
        <button
          className="px-6 p-1.5 w-fit text-sm border border-slate-300 flex items-center justify-center text-slate-50 bg-orange-400 font-semibold rounded-xl shadow-md hover:brightness-95"
          onClick={() => onSubmit()}
        >
          Next
        </button>
      </div>
    </div>
  );
}
