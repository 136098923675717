import Widget from "components/widget/Widget";
import { setBreadCrumbs } from "features/navigation/NavigationSlice";
import customAxios from "middlewares/axios-interceptor/customAxios";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import EmployeeAttendanceChart from "widgets/employee/employee-card/EmployeeAttendanceChart";
import EmployeeAttendanceList from "widgets/employee/employee-card/EmployeeAttendanceList";

export default function ViewEmmployeeAttendance() {
  let { Name, EmployeeId } = useParams();
  let dispatch = useDispatch();

  React.useEffect(() => {
    customAxios.get(`/api/employee/attendance`).then((res) => {
      console.log(res);
    });
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Employees", link: "/employees/all" },
        { name: `${Name}`, link: `` },
        { name: "Attendance", link: `` },
      ])
    );
  }, []);

  return (
    <div className="grid grid-cols-4 overflow-auto h-[75vh] pb-10  gap-3 ">
      <div className="col-span-4 min-h-full">
        <EmployeeAttendanceList />
      </div>
    </div>
  );
}
