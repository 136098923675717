import Modal from "components/modal/Modal";
import StepModal from "components/step-modal/StepModal";
import Widget from "components/widget/Widget";
import {
  setBreadCrumbs,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import Tabs from "layout/navigation/Tabs";
import React from "react";
import { useDispatch } from "react-redux";
import GeneralInformation from "./steps/general-information/GeneralInformation";
import ReviewAndSubmit from "./steps/review-and-submit/ReviewAndSubmit";
import SelectEmployees from "./steps/select-employees/SelectEmployees";
import CModal from "components/modal/Modal";

let tabData = [
  {
    index: 1,
    name: "General Information",
    component: <GeneralInformation />,
  },
  {
    index: 2,
    name: "Select Employees",
    component: <SelectEmployees />,
  },
  {
    index: 3,
    name: "Review & Submit",
    component: <ReviewAndSubmit />,
  },
];

export default function AddGroupDepartment() {
  let [selectedTab, setSelectedTab] = React.useState(tabData[0]);

  return (
    <div className="flex flex-col space-y-2">
      <CModal>
        <div>This is a Form the longer I make the longer the form</div>
        <div>For Department</div>
      </CModal>
      <StepModal
        TabData={tabData}
        SelectedTab={selectedTab}
        SetSelectedTab={setSelectedTab}
      />
    </div>
  );
}
