import { ArrowCircleRightIcon } from "@heroicons/react/outline";
import Widget from "components/widget/Widget";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function EmployeeLeaveApprovalStages({ data }) {
  let navigate = useNavigate();
  let url = window.location.pathname;

  function statusRender(status) {
    if (status == "approved") {
      return <span className="text-green-500">{status}</span>;
    } else if (status == "rejected") {
      return <span className="text-red-500">{status}</span>;
    } else {
      return <span className="text-yellow-500">{status}</span>;
    }
  }

  return (
    <Widget heading={"Approval Stages"}>
      <div className=" flex flex-row space-x-3 ">
        {data?.map((item, index) => {
          return (
            <div className="flex justify-between w-full items-center">
              <div className="p-3 w-full text-center border border-slate-200 rounded-xl shadow-md">
                <h1 className="border-slate-200 text-white rounded-lg bg-orange-300 border-b py-1 font-semibold text-sm">
                  {item.stage ?? "N/A"}
                </h1>
                <div className="flex flex-col divide divide-slate-200"></div>
                {item?.approvers?.map((approver, index) => {
                  console.log(approver.status);
                  return (
                    <div className="flex flex-col justify-center items-start">
                      <div className="flex flex-row justify-between items-center p-2 text-left w-full">
                        <div className="flex flex-col space-y-1">
                          <p className="text-sm font-semibold">
                            {approver?.name}
                          </p>
                          <p className="text-xs text-slate-500">
                            {approver?.designation}
                          </p>
                        </div>
                        <div className="flex flex-col space-y-1">
                          <p className="text-xs uppercase font-semibold">
                            {statusRender(approver?.status)}
                          </p>
                          <p className="text-xs text-slate-500">
                            {approver?.approverDate}
                          </p>
                        </div>
                      </div>
                      {approver?.status != "pending" && (
                        <div className="p-2 text-sm">
                          <h1 className="text-slate-400 border-b border-slate-100 w-full">
                            Remarks
                          </h1>
                          <p>{approver?.remarks}</p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              {/* Check if the item is not the last */}
              {index !== data.length - 1 && (
                <ArrowCircleRightIcon className="w-5 h-5 ml-2" />
              )}
            </div>
          );
        })}
      </div>
    </Widget>
  );
}
