import customAxios from "middlewares/axios-interceptor/customAxios";
import React, { useEffect, useState } from "react";

type Props = {
  title: String;
  endpoint?: string;
  columns: string[];
  data?: any[];
  selectable?: boolean;
  showHeader?: boolean;
  search?: boolean;
  objectDefaultToId?: boolean;
  arrayDefaultToCount?: boolean;
};

//uncamel case
function unCamelCase(str: string) {
  return str
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, function (str) {
      return str.toUpperCase();
    })
    .trim();
}

export default function AdvanceDataGrid(props: Props) {
  let [data, setData] = useState([] as any[]);
  let [headers, setHeaders] = useState([] as any[]);
  let [loading, setLoading] = useState(false);
  let [isPaginated, setIsPaginated] = useState(false);

  async function fetchRemoteData() {
    // fetch data from endpoint
    const result = (await customAxios.get(props.endpoint)) as any;

    if (result.hasOwnProperty("items")) {
      //Map data to headers
      const final = result.items.map((item: any) => {
        let newItem: any = {};
        props.columns.map((column) => {
          if (column.includes(".")) {
            let split = column.split(".");
            newItem[split[0]] = item[split[0]][split[1]];
          } else {
            newItem[column] = item[column];
          }
        });

        return newItem;
      });

      setData(final);
      setIsPaginated(true);
    }
  }

  function setColumns() {
    props.columns.map((column) => {
      if (column.includes(".")) {
        let split = column.split(".");
        setHeaders((prev) => [...prev, split[0]]);
      } else {
        setHeaders((prev) => [...prev, column]);
      }
    });
  }

  useEffect(() => {
    console.log(props);
    if (props.data) {
      setData(props.data);
    }

    props.columns.forEach((element) => {
      console.log(element);
      console.log(element.includes("."));
    });

    if (props.endpoint) {
      fetchRemoteData();
    }

    setColumns();
  }, []);

  return (
    <div className="h-full max-h-screen overflow-auto bg-white  w-full  flex flex-col">
      <div className="header border-b h-20 py-4 px-2 sticky bg-white top-0">
        <div className="flex flex-row items-center h-full justify-between">
          <div className="title text-lg">{props.title}</div>
        </div>
      </div>

      <table className={`${isPaginated ? "mb-20" : "p-2"}`}>
        <thead className="border-b sticky top-14  bg-white  px-2 rounded-xl pb-2">
          <tr className="border-b">
            {props.showHeader &&
              headers.map((column, index) => {
                return <th key={index}>{unCamelCase(column)}</th>;
              })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <tr key={index}>
                {Object.keys(item).map((key, index) => {
                  return <td key={index}>{item[key]}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot
          className="absolute w-full bottom-0  h-14 bg-white border-t border-gray-300  px-2 
        "
        >
          <tr>
            <td className="w-full">{isPaginated && <div></div>}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
