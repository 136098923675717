import GlobalLayout from "layout/GlobalLayout";
import React from "react";
import { Outlet } from "react-router-dom";

export default function Index() {
  const menuItems = [
    {
      title: "Overview",
      path: "/calendar/overview",
    },
    {
      title: "All Schedules",
      path: "schedules",
    },
    {
      title: "ScheduleTypes",
      path: "schedules-Types",
    },
    {
      title: "Holidays",
      path: "holidays",
    },
    {
      title: "Calendars",
      path: "calendars",
    },
    {
      title: "Work Schedular",
      path: "scheduler?type=work",
    },
    {
      title: "Settings",
      path: "settings",
    },
  ];

  return (
    <GlobalLayout
      mainTitle={"Calendar"}
      heading={"Calendar"}
      selectedMenu={menuItems[1]}
      MenuItems={menuItems}
      buttons={[]}
    >
      <Outlet />
    </GlobalLayout>
  );
}
