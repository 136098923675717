export const columns = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Employee Code",
    accessor: "employeeId",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Leave Type",
    accessor: "leaveType",
  },
  {
    Header: "Start",
    accessor: "startDate",
  },
  {
    Header: "End",
    accessor: "endDate",
  },
  {
    Header: "Working Days",
    accessor: "effectiveQty",
  },
  // {
  //   Header: "Remarks",
  //   accessor: "remarks",
  //   maxWidth: 100,
  //   minWidth: 50,
  //   width: 50,
  // },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Applied On",
    accessor: "createdAt",
  },
];
