import { useDispatch } from "react-redux"
import { setSelectedSubMenu } from "features/navigation/NavigationSlice"
import MenuItems from "../MenuItems"
import Widget from "components/widget/Widget"
import { useEffect, useState } from "react"
import { getEmployees } from "middlewares/axios-interceptor/quickEndpoints"
import DataGrid, { CONSTANTS as GRIDCONSTANTS } from "components/data-grid/DataGrid"
import { cellFunction } from "./cellFunction"
import columns from "./columns"
import customAxios from "middlewares/axios-interceptor/customAxios"


const EmployeesSettings = () => {
    const [employees, setEmployees] = useState([])
    const dispatch = useDispatch()
    dispatch(setSelectedSubMenu(MenuItems[0]))

    useEffect(() => {
        getEmployees()
            .then(res => {
                console.log(res)
                setEmployees(res)
            })
    }, [])


    const refreshGridData = (row) => {
        customAxios.get("/employee").then((res) => {
            setEmployees(res.items);
        });
    }

    const onActionBegin = (args) => {
        if (args.requestType === GRIDCONSTANTS.GRID_ACTION_SAVE) {
            if (args.action === GRIDCONSTANTS.ACTION_ADD) {
                console.log("ADDY HERE")
                const payload =
                {
                    ...args.entity,
                    identificationNumber: args.entity.idNumber,
                    // positionId: args.entity.
                }

                console.log("SAVE>ADD", payload)
                // customAxios
                //     .post(
                //         "/employee/create",
                //         payload,
                //         {
                //             headers: {
                //                 'Content-Type': 'multipart/form-data'
                //             }
                //         }
                //     )
                //     .then(() => { refreshGridData(); });
            }
            else if (args.action === GRIDCONSTANTS.ACTION_UPDATE) {
                console.log("HMMM, HOW DO I KNOW WHICH PROPERTIES CHANGED TO THEN SEND AN APPROPRIATE PATCH?")
            }
        }
    }




    return (
        <div className=" w-full h-full overflow-auto">
            <Widget className={""}>
                <DataGrid
                    showHeader={true}
                    columns={columns}
                    selectable={false}
                    cellFunction={cellFunction}
                    testData={employees}
                    allowAdd
                    allowEdit
                    onActionBegin={onActionBegin}
                />
            </Widget>
        </div>
    )
}

export default EmployeesSettings