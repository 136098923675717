import { PlusIcon } from "@heroicons/react/solid";
import GlobalLayout from "layout/GlobalLayout";
import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import DropDownButton from "ui/buttons/DropDownButton";
import { menuItems } from "./MenuItems";
import customAxios from "middlewares/axios-interceptor/customAxios";


export default function Index() {
  let [selected, setSelected] = React.useState(null);

  const [leaveTypes, setLeaveTypes] = useState([])

  useEffect(() => {
    customAxios.get("/leave/types").then((res) => {
      setLeaveTypes(res);
    });
  }, [])

  return (
    <GlobalLayout
      mainTitle={"Leaves"}
      heading={"Overview"}
      selectedMenu={menuItems[1]}
      breadCrumbs={[{ title: "Groups" }]}
      MenuItems={menuItems}
      buttons={[
        <DropDownButton
          setSelected={setSelected}
          path="/Leaves/AddLeave/Modal=true&LeaveType="
          title={"Add Leave"}
          items={leaveTypes}
          icon={<PlusIcon className="h-4 w-4" />}
        />,
      ]}
    >
      <Outlet />
    </GlobalLayout>
  );
}
