import TimePicker from "./TimePicker";

const TimeRangePicker = ({onStartTimeChange = () => null, onEndTimeChange = () => null, startTimeTitle = "", endTimeTitle = ""}) => {
    return (
        <div className="flex flex-row items-center text-xs space-x-3">
        <div>
          <div className="mb-1">{startTimeTitle}</div>
          <TimePicker onTimeChange={onStartTimeChange} />
        </div>
          <div>{!(startTimeTitle || endTimeTitle) && "To"}</div>
        <div>
          <div className="mb-1">{endTimeTitle}</div>
          <TimePicker onTimeChange={onEndTimeChange} />
        </div>
      </div>
    )
}

export default TimeRangePicker