import React from "react";
import { useParams } from "react-router-dom";
import customAxios from "../../../../middlewares/axios-interceptor/customAxios";
import Widget from "components/widget/Widget";
import { useDispatch } from "react-redux";
import {
  setBreadCrumbs,
  setSelectedMenu,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import moment from "moment";
import { menuItems } from "../../MenuItems";
import EmployeeLeaveDetails from "widgets/leaves/EmployeeLeaveDetails";
import EmployeeLeaveBalance from "widgets/employee/employee-card/EmployeeLeaveBalance";
import ApprovalStages from "./components/approval-stages/ApprovalStages";
import EmployeeLeaveApprovalStages from "widgets/leaves/EmployeeLeaveApprovalStages";
import ConflictingLeave from "./components/conflicting-leaves/ConflictingLeave";
import LeaveBalance from "pages/employees/view/views/overview/components/LeaveBalance";
import LeaveBalances from "./components/leave-balances/LeaveBalances";
import EmployeeLeaveList from "widgets/employee/employee-card/EmployeeLeaveList";

export default function ViewLeave() {
  let { id, empCode } = useParams();
  let [leaveData, setLeaveData] = React.useState([]);
  let [loading, setLoading] = React.useState(false);
  let [leaveBalance, setLeaveBalance] = React.useState([]);
  let [leaves, setLeaves] = React.useState([]);

  let dispatch = useDispatch();

  React.useEffect(() => {
    customAxios.get(`/leave/find/${id}`).then((res) => {
      setLeaveData(res);
      if (leaveData.employeeId) {
        customAxios.get(`/leave?id=${leaveData.employeeId}`).then(
          (res) => setLeaves(res.items),
          (err) => console.log(err)
        );
        setLoading(false);
      }
    });
  }, [leaveData.employeeId]);

  let leaveDetailsData = {
    leaveType: leaveData.leaveType?.name ?? "N/A",
    startDate: moment(leaveData.from).format("DD MMM YYYY") ?? "N/A",
    endDate: moment(leaveData.to).format("DD MMM YYYY") ?? "N/A",
    duration: leaveData.workingDays + " Day(s)" ?? "N/A",
    status: leaveData.status ?? "N/A",
    employeeId: leaveData.employeeId ?? "N/A",
    appliedOn: moment(leaveData.createdAt).format("DD MMM YYYY") ?? "N/A",
  };

  React.useEffect(() => {
    dispatch(setSelectedMenu(menuItems[0]));
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Leaves", link: "/Leaves/overview" },
        { name: "Leave" },
      ])
    );
    customAxios.get(`/employee/profile/${leaveData.employeeId ?? 124}`).then(
      (res) => {
        setLeaveBalance(
          res?.leaveBalance?.map((item) => {
            return {
              leaveType: item?.leaveType ?? "N/A",
              max: item?.max ?? "N/A",
              days: item?.balance ?? "N/A",
              percentage: (item?.balance / item?.max) * 100 ?? "N/A",
            };
          })
        );
        setLoading(false);
      },
      (err) => console.log(err)
    );
  }, [loading]);

  if (!loading) {
    dispatch(
      setSubHeader(
        `${leaveData.leaveType?.name} Leave Request from ${leaveData?.employee?.name}`
      )
    );
  }

  console.log(id, empCode);

  return (
    <>
      {!loading && leaveData != null && (
        <div className="overflow-auto h-[90vh] pb-20">
          <div className="grid grid-cols-4 gap-3">
            <div className="col-span-4">
              <EmployeeLeaveApprovalStages
                data={leaveData?.request?.approvers}
              />
            </div>
            <EmployeeLeaveDetails data={leaveDetailsData} />
            {/* <EmployeeLeaveBalance leaveBalanceData={leaveBalance} /> */}
            {loading ? (
              <div className="col-span-4">Loading...</div>
            ) : (
              <div className="col-span-2">
                <Widget heading={"Conflicting Leaves"}>
                  <ConflictingLeave LeaveId={leaveData?.id} />
                </Widget>
              </div>
            )}

            {leaveData?.employee && (
              <LeaveBalances
                id={leaveData?.employee?.id}
                empCode={leaveData.employeeId}
              />
            )}
            <div className="col-span-4">
              <EmployeeLeaveList data={leaves} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
