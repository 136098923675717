import React, { Fragment, useEffect } from "react";
import { columns } from "./columns";
import DataTable from "../../../components/data-grid/DataGrid";
import customAxios from "../../../middlewares/axios-interceptor/customAxios";
import { menuItems } from "./../MenuItems";
import Widget from "components/widget/Widget";
import {
  setBreadCrumbs,
  setSelectedSubMenu,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import { useDispatch } from "react-redux";

export default function AllGroups() {
  let [data, setData] = React.useState([]);
  let [loading, setLoading] = React.useState(false);

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedSubMenu(menuItems[1]));
    dispatch(setSubHeader("All Groups"));
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Groups", link: "/groups/overview" },
      ])
    );
  }, []);

  useEffect(() => {
    customAxios.get("/group").then((res) => {
      setData(res);
    });
  }, []);

  function cellFunction(cell) {
    return cell.render("Cell");
  }

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Widget heading={"All Groups"}>
          <DataTable
            showHeader={true}
            cellFunction={cellFunction}
            columns={columns}
            testData={data}
          />
        </Widget>
      )}
    </div>
  );
}
