import { useState, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import customAxios from 'middlewares/axios-interceptor/customAxios'
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Fragment } from 'react';


export default function EmployeeDropdown({ onEmployeeSelect }) {
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
  
    useEffect(() => {
      customAxios.get("/employee")
        .then((res) => {
            setEmployees(res.items ?? [])
      });
    }, []);
  
    return (
        <div>
        <Listbox
            value={selectedEmployee}
            onChange={(employee) => {
            onEmployeeSelect(employee.id);
            setSelectedEmployee(employee);
            }}
        >
            <Listbox.Button className="p-0.5 px-2 border border-gray-200 w-fit h-8 text-xs rounded-lg flex justify-between items-center flex-row space-x-3 bg-white font-semibold">
            <div className="text-xs">
                {selectedEmployee ? selectedEmployee.name : "Select An Employee"}
            </div>
            <ChevronDownIcon className="ml-2 -mr-1 h-4 w-4" aria-hidden="true" />
            </Listbox.Button>
            <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
            <Listbox.Options className="absolute rounded-md mt-1 z-50 w-56 h-fit max-h-56 overflow-auto  origin-bottom-right divide-y divide-gray-100  bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {employees.map((employee) => {
                    return (
                    <Listbox.Option
                        className="cursor-pointer p-2 hover:bg-orange-100 text-xs"
                        key={employee.id}
                        value={employee}
                    >
                        {employee.name ?? ".name not found"}
                    </Listbox.Option>
                    );
                })}
            </Listbox.Options>
            </Transition>
        </Listbox>
      </div>
    );
}