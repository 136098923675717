import endOfDay from "date-fns/endOfDay"
import startOfDay from "date-fns/startOfDay"
import subDays from "date-fns/subDays"
import eachDayOfInterval from "date-fns/eachDayOfInterval"
import isAfter from "date-fns/isAfter"
import { isLate } from "pages/attendance/overview/charts/TodaysAttendance"
import { taskAxios } from "pages/attendance/timesheet/taskAxios/taskAxios"
import axios from "axios"
import format from "date-fns-tz/format"
import { employeeMapper } from "pages/attendance/timesheet/EmployeeMapper"
import { getImmediateTenant } from "pages/attendance/timesheet/HelperFunctions"

export const defaultFormFields = {
    "AsigneeId": "",
    "AsigneeNav": null,
    "StartTime": new Date(),
    "EndTime": new Date(),
    "TimeStart": "",
    "TimeEnd": "",
    "Remarks": "",
    "TimeSpent": "00:00:00",
    "LabourCost": 0,
    "Id": 0,
    "CreatedAt": "2001-01-01T00:00:00",
    "CreatedById": 23,
    "ModifiedAt": "2001-01-01T00:00:00",
    "ModifiedById": 23
}

const MAX_BACKTRACK = 2

const ERROR_MESSAGES = {
    BACKTRACKING: `Task cannot be backtracked by more than ${MAX_BACKTRACK} days`,
    NO_TASK: `Task cannot be empty`,
    NO_START_TIME: `Start Time cannot be empty`,
    NO_END_TIME: `End Time cannot be empty`,
    NO_START_DATE: `Start Date cannot be empty`,
    NO_END_DATE: `End Date cannot be empty`,
    START_DAY_AFTER_END_DAY: `Start day cannot be after end day`,
    START_TIME_AFTER_END_TIME: `Start time cannot be after end time`
}

export const handleImmediateFormErrors = (formFields) => {
    const { AsigneeId, StartTime, EndTime, TimeStart, TimeEnd, Remarks } = formFields
    const today = endOfDay(new Date())
    const taskStartDay = startOfDay(StartTime)

    if(isAfter(StartTime, EndTime))
    {
        return ERROR_MESSAGES.START_DAY_AFTER_END_DAY
    }

    if(isLate(TimeStart, TimeEnd))
    {
        return ERROR_MESSAGES.START_TIME_AFTER_END_TIME
    }

    const daysBetween = eachDayOfInterval({start: taskStartDay, end: today})
    const taskIsEmpty = !Object.keys(AsigneeId).length

    if (daysBetween.length > MAX_BACKTRACK)
    {
        return ERROR_MESSAGES.BACKTRACKING
    }
    return ""
}

export const handleFormErrorsOnSubmit = (formFields) => {
    const { AsigneeId, StartTime, EndTime, TimeStart, TimeEnd, Remarks } = formFields
    const taskIsEmpty = !Object.keys(AsigneeId).length
    
    if(taskIsEmpty)
    {
        return ERROR_MESSAGES.NO_TASK
    }
    if(!StartTime)
    {
        return ERROR_MESSAGES.NO_START_DATE
    }
    if(!EndTime)
    {
        return ERROR_MESSAGES.NO_END_DATE
    }
    if(!TimeStart)
    {
        return ERROR_MESSAGES.NO_START_TIME
    }
    if(!TimeEnd)
    {
        return ERROR_MESSAGES.NO_END_TIME
    }
    return ""
}

export const changeTimeOfDateObject = (dateObject, timeString) => {
    let bufferTimeString = timeString+":00"
    return `${format(dateObject, "yyyy-MM-dd")}T${bufferTimeString}`
}

export const getAsigneeIdFromActivityId = async (activityId, taskUserId) => {
    let res = await taskAxios.get("/asigneesofactivity", {
        params: {
            Id: activityId
        }
    })
    return res.find(x => x.assigendToId == taskUserId).id
}

export const logInToTaskSheet = async (credentials) => await taskAxios.post("/login", credentials)
export const defaultLoginFormFields = {
    "id": 0,
    "name": "",
    "depId": 0,
    "password": "",
    "newPassword": ""
  }

  export const prepareFormFields = (formFields, asigneeId) => {
    return {
        ...formFields,
        TimeStart: changeTimeOfDateObject(formFields.StartTime, formFields.TimeStart),
        TimeEnd: changeTimeOfDateObject(formFields.EndTime, formFields.TimeEnd),
        StartTime: changeTimeOfDateObject(formFields.StartTime, formFields.TimeStart),
        EndTime: changeTimeOfDateObject(formFields.EndTime, formFields.TimeEnd),
        AsigneeId: asigneeId
    }
  }

export const getTaskSheetIdFromHrId = (id) => {
    return employeeMapper.find((emp) => (emp.hrId == id) && (emp.tenant == getImmediateTenant()) )?.taskId ?? 1;
}