export const columns = [
  {
    Header: "Employee ID",
    accessor: "employeeId",
    editable: false,
  },
  {
    Header: "Employee Name",
    accessor: "employeeName",
    editable: false,
  },
  {
    Header: "Department",
    accessor: "department",
    editable: false,
  },
  {
    Header: "Check In",
    accessor: "checkIn",
    editable: true,
  },
  {
    Header: "Check Out",
    accessor: "checkOut",
    editable: true,
  },
  {
    Header: "Start Time",
    accessor: "start",
    editable: true,
  },
  {
    Header: "End Time",
    accessor: "end",
    editable: true,
  },
  {
    Header: "Status",
    accessor: "status",
    editable: false,
  },
  {
    Header: "Day Type",
    accessor: "dayType",
    editable: false,
  },
];
