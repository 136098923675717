import { useState, useEffect } from "react"
import DataGrid, { CONSTANTS as GridConstants } from "components/data-grid/DataGrid"
import customAxios from "middlewares/axios-interceptor/customAxios"


const LeaveApprovalGroupEmployeeSettings = ({ selectedLeaveGroup }) => {
    let [isLoading, setIsLoading] = useState(true);
    let [data, setData] = useState([]);

    let [selectedLeaveApprovalGroup, setSelectedLeaveApprovalGroup] = useState({});

    const refreshGridData = (row) => {
        setIsLoading(true)
        if (selectedLeaveGroup) {
            customAxios.get(`/group/${selectedLeaveGroup.id}`)
                .then((res) => {
                    const mappedData = res?.employees?.map(x =>
                    ({
                        id: selectedLeaveGroup.id,
                        employeeId: x.id,
                        employeeName: x.name

                    })
                    )
                    setData(mappedData ?? []);
                    setSelectedLeaveApprovalGroup(res);
                    setIsLoading(false)
                });
        }
    }

    useEffect(() => {
        setTimeout(() => {
            refreshGridData()
        }, 0);
    }, [])

    useEffect(() => {
        setTimeout(() => {
            refreshGridData()
        }, 0);
    }, [selectedLeaveGroup])


    const hideOnAdd = true;
    const hideOnEdit = true;


    const columns = [
        {
            accessor: "id",
            Header: "ApprovalGroupId",
            hideOnAdd: true,
            hideOnEdit: true,
            formFieldProps:
            {
                // validation: (yup) => yup.string().required("Date is required"),
                // type: "dateSelect",
                // initialValue: () => dayjs().format()
            }
        },
        {
            accessor: "employeeId",
            Header: "Employee Id",
            formFieldProps:
            {
                displayName: "Employee",
                type: "autoComplete",
                validation: (yup) => yup.number().required(`Select an Employee to add them to the  [ ${selectedLeaveApprovalGroup.name} ]`),
                initialDataSource: async () => {
                    const res = (await customAxios.get("/employee")).items;
                    const existingEmployeeIds = data.map(x => x.employeeId) ?? [];
                    return existingEmployeeIds
                        ? res?.filter(x => !existingEmployeeIds.includes(x.id))
                        : res
                },
                fieldProps: {
                    label: "Select Employee",
                    optionValue: "id",
                    nullable: false,
                    fieldDisplay: (x) => `${x.name}`,
                    optionDisplay: (x) =>
                        <div className="grid grid-cols-12 justify-center items-center gap-2">
                            <span className="col-span-1">{x.id}</span>
                            <span className="col-span-5">{x.name}</span>
                            <span className="col-span-6 ">
                                {x.designation ?? "N/A"}
                            </span>
                        </div>,
                }
                // validation: (yup) => yup.string().required("Date is required"),
                // type: "dateSelect",
                // initialValue: () => dayjs().format()
            }
        },
        {
            accessor: "employeeName",
            Header: "Employee Name",
            hideOnAdd: true,
            hideOnEdit: true,
            formFieldProps:
            {
                // validation: (yup) => yup.string().required("Date is required"),
                // type: "dateSelect",
                // initialValue: () => dayjs().format()
            }
        },

    ];



    const onActionBegin = (args) => {
        if (args.requestType === GridConstants.GRID_ACTION_SAVE) {

            const updateLeaveApprovalGroupPayload =
            {
                ...selectedLeaveApprovalGroup,
                policies: null,
                // leaveTypeGroups: null,
                employees: selectedLeaveApprovalGroup.employees.map(x => x.id)
            }



            const employeeId = args.entity.employeeId;

            if (args.action === GridConstants.ACTION_ADD) {

                updateLeaveApprovalGroupPayload.employees.push(employeeId)

            } else if (args.action === GridConstants.ACTION_DELETE) {

                const indexToRemove = updateLeaveApprovalGroupPayload.employees.indexOf(employeeId);
                updateLeaveApprovalGroupPayload.employees.splice(indexToRemove, 1);

            }

            console.log("SAVE >> ADD || DELETE", updateLeaveApprovalGroupPayload)

            customAxios
                .put("/group/update", updateLeaveApprovalGroupPayload)
                .then(() => { refreshGridData(); });
        }
    }



    return (
        isLoading
            ? <div className="h-full w-full rounded-lg bg-slate-200 animate-pulse"></div>
            : <DataGrid
                header="Leave Groups"
                showHeader={true}
                cellFunction={cell => cell.render("Cell")}
                columns={columns}
                testData={data}
                setData={setData}
                allowAdd={true}
                // allowEdit={true}
                // allowEditRowOnDoubleClick={true}
                allowDelete={true}
                confirmOnDelete={true}
                onActionBegin={onActionBegin}
            />
    )
}

export default LeaveApprovalGroupEmployeeSettings