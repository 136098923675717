import StepModal from "components/step-modal/StepModal";
import {
  setBreadCrumbs,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ModifyPayroll from "./steps/modify-payroll/ModifyPayroll";
import SelectPeriod from "./steps/select-period/SelectPeriod";
import FormGenerator from "components/form-generator/FormGenerator";
import DataGrid, { CONSTANTS as GridConstants } from "components/data-grid/DataGrid";
import Widget from "components/widget/Widget";
import customAxios from "middlewares/axios-interceptor/customAxios";
import { formatMuiErrorMessage } from "@mui/utils";
import moment from "moment";
import { ro } from "date-fns/locale";
import dayjs from "dayjs";

let tabData = [
  {
    index: 1,
    name: "Select Period",
    component: <SelectPeriod />,
  },
  {
    index: 2,
    name: "Select Employees",
    component: <div>Hello</div>,
  },
  {
    index: 3,
    name: "Modify Payroll",
    component: <ModifyPayroll />,
  },
  {
    index: 5,
    name: "Salary Adjustments",
    component: <div>Salary Adjustments</div>,
  },
  {
    index: 6,
    name: "Review & Submit",
    component: <div>Review</div>,
  },
];

export default function GeneratePayroll() {
  let [selectedTab, setSelectedTab] = React.useState(tabData[1]);
  let [startDate, setStartDate] = React.useState(new Date(2023, 3, 1));
  let [endDate, setEndDate] = React.useState(new Date(2023, 3, 30));
  let [selectedEmployee, setSelectedEmployee] = React.useState(JSON.parse(localStorage.getItem("employees")).find(x => x.id === 54));
  let [scheduleLogs, setScheduleLogs] = React.useState([]);
  let [employeeAttendance, setEmployeeAttendance] = React.useState([]);
  let [employeeAllowances, setEmployeeAllowances] = React.useState([]);
  let [generatedPayroll, setGeneratedPayroll] = React.useState([]);
  let [isGridLoading, setIsGridLoading] = React.useState(false);
  let [allowances, setAllowances] = React.useState([])


  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSubHeader("Generate Payroll"));
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Payroll", link: "/Payroll/Overview" },
        {
          name: "Generate Payroll",
        },
      ])
    );


    const allowancesRoute = `https://api.echeshr.com/api/v1/pay/allowances`
    console.log("PAYROLL ROUTE", allowancesRoute)
    customAxios
      .get(allowancesRoute)
      .then
      (
        (res) => {
          console.log("APE RESPONSE:\n==============\n\n", res)
          setAllowances(res.map(x => x.name))
        }
      );

  }, []);


  useEffect(() => {
    console.log("!!>>:", generatedPayroll)
  }, [generatedPayroll])


  const formData = {
    contents: [
      {
        heading: "Generate Payroll",
        description:
          "Select an employee and select the payroll period to generate a payroll",
        // function: () => {
        //   return <div></div>;
        // },
        fields: [
          // {
          //   name: "employee",
          //   displayName: "Employee",
          //   type: "comboBox",
          //   inputType: "text",
          //   required: true,
          //   value: (formik) => {
          //     setSelectedEmployee(formik.values.employee);
          //     return formik.values.employee;
          //   },
          //   values: JSON.parse(localStorage.getItem("employees")),
          //   options: function (item) {
          //     return (
          //       <div className="grid grid-cols-12 justify-center items-center gap-2">
          //         <span className="col-span-1">{item.id}</span>
          //         <span className="col-span-5">{item.name}</span>
          //         <span className="col-span-6 ">
          //           {item.designation ?? "N/A"}
          //         </span>
          //       </div>
          //     );
          //   },
          // },
          {
            name: "dateStart",
            displayName: "Starts on",
            type: "dateSelect",
            default: new Date(),
            inputType: "text",
            value: (formik) => {
              // dispatch(setStartDate(formik.values.dateStart));
              setStartDate(formik.values.dateStart)
              return formik.values.dateStart;
            },
            required: true,
          },
          {
            name: "dateEnd",
            displayName: "Ends On",
            type: "dateSelect",
            default: new Date(),
            inputType: "text",
            value: (formik) => {
              setEndDate(formik.values.dateEnd)
              // dispatch(setEndDate(formik.values.dateEnd));
              return formik.values.dateEnd;
            },
            required: true,
          },
        ]
      }
    ],
    initialValues: {
      employeeIds: [
        32,
        33,
        34,
        35,
        36,
        // 37,
        38,
        // 39,
        40,
        41,
        // 42,
        43,
        44,
        // 45,
        // 46,
        // 47,
        // 48,
        // 49,
        50,
        // 51,
        // 52,
        // 53,
        // 54,
        55,
        // 56,
        57,
        58,
        59,
        60,
        61,
        62,
        66
      ],
      dateStart: startDate,
      dateEnd: endDate,
      // empDutyCycle: basics?.data?.empDutyCycle
      //   ? basics?.data?.empDutyCycle
      //   : "",
    },
    // validationSchema: Yup.object().shape({
    //   remarks: Yup.string().required(
    //     "Please Enter some remarks for clarifications"
    //   ),
    // }),
    onSubmit: async (
      values,
      { setSubmitting, setErrors, setStatus, resetForm, handleSubmit }
    ) => {
      isGridLoading = true;

      console.log("==========================\nFORM VALUES\n==========================", values);
      console.log(formData)

      const routeGetEmployeeProfile = `https://api.echeshr.com/api/v2/employee/profile/${selectedEmployee?.id}`;
      console.log(routeGetEmployeeProfile)

      customAxios
        // .get(`/employee/attendance?Page=1&Size=100&Id=54&From=2022-06-02&To=2023-02-08&IsSortAscending=true&SortBy=Nothing`)
        .get(routeGetEmployeeProfile)
        .then
        (
          (res) => {
            // console.log("APIII RESPONSE:\n==============\n\n", res)
            setEmployeeAllowances(res.allowances ?? []);
          }
        );

      const routeGetScheduleLogs = `/employee/attendance?Page=1&Size=100&IsSortAscending=true&Id=${selectedEmployee?.id}&From=${dayjs(startDate).format("YYYY-M-D")}&To=${dayjs(endDate).format("YYYY-M-D")}`;
      customAxios
        // .get(`/employee/attendance?Page=1&Size=100&Id=54&From=2022-06-02&To=2023-02-08&IsSortAscending=true&SortBy=Nothing`)
        .get(routeGetScheduleLogs)
        .then
        (
          (res) => {
            // console.log("API RESPONSE:\n==============\n\n", res)
            setScheduleLogs(res?.items ?? []);
          }
        );

      const routeGetEmployeeAttendance = `/hr/generate-payroll?EmployeeId=${selectedEmployee?.id}&From=${dayjs(startDate).format("YYYY-M-D")}&To=${dayjs(endDate).format("YYYY-M-D")}`;
      const dataGetEmployeeAttendance = {
        "startDate": "2023-01-01",
        "endDate": "2023-01-31",
        "employeeIds": [
          54
        ]
      }

      console.log("Employee Attendance Route: ", routeGetEmployeeAttendance)

      customAxios
        .post(routeGetEmployeeAttendance, dataGetEmployeeAttendance)
        .then
        (
          (res) => {
            // console.log("APE RESPONSE:\n==============\n\n", res)
            setEmployeeAttendance(res);
            // console.log(">>", employeeAttendance);
          }
        );

      const routeGeneratePayroll = `https://api.echeshr.com/api/v1/pay/generate`
      const dataGeneratePayroll = {
        "startDate": dayjs(values.dateStart).format("YYYY-M-D"),
        "endDate": dayjs(values.dateEnd).format("YYYY-M-D"),
        // "startDate": "2023-01-01",
        // "endDate": "2023-01-31",
        "employeeIds": values.employeeIds
      }

      console.log("PAYROLL ROUTE", routeGeneratePayroll)
      customAxios
        .post(routeGeneratePayroll, dataGeneratePayroll)
        .then
        (
          (res) => {
            console.log("APE RESPONSE:\n==============\n\n", res)


            console.log(res.paySlips)
            // const flat = res.paySlips.reduce(
            //   (accu, curr) => {
            //     const { paySlipDetails, ...paySlipInfo } = curr;
            //     const flatt = paySlipDetails.map(x => ({
            //       ...x,
            //       employeeId: paySlipInfo.employeeId,
            //       employeeName: paySlipInfo.employeeName,
            //     }))
            //     accu = [...accu, ...flatt]
            //     return accu;
            //   },
            //   []
            // )
            const flat = res.paySlips.reduce(
              (accu, curr) => {
                const { paySlipDetails, ...paySlipInfo } = curr;
                // let netAmount = 0;
                const flatt = paySlipDetails.reduce((accu1, curr1) => {
                  // Already done in payslip info?
                  // netAmount += curr.amount;
                  return { ...accu1, [curr1.item]: curr1.amount }
                }, {})
                let row = {
                  employeeId: paySlipInfo.employeeId,
                  employeeName: paySlipInfo.employeeName,
                  ...flatt,
                  // netAmount
                  netAmount: paySlipInfo.netPay
                }
                return [...accu, row];
              },
              []
            )

            setGeneratedPayroll(flat);
            console.log(">>", generatedPayroll);
            isGridLoading = false;
          }
        );




      // var form_data = new FormData();
      // const data = {
      //   LeaveTypeId: selectedLeaveType?.id,
      //   StartDate: moment(values.dateStart).format("YYYY-MM-DD"),
      //   EndDate: moment(values.dateEnd).format("YYYY-MM-DD"),
      //   Remarks: values.remarks,
      // };
      // for (var key in data) {
      //   form_data.append(key, data[key]);
      // }

      // customAxios.post("/request/leave", form_data).then(
      //   (res) => {
      //     dispatch(
      //       addNotification({
      //         id: 1,
      //         heading: `The Leave was added successfully`,
      //       })
      //     );

      //     navigate(`/leaves/view/id=${res.id}`);
      //   },
      //   (err) => {
      //     setIsError(true);
      //     setErrorMsg(err);
      //     console.log(err);
      //   }
      // );
    },

  }


  const hideOnAdd = true;
  const hideOnEdit = true;


  const allowanceColumns = allowances.map(x => ({
    accessor: x,
    Header: x,
    cellRenderFunction: (cell) => <div>{`${cell.value ?? "-"}`}</div>,
    formFieldProps:
    {
    }

  }))






  const columns = [
    {
      accessor: "id",
      Header: "Id",
      hideOnAdd,
      hideOnEdit,
      formFieldProps:
      {
        // validation: (yup) => yup.string().required("Date is required"),
        // type: "dateSelect",
        // initialValue: () => dayjs().format()
      }
    },
    {
      accessor: "employeeId",
      Header: "EmployeeId",
      formFieldProps:
      {
        // validation: (yup) => yup.string().required("Name is required"),
      }
    },
    {
      accessor: "employeeName",
      Header: "EmployeeName",
      formFieldProps:
      {
        // validation: (yup) => yup.string().required("Name is required"),
      }
    },
    ...allowanceColumns,
    // {
    //   accessor: "Attendance Bonus",
    //   Header: "Attendance Bonus",
    //   formFieldProps:
    //   {
    //   }
    // },
    {
      accessor: "netAmount",
      Header: "NET PAY",
      formFieldProps:
      {
      }
    },
    // {
    //   accessor: "Travel Allowance",
    //   Header: "Travel Allowance",
    //   formFieldProps:
    //   {
    //   }
    // },
    // {
    //   accessor: "amount",
    //   Header: "Amount",
    //   formFieldProps:
    //   {
    //     // validation: (yup) => yup.string().required("Name is required"),
    //   }
    // },
  ];

  const onActionBegin = (args) => {

    // if (args.requestType === GridConstants.GRID_ACTION_SAVE) {

    //     if (args.action === GridConstants.ACTION_ADD) {
    //         const payload =
    //         {
    //             ...args.entity,
    //             type: "Leave"
    //         }
    //         console.log("SAVE >> ADD", payload)
    //         customAxios
    //             .post("/group/create", payload)
    //             .then(() => { refreshGridData(); });

    //     }

    //     else if (args.action === GridConstants.ACTION_UPDATE) {

    //         const { policies: _, ...payload } = args.entity;
    //         console.log("SAVE >> UPDATE", payload)

    //         customAxios
    //             .put("/group/update", payload)
    //             .then(() => { refreshGridData(); });
    //     }

    //     else if (args.action === GridConstants.ACTION_DELETE) {
    //         console.log("SAVE >> DELETE", args.entity)
    //         const entityId = args.entity.id;
    //         customAxios
    //             .delete(`/group/delete/${entityId}`)
    //             .then(() => { refreshGridData() })
    //     }

    // } else if (args.requestType === GridConstants.GRID_ACTION_ROWSINGLECLICK) {
    //     console.log("ROW CLICK", args.entity)
    //     setSelectedLeaveGroup(args.entity)
    // }
  }

  return (
    <div className="flex flex-col space-y-2 h-full overflow-hidden">
      {/* <StepModal
        TabData={tabData}
        SelectedTab={selectedTab}
        SetSelectedTab={setSelectedTab}
      /> */}
      {/* <div className="grid grid-cols-2 gap-5 w-full max-w-2xl">
        <div className="bg-white text-gray-500 rounded-lg w-full pl-2 py-1 col-span-2">Select Employee</div>
        <div className="bg-white text-gray-500 rounded-lg w-full pl-2 py-1 col-span-2 md:col-span-1">Start</div>
        <div className="bg-white text-gray-500 rounded-lg w-full pl-2 py-1 col-span-2 md:col-span-1">End</div>
        <div className="bg-orange-500 text-white text-center rounded-lg w-full pl-2 py-1 col-span-2 md:col-span-1">Generate</div>
      </div> */}
      <div className="">
        <FormGenerator
          // ErrorMsg={errorMsg}
          // isError={isError}
          height={""}
          buttonSubmit={{ title: "Generate" }}
          formData={formData}
        />
      </div>
      <div className="grid grid-cols-5 text-xs">
        <div>EarlyArrival</div>
        <div>EarlyDeparture</div>
        <div>LateArrival</div>
        <div>LateDeparture</div>
        <div>OvertimeMinutes</div>
        <div>TotalDaysOnLeave</div>
        <div>LateMinutes</div>
        <div>TotalDays</div>
        <div>TotalHolidays</div>
        <div>TotalWorkingDays</div>
        <div>TotalWorkMinutes</div>
        <div>WorkStatusMinutes.Present</div>
        <div>WorkStatusCounts.Present</div>
        <div>WorkStatusMinutes.Absent</div>
        <div>WorkStatusCounts.Absent</div>
        <div>WorkStatusMinutes.HalfPay</div>
        <div>WorkStatusCounts.HalfPay</div>
        <div>WorkStatusMinutes.NoPay</div>
        <div>WorkStatusCounts.NoPay</div>
        <div>WorkStatusMinutes.Late</div>
        <div>WorkStatusCounts.Late</div>
        <div>WorkStatusMinutes.OnLeave</div>
        <div>WorkStatusCounts.OnLeave</div>
        <div>WorkStatusMinutes.NotSet</div>
        <div>WorkStatusCounts.NotSet</div>
        <div>HolidayCount</div>
      </div>
      <div className="flex-grow min-h-0">
        <Widget heading={"Generated Payroll"}
        >
          {
            isGridLoading
              ? <div className="h-full w-full rounded-lg bg-slate-200 animate-pulse"></div>
              : <DataGrid
                header="Leave Groups"
                showHeader={true}
                cellFunction={cell => cell.render("Cell")}
                columns={columns}
                testData={generatedPayroll}
                setData={setGeneratedPayroll}
                // allowAdd={true}
                // allowEdit={true}
                allowEditRowOnDoubleClick={true}
                // allowDelete={true}
                confirmOnDelete={true}
                showVerticalDividers={true}
                onActionBegin={onActionBegin}
              />
          }
        </Widget>
      </div>
    </div>
  );
}
