import PrimaryButton from "ui/buttons/PrimaryButton"

const AddAlowancesForm = ({onSubmit, onChange}) => {
    return (
        <form 
            className="w-full h-full flex flex-col space-y-3 p-2"
            onSubmit={onSubmit} 
            onChange={onChange}
        >
            <input 
                type="text" 
                name="Name"
                required
                placeholder="Name"
                className="p-0.5 px-2 border border-gray-200 w-fit h-8 text-xs rounded-lg flex justify-between items-center flex-row space-x-3 bg-white font-semibold"
            />
            <input 
                type="number" 
                name="BaseAmount"
                required
                placeholder="Base Amount"
                className="p-0.5 px-2 border border-gray-200 w-fit h-8 text-xs rounded-lg flex justify-between items-center flex-row space-x-3 bg-white font-semibold"
            />
            <textarea 
                type="text" 
                name="Description"
                placeholder="Description"
                className="p-2 px-2 border border-gray-200 w-56 h-24 text-xs rounded-lg flex justify-between items-center flex-row space-x-3 bg-white font-semibold"
            />
            <input 
                type="number" 
                name="Currency"
                placeholder="Currency"
                className="p-0.5 px-2 border border-gray-200 w-fit h-8 text-xs rounded-lg flex justify-between items-center flex-row space-x-3 bg-white font-semibold"
            />
            <textarea 
                type="text" 
                name="Query"
                placeholder="Query"
                className="p-2 px-2 border border-gray-200 w-56 h-24 text-xs rounded-lg flex justify-between items-center flex-row space-x-3 bg-white font-semibold"
            />
            <div className="w-full h-fit flex flex-row justify-end">
                <PrimaryButton 
                    title="Add" 
                    type="submit" 
                />
            </div>
        </form>
    )
}

export default AddAlowancesForm