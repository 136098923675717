import React, { Fragment, useEffect } from "react";
import { menuItems } from "./../MenuItems";
import Widget from "components/widget/Widget";
import { useDispatch } from "react-redux";
import {
  setBreadCrumbs,
  setSelectedSubMenu,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import MonthlyLeaves from "../charts/MonthlyLeaves";
import LeavesPerDepartment from "../charts/LeavesPerDepartment";
import LeavesPerType from "../charts/LeavesPerType";
import format from "date-fns/format";
import { useState } from "react";
import DataGrid from "components/data-grid/DataGrid";
import OnLeaveToday from "../charts/OnLeaveToday";

export default function GroupsOverview() {
  const[onLeaveToday, setOnLeaveToday] = useState([])
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedSubMenu(menuItems[0]));
    dispatch(setSubHeader("Overview"));
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Leaves", link: "/Leaves" },
      ])
    );
  }, []);

  const leaveToday = (leaves) => {
    setOnLeaveToday(leaves.length)
  }

  return (
    <div className="grid grid-cols-4 gap-2">
      <div className="col-span-1">
        <Widget heading={`Leave Distribution Per Type \(${format(new Date(), "yyyy")}\)`}>
              <LeavesPerType leaveTodayFunc={leaveToday}/>
        </Widget>
      </div> 
      <div className="col-span-2">
        <Widget heading={`Monthly Leaves \(${format(new Date(), "yyyy")}\)`}>
          <MonthlyLeaves/>
        </Widget>
      </div>
      <div className="col-span-1 h-fit">
        <Widget heading={`On Leave Today`}>
          <OnLeaveToday/>
        </Widget>
      </div>
    </div>
  );
}
