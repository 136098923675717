import * as Yup from "yup";
import customAxios from "middlewares/axios-interceptor/customAxios";
import { useState, useEffect } from "react"
import dayjs from "dayjs";
import { fi } from "date-fns/locale";


// export const columns = [
//   {
//     accessor: "date",
//     Header: "Date",
//     type: "dateSelect",
//     formFieldProps:
//     {
//       validation: (yup) => yup.string().required("Date is required"),
//       type: "dateSelect",
//       initialValue: () => { return dayjs().format() }
//     }
//   },
//   {
//     accessor: "employeeId",
//     Header: "Employee ID",
//     formFieldProps:
//     {
//       displayName: "Employee",
//       type: "comboBox",
//       values: async (customAxios) => { const res = await customAxios.get("/employee"); return res.items; },
//       // values: () => { return [] },
//       options: function (item) {
//         return (
//           <div className="grid grid-cols-12 justify-center items-center gap-2">
//             <span className="col-span-1">{item.id}</span>
//             <span className="col-span-5">{item.name}</span>
//             <span className="col-span-6 ">
//               {item.designation ?? "N/A"}
//             </span>
//           </div>
//         );
//       }
//     }
//   },
//   {
//     accessor: "employeeName",
//     Header: "Employee Name",
//     hideOnAdd: true,
//     hideOnEdit: true,
//   },
//   {
//     accessor: "start",
//     Header: "Start Time",
//     formFieldProps:
//     {
//       initialValue: () => { return "09:00" }
//     }
//   },
//   {
//     accessor: "end",
//     Header: "End Time",
//     formFieldProps:
//     {
//       initialValue: () => { return "17:00" }
//     }
//   },
//   {
//     accessor: "checkIn",
//     Header: "Check In",
//     formFieldProps:
//     {
//       initialValue: () => { return "09:00" }
//     }
//   },
//   {
//     accessor: "checkOut",
//     Header: "Check Out",
//     formFieldProps:
//     {
//       initialValue: () => { return "17:00" }
//     }
//   },
//   {
//     accessor: "dayType",
//     Header: "Schedule Type",
//     formFieldProps:
//     {
//       type: "comboBox",
//       values: async (customAxios) => { const res = await customAxios.get("/calendar/scheduletypes"); return res; },
//       options: function (item) {
//         return (
//           <div className="grid grid-cols-12 justify-center items-center gap-2">
//             <span className="col-span-1">{item.id}</span>
//             <span className="col-span-5">{item.name}</span>
//           </div>
//         );
//       }
//     }
//   },
//   {
//     accessor: "status",
//     Header: "Status",
//   },
// ];

const testFields = [
  {
    name: "firstName",
    label: "First Name",
    initialValue: "Timmy",
    validation: (yup) =>
      yup.string()
        .max(15, 'Must be 15 characters or less')
        .required('Required'),

  },
  {
    name: "lastName",
    label: "Last Name",
    initialValue: "Tome",
    validation: (yup) =>
      yup.string()
        .max(20, 'Must be 20 characters or less')
        .required('Required'),

  },
  {
    name: "email",
    label: "Email",
    initialValue: "example@mail.com",
    validation: (yup) =>
      yup.string().email('Invalid email address').required('Required'),
  },
  {
    name: "departmentId",
    label: "Department",
    initialValue: 14,
    validation: (yup) =>
      yup.number().required('Required')
  },
  {
    name: "designation",
    initialValue: 0,
    type: "dropdown",
    label: "Designation",
    fieldProps:
    {
      label: "Designation",
      isSearchable: true,
      optionValue: "id",
      optionDisplay: (x) => <div className="w-full">{`${x.id} >> ${x.name}`}</div>,
      // optionDisplay: (x) => `${x.id} >> ${x.name}`,
      dataSource: () => [
        { id: 1, name: "Devo" },
        { id: 2, name: "Solo" },
      ],
      dependents: ["departmentId"],
      onDependentsChange:
        (name, values, setFieldValue, dataSource, setDataSource, isCurrent) => {
          if (values["departmentId"] != null) {
            console.log(">>>>>>>>>>>>>>>>>>>>>>>>>TRACER")
            // fetchDataSource(setInternalDataSource)
            const route = `/department/${values["departmentId"]}/designations`
            console.log(route)
            customAxios
              .get(route)
              .then((res) => {
                if (isCurrent) {
                  // prevent setting old values
                  setDataSource(res);
                  setFieldValue(name, null)
                  console.log(res)
                }
              })
          }
        }

    }
    // validation: (yup) =>
    //     yup.number().required('Required')

  },
  {
    name: "startDate",
    initialValue: new Date(),
    type: "dateSelect",
    label: "Start Date",
    fieldProps:
    {
      label: "Start Date"
    },
    validation: (yup) =>
      yup.date().required('Required')

  },
  {
    name: "endDate",
    initialValue: null,
    type: "dateSelect",
    label: "End Date",
    fieldProps:
    {
      label: "End Date",
      dependents: ["startDate"],
      onDependentsChange:
        (name, values, setFieldValue, dataSource, setDataSource, isCurrent) => {
          if (isCurrent) {
            // prevent setting old values
            if (values.startDate > values.endDate) {
              setFieldValue(name, values["startDate"])
            }
          }
        }
    }
    // validation: (yup) =>
    //     yup.number().required('Required')

  }
]


const testFieldTranslation = testFields.map(x => {
  let { name, label, ...rest } = x;
  const field = { accessor: name, Header: label, formFieldProps: { ...rest } }
  return field;
}

)


export const columns = [
  {
    accessor: "date",
    Header: "Dateee",
    formFieldProps:
    {
      validation: (yup) => yup.string().required("Date is required"),
      type: "dateSelect",
      initialValue: () => dayjs().format()
    }
  },
  {
    accessor: "employeeId",
    Header: "Employee ID",
    formFieldProps:
    {
      displayName: "Employee",
      type: "autoComplete",
      initialDataSource: async () => { const res = await customAxios.get("/employee"); return res.items; },
      fieldProps: {
        optionValue: "id",
        fieldDisplay: (x) => `${x.name}`,
        optionDisplay: (x) =>
          <div className="grid grid-cols-12 justify-center items-center gap-2">
            <span className="col-span-1">{x.id}</span>
            <span className="col-span-5">{x.name}</span>
            <span className="col-span-6 ">
              {x.designation ?? "N/A"}
            </span>
          </div>,
        onBlur:
          ({ fieldValueOnFocus, fieldName, formValues, setFormFieldValue, formDataSources, setFormFieldDataSources }) => {
            // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>TRACER>>>>>>>>>>", formDataSources)
            // fetchDataSource(setInternalDataSource)
            if (fieldValueOnFocus !== formValues[fieldName]) {
              const route = `/employee/shifts/${formValues["employeeId"]}`
              console.log(route)
              customAxios
                .get(route)
                .then((res) => {
                  setFormFieldValue("start", res[0].startOn)
                  setFormFieldValue("end", res[0].endOn)
                  setFormFieldValue("checkIn", res[0].startOn)
                  setFormFieldValue("checkOut", res[0].endOn)
                  console.log(res)
                })
            }
          }
        // <div className="w-full">{`${x.id} >> ${x.name}`}</div>,
      }
    }
  },
  // {
  //   accessor: "scheduleId",
  //   Header: "Schedule Id",
  //   showOnGrid: false,
  //   formFieldProps:
  //   {
  //     type: "autoComplete",
  //     initialDataSource: async () => { const res = await customAxios.get("/attendance/schedule-item"); return res.items; },
  //     // initialValue: () => 188,
  //     fieldProps: {
  //       label: "Schedule EYED",
  //       // initialDataSource: async () => { const res = await customAxios.get("/employee"); return res.items; },
  //       optionValue: "id",
  //       optionDisplay: (x) =>
  //         <div className="grid grid-cols-12 justify-center items-center gap-2">
  //           <span className="col-span-1">{x.id}</span>
  //           <span className="col-span-5">{x.title}</span>
  //           <span className="col-span-6 ">
  //             {x.designation ?? "N/A"}
  //           </span>
  //         </div>,
  //       fieldDisplay: (x) => `${x.id} | ${x.title}`,
  //       onBlur:
  //         ({ fieldValueOnFocus, fieldName, formValues, setFormFieldValue, formDataSources, setFormFieldDataSources }) => {
  //           // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>TRACER>>>>>>>>>>", formDataSources)
  //           // fetchDataSource(setInternalDataSource)
  //           // console.log(formValues)
  //           // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>TRACER>>>>>>>>>>")
  //           // console.log(">>", fieldValueOnFocus);
  //           // console.log(">>", formValues[fieldName]);
  //           // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>TRACER>>>>>>>>>>")
  //           // NEED TO FIGURE HOW TO INJECT CODE TO AFTER SETSTATE
  //           // if (fieldValueOnFocus !== formValues[fieldName]) {
  //           console.log(">>>>>>>>>>>>>>>>>>>>>>>>>TRACER>>>>>>>>>>INTERNAL", formDataSources[fieldName].find(x => x.id === formValues[fieldName]))




  //           setFormFieldValue("start", formDataSources[fieldName].find(x => x.id === formValues[fieldName]).startOn)
  //           setFormFieldValue("end", formDataSources[fieldName].find(x => x.id === formValues[fieldName]).endOn)
  //           // }
  //         }
  //       // <div className="w-full">{`${x.id} >> ${x.name}`}</div>,
  //     }
  //   }
  // },
  // {
  //   accessor: "employeeName",
  //   Header: "Employee Name",
  //   hideOnAdd: false,
  //   hideOnEdit: true,
  //   formFieldProps:
  //   {
  //     fieldProps: {
  //       onBlur:
  //         ({ fieldValueOnFocus, fieldName, formValues, setFormFieldValue, formDataSources, setFormFieldDataSources }) => {
  //           // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>TRACER>>>>>>>>>>", formDataSources)
  //           // fetchDataSource(setInternalDataSource)
  //           // if (fieldValueOnFocus !== formValues[fieldName]) {
  //           const route = `/attendance/schedule-item`
  //           console.log(route)
  //           customAxios
  //             .get(route)
  //             .then((res) => {
  //               // prevent setting old values
  //               setFormFieldDataSources("scheduleId", res.items);
  //               setFormFieldValue("scheduleId", res.items[0].id)
  //               console.log(res)
  //             })
  //           // const route = `/employee`
  //           // console.log(route)
  //           // customAxios
  //           //   .get(route)
  //           //   .then((res) => {
  //           //     // prevent setting old values
  //           //     setFormFieldDataSources("employeeId", res.items);
  //           //     setFormFieldValue("employeeId", res.items[0].id)
  //           //     console.log(res)
  //           //   })
  //         }
  //       // }
  //     }
  //   }
  // },
  {
    accessor: "start",
    Header: "Start Time",
    formFieldProps:
    {
      // initialValue: "09:00"
    }
  },
  {
    accessor: "end",
    Header: "End Time",
    formFieldProps:
    {
      // initialValue: "17:00"
    }
  },
  {
    accessor: "checkIn",
    Header: "Check In",
    formFieldProps:
    {
      // initialValue: "09:00"
    }
  },
  {
    accessor: "checkOut",
    Header: "Check Out",
    formFieldProps:
    {
      // initialValue: "09:00"
    }
  },
  // {
  //   accessor: "checkOut",
  //   Header: "Check Out",
  //   formFieldProps:
  //   {
  //     initialValue: "17:00",
  //     fieldProps: {
  //       dependents: ["checkIn"],
  //       onDependentsChange:
  //         (name, values, setFieldValue, dataSource, setDataSource, isCurrent) => {
  //           if (isCurrent) {
  //             // prevent setting old values
  //             console.log("TRACER<<<<<<<<<<<<<<<<<<<<<<<<<")
  //             if (values.checkIn > values.checkOut) {
  //               setFieldValue(name, values["checkIn"])
  //             }
  //           }
  //         }
  //     }
  //   }
  // },
  {
    accessor: "dayType",
    Header: "Schedule Type",
    formFieldProps:
    {
      type: "autoComplete",
      initialDataSource: async () => { const res = await customAxios.get("/calendar/scheduletypes"); return res; },
      fieldProps: {
        optionValue: "id",
        fieldDisplay: (item) => item.name,
        optionDisplay: (item) => {
          return (
            <div className="grid grid-cols-12 justify-center items-center gap-2">
              <span className="col-span-1">{item.id}</span>
              <span className="col-span-5">{item.name}</span>
            </div>
          );
        }
      }
    }
  },
  // {
  //   accessor: "dayType",
  //   Header: "Schedule Type",
  //   formFieldProps:
  //   {
  //     type: "dropdown",
  //     initialDataSource: async () => { const res = await customAxios.get("/calendar/scheduletypes"); return res; },
  //     fieldProps: {
  //       optionValue: "id",
  //       fieldDisplay: (item) => item.name,
  //       optionDisplay: (item) => {
  //         return (
  //           <div className="grid grid-cols-12 justify-center items-center gap-2">
  //             <span className="col-span-1">{item.id}</span>
  //             <span className="col-span-5">{item.name}</span>
  //           </div>
  //         );
  //       }
  //     }
  //   }
  // },
  // {
  //   accessor: "status",
  //   Header: "Status",
  // },
  // ...testFieldTranslation
];
