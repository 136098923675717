import Widget from "components/widget/Widget"
import UpdateAllowanceForm from "./UpdateAllowanceForm"
import FormWrapper from "pages/employees/view-employee/views/timesheet/components/FormWrapper"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { setSubHeader } from "features/navigation/NavigationSlice"
import customAxios from "middlewares/axios-interceptor/customAxios"
import { resHasError } from "middlewares/axios-interceptor/quickEndpoints"
import { addNotification } from "features/notifications/NotificationSlice"

const UpdateAllowance = () => {
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [formFields, setFormFields] = useState({})
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setSubHeader("Update Allowance"))
    }, [])

    const handleChange = (event) => {
        setFormFields({
            ...formFields,
            [event.target.name]: event.target.value
        })
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)
        console.log(formFields)
        const res = await customAxios.put("/pay/update", formFields)
        if (resHasError(res))
        {
            setErrorMessage(`${res.message}`)
        }
        else
        {
            dispatch(addNotification({
                id: 1,
                heading: `${res.name} updated successfully`
            }))
        }
        setLoading(false)
    }

    const handleAllowanceChange = (allowance) => {
        let newFormFields = {
            ...allowance,
        }
        setFormFields(newFormFields)
    }

    return (
        <div className="w-full min-h-96 max-h-full overflow-y-auto">
            <Widget>
                <FormWrapper errorMessage={errorMessage} loading={loading}>
                    <UpdateAllowanceForm onChange={handleChange} onSubmit={handleSubmit} onAllowanceChange={handleAllowanceChange} />
                </FormWrapper>
            </Widget>
        </div>
    )
}

export default UpdateAllowance