import { Transition } from "@headlessui/react";
import React from "react";
import { Dialog } from "@headlessui/react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

export default function Modal({ children }) {
  let navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(true);

  return (
    <Transition
      appear
      show={isOpen}
      as={Fragment}
      onClose={() => {
        setIsOpen(false);
        navigate(-1);
      }}
    >
      <Dialog as="div" className="relative bg-slate-100 z-50 w-screen ">
        <div className="fixed inset-0     overflow-y-auto">
          <div className="flex min-h-full w-full justify-end  text-center">
            <Dialog.Panel className=" top-20 bg-white flex flex-col justify-between pt-16  z-20 space-y-3  transform overflow-hidden   p-5 text-left  shadow-xl transition-all">
              {children}
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
