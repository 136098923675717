import { useState } from "react"
import PrimaryButton from "ui/buttons/PrimaryButton"
import AllowancesDropdown from "./components/AllowancesDropdown"
import EmployeeDropdown from "./components/EmployeeDropdown"
import DateRangePickerMain from "components/date-picker-v2/DateRangePicker"

const AssignAllowancesForm = ({onSubmit, onChange}) => {
    const [selectedAllowance, setSelectedAllowance] = useState({})

    return (
        <form 
            className="w-full h-full flex flex-col space-y-3 p-2 overflow-auto"
            onSubmit={onSubmit} 
            onChange={onChange}
        >
            <AllowancesDropdown 
                onAllowanceSelect={(allowance) => {
                    // console.log(allowance)
                    setSelectedAllowance(allowance)
                    onChange({
                        target: {
                            value: allowance.id,
                            name: "Allowance"
                        }
                    },)
                }}
            />
            <EmployeeDropdown 
                onEmployeeSelect={(employeeId) => {
                    onChange({
                        target: {
                            value: employeeId,
                            name: "Employee"
                        }
                    },)
                }}
            />
            <DateRangePickerMain
                fDateHeading="Start Date"
                eDateHeading="End Date"
                onFDateChange={(fDate) => {
                    onChange({
                        target: {
                            name: "startDate",
                            value: fDate
                        }
                    })
                }}
                onEDateChange={(eDate) => {
                    onChange({
                        target: {
                            name: "endDate",
                            value: eDate
                        }
                    })
                }}
            />
            <input 
                type="text" 
                name="BaseAmount"
                required
                placeholder={selectedAllowance?.baseAmount ?? "Base Amount"}
                className="p-0.5 px-2 border border-gray-200 w-fit h-8 text-xs rounded-lg flex justify-between items-center flex-row space-x-3 bg-white font-semibold"
            />
            <textarea 
                type="text" 
                name="Description"
                placeholder={selectedAllowance?.description ?? "Description"}
                className="p-2 px-2 border border-gray-200 w-56 h-24 text-xs rounded-lg flex justify-between items-center flex-row space-x-3 bg-white font-semibold"
            />
            <input 
                type="number" 
                name="Currency"
                placeholder="Currency"
                className="p-0.5 px-2 border border-gray-200 w-fit h-8 text-xs rounded-lg flex justify-between items-center flex-row space-x-3 bg-white font-semibold"
            />
            <div className="w-full h-fit flex flex-row justify-end">
                <PrimaryButton 
                    title="Assign"
                    type="submit" 
                />
            </div>
        </form>
    )
}

export default AssignAllowancesForm