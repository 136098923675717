import React, { useState, Fragment } from "react";
import MainLayout from "../../../components/layout/MainLayout";
import GlobalCard from "../../../ui/GlobalCard";
import { Tab } from "@headlessui/react";
import DataGrid from "../../../components/data-grid/DataGrid";
import { columns } from "./columns";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TabData = [
  {
    name: "Pending",
  },
  {
    name: "Approved",
  },
  {
    name: "Rejected",
  },
];

function cellFunction(cell) {
  return cell.render("Cell");
}

export default function AllApprovals() {
  let [loading, setLoading] = React.useState(false);
  let [rowData, setRowData] = React.useState([]);
  return (
    <div>
      <GlobalCard>
        <Tab.Group>
          <Tab.List
            className={"flex flex-row justify-start items-center space-x-5"}
          >
            <Tab
              className={({ selected }) =>
                classNames(
                  "flex flex-row outline-none border-b-2  text-md justify-start focus:outline-none items-center space-x-2 pl-4 p-2 hover:cursor-pointer ",
                  selected
                    ? " font-medium text-black border-b-orange-400 "
                    : "text-gray-400 border-b-white"
                )
              }
            >
              All
            </Tab>
            {TabData?.map((td, i) => (
              <Tab
                key={i}
                className={({ selected }) =>
                  classNames(
                    "flex flex-row outline-none border-b-2  text-md justify-start focus:outline-none items-center space-x-2 pl-4 p-2 hover:cursor-pointer ",
                    selected
                      ? " font-medium text-black border-b-orange-400 "
                      : "text-gray-400 border-b-white"
                  )
                }
              >
                {td.name}
              </Tab>
            ))}
          </Tab.List>
        </Tab.Group>
        {!loading ? (
          <DataGrid
            cellFunction={cellFunction}
            columns={columns}
            testData={rowData}
            path={"/leaves/view/id="}
            // setSelectedRow={setSelectedRow}
            // selectedRow={selectedRow}
            // path={"/leaves/view/id="}
            // setIsOpen={setIsOpen}
            height={"h-[70vh]"}
          />
        ) : (
          <>
            <div class="animate-pulse p-5">
              <div class="h-8 bg-gray-200 mt-3 mb-6 rounded"></div>
              <div class="h-8 bg-gray-300 mb-6 rounded"></div>
              <div class="h-8 bg-gray-200 mb-6 rounded"></div>
              <div class="h-8 bg-gray-300 mb-6 rounded"></div>
              <div class="h-8 bg-gray-200 mb-6 rounded"></div>
            </div>
          </>
        )}
      </GlobalCard>
    </div>
  );
}
