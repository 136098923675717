import customAxios from "./customAxios";

export const resHasError = (res) =>  res?.response?.status ?? ""

export const getEmployees = async () => {
    const res = await customAxios.get("/employee")
    if (resHasError(res))
    {
        return res
    }
    else
    {
        return res.items 
    }
}

export const getAllowances = async () => {
    return await customAxios.get("/pay/allowances")
}

export const postAllowances = async (...allowances) => {
    const numAllowances = allowances.length
    for (let i = 0; i < numAllowances; i++)
    {
        let allowance = allowances[i]
        await customAxios.post("/pay/create", allowance)
    }
    return await getAllowances();
}
