import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import MainLayout from "../../../components/layout/MainLayout";
import GlobalCard from "../../../ui/GlobalCard";
import { Outlet, useNavigate } from "react-router-dom";
import { Tab } from "@headlessui/react";
import DataGrid from "../../../components/data-grid/DataGrid";
import { columns } from "./columns";
import customAxios from "middlewares/axios-interceptor/customAxios";
import PrimaryButton from "../../../ui/buttons/PrimaryButton";
import SecondaryButton from "../../../ui/buttons/SecondaryButton";
import Confirmation from "./modals/Confirmation";
import moment from "moment";
import { colors } from "constants/Colors";
import {
  setBreadCrumbs,
  setSelectedSubMenu,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import { useDispatch } from "react-redux";
import { menuItems } from "./../MenuItems";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TabData = [
  {
    name: "Pending",
  },
  {
    name: "Approved",
  },
  {
    name: "Rejected",
  },
];

export default function ApprovalLeaves() {
  let navigate = useNavigate();
  let [loading, setLoading] = React.useState(true);
  let [rowData, setRowData] = React.useState([]);
  let [isOpen, setIsOpen] = React.useState(false);
  let [type, setType] = React.useState("");
  let [selectedRow, setSelectedRow] = React.useState();
  let dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setSelectedSubMenu(menuItems[1]));
    dispatch(setSubHeader("Leave Approvals"));
    customAxios.get("/employee/approvals").then((res) => {
      arrangeData(res.items);
    });
  }, []);

  async function arrangeData(items) {
    let tempData = [];
    items?.map((d) => {
      let payload = JSON.parse(d.request?.payload);

      var dt = {
        id: d.id,
        employee: d.request.employee,
        requestType: d.request.requestType,
        remarks: d.request?.remarks,
        request: d.request,
        createdAt: moment(d.request.createdAt).format("DD MMM YYYY"),
        status: d.status,
        payload: payload,
        component: d.request.entityId,
      };
      tempData.push(dt);
    });

    setRowData(tempData);
    setLoading(false);
  }

  function cellFunction(cell) {
    if (cell.column.id === "employee") {
      return (
        <div className="flex items-center w-60 rounded-full hover:bg-slate-200">
          <img
            src={
              cell.row.original.employee?.avatar
                ? cell.row.original.employee?.avatar
                : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
            }
            alt="avatar"
            className="w-8 h-8 rounded-full mr-2"
          />
          <div className="text-xs">
            <p className="font-medium text-start">
              {cell.row.original.employee?.name ?? "N/A"}
            </p>
            {/* <p className="text-xs text-gray-500">
              {cell.row.original.employee?.position.name} -{" "}
              {cell.row.original.employee?.position.department.name} -{" "}
              {cell.row.original.employee?.id}
            </p> */}
          </div>
        </div>
      );
    }
    if (cell.column.id === "remarks") {
      return (
        <div className="flex items-center w-40 rounded-full hover:bg-slate-200">
          <div className="text-xs">
            <p className="font-normal text-start">
              {cell.row.original?.request.remarks ?? "N/A"}
            </p>
          </div>
        </div>
      );
    }

    if (cell.column.id === "request") {
      console.log(cell);
      return (
        <div className="flex items-center w-64 rounded-full hover:bg-slate-200">
          <div className="text-xs">
            <p className="font-normal text-start">
              {cell.row.original?.payload?.LeaveType?.Name ?? "Undefined"}
            </p>
          </div>
        </div>
      );
    }

    if (cell.column.id === "status") {
      function colorRender() {
        if (cell.row.original.status == "Pending") {
          return colors.primary;
        } else if (cell.row.original.status == "approved") {
          return "text-green-200";
        } else {
          return "text-red-200";
        }
      }

      return (
        <div className="flex items-center  rounded-full hover:bg-slate-200">
          <div className="text-xs">
            <p
              style={{ color: colorRender() }}
              className="font-semibold text-start"
            >
              {cell.row.original.status}
            </p>
          </div>
        </div>
      );
    }

    if (cell.column.id == "component") {
      return (
        <button
          onClick={() => navigate(`/Leaves/View/id=${parseInt(cell.value)}`)}
          className="flex flex-row group"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4 group-hover:text-orange-800"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        </button>
      );
    } else if (cell.column.id == "actions") {
      return (
        <div className="flex flex-row space-x-2">
          <SecondaryButton>
            <button
              style={{ color: colors.success }}
              onClick={() => {
                setIsOpen(true);
                setType("Approved");
              }}
            >
              Approve
            </button>
          </SecondaryButton>
          <SecondaryButton>
            <button
              style={{ color: colors.danger }}
              onClick={() => {
                setIsOpen(true);
                setType("Rejected");
              }}
            >
              Reject
            </button>
          </SecondaryButton>
        </div>
      );
    } else {
      return cell.render("Cell");
    }
  }

  return (
    <div className="h-full">
      <GlobalCard>
        {!loading ? (
          <DataGrid
            cellFunction={cellFunction}
            columns={columns}
            testData={rowData}
            setSelectedRow={setSelectedRow}
            selectedRow={selectedRow}
            selectable={false}
            showHeader={true}
            // path={"/leaves/view/id="}
            // setIsOpen={setIsOpen}
            height={"h-[76vh]"}
          />
        ) : (
          <>
            <div class="animate-pulse p-5">
              <div class="h-8 bg-gray-200 mt-3 mb-6 rounded"></div>
              <div class="h-8 bg-gray-300 mb-6 rounded"></div>
              <div class="h-8 bg-gray-200 mb-6 rounded"></div>
              <div class="h-8 bg-gray-300 mb-6 rounded"></div>
              <div class="h-8 bg-gray-200 mb-6 rounded"></div>
            </div>
          </>
        )}
      </GlobalCard>
      {/* Outlet for other routes */}
      <div className="absolute z-50 h-screen">
        <Outlet />
      </div>
      {/* Modals */}
      <Transition
        appear
        show={isOpen}
        as={Fragment}
        onClose={() => setIsOpen(false)}
      >
        <Dialog as="div" className="relative z-50 w-screen ">
          <div className="fixed inset-0     overflow-y-auto">
            <div className="flex min-h-full w-full justify-end  text-center">
              <Dialog.Panel className=" top-20 flex flex-col justify-between pt-16  z-20 space-y-3  transform overflow-hidden  bg-white p-5 text-left  shadow-xl transition-all">
                <Confirmation
                  data={selectedRow}
                  type={type}
                  setIsOpen={setIsOpen}
                />
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
