import { useState, useEffect } from "react"
import DataGrid, { CONSTANTS as GridConstants } from "components/data-grid/DataGrid"
import customAxios from "middlewares/axios-interceptor/customAxios"


const LeaveApprovalGroupSettings = ({ setSelectedLeaveApprovalGroup }) => {
    let [isLoading, setIsLoading] = useState(true);
    let [data, setData] = useState([]);

    const refreshGridData = (row) => {
        setIsLoading(true)
        const groupType = "approval"
        customAxios
            .get(`/group/type/${groupType}`)
            .then((res) => {
                setData(res);
                setIsLoading(false)
                setSelectedLeaveApprovalGroup(res[0])
            });
    }

    useEffect(() => {
        setTimeout(() => {
            refreshGridData()
        }, 0);
    }, [])


    const hideOnAdd = true;
    const hideOnEdit = true;


    const columns = [
        {
            accessor: "id",
            Header: "Id",
            hideOnAdd,
            hideOnEdit,
            formFieldProps:
            {
                // validation: (yup) => yup.string().required("Date is required"),
                // type: "dateSelect",
                // initialValue: () => dayjs().format()
            }
        },
        {
            accessor: "name",
            Header: "Name",
            formFieldProps:
            {
                validation: (yup) => yup.string().required("Name is required"),
            }
        },
        {
            accessor: "description",
            Header: "Description",
            formFieldProps:
            {
                // validation: (yup) => yup.string().required("Name is required"),
            }
        },
        {
            accessor: "type",
            Header: "Type",
            hideOnAdd,
            hideOnEdit,
            formFieldProps:
            {
                // validation: (yup) => yup.string().required("Name is required"),
            }
        }
    ];



    const onActionBegin = (args) => {

        if (args.requestType === GridConstants.GRID_ACTION_SAVE) {

            if (args.action === GridConstants.ACTION_ADD) {
                const payload =
                {
                    ...args.entity,
                    type: "Approval"
                }
                console.log("SAVE >> ADD", payload)
                customAxios
                    .post("/group/create", payload)
                    .then(() => { refreshGridData(); });

            }

            else if (args.action === GridConstants.ACTION_UPDATE) {

                const { policies: _, ...payload } = args.entity;
                console.log("SAVE >> UPDATE", payload)

                customAxios
                    .put("/group/update", payload)
                    .then(() => { refreshGridData(); });
            }

            else if (args.action === GridConstants.ACTION_DELETE) {
                console.log("SAVE >> DELETE", args.entity)
                const entityId = args.entity.id;
                customAxios
                    .delete(`/group/delete/${entityId}`)
                    .then(() => { refreshGridData() })
            }

        } else if (args.requestType === GridConstants.GRID_ACTION_ROWSINGLECLICK) {
            console.log("ROW CLICK", args.entity)
            setSelectedLeaveApprovalGroup(args.entity)
        }
    }



    return (
        isLoading
            ? <div className="h-full w-full rounded-lg bg-slate-200 animate-pulse"></div>
            : <DataGrid
                header="Leave Groups"
                showHeader={true}
                cellFunction={cell => cell.render("Cell")}
                columns={columns}
                testData={data}
                setData={setData}
                allowAdd={true}
                allowEdit={true}
                allowEditRowOnDoubleClick={true}
                allowDelete={true}
                confirmOnDelete={true}
                onActionBegin={onActionBegin}
            />
    )
}

export default LeaveApprovalGroupSettings