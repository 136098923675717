import React, { Fragment, useEffect } from "react";
import { menuItems } from "./../MenuItems";
import Widget from "components/widget/Widget";
import { useDispatch } from "react-redux";
import {
  setBreadCrumbs,
  setSelectedSubMenu,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import AttendanceChart from "./charts/TodaysAttendance";
import MonthlyAttendance from "./charts/MonthlyAttendance";

export default function AttendanceOverview() {
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedSubMenu(menuItems[0]));
    dispatch(setSubHeader("Attendance"));
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Attendance", link: "/Attendance/Overiew" },
      ])
    );
  }, []);

  return (
    <div className="grid grid-cols-4 gap-2">
      <div className="col-span-1">
        <Widget heading={"Today's Attendance"}>
              <AttendanceChart/>
        </Widget>
      </div>
      <div className="col-span-1">
        <Widget heading={""}>
        </Widget>
      </div>
    </div>
  );
}
