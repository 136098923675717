import LineChart from "components/charts/line-chart/LineChart";
import Widget from "components/widget/Widget";
import React from "react";

const datasets = [
  {
    label: "Sick", // the name of the dataset
    data: [20, 10, 30, 20, 10, 18, 10, 30, 19, 19, 19, 4],
    fill: true,
  },
  {
    label: "FRL",
    data: [9, 6, 5, 7, 6, 9, 10, 10, 8, 8, 9, 0],
    fill: false,
  },
];

const months = [
  new Date("1/1/2021"),
  new Date("2/1/2021"),
  new Date("3/1/2021"),
  new Date("4/1/2021"),
  new Date("5/1/2021"),
  new Date("6/1/2021"),
  new Date("7/1/2021"),
  new Date("8/1/2021"),
  new Date("9/1/2021"),
  new Date("10/1/2021"),
  new Date("11/1/2021"),
  new Date("12/1/2021"),
];

let labels = months.map((month) =>
  month.toLocaleString("default", { month: "short" })
);

export default function EmployeeLeaveChart({ data }) {
  console.log(data);
  return (
    <Widget heading={"Leave Chart "}>
      <div className="flex flex-col justify-start items-start space-y-1">
        <LineChart labels={labels} datasets={datasets} />
      </div>
    </Widget>
  );
}
