import PrimaryButton from "ui/buttons/PrimaryButton"

const SubmitButton = ({onClick, disabled}) => {
    return (
    <div className="flex flex-row justify-end h-10">
        <PrimaryButton
            onClick={onClick}
            title="Submit"
            disabled={disabled}
        />
    </div>
    )
}

export default SubmitButton