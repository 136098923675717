const TaskSheetLogInForm = ({onSubmit, onChange}) => (
    <>
        <form 
            className="flex flex-col items-center justify-center space-y-3 w-full h-full mt-10"
            onSubmit={onSubmit}
        >
            <input 
                className="p-0.5 px-2 border border-gray-200 w-fit h-8 text-xs rounded-lg flex justify-between items-center flex-row space-x-3 bg-white font-semibold"
                type="text" 
                placeholder="Username" 
                name="name"
                required
                onChange={onChange}
            />
            <input 
                className="p-0.5 px-2 border border-gray-200 w-fit h-8 text-xs rounded-lg flex justify-between items-center flex-row space-x-3 bg-white font-semibold"
                type="password" 
                placeholder="Password" 
                name="password"
                onChange={onChange}
            />
            <button 
                type="submit"
                className="bg-orange-500 px-6 p-1.5 w-fit text-sm border border-slate-300flex items-center justify-center text-slate-50 font-semibold rounded-xl shadow-md hover:brightness-95"
            >
                Log In
            </button>
        </form>
    </>
)

export default TaskSheetLogInForm