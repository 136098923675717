import FormCreator from "components/form-creator/FormCreator";
import {
  setBreadCrumbs,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import FormGenerator from "components/form-generator/FormGenerator";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  selectCurrentLeave,
  selectWorkingDays,
  setEndDate,
  setIsBackDate,
  setStartDate,
  setWorkingDays,
} from "features/leaves/LeavesSlice";
import moment from "moment";
import { isAfter, isBefore, isSameDay } from "date-fns";
import customAxios from "middlewares/axios-interceptor/customAxios";
import { addNotification } from "features/notifications/NotificationSlice";
import * as Yup from "yup";
import { setEmployeeLoans } from "features/employees/EmployeesSlice";

let employees = JSON.parse(localStorage.getItem("employees"));
let leaveTypes = JSON.parse(localStorage.getItem("leaveTypes"));

export default function LoanInformation() {
  let navigate = useNavigate();
  let url = window.location.pathname;
  let { LeaveType } = useParams();
  let [isError, setIsError] = React.useState(false);
  let [errorMsg, setErrorMsg] = React.useState("");
  let dispatch = useDispatch();
  let { EmployeeId } = useParams();

  let selectedLeaveType = leaveTypes?.find((l) => l.name === LeaveType);

  const formData = {
    contents: [
      {
        heading: "Loan Information",
        description:
          "You are required to fill the following information to add a new Employee. Rest of the steps maybe avoided if necessary.",
        function: () => {
          return <div></div>;
        },
        fields: [
          {
            name: "loanType",
            displayName: "Loan Type",
            type: "comboBox",
            inputType: "text",
            required: true,
            value: (formik) => {
              return formik.values.loanType;
            },
            values: [
              {
                id: 84,
                name: "Personal Loan",
              },
            ],
            options: function (item) {
              return (
                <div className="grid grid-cols-12 justify-center items-center gap-2">
                  <span className="col-span-5">{item.name}</span>
                </div>
              );
            },
          },
          {
            name: "loanAmount",
            displayName: "Loan Amount",
            type: "field",
            inputType: "numeric",
            required: true,
          },
          {
            name: "interestRate",
            displayName: "Interest Rate",
            type: "field",
            inputType: "numeric",
            required: true,
          },
          {
            name: "dateStart",
            displayName: "Starts on",
            type: "dateSelect",
            default: new Date(),
            inputType: "text",
            value: (formik) => {
              dispatch(setStartDate(formik.values.dateStart));
              return formik.values.dateStart;
            },
            required: true,
          },
          {
            name: "loanDuration",
            displayName: "Loan Duration",
            type: "comboBox",
            inputType: "text",
            required: true,
            value: (formik) => {
              return formik.values.loanDuration;
            },
            values: function () {
              let values = [];
              for (let i = 1; i <= 60; i++) {
                values.push({
                  id: i,
                  name: i + `${i === 1 ? " Month" : " Months"}`,
                  value: i,
                });
              }
              return values;
            },
            options: function (item) {
              return (
                <div className="grid grid-cols-12 justify-center items-center gap-2">
                  <span className="col-span-5">{item.name}</span>
                </div>
              );
            },
          },
          {
            name: "repaymentType",
            displayName: "Repayment Type",
            type: "comboBox",
            inputType: "text",
            required: true,
            value: (formik) => {
              return formik.values.repaymentType;
            },
            values: [
              {
                id: 1,
                name: "Salary Deduction",
              },
              {
                id: 2,
                name: "Cash",
              },
            ],
            options: function (item) {
              return (
                <div className="grid grid-cols-12 justify-center items-center gap-2">
                  <span className="col-span-5">{item.name}</span>
                </div>
              );
            },
          },
          {
            name: "remarks",
            displayName: "Remarks",
            type: "textArea",
            inputType: "text",
            required: true,
          },
          {
            name: "Attachments",
            displayName: "Attachments",
            type: "fileUpload",
            inputType: "text",
            required: false,
          },
        ],
      },
    ],
    initialValues: {
      loanType: "",
      loanAmount: "",
      interestRate: "",
      dateStart: new Date(),
      loanDuration: "",
      repaymentType: "",
      remarks: "",
      attachments: "",
    },
    validationSchema: Yup.object().shape({
      remarks: Yup.string().required(
        "Please Enter some remarks for clarifications"
      ),
    }),
    onSubmit: async (
      values,
      { setSubmitting, setErrors, setStatus, resetForm, handleSubmit }
    ) => {
      console.log(values);
      let data = {
        employeeId: parseInt(EmployeeId),
        TypeId: values.loanType.id,
        PrincipalAmount: parseInt(values.loanAmount),
        Rate: parseInt(values.interestRate),
        Start: moment(values.dateStart).format("YYYY-MM-DD"),
        loanDuration: values.loanDuration.value,
        repaymentType: values.repaymentType.id,
        IsRepayableOnPayroll: values.repaymentType.id === 1 ? true : false,
        IsDebitNotCreditTransaction: 1,
        remarks: values.remarks,
        attachments: "",
      };

      try {
        customAxios
          .post(`/employeefinance/loans-and-reimbursements`, data)
          .then((res) => {
            dispatch(setEmployeeLoans(data));
          });
      } catch (error) {
        console.log(error);
      }
    },
  };

  return (
    <div className="">
      <FormGenerator
        ErrorMsg={errorMsg}
        isError={isError}
        height={""}
        buttonSubmit={{ title: "Submit" }}
        formData={formData}
      />
    </div>
  );
}
