import { useState, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import customAxios from 'middlewares/axios-interceptor/customAxios'
import { taskAxios } from 'pages/attendance/timesheet/taskAxios/taskAxios';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Fragment } from 'react';

export default function AllowancesDropdown({ onAllowanceSelect }) {
    const [allowances, setAllowances] = useState([]);
    const [selectedAllowance, setSelectedAllowance] = useState(null);
  
    useEffect(() => {
      customAxios.get("/pay/allowances")
        .then((res) => {
            setAllowances(res)
      });
    }, []);
  
    return (
        <div>
        <Listbox
            value={selectedAllowance}
            onChange={(allowance) => {
                onAllowanceSelect(allowance);
                setSelectedAllowance(allowance);
            }}
        >
            <Listbox.Button className="p-0.5 px-2 border border-gray-200 w-fit h-8 text-xs rounded-lg flex justify-between items-center flex-row space-x-3 bg-white font-semibold">
            <div className="text-xs">
                {selectedAllowance ? selectedAllowance.name : "Select An Allowance"}
            </div>
            <ChevronDownIcon className="ml-2 -mr-1 h-4 w-4" aria-hidden="true" />
            </Listbox.Button>
            <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
            <Listbox.Options className="absolute rounded-md mt-1 z-50 w-56 h-fit max-h-56 overflow-auto  origin-bottom-right divide-y divide-gray-100  bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {allowances.map((allowance) => {
                    return (
                    <Listbox.Option
                        className="cursor-pointer p-2 hover:bg-orange-100 text-xs"
                        key={allowance.id}
                        value={allowance}
                    >
                        {allowance.name}
                    </Listbox.Option>
                    );
                })}
            </Listbox.Options>
            </Transition>
        </Listbox>
      </div>
    );
}