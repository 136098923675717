import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  groupTypes: null,
  selectedGroupType: null,
  general: {
    errorStatus: false,
    error: null,
    data: null,
  },
  configuration: {
    errorStatus: false,
    error: null,
    data: [],
  },
  selectedEmployees: [],
  selectedPolicies: [],
  selectedGroups: [],
  selectedTab: null,
};

export const GroupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    // Set Selected Tab
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setSelectedGroupType: (state, actions) => {
      state.selectedGroupType = actions.payload;
    },
    setGeneralData: (state, action) => {
      state.general.data = action.payload;
    },
    setSelectedEmployees: (state, action) => {
      state.selectedEmployees = action.payload;
    },
  },
});

export const {
  setSelectedTab,
  setSelectedGroupType,
  setGeneralData,
  setSelectedEmployees,
} = GroupSlice.actions;

// Selectors
export const selectTab = (state) => state.group.selectedTab;
export const selectedGroupType = (state) => state.group.selectedGroupType;
export const selectGeneralData = (state) => state.group.general.data;
export const selectSelectedEmployees = (state) => state.group.selectedEmployees;

export default GroupSlice.reducer;
