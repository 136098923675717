import DataGrid from "components/data-grid/DataGrid";
import Widget from "components/widget/Widget";
import {
  setBreadCrumbs,
  setSelectedSubMenu,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import customAxios from "middlewares/axios-interceptor/customAxios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { columns } from "./columns";
import { menuItems } from "./../MenuItems";
import moment from "moment";

export default function Designations() {
  const [selectedRows, setSelectedRows] = React.useState([]);
  const handleSelection = React.useCallback((value) => {
    setSelectedRows(value);
  }, []);
  let [data, setData] = React.useState([]);
  let [loading, setLoading] = React.useState(true);

  let dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setSelectedSubMenu(menuItems[2]));
    dispatch(setSubHeader("Designations"));
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Recruitment", link: "/employees/overview" },
        {
          name: "Designations",
          link: "/employees/inactive-employees",
        },
      ])
    );
  }, []);

  React.useEffect(() => {
    customAxios.get("/employment/designation").then((res) => {
      console.log(res);
      arrangeData(res);
    });
  }, []);

  async function arrangeData(items) {
    let tempData = [];
    items?.map((d) => {
      tempData.push({
        id: d.id ?? "N/A",
        name: d.name ?? "N/A",
        department: d.department ?? "N/A",
      });
    });
    setData(tempData);
    setLoading(false);
  }

  function cellFunction(cell) {
    return cell.render("Cell");
  }

  // find index of selected row items from data array

  return (

    <Widget heading={"All Designations"}>
      <DataGrid
        showHeader={true}
        search={true}
        columns={columns}
        testData={data}
        selectable={false}
        cellFunction={cellFunction}
      />
    </Widget>

  );
}
