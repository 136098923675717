import axios from "axios";
import { getImmediateTenant } from "../HelperFunctions";

export const taskAxios = axios.create({
    baseURL: "https://task.echesconsultancy.com:8088"
})

taskAxios.interceptors.request.use(
    (config) => {
        config.headers["x-tenant"] = getImmediateTenant()
        return config
    },
    (error) => {
        Promise.reject(error)
        return error
    }
)

taskAxios.interceptors.response.use(
    (response) => response.data,
    (error) => {
        Promise.reject(error)
        return error
    }
)