import NavigationBar from "components/navigation/nav-bar/NavigationBar";
import React from "react";
import { Link } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";
import MainLayout from "../../../components/layout/MainLayout";

export default function NoPageFound() {
  return (
    <div className="flex p-1 flex-col space-y-1.5 h-screen  bg-slate-100">
      <NavigationBar />
      <div className="flex flex-col h-full justify-center items-center space-y-5">
        <img
          src="https://cdn-icons-png.flaticon.com/512/7465/7465751.png"
          className="h-72 w-72"
        />
        <p className="text-slate-800 font-medium">Ooops! No Page Found!</p>
        <PrimaryButton
          className="py-1 px-4 text-md text-white bg-orange-600 hover:brightness-110"
          to={"/"}
        >
          Go Back Home
        </PrimaryButton>
      </div>
    </div>
  );
}
