import BarChart from "components/charts/bar-chart/barChart";
import customAxios from "middlewares/axios-interceptor/customAxios";
import { useEffect } from "react";
import { useState } from "react";
import addMonths from "date-fns/addMonths";
import startOfYear from "date-fns/startOfYear";
import format from "date-fns/format";
import isSameYear from "date-fns/isSameYear";

export default function MonthlyLeaves () {
  const [leaves, setLeaves] = useState([])

  useEffect(() => {
    customAxios.get("/leave")
    .then((res) => {
      let leaveItems = res.items;
      setLeaves(leaveItems)
    }
    )
  }, [])

  let firstDayOfTheYear = startOfYear(new Date())
  let monthlyLeaveCount = {}
  for(let i = 0; i < 12; i++)
  {
    let monthThreeLetters = format(firstDayOfTheYear, "MMMM").substring(0, 3);
    monthlyLeaveCount[monthThreeLetters] = 0;
    firstDayOfTheYear = addMonths(firstDayOfTheYear, 1);
  }

  for(let i = 0; i < leaves.length; i++)
  {
    let leaf = leaves[i]
    let leaveStartDay = new Date(leaf.from);
    if(!isSameYear(leaveStartDay, new Date()))
    {
      continue;
    }
    let month = format(leaveStartDay, "MMMM")
    let monthThreeLetters = month.substring(0, 3)
    monthlyLeaveCount[monthThreeLetters]++;
  }

  const data = {
    labels: [...Object.keys(monthlyLeaveCount)],
    datasets: [
      {
        data: [...Object.values(monthlyLeaveCount)],
      },
    ],
  };

  return (
      <BarChart labels={data.labels} datasets={data.datasets} />
  )
}