import GlobalLayout from "layout/GlobalLayout";
import React, { useEffect, useState } from "react";
import AdvanceDataGrid from "components/advance-data-grid/AdvanceDataGrid.tsx";
import customAxios from "middlewares/axios-interceptor/customAxios";

export default function Schedules() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    "id",
    "title",
    "description",
    "startOn",
    "endOn",
    "type.name",
    "frequency",
  ];

  async function fetchScheduleList() {
    const result = await customAxios.get("/calendar/schedules");
    setData(result.items);
  }

  useEffect(() => {
    fetchScheduleList();
  }, []);

  return (
    <AdvanceDataGrid
      title="Schedules"
      showHeader={true}
      endpoint="/calendar/schedules"
      columns={columns}
    />
  );
}
