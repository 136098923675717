import Widget from "components/widget/Widget";
import { selectEmployee } from "features/employees/EmployeesSlice";
import React from "react";
import { useSelector } from "react-redux";

export default function EmployeeCard({ data }) {
  const selectedEmployeeData = useSelector(selectEmployee);

  // setEmployeeCardData({
  //   profilePicture:
  //     res?.avatar ?? "https://www.mydthpay.com/img/review.png",
  //   name: res?.name ?? `N/A`,
  //   designation: res?.designation?.name ?? "N/A",
  //   department: res?.department?.name ?? "N/A",
  //   joinedDate: moment(res.joinedDate).format("DD MMM YYYY") ?? "N/A",
  //   status: res.state ?? "N/A",
  // });

  return (
    <Widget>
      <div className="flex flex-col space-y-1  p-2">
        <div className="absolute bg-orange-600 h-20 top-0 z-50" />
        <div className="flex flex-col justify-center items-center gap-3 text-sm">
          {Object.keys(data).map((key, index) => {
            if (key === "profilePicture") {
              return (
                <div
                  className="col-span-6 flex justify-center mb-5 items-center"
                  key={index}
                >
                  <div
                    style={{ backgroundImage: `url(${data[key]})` }}
                    alt={data["name"]}
                    className=" w-32 h-32 border-2 grayscale  bg-cover bg-center rounded-full"
                  />
                </div>
              );
            }
            if (key === "name") {
              return (
                <div className="col-span-6 flex justify-center mb-2 items-center">
                  <p className="text-xl text-center font-semibold">
                    {data[key].replace(/[A-Z]/g, " $&").trim()}
                  </p>
                </div>
              );
            }
            if (key === "status") {
              return (
                <div className="col-span-6 bg-emerald-600 px-6 py-1 text-white rounded-xl flex justify-center mb-2 items-center">
                  <p className="text-sm font-semibold capitalize">
                    {data[key]}
                  </p>
                </div>
              );
            }
            if (key === "joinedDate") {
              return (
                <div className="col-span-6 flex justify-center mb-2 items-center">
                  Since
                  <p className="text-sm ml-1 capitalize">{data[key]}</p>
                </div>
              );
            }

            return (
              <>
                {/* <div className="col-span-2 text-xs capitalize">
                  <p>
                    {keyp
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                  </p>
                </div> */}
                <div className="col-span-4 font-semibold">
                  <p>{data[key]}</p>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </Widget>
  );
}
