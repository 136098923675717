import Widget from "components/widget/Widget";
import React from "react";
import moment from "moment";

export default function EmployeeDocumentExpiry({ Data }) {
  return (
    <Widget heading={"Employee Document Expiry"}>
      {Data.map((e) => {
        return (
          <div className="flex flex-col">
            <div className="flex flex-row justify-between items-center">
              <div className="text-sm font-semibold ">{e.documentType}</div>

              <div className="text-sm font-semibold text-gray-500">
                {moment(e.expiryDate).startOf("day").fromNow()}
              </div>
            </div>
          </div>
        );
      })}
    </Widget>
  );
}
