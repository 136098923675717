import { Tab } from "@headlessui/react";
import { CogIcon } from "@heroicons/react/outline";
import Services from "data/services";
import {
  setSelectedMenu,
  selectSelectedMenu,
} from "features/navigation/NavigationSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SideMenu() {
  let dispatch = useDispatch();
  let selectedMenu = useSelector(selectSelectedMenu);
  let navigate;
  return (
    <div className="h-full w-full">
      <Tab.Group>
        <div className="flex flex-col justify-between items-center w-full bg-white h-full rounded-xl shadow py-2">
          <Tab.List className="flex flex-col justify-between space-y-4 h-full">
            <div className="flex flex-col space-y-2">
              {Services?.map((item, i) => (
                <Link to={item.route} key={i} className="w-full">
                  <Tab
                    onClick={() => {
                      dispatch(setSelectedMenu(item));
                    }}
                    className={({ selected }) =>
                      classNames(
                        "flex flex-row justify-start rounded-md  text-md focus:outline-none w-full items-center space-x-4 p-2  hover:cursor-pointer hover:bg-orange-50",
                        selectedMenu === item
                          ? "bg-orange-100 text-orange-600  "
                          : "bg-slate-50"
                      )
                    }
                    key={i}
                  >
                    <p className="text-sm">{item.icon}</p>
                  </Tab>
                </Link>
              ))}
            </div>
            <Link to={"/Settings"} className="w-full justify-end">
              <Tab
                onClick={() => {
                  dispatch(setSelectedMenu(null));
                }}
                className="flex flex-row rounded-md justify-start  text-md focus:outline-none w-full items-center space-x-4 p-2  hover:cursor-pointer hover:bg-orange-50 bg-orange-100 text-orange-600"
              >
                <p className="text-sm">
                  <CogIcon className="w-6 h-6" />
                </p>
              </Tab>
            </Link>
          </Tab.List>
        </div>
      </Tab.Group>
    </div>
  );
}
