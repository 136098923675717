export const columns = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Group Type",
    accessor: "type",
  },
  {
    Header: "Owner",
    accessor: "owner",
  },
  {
    Header: "Created By",
    accessor: "createdBy",
  },
  {
    Header: "Created On",
    accessor: "createdAt",
  },
];
