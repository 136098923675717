import Widget from "components/widget/Widget";
import {
  selectRefreshTrigger,
  setBreadCrumbs,
} from "features/navigation/NavigationSlice";
import customAxios from "middlewares/axios-interceptor/customAxios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { employeeMapper } from "pages/attendance/timesheet/EmployeeMapper";
import DataGrid from "components/data-grid/DataGrid";
import moment from "moment";
import { addHours } from "date-fns";

const USERNAME = "9494";
const PASSWORD = "236FE197-7CC5-41F2-A01C-9EB36D8B7D38";

const auth = {
  username: USERNAME,
  password: PASSWORD,
};

export default function ViewEmployeeTaskSheet({}) {
  let { Name, EmployeeId } = useParams();
  let dispatch = useDispatch();
  let refreshTrigger = useSelector(selectRefreshTrigger);
  let [data, setData] = React.useState([]);
  let date = moment(new Date()).format("MM-DD-YY");

  let TaskSheetEmployeeId =
    employeeMapper.find((emp) => emp.hrId == EmployeeId)?.taskId ?? 1;

  React.useEffect(() => {
    customAxios
      .get(
        `https://task.echesconsultancy.com:8088/repmytasks/1/` + EmployeeId,

        {
          auth: auth,
        }
      )
      .then((res) => {
        setData(res);
      });

    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Employees", link: "/employees/all" },
        { name: `${Name}`, link: `` },
        { name: "Task Sheet", link: `` },
      ])
    );
  }, []);

  let array = [];

  // add all the times in the array
  let totalHours = array.reduce((a, b) => a + b, 0);

  let test = addHours(new Date(), totalHours);

  let columns = [
    {
      Header: "Task Name",
      accessor: "task",
    },

    {
      Header: "Activity",
      accessor: "activity",
    },

    {
      Header: "Customer",
      accessor: "customer",
    },

    // {
    //   Header: "Completed On",
    //   accessor: "endDate",
    // },
    {
      Header: "Time spent",
      accessor: "timeSpent",
    },
    {
      Header: "Due Date",
      accessor: "dueDate",
    },
  ];

  let cellFunction = (cell) => {
    return cell.value;
  };

  return (
    <div className="grid grid-cols-4 overflow-auto h-[75vh] pb-10  gap-3 ">
      <div className="col-span-4">
        <Widget heading="Employee Pending Tasks">
          {data.length == 0 ? (
            <div className="text-center text-gray-500">
              No data found{EmployeeId}
            </div>
          ) : (
            <>
              <DataGrid
                showHeader={true}
                testData={data}
                columns={columns}
                cellFunction={cellFunction}
              />
            </>
          )}
        </Widget>
      </div>
      <Outlet />
    </div>
  );
}
