const columns = [
    {
        Header: "Id",
        accessor: "id",
        hideOnAdd: true,
        hideOnEdit: true
    },
    {
        Header: "Title",
        accessor: "title",
    },
    {
        Header: "Name",
        accessor: "name",
        hideOnAdd: true,
        hideOnEdit: true
    },
    {
        Header: "First Name",
        accessor: "firstName",
        // validation: (yup) => yup.string().required("Name is required").min(5, "Must be at least 5 charaters long"),
        // validation: (yup) => yup.string().required("Name is required").min(5, "Must be at least 5 charaters long"),

    },
    {
        Header: "Last Name",
        accessor: "lastName",
    },
    {
        Header: "Middle Name",
        accessor: "middleName",
    },
    {
        Header: "Common Name",
        accessor: "commonName",
    },
    {
        Header: "Reports To",
        accessor: "reportsTo",
        hideOnAdd: true
    },
    {
        Header: "Employment Type",
        accessor: "employmentType",
    },
    {
        Header: "Date Joined",
        accessor: "dateJoined",
    },
    {
        Header: "Employment",
        accessor: "employment",
    },
    {
        Header: "Leave Group",
        accessor: "leaveGroup",
    },
    {
        Header: "Pay Group",
        accessor: "payGroup",
    },
    {
        Header: "Department",
        accessor: "department",
    },
    {
        Header: "Security Group",
        accessor: "securityGroup",
    },
    {
        Header: "Nationality",
        accessor: "nationality",
    },
    {
        Header: "Employee Code",
        accessor: "employeeCode",
    },
    {
        Header: "ID Card Number",
        accessor: "idNumber",
    },
    {
        Header: "Country",
        accessor: "country",
    },
    {
        Header: "Email",
        accessor: "email",
    }
]

export default columns