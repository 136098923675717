import DateRangePickerMain from "components/date-picker-v2/DateRangePicker";
import Widget from "components/widget/Widget";
import React from "react";
import PrimaryButton from "ui/buttons/PrimaryButton";

export default function SelectPeriod({ SelectedTab }) {
  let [startDate, setStartDate] = React.useState(new Date());
  let [endDate, setEndDate] = React.useState(new Date());
  return (
    <div className="h-full flex">
      <Widget heading={"Select Period"}>
        <DateRangePickerMain />
      </Widget>
      <div className="fixed bottom-2">
        <PrimaryButton title={"Next: Select Employees"} />
      </div>
    </div>
  );
}
