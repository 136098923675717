import React, { useEffect, useState } from "react";
import NavBar from "../../components/navigation/nav-bar/NavigationBar";
import { Link, Outlet } from "react-router-dom";
import customAxios from "../../middlewares/axios-interceptor/customAxios";
import Services from "data/services";
import { useDispatch } from "react-redux";
import { setSelectedMenu } from "features/navigation/NavigationSlice";
import SecondaryButton from "ui/buttons/SecondaryButton";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { PencilIcon, PlusIcon } from "@heroicons/react/solid";
import Widget from "components/widget/Widget";
import UpcomingEvents from "widgets/announcements/UpcomingEvents";

export default function Home() {
  let init = localStorage.getItem("init");
  let dispatch = useDispatch();
  customAxios.get("/leave/types").then((res) => {
    localStorage.setItem("leaveTypes", JSON.stringify(res ?? []));
  });

  React.useEffect(() => {
    customAxios.get("/employee").then((res) => arrangeData(res.items));
  }, []);

  async function arrangeData(items) {
    let tempData = [];
    items?.map((d) => {
      tempData.push({
        id: d.id ?? "N/A",
        name: d.name ?? "N/A",
        empCode: d.id ?? "N/A",
        identificationNumber: d.idNumber ?? "N/A",
        dateOfBirth: d.dob ?? "N/A",
        nationality: d.nationality ?? "N/A",
        designation: d.position?.name ?? "N/A",
        department: d.position?.department ?? "N/A",
        email: d.email ?? "N/A",
        empType: d.employeeType ?? "N/A",
        status: d.status ?? "N/A",
        dateJoined: d.dateJoined ?? "N/A",
      });
    });

    localStorage.setItem("employees", JSON.stringify(tempData));
  }

  function renderContent() {
    return (
      <div className="flex  overflow-auto flex-col ">
        {/* Services */}
        <div className="">
          <div className="grid grid-cols-8 gap-5 bg-white p-2">
            {Services.map((item, i) => (
              <Link
                onClick={() => dispatch(setSelectedMenu(item))}
                to={item.route}
                className="flex hover:cursor-pointer group hover:bg-orange-50 rounded-xl  h-24  p-2 flex-col justify-center items-center space-y-3"
                key={i}
              >
                {item.icon}
                <p className=" text-gray-700 text-center text-sm">
                  {item.name}
                </p>
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex p-1 flex-col space-y-1.5 h-screen  bg-slate-100">
      <NavBar />
      <div className="flex w-screen  h-screen  justify-center pt-4 items-start">
        <div className="grid grid-cols-12  gap-3 max-w-7xl w-full">
          <div className="col-span-12 flex flex-row justify-between items-center">
            <div className="flex justify-between items-center">
              <h1 className="text-3xl font-semibold">Dashboard</h1>
            </div>
            <div className=" flex justify-end space-x-3 ">
              <SecondaryButton>
                <PencilIcon className="h-4 w-4 mr-2" />
                <button> Change Layout</button>
              </SecondaryButton>
              <PrimaryButton title="Add a Widget">
                <PlusIcon className="h-4 w-4 mr-2" />
                <button> Add a Widget</button>
              </PrimaryButton>
            </div>
          </div>
          <div className="flex col-span-12 flex-col space-y-8">
            <Widget
              heading={"Eches HR Services"}
              subHeading={"Home"}
              component={function () {
                return (
                  <div className="flex flex-col space-y-3">
                    <h1 className="text-sm text-orange-400 font-base">
                      View All
                    </h1>
                  </div>
                );
              }}
            >
              <div>{renderContent()}</div>
            </Widget>
          </div>
          {/* <div className="col-span-6">
            <Widget heading={"My Tasks"}>
              <p>Tasks</p>
            </Widget>
          </div>
          <div className="col-span-6">
            <Widget heading={"Employee Directory"}>
              <p>Tasks</p>
            </Widget>
          </div> */}
          <div className="col-span-4">
            <UpcomingEvents />
          </div>
        </div>
      </div>

      <Outlet />
    </div>
  );
}
