import DatePickerMain from "./DatePickerMain";

export default function DateRangePickerMain({ onFDateChange, onEDateChange, fDateHeading="", eDateHeading="" }) {
  return (
    <div className="flex flex-row items-center text-xs space-x-3">
      <div>
        <div className="mb-1">{fDateHeading}</div>
        <DatePickerMain onDateChange={onFDateChange} />
      </div>
        <div>{!(fDateHeading || eDateHeading) && "To"}</div>
      <div>
        <div className="mb-1">{eDateHeading}</div>
        <DatePickerMain onDateChange={onEDateChange} />
      </div>
    </div>
  );
}
