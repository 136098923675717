import NavigationBar from "components/navigation/nav-bar/NavigationBar";
import ModalRegisterer from "modals/ModalRegisterer";
import React from "react";
import HeadingCard from "./navigation/HeadingCard";
import SideMenu from "./navigation/SideMenu";
import SubSideMenu from "./navigation/SubSideMenu";

export default function GlobalLayout({
  children,
  MenuItems,
  heading,
  breadCrumbs,
  buttons,
  selectedMenu,
  mainTitle,
}) {
  // check if URL containts modal
  let url = window.location.href;
  let isModal = url.includes("modal");

  return (
    <div className="h-screen max-h-screen overflow-hidden w-full bg-slate-200">
      <div className="flex h-full w-full flex-col">
        {/* <!-- NAVBAR --> */}
        <div className="flex flex-none h-14 flex-row justify-between">
          <NavigationBar heading={mainTitle ?? "Title"} />
        </div>

        {/* <!-- BELLOWNAVBAR --> */}
        <div className="flex-grow min-h-0">
          <div className="flex h-full w-full flex-row gap-0">
            {/* <!-- MAINMENU --> */}
            <div className="w-0 hidden h-full flex-none lg:flex lg:w-20 lg:p-2 transition-all">
              <SideMenu />
            </div>

            {/* <!-- SUBMENU --> */}
            <div className="flex-none flex w-20 h-full flex-col items-center p-2 lg:p-0 lg:pr-2 lg:py-2 lg:w-56 transition-all">
              <SubSideMenu selectedMenu={selectedMenu} MenuItems={MenuItems} />
            </div>


            {/* <!-- CONTENT --> */}
            {/* Rafhaan Helped */}
            <div className="relative h-full w-full">
              <div className="flex-grow h-full min-w-0 pr-2 py-2 overflow-hidden">
                <div className="flex flex-col h-full relative">
                  <div className="pl-2 py-2 absolute z-10 w-[calc(100%-1rem)] backdrop-blur-md">
                    <HeadingCard
                      BreadCrumbsData={breadCrumbs}
                      Buttons={buttons}
                      Heading={heading}
                    />
                  </div>
                  {/* <div className={`h-4 w-full absolute top-20 z-10 bg-gradient-to-t from-transparent to-slate-200 border-2 border-black`}></div> */}
                  {/* <div className={`h-4 w-full absolute top-20 z-10 backdrop-blur-sm bg-transparent`}></div> */}
                  <div className="flex-grow min-h-0 overflow-auto pt-20">
                    {/* <div className="h-2"></div> */}
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
