const Remarks = ({onRemarksChange}) => (
    <div className="text-xs">
        <div className="mb-1">Remarks</div>
        <textarea 
            onChange={e => onRemarksChange(e.target.value)}
            className="p-3 px-2 border border-gray-200 w-full h-32 text-xs rounded-lg flex justify-between items-center flex-row space-x-3 bg-white font-semibold" 
        />
    </div>
)

export default Remarks