import GlobalLayout from "layout/GlobalLayout";
import React from "react";
import { Outlet } from "react-router-dom";
import { menuItems } from "./MenuItems";

export default function Approvals() {
  return (
    <GlobalLayout
      mainTitle={"Approvals"}
      heading={"Approvals"}
      selectedMenu={menuItems[1]}
      breadCrumbs={[{ title: "Approvals" }]}
      MenuItems={menuItems}
      buttons={[]}
    >
      <Outlet />
    </GlobalLayout>
  );
}
