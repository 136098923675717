import ErrorMessageView from "./ErrorMessageView"


const FormWrapper = ({loading, children, errorMessage}) => (
    <>
        <div 
            style={{
                opacity: loading ? 0.35 : 1,
                cursor: loading ? "not-allowed" : "default",
                pointerEvents: loading ? "none" : "all"
            }}
            className="space-y-5"
        >   
            {
                errorMessage && (
                    <ErrorMessageView errorMessage={errorMessage} />
                )
            }
            {children}
        </div>
    </>
)

export default FormWrapper