import React from "react";

export default function ProgressBar({ progress }) {
  return (
    <div className="w-full  h-2 bg-white rounded-full">
      <div
        style={{ width: `${progress}%` }}
        className="h-full bg-orange-400 p-1 rounded-full"
      ></div>
    </div>
  );
}
