import React from "react";
import * as Yup from "yup";
import FormGenerator from "../../../../components/form-generator/FormGenerator";
import {
  addPersonal,
  selectBasics,
  setSelectedTab,
  selectDesignations,
  setDesignations,
  addEmployment,
  selectEmployment,
} from "../../../../features/employees/EmployeesSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

let employees = JSON.parse(localStorage.getItem("employees"));
let groups = JSON.parse(localStorage.getItem("groups"));
let variables = JSON.parse(localStorage.getItem("variables"));
let employmentTypes = variables?.filter((x) => x.key == "EmploymentType");
let departments = groups?.filter((x) => x.type == "Department");

export default function EmploymentDetails({}) {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let [selectedValues, setSelectedValues] = React.useState({});
  let designations = useSelector(selectDesignations);
  let [selectedDepartment, setSelectedDepartment] = React.useState("helllo");
  let [loading, setLoading] = React.useState(false);
  let [selectedDesignation, setSelectedDesignation] = React.useState("helllo");
  let employmentData = useSelector(selectEmployment);

  const formData = {
    contents: [
      {
        heading: "General Employment Information",
        description: null,
        function: () => {
          return <div></div>;
        },
        fields: [
          {
            name: "employmentType",
            displayName: "Employment Type",
            type: "comboBox",
            value: (formik) => {
              return formik.values.employmentType;
            },
            values: employmentTypes,
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },

          {
            name: "department",
            displayName: "Department",
            type: "component",
            component: function (formik) {
              return (
                <div className="grid grid-cols-12">
                  <div className="col-span-12">
                    <select
                      className="w-full p-1 text-sm ml border border-gray-300 rounded-md pl-2 min-w-96"
                      onChange={(e) => {
                        setSelectedDepartment(e.target);
                        formik.setFieldValue("department", e.target.value);
                      }}
                    >
                      <option className=" text-slate-500" value="0">
                        {selectedDepartment
                          ? selectedDepartment.value
                          : "Select"}
                      </option>
                      {loading &&
                        departments?.map((x) => {
                          return (
                            <option className="ml-2" value={x.id} key={x.id}>
                              {x.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              );
            },
          },

          {
            name: "Designation",
            displayName: "Designation",
            type: "component",
            component: function (formik) {
              return (
                <div className="grid grid-cols-12">
                  <div className="col-span-12">
                    <select
                      className="w-full p-1 text-sm ml border border-gray-300 rounded-md pl-2 min-w-96"
                      onChange={(e) => {
                        setSelectedDesignation(e.target);
                        formik.setFieldValue("designation", e.target.value);
                      }}
                    >
                      <option className=" text-slate-200" value="0">
                        Select Designation
                      </option>
                      {!loading
                        ? designations?.map((x) => {
                            return (
                              <option className="ml-2" value={x.id} key={x.id}>
                                {x.name}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                </div>
              );
            },
          },
          {
            name: "leaveGroup",
            displayName: "Leave Group",
            type: "comboBox",
            value: (formik) => {
              return formik.values.bank;
            },
            values: groups?.filter((x) => x.type == "Leave"),
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },

          {
            name: "scheduleGroup",
            displayName: "Schedule Group",
            type: "comboBox",
            value: (formik) => {
              return formik.values.bank;
            },
            values: groups?.filter((x) => x.type == "Schedule"),
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },

          {
            name: "reportsTo",
            displayName: "Reports To",
            type: "comboBox",
            inputType: "text",
            required: true,
            value: (formik) => {
              return formik.values.employee;
            },
            values: employees ?? [],
            options: function (item) {
              return (
                <div className="grid grid-cols-12 gap-3">
                  <span className="col-span-3">{item.empCode}</span>
                  <span className="col-span-5">{item.name}</span>
                  <span className="col-span-4">Marketing</span>
                </div>
              );
            },
          },
        ],
      },
      {
        heading: "Contract Information",
        description:
          "This section is only required if the employee has been selected as contract employee",
        fields: [
          {
            name: "contractPeriod",
            displayName: "Contract Period",
            type: "field",
            inputType: "text",
          },
          {
            name: "probation",
            displayName: "Probation Period",
            type: "field",
            inputType: "text",
          },
        ],
      },
    ],
    initialValues: {
      employmentType: employmentData?.data?.employmentType ?? "",
      department: employmentData?.data?.department ?? "",
      designation: employmentData?.data?.designation ?? "",
      leaveGroup: employmentData?.data?.leaveGroup ?? "",
      scheduleGroup: employmentData?.data?.scheduleGroup ?? "",
      reportsTo: employmentData?.data?.reportsTo ?? "",
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: async (
      values,
      { setSubmitting, setErrors, setStatus, resetForm, handleSubmit }
    ) => {
      // add designation state to values
      navigate("/Employees/Onboarding/Personal Info", { state: { values } });
      values.designationId = parseInt(selectedDesignation);
      values.departmentId = parseInt(selectedDepartment);
      console.log(values);
      dispatch(addEmployment(values));
    },
  };

  React.useEffect(() => {
    setLoading(true);
    console.log("hey");
  }, [selectedDepartment]);

  return (
    <>
      <FormGenerator
        setSelectedValues={setSelectedValues}
        formData={formData}
      />
    </>
  );
}
