import EditableDataGrid from "components/editable-data-grid/EditableDataGrid";
import Widget from "components/widget/Widget";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { columns } from "./columns";

export default function ModifyPayroll() {
  let [data, setData] = React.useState([
    {
      employee: "John Doe",
      designation: "Software Engineer",
      department: "IT",
      daysWorked: 20,
      daysAbsent: 0,
      daysLeave: 0,
      daysHoliday: 0,
      daysPaidLeave: 0,
      daysUnpaidLeave: 0,
      totalWorkHours: 160,
      totalLateHours: 0,
      basic: 10000,
      hra: 2000,
      da: 1000,
      ta: 500,
      sa: 500,
      pf: 1000,
      esi: 500,
      pt: 500,
      otherDeductions: 0,
      totalEarnings: 14000,
      totalDeductions: 3000,
      netPay: 11000,
    },
  ]);

  return (
    <div className=" h-[50vh] overflow-auto">
      <Widget heading={"Edit Generated Attendance"}>
        <EditableDataGrid
          columns={columns}
          updateMyData={setData}
          data={data}
        />
      </Widget>
    </div>
  );
}
