import AllowancesDropdown from "./components/AllowancesDropdown"
import PrimaryButton from "ui/buttons/PrimaryButton"
import { useState } from "react"

const UpdateAllowanceForm = ({onChange, onSubmit, onAllowanceChange}) => {
    const [selectedAllowance, setSelectedAllowance] = useState(null)
    const [value, setValue] = useState("")

    const resetValues = () => {
        setValue("")
    }
    
    return (
        <form 
            onChange={onChange} 
            onSubmit={onSubmit}
            className="w-full h-full flex flex-col space-y-3 p-2"
        >
            <AllowancesDropdown
                onAllowanceSelect={(allowance) => {
                    setSelectedAllowance(allowance)
                    onAllowanceChange(allowance)
                }}
            />
            <input 
                type="text" 
                name="name"
                placeholder={`${selectedAllowance?.name ?? "Name"}`}
                className="p-0.5 px-2 border border-gray-200 w-fit h-8 text-xs rounded-lg flex justify-between items-center flex-row space-x-3 bg-white font-semibold"
            />
            <input 
                type="number" 
                name="baseAmount"
                placeholder={`${selectedAllowance?.baseAmount ?? "Amount"}`}
                className="p-0.5 px-2 border border-gray-200 w-fit h-8 text-xs rounded-lg flex justify-between items-center flex-row space-x-3 bg-white font-semibold"
            />
            <textarea 
                type="text" 
                name="description"
                placeholder={`${selectedAllowance?.description ?? "Description"}`}
                className="p-2 px-2 border border-gray-200 w-56 h-24 text-xs rounded-lg flex justify-between items-center flex-row space-x-3 bg-white font-semibold"
            />
            <input 
                type="number" 
                name="currency"
                placeholder={`${selectedAllowance?.currency ?? "Currency"}`}
                className="p-0.5 px-2 border border-gray-200 w-fit h-8 text-xs rounded-lg flex justify-between items-center flex-row space-x-3 bg-white font-semibold"
            />
            <textarea 
                type="text" 
                name="Query"
                placeholder={`${selectedAllowance?.query ?? "Query"}`}
                className="p-2 px-2 border border-gray-200 w-56 h-24 text-xs rounded-lg flex justify-between items-center flex-row space-x-3 bg-white font-semibold"
            />
            <div className="w-full h-fit flex flex-row justify-end">
                <PrimaryButton 
                    title="Update" 
                    type="submit" 
                />
            </div>
        </form>
    )
}

export default UpdateAllowanceForm