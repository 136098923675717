export const menuItems = [
  {
    title: "Overview",
    path: "/Approvals/Overview",
  },
  {
    title: "Leave Approvals",
    path: "/Approvals/Leaves",
  },
];
