import { PencilIcon, XIcon } from "@heroicons/react/solid";
import React from "react";
import * as Yup from "yup";
import FormGenerator from "../../../../components/form-generator/FormGenerator";
import {
  addAddresses,
  addContacts,
  deleteAddress,
  selectContacts,
} from "../../../../features/employees/EmployeesSlice";
import { useSelector, useDispatch } from "react-redux";
import Widget from "components/widget/Widget";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export default function Addresses({ setChildSelectedTab, selectedTab }) {
  const dispatch = useDispatch();
  const addresses = useSelector(
    (state) => state.employees.addEmployee.addresses
  );
  const formData = {
    array: true,
    contents: [
      {
        heading: "Address Information",
        description:
          "Multiple Address can be added for an employee. The contact types can be set in settings please click on the link below to add more contact types",
        function: () => {
          return <div></div>;
        },
        fields: [
          {
            name: "addressType",
            displayName: "Adress Type",
            type: "comboBox",
            value: (formik) => {
              return formik.values.bank;
            },
            values: [
              { id: 1, name: "Current" },
              { id: 2, name: "Permanent" },
              { id: 2, name: "Abroad" },
            ],
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },

          {
            name: "apartmentNo",
            displayName: "Apartment Number",
            type: "field",
            inputType: "text",
            required: false,
          },
          {
            name: "building",
            displayName: "Building",
            type: "field",
            inputType: "text",
            required: false,
          },

          {
            name: "block",
            displayName: "Block",
            type: "field",
            inputType: "text",
            required: false,
          },
          {
            name: "street",
            displayName: "Street",
            type: "field",
            inputType: "text",
            required: false,
          },
          {
            name: "postalCode",
            displayName: "Postal Code",
            type: "field",
            inputType: "text",
            required: false,
          },
          {
            name: "city",
            displayName: "City",
            type: "field",
            inputType: "text",
            required: false,
          },
          {
            name: "country",
            displayName: "country",
            type: "comboBox",
            value: (formik) => {
              return formik.values.bank;
            },
            values: [
              {
                id: 1,
                name: "Maldives'",
              },
            ],
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },

          // {
          //   name: "isDefault",
          //   displayName: "Make Primary",
          //   type: "checkbox",
          //   inputType: null,
          //   value: (formik) => {
          //     return formik.values.isDefault;
          //   },
          //   required: true,
          // },
        ],
      },
    ],
    initialValues: {},
    validationSchema: Yup.object().shape({}),
    onSubmit: async (
      values,
      { setSubmitting, setErrors, setStatus, resetForm, handleSubmit }
    ) => {
      let data = {
        type: values.addressType,
        bulding: values.building,
        appartmentNo: values.apartmentNo,
        postalCode: values.postalCode,
        street: values.street,
        block: values.block,
        city: values.city,
        country: values.country,
      };

      console.log(data);
      dispatch(addAddresses(data));
      resetForm();
    },
  };
  return (
    <div className="flex flex-col space-y-3">
      <div className="grid grid-cols-4 gap-3 text-xs">
        {addresses?.data?.map((address, i) => (
          <Widget
            heading={address?.type?.name}
            component={function () {
              return (
                <div className="flex flex-row space-x-2">
                  <PencilIcon
                    className="h-5 w-5 text-blue-500 cursor-pointer"
                    onClick={() => {
                      dispatch(deleteAddress(address, true));
                    }}
                  />
                  <XIcon
                    className="h-5 w-5 text-red-500 cursor-pointer"
                    onClick={() => {
                      dispatch(deleteAddress(address, true));
                    }}
                  />
                </div>
              );
            }}
            key={i}
          >
            {Object.keys(address).map((key, i) => (
              <div key={i}>
                <span className="font-semibold">{key}:</span>{" "}
                <span>{JSON.stringify(address[key])}</span>
              </div>
            ))}
          </Widget>
        ))}
      </div>

      <FormGenerator
        buttonSubmit={{
          title: addresses?.data.length == 0 ? "Add" : "Add More",
        }}
        buttonNext={{ title: "Proceed to" }}
        buttonPrev={{ title: "" }}
        formData={formData}
      />
    </div>
  );
}
