import PieChart from "components/charts/pie-chart/PieChart";
import isAfter from "date-fns/isAfter";
import parse from "date-fns/parse";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import { useEffect, useState } from "react";
import customAxios from "middlewares/axios-interceptor/customAxios";

export default function AttendanceChart () {
  const [attendanceItems, setAttendanceItems] = useState([])


  useEffect(() => {
    customAxios.get("/attendance",
    {
      params: {
        From: startOfDay(new Date()),
        To: endOfDay(new Date())
      }
    }
    )
    .then((res) => {
      setAttendanceItems(res?.items ?? [])
    })
  }, [])

  let attendance

  attendanceItems.forEach((item) => {

  })

  let numOnTime = 0;
  let numAbsent = 0;
  let numLate = 0;
  
  const attendanceData = {
    labels: ["Present", "Absent", "Late"],
    datasets: [
      {
        data: [numOnTime, numAbsent, numLate]
      }
    ]
  }

  return (
      <PieChart labels={attendanceData.labels} datasets={attendanceData.datasets} />
  )
}

export function isLate(checkedInTime, startTime) {
  let checkIn = parse(checkedInTime, 'HH:mm:ss', new Date());
  let start = parse(startTime, 'HH:mm:ss', new Date());
  return isAfter(checkIn, start);
}