import ModalLayout from "components/layout/ModalLayout";
import Modal from "components/modal/Modal";
import React from "react";
import FormGenerator from "components/form-generator/FormGenerator";
import Widget from "components/widget/Widget";
import SecondaryButton from "ui/buttons/SecondaryButton";
import customAxios from "middlewares/axios-interceptor/customAxios";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

export default function EmployeeResetPassword() {
  let [selectedOption, setSelectedOption] = React.useState("email");
  let [submited, setSubmited] = React.useState(false);
  let [employee, setEmployee] = React.useState(null);
  let [loading, setLoading] = React.useState(true);
  let [error, setError] = React.useState(null);
  let [isError, setIsError] = React.useState(false);
  let { EmployeeId } = useParams();

  const formData = {
    contents: [
      {
        heading: null,
        description: null,
        function: () => {
          return <div></div>;
        },
        fields: [
          {
            name: "otp",
            displayName: "One Time Password",
            type: "field",
            required: true,
          },
          {
            name: "password",
            displayName: "Password",
            type: "field",
            inputType: "password",
            required: true,
          },
          {
            name: "confirmPassword",
            displayName: "Confirm Password",
            type: "field",
            inputType: "password",
            required: true,
          },
        ],
      },
    ],
    initialValues: {
      otp: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      otp: Yup.string().required("Required"),
      password: Yup.string().required("Required"),
      confirmPassword: Yup.string().required("Required"),
    }),
    onSubmit: async (
      values,
      { setSubmitting, setErrors, setStatus, resetForm, handleSubmit }
    ) => {
      if (values.password !== values.confirmPassword) {
        setErrors({ confirmPassword: "Password does not match" });
        setError(true);
        return;
      }
      let data = {
        username: employee.email,
        newPassword: values.password,
        code: values.otp,
      };
      customAxios
        .put(
          "https://auth.echeshr.com/api/auth/forgot?applicationId=e7bc573b-380b-4c9c-8698-4de65c995c52",
          data
        )
        .then(
          (res) => {
            console.log(res);
          },
          (err) => {
            console.log(err);
          }
        );
    },
  };

  React.useEffect(() => {
    customAxios.get(`/employee/profile/${EmployeeId}`).then(
      (res) => {
        setEmployee({
          profilePicture:
            res?.avatar ?? "https://www.mydthpay.com/img/review.png",
          name: res?.title + ". " + res?.name ?? `N/A`,
          designation: res?.designation?.name ?? "N/A",
          department: res?.department?.name ?? "N/A",
          email: res?.email ?? "N/A",
          status: res.status ?? "N/A",
        });
        setLoading(false);
      },
      (err) => console.log(err)
    );
  }, []);

  function optionHandler(option) {
    setSelectedOption(option);
    setSubmited(true);
    let data = {
      username: employee.email,
      type: selectedOption,
    };
    customAxios
      .put(
        "https://auth.echeshr.com/api/auth/forgot?applicationId=e7bc573b-380b-4c9c-8698-4de65c995c52",
        data
      )
      .then(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  return (
    <Modal>
      {/* <code>{JSON.stringify(selectedEmployee)}</code> */}
      <ModalLayout heading={"Edit Employee Details"} width={"w-[33vw]"}>
        <Widget
          heading={
            submited
              ? "Password Reset OTP"
              : "Please select an option to which the password reset OTP needs to be sent!"
          }
          subHeading={
            submited
              ? "Please enter the OTP sent to your email address to reset your password."
              : ""
          }
        >
          {submited ? (
            <FormGenerator
              ErrorMsg={error}
              isError={isError}
              height={""}
              buttonSubmit={{ title: "Submit" }}
              formData={formData}
            />
          ) : (
            <div className="flex flex-row justify-start items-center space-x-2">
              <SecondaryButton className="btn btn-primary">
                <button onClick={() => optionHandler("email")}>Email</button>
              </SecondaryButton>
              <SecondaryButton className="btn btn-primary">
                <button onClick={() => optionHandler("mobile")}>Phone</button>
              </SecondaryButton>
            </div>
          )}
        </Widget>
      </ModalLayout>
    </Modal>
  );
}
