import format from "date-fns/format";

export function cellFunction(cell) {
    const {value, column} = cell

    switch(column.id)
    {
        case "dateJoined":
            return getValue(format(new Date(value), "dd MM yyyy"))
    }

    if (!value)
    {
        return <div className="text-gray-300">N/A</div>
    }
    return getValue(value);
}

const getValue = (value) => <div>{typeof value != 'object' ? value : 'Object'}</div>