import { PlusCircleIcon } from "@heroicons/react/outline";
import Widget from "components/widget/Widget";
import React from "react";
import { Link } from "react-router-dom";
import SecondaryButton from "ui/buttons/SecondaryButton";

export default function EmployeeEducation({ education }) {
  let url = window.location.pathname;
  return (
    <Widget
      heading={"Education"}
      // component={function () {
      //   return (
      //     <div className="flex flex-col space-y-3">
      //       <SecondaryButton>
      //         <button className="text-orange-600">
      //           Edit Employee Education
      //         </button>
      //       </SecondaryButton>
      //     </div>
      //   );
      // }}
    >
      <div className="grid grid-cols-2 gap-3">
        {education?.map((education, index) => {
          return (
            <div
              className={`
 shadow-md  divide-slate-300
               rounded-xl border border-slate-100 p-3 divide-y `}
              key={index}
            >
              <div className="flex flex-row justify-between pb-1 items-center">
                <h1 className="capitalize text-sm font-bold ">
                  {education.awardType}
                </h1>
                {education.isHighest && (
                  <div className="flex flex-row items-center space-x-1">
                    <p className="text-xs text-white px-4 py-1 bg-orange-400 rounded-xl ">
                      Highest
                    </p>
                  </div>
                )}
              </div>

              <div
                className={`grid grid-rows-4 space-y-2 p-2 grid-flow-col gap-1 text-sm`}
              >
                {Object.keys(education).map((key, index) => {
                  return (
                    <div className=" flex flex-col space-y-1.5">
                      {key === "isHighest" ? (
                        <div className="capitalize text-xs text-slate-500"></div>
                      ) : (
                        <>
                          <div className="capitalize text-xs text-slate-500">
                            <p>
                              {key
                                .replace(/([A-Z])/g, " $1")
                                .replace(/^./, function (str) {
                                  return str.toUpperCase();
                                })}
                            </p>
                          </div>
                          <div className=" font-semibold">
                            <p>{education[key]}</p>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        <Link
          to={`${url}/Add/Type=Education&Modal=true`}
          className="flex col-span-1 hover:bg-orange-50  rounded-xl w-full h-full items-center justify-center"
        >
          <div className="rounded-xl text-sm space-y-2 items-center text-orange-600 justify-center flex flex-col  p-3 ">
            <PlusCircleIcon className="h-10 w-10 " />
            <p>Add a new Qualification</p>
          </div>
        </Link>
      </div>
    </Widget>
  );
}
