import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: true,
  notifications: [],
};

export const NotificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    // Open or Close Notification
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    // Contacts
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
    },
    // removeNotification
    removeNotification: (state, action) => {
      let tempNotifications = [...state.notifications];
      tempNotifications.splice(
        tempNotifications.findIndex((i) => i.id === action.payload.id),
        1
      );
      state.notifications = tempNotifications;
    },
  },
});

export const { setOpen, addNotification, removeNotification } =
  NotificationsSlice.actions;

// Selectors
export const open = (state) => state.notifications.open;
export const notifications = (state) => state.notifications.notifications;

export default NotificationsSlice.reducer;
