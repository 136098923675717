export const columns = [
  {
    Header: "View",
    accessor: "component",
  },
  {
    Header: "Employee",
    accessor: "employee",
  },
  {
    Header: "Request",
    accessor: "request",
  },

  {
    Header: "Remarks",
    accessor: "remarks",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Applied On",
    accessor: "createdAt",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];
