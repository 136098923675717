import ModalLayout from "components/layout/ModalLayout";
import Modal from "components/modal/Modal";
import React from "react";
import * as Yup from "yup";
import FormGenerator from "components/form-generator/FormGenerator";
import { useSelector } from "react-redux";
import { selectEmployee } from "features/employees/EmployeesSlice";
import moment from "moment";

let employees = JSON.parse(localStorage.getItem("employees"));
let groups = JSON.parse(localStorage.getItem("groups"));
let variables = JSON.parse(localStorage.getItem("variables"));
let employmentTypes = variables?.filter((x) => x.key == "EmploymentType");
let departments = groups?.filter((x) => x.type == "Department");

export default function EditEmployeeDetails() {
  let [selectedValues, setSelectedValues] = React.useState({});
  let [designations, setDesignations] = React.useState([]);
  let selectedEmployee = useSelector(selectEmployee);
  let [selectedDepartment, setSelectedDepartment] = React.useState(null);

  // React.useEffect(() => {
  //   customAxios
  //     .get(`/department/designatios/${selectedDepartment.id}`)
  //     .then((res) => {
  //       setDesignations(res.data);
  //     });
  // }, [selectedDepartment]);

  const formData = {
    contents: [
      {
        heading: "General Employment Information",
        description: null,
        function: () => {
          return <div></div>;
        },
        fields: [
          {
            name: "employeeCode",
            displayName: "Employee Code",
            type: "field",
          },
          {
            name: "employmentType",
            displayName: "Employment Type",
            type: "comboBox",
            value: (formik) => {
              return formik.values.employmentType;
            },
            values: employmentTypes ?? [],
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },
          // {
          //   name: "division",
          //   displayName: "Division",
          //   type: "comboBox",
          //   value: (formik) => {
          //     return formik.values.bank;
          //   },
          //   values: [
          //     { id: 1, name: "Hulhumale'" },
          //     { id: 2, name: "Addu" },
          //     { id: 2, name: "Male'" },
          //     { id: 2, name: "Ihavdhoo" },
          //   ],
          //   options: function (item) {
          //     return (
          //       <div className="grid grid-cols-12">
          //         <span className="col-span-12">{item.name}</span>
          //       </div>
          //     );
          //   },
          // },
          {
            name: "Department",
            displayName: "Department",
            type: "comboBox",
            value: (formik) => {
              return formik.values.department;
            },
            values: departments,
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span
                    onClick={() => setSelectedDepartment(item)}
                    className="col-span-12"
                  >
                    {item.name}
                  </span>
                </div>
              );
            },
          },

          {
            name: "Designation",
            displayName: "Designation",
            type: "comboBox",
            value: (formik) => {
              return formik.values.designation;
            },
            values: designations,
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },
          {
            name: "leaveGroup",
            displayName: "Leave Group",
            type: "comboBox",
            value: (formik) => {
              return formik.values.bank;
            },
            values: groups?.filter((x) => x.type == "Leave"),
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },

          {
            name: "payGroup",
            displayName: "Pay Group",
            type: "comboBox",
            value: (formik) => {
              return formik.values.bank;
            },
            values: groups?.filter((x) => x.type == "Pay"),
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },
          {
            name: "scheduleGroup",
            displayName: "Schedule Group",
            type: "comboBox",
            value: (formik) => {
              return formik.values.bank;
            },
            values: groups?.filter((x) => x.type == "Schedule"),
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },

          {
            name: "reportsTo",
            displayName: "Reports To",
            type: "comboBox",
            inputType: "text",

            value: (formik) => {
              return formik.values.employee;
            },
            values: employees ?? [],
            options: function (item) {
              return (
                <div className="grid grid-cols-12 justify-center items-center gap-2">
                  <span className="col-span-1">{item.empCode}</span>
                  <span className="col-span-5">{item.name}</span>
                  <span className="col-span-6 ">
                    {item.department ?? "N/A"}
                  </span>
                </div>
              );
            },
          },
        ],
      },
    ],
    initialValues: {
      employmentType: selectedEmployee?.employeeType ?? "N/A",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      suffix: Yup.object().required("Suffix is required"),
      nationalIdentificationNumber: Yup.string().required(
        "National ID card number or passport number is required"
      ),
      dateOfBirth: Yup.date().required(true),
      empCode: Yup.string().required("Employee Code is required"),
    }),
    onSubmit: async (
      values,
      { setSubmitting, setErrors, setStatus, resetForm, handleSubmit }
    ) => {
      console.log(values);
    },
  };

  return (
    <Modal>
      {/* <code>{JSON.stringify(selectedEmployee)}</code> */}
      <ModalLayout heading={"Edit Employee Details"} width={"w-[33vw]"}>
        <FormGenerator
          height={""}
          buttonSubmit={{ title: "Submit" }}
          formData={formData}
        />
      </ModalLayout>
    </Modal>
  );
}
