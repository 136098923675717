import { setBreadCrumbs } from "features/navigation/NavigationSlice";
import customAxios from "middlewares/axios-interceptor/customAxios";
import React from "react";
import { useDispatch } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import EmployeeDocumentExpiry from "widgets/employee/employee-card/EmployeeDocumentExpiry";
import EmployeeDocuments from "widgets/employee/employee-card/EmployeeDocuments";

export default function ViewEmployeeDocuments() {
  let { Name, EmployeeId } = useParams();
  let [loading, setLoading] = React.useState(false);
  let [documents, setDocuments] = React.useState([]);

  let dispatch = useDispatch();

  React.useEffect(() => {
    customAxios.get(`/employee/profile/${EmployeeId}`).then(
      (res) => {
        setDocuments(
          res.documents.map((e) => {
            return {
              id: e.id,
              documentType: e.type,
              documentNumber: e.documentNumber,
              expiryDate: e.expiresOn,
              issueDate: e.issuedOn,
              issueCountry: e.issuedCountry,
              issuedBy: e.issuedBy,
              attachment: e.attachments[0],
            };
          })
        );
        setLoading(false);
      },
      (err) => console.log(err)
    );
  }, []);

  React.useEffect(() => {
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Employees", link: "/employees/all" },
        { name: `${Name}`, link: `` },
        { name: "Documents", link: `` },
      ])
    );
  }, []);

  return (
    <div className="grid grid-cols-4 overflow-auto h-[75vh] pb-10  gap-2 ">
      <div className="col-span-3">
        <EmployeeDocuments Data={documents} />
      </div>
      <div className="col-span-1">
        <EmployeeDocumentExpiry Data={documents} />
      </div>
      <Outlet />
    </div>
  );
}
