import GlobalLayout from "layout/GlobalLayout";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import SecondaryButton from "ui/buttons/SecondaryButton";
import { menuItems } from "./MenuItems";


export default function PayrollIndex() {
    let navigate = useNavigate()
  
  return (
    <GlobalLayout
      mainTitle={"Payroll"}
      heading={"Payroll"}
      selectedMenu={menuItems[1]}
      breadCrumbs={[{ title: "Payroll" }]}
      MenuItems={menuItems}
      buttons={[
        <SecondaryButton>
            <button onClick={() => navigate('/Payroll/GeneratePayroll/Modal=true')}>Generate Payroll</button>
        </SecondaryButton>
      ]}
    >
      <Outlet />
    </GlobalLayout>
  );
}
