import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import allEmployeesReducer from "../features/all-employees/AllEmployeeSlice";
import notificationReducer from "../features/notifications/NotificationSlice";
import LeavesReducer from "../features/leaves/LeavesSlice";
import GroupsReducer from "../features/groups/GroupsSlice";
import EmployeesReducer from "../features/employees/EmployeesSlice";
import AttendanceReducer from "../features/attendance/AttendanceSlice";
import NavigationReducer from "features/navigation/NavigationSlice";
import { tasksheetReducer } from "features/tasksheet/auth/tasksheet.reducer";

export const store = configureStore(
  {
    reducer: {
      employees: EmployeesReducer,
      allEmployees: allEmployeesReducer,
      notifications: notificationReducer,
      leaves: LeavesReducer,
      group: GroupsReducer,
      attendance: AttendanceReducer,
      navigation: NavigationReducer,
      taskSheet: tasksheetReducer
    },

    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  },
  applyMiddleware(thunk)
);
