import axios from "axios";

export const customAxios = axios.create({
  baseURL: "https://api.echeshr.com/api/v1"
  // baseURL:
  //   process.env.NODE_ENV === "production"
  //     ? "https://api.echeshr.com/api/v1"
  //     : "http://localhost/api/v1",
});

// Add a request interceptor

customAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["X-Tenant-Id"] = tenantId;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

customAxios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.assign("/auth/login");
    }

    if (error.response.status === 400) {
      return Promise.reject(error.response.data);
    }

    if (error.response.status === 445) {
      return Promise.reject(error.response.data);
    }

    Promise.reject(error);
    return error
  }
);

export default customAxios;
