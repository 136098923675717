import { Listbox, Transition } from "@headlessui/react";
import { useState, Fragment } from "react";
import { taskAxios } from "../taskAxios/taskAxios";
import { useEffect } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { getTenant } from "../HelperFunctions";
import { useSelector } from "react-redux";
import { selectTaskSheet } from "features/tasksheet/auth/tasksheet.selector";

export default function EmployeeDropDown({onEmployeeChange}) {
    const [employees, setEmployees] = useState([])
    const [selectedEmployee, setSelectedEmployee] = useState({name: "All Employees"})
    const taskSheetUserName = useSelector(selectTaskSheet).userId
    const taskSheetPassword = useSelector(selectTaskSheet).password
    const auth = {
        username: taskSheetUserName,
        password: taskSheetPassword
    }

    useEffect(() => {
        taskAxios.get("/employees", {auth: auth})
        .then((res) => {
            let data = res;
            data.unshift({
                name: "",
                isActive: true
            })
            console.log(data)
            setEmployees(
                data.filter((employee) => employee.isActive)
            )
        })
    }, [])

    return(
        <Listbox value={selectedEmployee} onChange={(employee) => {
            setSelectedEmployee(employee);
            onEmployeeChange(employee);
        }}>
            <Listbox.Button className="w-[150px] h-8 border-2 border-slate-400 rounded-lg flex justify-between items-center p-2 text-sm flex-row space-x-3 text-slate-800 bg-white font-semibold shadow-md hover:brightness-95">
                <div className="text-xs">
                    {selectedEmployee.name === "" ? "All Employees" : selectedEmployee.name}
                </div>
                <ChevronDownIcon 
                    className="ml-2 -mr-1 h-4 w-4"
                    aria-hidden="true"
                />
            </Listbox.Button>
            <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <Listbox.Options className="absolute rounded-md mt-1 z-50 w-56 h-56 overflow-auto  origin-bottom-right divide-y divide-gray-100  bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {
                        employees.map((employee) => 
                            {
                                if(employee.name === "")
                                {
                                    return (
                                    <Listbox.Option
                                        className="cursor-pointer p-2 hover:bg-orange-100 text-xs text-orange-400 font-bold"
                                        key={employee.id}
                                        value={employee}
                                    >
                                        All Employees
                                    </Listbox.Option>
                                    )
                                }
                                else
                                {
                                    return(
                                        <Listbox.Option
                                            className="cursor-pointer p-2 hover:bg-orange-100 text-xs"
                                            key={employee.id}
                                            value={employee}
                                        >
                                            {employee.name}
                                        </Listbox.Option>
                                    )
                                }
                            }
                        )
                    }
                </Listbox.Options>
            </Transition>
        </Listbox>
    )
}