import React from "react";
import ActionButtonsData from "./ActionButtonsData";
import Gender from "./charts/employee-distribution/Gender";
import Widget from "components/widget/Widget";
import { useDispatch } from "react-redux";
import {
  setBreadCrumbs,
  setSelectedSubMenu,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import { menuItems } from "../MenuItems";
import Department from "./charts/employee-distribution/Department";

import { useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Overview() {
  let actionButtons = ActionButtonsData;
  const [selectedActionButton, setSelectedActionButton] = React.useState(null);
  let [isOpen, setIsOpen] = React.useState(false);
  let [emplyeeCount, setEmployeeCount] = useState(0);

  let dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setSelectedSubMenu(menuItems[0]));
    dispatch(setSubHeader("Overview"));
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Employees", link: "/employees/overview" },
      ])
    );
  }, []);

  const handleEmployeeCount = (count) => {
    setEmployeeCount(count);
  };

  return (
    <div className=" grid grid-cols-3 gap-2 overflow-auto h-ful l">
      <div className="col-span-2 row-span-1">
        <Widget heading={"Employee Distribution by department"}>
          <Department handleEmployeeCount={handleEmployeeCount} />
        </Widget>
      </div>
      <Widget heading={"Employee Distribution by Gender"}>
        <Gender />
      </Widget>

      <div className="w-fit h-fit">
        <Widget heading={"Total Employee Count"}>
          <div className="text-center text-5xl">{emplyeeCount}</div>
        </Widget>
      </div>
    </div>
  );
}
