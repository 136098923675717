import { useDispatch } from "react-redux"
import { setSelectedSubMenu } from "features/navigation/NavigationSlice"
import MenuItems from "../MenuItems"
import Widget from "components/widget/Widget"
import { useEffect, useState } from "react"
import { getEmployees } from "middlewares/axios-interceptor/quickEndpoints"
import DataGrid, { CONSTANTS as GRIDCONSTANTS } from "components/data-grid/DataGrid"
import { cellFunction } from "./cellFunction"
import columns from "./columns"
import customAxios from "middlewares/axios-interceptor/customAxios"

import FormGeneratorV2 from "components/form-generator/FormGeneratorV2"
// import { Form } from "react-router-dom"





import { Formik, Form, useField, useFormikContext, Field } from "formik"
import { fi } from "date-fns/locale"
import { formControlClasses } from "@mui/material"

// const DesignationSettings = () => {
//     const [designations, setDesignations] = useState([])
//     const dispatch = useDispatch()
//     dispatch(setSelectedSubMenu(MenuItems[1]))

//     useEffect(() => {
//         customAxios
//             .get("/employment/designation")
//             .then(res => {
//                 setDesignations(res)
//             })
//     }, [])


//     const refreshGridData = (row) => {
//         customAxios.get("/employment/designation")
//             .then((res) => {
//                 setDesignations(res);
//             });
//     }

//     const onActionBegin = (args) => {
//         if (args.requestType === GRIDCONSTANTS.GRID_ACTION_SAVE) {
//             if (args.action === GRIDCONSTANTS.ACTION_ADD) {
//                 console.log("ADDY HERE", args.entity)
//                 const payload =
//                 {
//                     ...args.entity,
//                     departmentId: args.entity.departmentId.id,
//                 }

//                 console.log("SAVE>ADD", payload)
//                 customAxios
//                     .post(
//                         "/employment/designation/create",
//                         payload
//                         // {
//                         //     headers: {
//                         //         'Content-Type': 'multipart/form-data'
//                         //     }
//                         // }
//                     )
//                     .then(() => { refreshGridData(); });
//             }
//             else if (args.action === GRIDCONSTANTS.ACTION_UPDATE) {
//                 console.log(">>>>>>>>>>>>>>>>>>>>>EDIT::", args.entity)
//             }
//         }
//     }




//     return (
//         <div className=" w-full h-full overflow-auto">
//             <Widget className={""}>
//                 <DataGrid
//                     showHeader={true}
//                     columns={columns}
//                     selectable={false}
//                     cellFunction={cellFunction}
//                     testData={designations}
//                     allowAdd
//                     allowEdit
//                     onActionBegin={onActionBegin}
//                 />
//             </Widget>
//         </div>
//     )
// }


const DesignationSettings = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setSelectedSubMenu(MenuItems.find(x => x.title === "Designation Settings")))
    }, [])
    // const sections = [
    //     {
    //         header: "Create A Designation",
    //         subHeader: "Sub-Head",
    //         styling:
    //         {
    //             layout: "grid grid-cols-12 gap-2",
    //             self: {

    //             },
    //             field: {
    //                 container: "col-span-5",
    //                 cellSkip: 2,
    //                 isCellSkipAlternate: true,
    //                 // label: "pl-1 font-bold",
    //                 // input: "rounded-lg",
    //                 // error: "ml-1 mt-1 text-red-500 text-sm font-semibold"
    //             }
    //         },
    //         fields: [
    //             {
    //                 name: "firstName",
    //                 label: "S First Name",
    //                 initialValue: "Timmy",
    //                 validation: (yup) =>
    //                     yup.string()
    //                         .max(15, 'Must be 15 characters or less')
    //                         .required('Required'),

    //             },
    //             {
    //                 name: "lastName",
    //                 label: "S Last Name",
    //                 initialValue: "Tome",
    //                 validation: (yup) =>
    //                     yup.string()
    //                         .max(20, 'Must be 20 characters or less')
    //                         .required('Required'),

    //             },
    //             {
    //                 name: "email",
    //                 label: "Email",
    //                 initialValue: "example@mail.com",
    //                 validation: (yup) =>
    //                     yup.string().email('Invalid email address').required('Required'),
    //             },
    //             {
    //                 name: "departmentId",
    //                 label: "Department",
    //                 initialValue: 14,
    //                 validation: (yup) =>
    //                     yup.number().required('Required')
    //             },
    //             {
    //                 name: "designation",
    //                 initialValue: 0,
    //                 type: "dropdown",
    //                 fieldProps:
    //                 {
    //                     label: "Designation",
    //                     isSearchable: true,
    //                     optionValue: "id",
    //                     optionDisplay: (x) => <div className="w-full">{`${x.id} >> ${x.name}`}</div>,
    //                     // optionDisplay: (x) => `${x.id} >> ${x.name}`,
    //                     dataSource: () => [
    //                         { id: 1, name: "Devo" },
    //                         { id: 2, name: "Solo" },
    //                     ],
    //                     dependents: ["departmentId"],
    //                     onDependentsChange:
    //                         (name, values, setFieldValue, dataSource, setDataSource, isCurrent) => {
    //                             if (values["departmentId"] != null) {
    //                                 console.log(">>>>>>>>>>>>>>>>>>>>>>>>>TRACER")
    //                                 // fetchDataSource(setInternalDataSource)
    //                                 const route = `/department/${values["departmentId"]}/designations`
    //                                 console.log(route)
    //                                 customAxios
    //                                     .get(route)
    //                                     .then((res) => {
    //                                         if (isCurrent) {
    //                                             // prevent setting old values
    //                                             setDataSource(res);
    //                                             setFieldValue(name, null)
    //                                             console.log(res)
    //                                         }
    //                                     })
    //                             }
    //                         }

    //                 }
    //                 // validation: (yup) =>
    //                 //     yup.number().required('Required')

    //             },
    //             {
    //                 name: "startDate",
    //                 initialValue: new Date(),
    //                 type: "dateSelect",
    //                 fieldProps:
    //                 {
    //                     label: "Start Date"
    //                 },
    //                 validation: (yup) =>
    //                     yup.date().required('Required')

    //             },
    //             {
    //                 name: "endDate",
    //                 initialValue: null,
    //                 type: "dateSelect",
    //                 fieldProps:
    //                 {
    //                     label: "End Date",
    //                     dependents: ["startDate"],
    //                     onDependentsChange:
    //                         (name, values, setFieldValue, dataSource, setDataSource, isCurrent) => {
    //                             if (isCurrent) {
    //                                 // prevent setting old values
    //                                 if (values.startDate > values.endDate) {
    //                                     setFieldValue(name, values["startDate"])
    //                                 }
    //                             }
    //                         }
    //                 }
    //                 // validation: (yup) =>
    //                 //     yup.number().required('Required')

    //             }
    //         ]
    //     }
    // ]

    const fields = [
        {
            name: "firstName",
            label: "First Name",
            initialValue: "Timmy",
            validation: (yup) =>
                yup.string()
                    .max(15, 'Must be 15 characters or less')
                    .required('Required'),

        },
        {
            name: "test",
            fieldProps:
            {
                label: "Test Field",
                onBlur:
                    ({ fieldName, formValues, setFormFieldValue, formDataSources, setFormFieldDataSources }) => {

                        setTimeout(() => {
                            setFormFieldValue(fieldName, formValues["firstName"])
                            console.log("I've been BLURSED");
                        }, 3000)

                        setTimeout(() => {
                            setFormFieldDataSources("names", "DIGITAL BOOGALOO")
                            console.log("Almost there!!!!");
                        }, 1000)
                    }
            }
        },
        {
            name: "departmentId",
            initialValue: 14,
            fieldProps:
            {
                label: "Department Id",
                onBlur:
                    ({ fieldValueOnFocus, fieldName, formValues, setFormFieldValue, formDataSources, setFormFieldDataSources }) => {
                        // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>TRACER>>>>>>>>>>", formDataSources)
                        // fetchDataSource(setInternalDataSource)


                        if (fieldValueOnFocus !== formValues[fieldName]) {
                            const route = `/department/${formValues["departmentId"]}/designations`
                            console.log(route)
                            customAxios
                                .get(route)
                                .then((res) => {
                                    // prevent setting old values
                                    setFormFieldDataSources("designationId", res);
                                    setFormFieldValue("designationId", res[0].id)
                                    console.log(res)
                                })
                        }
                    }
            }
        },
        {
            name: "designationId",
            type: "autoComplete",
            fieldProps:
            {
                label: "Designations",
                onBlur:
                    ({ fieldName, formValues, setFormFieldValue, formDataSources, setFormFieldDataSources }) => {

                        // setTimeout(() => {
                        //     setFormFieldValue(fieldName, formValues["firstName"])
                        //     console.log("I've been BLURSED");
                        // }, 3000)

                        // setTimeout(() => {
                        //     setFormFieldDataSources("names", "DIGITAL BOOGALOO")
                        //     console.log("Almost there!!!!");
                        // }, 1000)
                    }
            }
        }
        // {
        //     name: "lastName",
        //     label: "Last Name",
        //     initialValue: "Tome",
        //     validation: (yup) =>
        //         yup.string()
        //             .max(20, 'Must be 20 characters or less')
        //             .required('Required'),
        //     fieldProps:
        //     {
        //         label: "Last Last Name",
        //         dependents: ["firstName"],
        //         onDependentsChange:
        //             (name, values, setFieldValue, dataSource, setDataSource, isCurrent) => {
        //                 console.log("TRACER<<<<<<<<<<<<<<<<<<<<<<<")
        //                 if (isCurrent) {
        //                     // prevent setting old values
        //                     if (true) {
        //                         setFieldValue(name, values["firstName"])
        //                     }
        //                 }
        //             }
        //     }

        // },
        // {
        //     name: "email",
        //     label: "Email",
        //     initialValue: "example@mail.com",
        //     validation: (yup) =>
        //         yup.string().email('Invalid email address').required('Required'),
        // },
        // {
        //     name: "departmentId",
        //     initialValue: 14,
        //     validation: (yup) =>
        //         yup.number().required('Required'),
        //     fieldProps: {
        //         label: "Department",
        //     }
        // },
        // {
        //     name: "designation",
        //     initialValue: 0,
        //     type: "autoComplete",
        //     fieldProps:
        //     {
        //         label: "Designation",
        //         isSearchable: true,
        //         optionValue: "id",
        //         optionDisplay: (x) => <div className="w-full">{`${x.id} >> ${x.name}`}</div>,
        //         // optionDisplay: (x) => `${x.id} >> ${x.name}`,
        //         dataSource: () => [
        //             { id: 1, name: "Devo" },
        //             { id: 2, name: "Solo" },
        //         ],
        //         dependents: ["departmentId"],
        //         onDependentsChange:
        //             (name, values, setFieldValue, dataSource, setDataSource, isCurrent) => {
        //                 if (values["departmentId"] != null) {
        //                     console.log(">>>>>>>>>>>>>>>>>>>>>>>>>TRACER>>>>>>>>>>", dataSource)
        //                     // fetchDataSource(setInternalDataSource)
        //                     const route = `/department/${values["departmentId"]}/designations`
        //                     console.log(route)
        //                     customAxios
        //                         .get(route)
        //                         .then((res) => {
        //                             if (isCurrent) {
        //                                 // prevent setting old values
        //                                 setDataSource(res);
        //                                 setFieldValue(name, null)
        //                                 console.log(res)
        //                             }
        //                         })
        //                 }
        //             }

        //     }
        //     // validation: (yup) =>
        //     //     yup.number().required('Required')

        // },
        // {
        //     name: "startDate",
        //     initialValue: new Date(),
        //     type: "dateSelect",
        //     fieldProps:
        //     {
        //         label: "Start Date"
        //     },
        //     validation: (yup) =>
        //         yup.date().required('Required')

        // },
        // {
        //     name: "endDate",
        //     initialValue: null,
        //     type: "dateSelect",
        //     fieldProps:
        //     {
        //         label: "End Date",
        //         dependents: ["startDate"],
        //         onDependentsChange:
        //             (name, values, setFieldValue, dataSource, setDataSource, isCurrent) => {
        //                 if (isCurrent) {
        //                     // prevent setting old values
        //                     if (values.startDate > values.endDate) {
        //                         setFieldValue(name, values["startDate"])
        //                     }
        //                 }
        //             }
        //     }
        //     // validation: (yup) =>
        //     //     yup.number().required('Required')

        // }
    ]



    const onSubmit = (values, { setSubmitting }) => {
        setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
        }, 400);
    }



    // const useDataSourcesManager = () => {
    //     let [data, setData] = useState({})
    //     return { data, setData };
    // }


    // async function fetchNewTextC(a, b) {
    //     await new Promise((r) => setTimeout(r, 500));
    //     return `textA: ${a}, textB: ${b}`;
    // }

    // const MyField = (props) => {
    //     const {
    //         values: { textA, textB },
    //         setFieldValue,
    //     } = useFormikContext();
    //     const [field, meta] = useField(props);
    //     console.log("MYFIELD")

    //     useEffect(() => {
    //         let isCurrent = true;
    //         // your business logic around when to fetch goes here.
    //         if (textA.trim() !== '' && textB.trim() !== '') {
    //             fetchNewTextC(textA, textB).then((textC) => {
    //                 console.log("FEEEEEEEEEETCH")
    //                 if (!!isCurrent) {
    //                     // prevent setting old values
    //                     setFieldValue(props.name, textC);
    //                 }
    //             });
    //         }
    //         return () => {
    //             isCurrent = false;
    //         };
    //     }, [textB, textA, setFieldValue, props.name]);

    //     return (
    //         <>
    //             <input {...props} {...field} />
    //             {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
    //         </>
    //     );
    // };



    // const TextField = (props) => {
    //     console.log("FIELD")
    //     return (
    //         <Field {...props} />
    //     )
    // }


    return (
        <div className="w-full">
            <FormGeneratorV2
                header="TEST FORM"
                subHeader="Sub Test Head"
                fields={fields}
                // sections={sections}
                styling=
                {
                    {
                        layout: "grid grid-cols-12 gap-2",
                        field: {
                            container: "col-span-12",
                            cellSkip: 0,
                            isCellSkipAlternate: true,
                            // label: "pl-1 font-bold",
                            // input: "rounded-lg",
                            // error: "ml-1 mt-1 text-red-500 text-sm font-semibold"
                        }
                    }
                }
                onSubmit={onSubmit}
            />
        </div>
    )



    // const Bapp = () => {
    //     const initialValues = { textA: '', textB: '', textC: '' };

    //     return (
    //         <div className="App">
    //             <Formik
    //                 initialValues={initialValues}
    //                 onSubmit={async (values) => alert(JSON.stringify(values, null, 2))}
    //             >
    //                 <div className="section">
    //                     <h1>Dependent Formik fields with Async Request</h1>
    //                     <p style={{ color: '#555' }}>
    //                         This is an example of a complex dependent field in Formik v2. In
    //                         this example, textC's value is set by making an async API request
    //                         based on the current values of fields textA and textB.
    //                     </p>
    //                     <div>
    //                         <small>
    //                             <em>
    //                                 Instructions: enter values for textA, and textB, and then watch
    //                                 textC
    //                             </em>
    //                         </small>
    //                     </div>
    //                     <Form>
    //                         <label>
    //                             textA
    //                             <TextField name="textA" />
    //                         </label>
    //                         <label>
    //                             textB
    //                             <TextField name="textB" />
    //                         </label>
    //                         <label>
    //                             textC
    //                             <MyField name="textC" />
    //                             <small>
    //                                 (the result of <code>fetchNewTextC(textA, textB))</code>
    //                             </small>
    //                         </label>
    //                         <button type="submit">Submit</button>
    //                     </Form>
    //                 </div>
    //             </Formik>
    //         </div>
    //     );
    // }


    // return (
    //     <Bapp />
    // )
}

export default DesignationSettings