import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

let init = JSON.parse(localStorage.getItem("init"));
let leaveTypes = JSON.parse(localStorage.getItem("leaveTypes"));

const initialState = {
  leaveTypes: leaveTypes,
  currentLeave: {
    id: 0,
    employee: null,
    leaveType: null,
    startDate: null,
    endDate: null,
    workDays: null,
    remarks: null,
    attachments: null,
  },
  isBackDate: false,
  workingDays: null,
};

export const LeavesSlice = createSlice({
  name: "leaves",
  initialState,
  reducers: {
    setWorkingDays: (state, action) => {
      state.workingDays = "Calculating...";
      let dayString = "day";
      if (action.payload > 1) {
        dayString = "days";
      }
      state.workingDays = action.payload + " " + dayString;
    },
    setIsBackDate: (state, action) => {
      state.isBackDate = action.payload;
    },
    setCurrentLeave: (state, action) => {
      state.currentLeave = action.payload;
    },
    setStartDate: (state, action) => {
      state.currentLeave.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.currentLeave.endDate = action.payload;
    },
    // Contacts
    addLeaveType: (state, action) => {
      state.changed = true;
      state.leaveTypes.push({
        id: null,
        type: 5,
        name: action.payload,
      });
    },
    // remove Leave
    removeLeaveType: (state, action) => {
      state.changed = true;
      let tempLeaveTypes = [...state.leaveTypes];
      tempLeaveTypes.splice(
        tempLeaveTypes.findIndex((i) => i.id === action.payload.id),
        1
      );
      state.leaveTypes = tempLeaveTypes;
    },
    editLeaveType: (state, action) => {
      state.changed = !state.changed;
      let tempLeaveTypes = [...state.leaveTypes];
      let objIndex = tempLeaveTypes.findIndex(
        (obj) => obj.id == action.payload.id
      );
      tempLeaveTypes[objIndex].name = action.payload.name;
    },
    // Reset
    resetLeaves: (state, action) => {
      state.changed = true;
      state.leaveTypes = leaveTypes;
    },
  },
});

export const {
  addLeaveType,
  editLeaveType,
  removeLeaveType,
  resetLeaves,
  setCurrentLeave,
  setStartDate,
  setEndDate,
  setWorkingDays,
  setIsBackDate,
} = LeavesSlice.actions;

// Selectors
export const LeaveTypes = (state) => state.leaves.leaveTypes;
export const selectCurrentLeave = (state) => state.leaves.currentLeave;
export const test = (state) => state.leaves.test;
export const endDate = (state) => state.leaves.currentLeave.endDate;
export const startDate = (state) => state.leaves.currentLeave.startDate;
export const selectWorkingDays = (state) => state.leaves.workingDays;
export const selectBackDate = (state) => state.leaves.isBackDate;

export default LeavesSlice.reducer;
