import { setBreadCrumbs } from "features/navigation/NavigationSlice";
import customAxios from "middlewares/axios-interceptor/customAxios";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import EmployeeLeaveApprovalGroups from "widgets/employee/employee-card/EmployeeLeaveApprovalGroups";
import EmployeeLeaveBalance from "widgets/employee/employee-card/EmployeeLeaveBalance";
import EmployeeLeaveByType from "widgets/employee/employee-card/EmployeeLeaveByType";
import EmployeeLeaveChart from "widgets/employee/employee-card/EmployeeLeaveChart";
import EmployeeLeaveList from "widgets/employee/employee-card/EmployeeLeaveList";
import EmployeeLeaveStatuses from "widgets/employee/employee-card/EmployeeLeaveStatuses";

export default function ViewEmployeeLeaves() {
  let { Name, EmployeeId } = useParams();
  let [leaveBalance, setLeaveBalance] = React.useState([]);
  let [leaves, setLeaves] = React.useState([]);
  let [loading, setLoading] = React.useState(true);
  let dispatch = useDispatch();

  React.useEffect(() => {
    customAxios.get(`/employee/profile/${EmployeeId}`).then(
      (res) => {
        setLeaveBalance(
          res?.leaveBalance?.map((item) => {
            return {
              leaveType: item?.leaveType ?? "N/A",
              max: item?.max ?? "N/A",
              days: item?.balance ?? "N/A",
              percentage: (item?.balance / item?.max) * 100 ?? "N/A",
            };
          })
        );
        setLoading(false);
      },
      (err) => console.log(err)
    );

    customAxios.get(`/leave?id=${EmployeeId}`).then(
      (res) => setLeaves(res.items),
      (err) => console.log(err)
    );
  }, []);

  React.useEffect(() => {
    customAxios.get(`/api/employee/leaves`).then((res) => {
      console.log(res);
    });
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Employees", link: "/employees/all" },
        { name: `${Name}`, link: `` },
        { name: "Leaves", link: `` },
      ])
    );
  }, []);

  return (
    <div className="grid grid-cols-4 overflow-auto h-[75vh] pb-10  gap-2 ">
      <EmployeeLeaveBalance leaveBalanceData={leaveBalance} />
      <EmployeeLeaveByType data={leaves} />
      <EmployeeLeaveStatuses data={leaves} />
      {/* <EmployeeLeaveApprovalGroups /> */}

      <div className="col-span-3">
        <EmployeeLeaveList data={leaves} />
      </div>
    </div>
  );
}
