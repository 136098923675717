import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setBreadCrumbs } from "features/navigation/NavigationSlice";
import Widget from "components/widget/Widget";
import customAxios from "middlewares/axios-interceptor/customAxios";

export default function Tasks() {
  let { Type } = useParams();
  let dispatch = useDispatch();

  // let auth = {
  //   username: process.env.REACT_APP_TS_USERNAME,
  //   password: process.env.REACT_APP_TS_PASSWORD,
  // };

  const USERNAME = "9494";
  const PASSWORD = "236FE197-7CC5-41F2-A01C-9EB36D8B7D38";

  const auth = {
    username: USERNAME,
    password: PASSWORD,
  };

  React.useEffect(() => {
    customAxios
      .get("https://task.echesconsultancy.com:8088/tasks/1", {
        auth: auth,
      })
      .then(
        (res) => console.log(res),
        (err) => console.log(err)
      );
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Task Sheet", link: "/TaskSheet/Tasks" },
        { name: Type, link: `` },
      ])
    );
  }, [Type]);

  return (
    <div className="grid grid-cols-4 gap-2">
      {Type === "All" ? (
        <div className="col-span-4 grid grid-cols-4 gap-2">
          <Widget heading={"All"} />
          <Widget heading={"Pending"} />
          <Widget heading={"Active"} />
          <Widget heading={"Completed"} />
        </div>
      ) : (
        ""
      )}
      <div className="col-span-4">
        <Widget heading={`${Type + " Tasks"}`} />
      </div>
    </div>
  );
}
