import React from "react";

export default function Widget({
  children,
  heading,
  headerRenderProp,
  subHeading,
  component,
  secondary,
}) {

  const widgetProperties = {
    headerStyling: `${secondary ? "text-sm text-slate-600" : "text-base"} text-slate-800 font-bold`,
    subHeaderStyling: "text-sm text-gray-400"
  }

  return (
    <div className="flex flex-col w-full h-full p-2 bg-white rounded-xl shadow">
      <div
        className={`flex flex-row justify-between items-center p-2 mb-2 ${(heading || subHeading || headerRenderProp) ? "border-b border-b-slate-200" : ""}`}
      >
        <div className="flex flex-col w-full space-y-2 justify-start items-start">
          {
            typeof headerRenderProp === "function"
              ?
              headerRenderProp(widgetProperties)
              :
              <>
                {
                  heading && (
                    <div>
                      <h1
                        className={widgetProperties.headerStyling}
                      >
                        {heading}
                      </h1>
                    </div>
                  )
                }
                {
                  subHeading && (
                    <div>
                      <p className={widgetProperties.subHeaderStyling}>{subHeading}</p>
                    </div>
                  )
                }
              </>
          }
        </div>
        {component && <div>{component() ?? ""}</div>}
      </div>

      <div className="flex-grow min-h-0 overflow-hidden">{children}</div>
    </div>
  );
}
