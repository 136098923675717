import React from "react";
import FormGenerator from "components/form-generator/FormGenerator";
import customAxios from "middlewares/axios-interceptor/customAxios";
import * as Yup from "yup";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";

let variables = JSON.parse(localStorage.getItem("variables"));

export default function AddExperience() {
  let { EmployeeId } = useParams();
  let navigate = useNavigate();

  const FormData = {
    contents: [
      {
        heading: "Add a new Work Experience",
        description: null,
        function: () => {
          return <div></div>;
        },
        fields: [
          {
            name: "title",
            displayName: "Job Title",
            type: "field",
            required: true,
          },
          {
            name: "employmentType",
            displayName: "Employment Type",
            type: "comboBox",
            required: true,
            values: [
              { id: 1, name: "Full Time" },
              { id: 2, name: "Part Time" },
              { id: 3, name: "Contract" },
              { id: 4, name: "Internship" },
              { id: 5, name: "Volunteer" },
            ],
            value: (formik) => {
              return formik.values.employmentType;
            },
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },
          {
            name: "companyName",
            displayName: "Company Name",
            type: "field",
            required: true,
          },

          {
            name: "location",
            displayName: "Location",
            type: "field",
            required: true,
          },
          {
            name: "startDate",
            displayName: "Start Date",
            type: "dateSelect",
            required: true,
            value: (formik) => {
              return formik.values.startDate;
            },
          },
          {
            name: "endDate",
            displayName: "End Date",
            type: "dateSelect",
            required: true,
            value: (formik) => {
              return formik.values.endDate;
            },
          },
          {
            name: "description",
            displayName: "Description",
            type: "textArea",
            required: true,
          },
        ],
      },
    ],
    initialValues: {
      employmentType: "",
    },
    validationSchema: Yup.object().shape({
      employmentType: Yup.object().required("Employment Type is required"),
    }),
    onSubmit: async (
      values,
      { setSubmitting, setErrors, setStatus, resetForm, handleSubmit }
    ) => {
      console.log(values);

      const data = [
        {
          title: values.title,
          employmentType: values.employmentType.name,
          companyName: values.companyName,
          location: values.location,
          startDate: moment(values.startDate).format("YYYY-MM-DD"),
          endDate: moment(values.endDate).format("YYYY-MM-DD"),
          description: values.description,
        },
      ];

      customAxios
        .post(
          `https://api.echeshr.com/api/v2/employee/${EmployeeId}/experience`,
          data
        )
        .then((response) => {
          navigate(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  };

  return <FormGenerator formData={FormData} />;
}
