import Widget from "components/widget/Widget";
import React from "react";

let repayments = [
  {
    loanType: "Home Loan",
    paidAmount: "10000",
    paidDate: "2021-01-01",
    transactionType: "Salary Deduction",
  },
  {
    loanType: "Home Loan",
    paidAmount: "10000",
    paidDate: "2021-02-01",
    transactionType: "Salary Deduction",
  },
];
export default function EmployeeLoanRepaymentHistory() {
  return (
    <Widget
      heading="Loan Repayment History"
      component={function () {
        return <div className="text-xs text-slate-600">View All</div>;
      }}
    >
      <div className="overflow-auto flex flex-col space-y-2 h-[30vh]"></div>
    </Widget>
  );
}
