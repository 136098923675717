import FormCreator from "components/form-creator/FormCreator";
import {
  setBreadCrumbs,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import FormGenerator from "components/form-generator/FormGenerator";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  selectCurrentLeave,
  selectWorkingDays,
  setEndDate,
  setIsBackDate,
  setStartDate,
  setWorkingDays,
} from "features/leaves/LeavesSlice";
import moment from "moment";
import { isAfter, isBefore, isSameDay } from "date-fns";
import customAxios from "middlewares/axios-interceptor/customAxios";
import { addNotification } from "features/notifications/NotificationSlice";
import * as Yup from "yup";

let leaveTypes = JSON.parse(localStorage.getItem("leaveTypes"));

export default function AddLeave() {
  let { LeaveType } = useParams();
  let startDate = useSelector(selectCurrentLeave)?.startDate;
  let endDate = useSelector(selectCurrentLeave)?.endDate;
  let [isError, setIsError] = React.useState(false);
  let [errorMsg, setErrorMsg] = React.useState("");
  let [loading, setLoading] = React.useState(false);
  let [leaveBalance, setLeaveBalance] = React.useState([]);
  let workDays = useSelector(selectWorkingDays);
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let [selectedEmployee, setSelectedEmployee] = React.useState("Hello");

  let selectedLeaveType = leaveTypes?.find((l) => l.name === LeaveType);

  React.useEffect(() => {
    customAxios.get(`/employee/profile/${selectedEmployee.id}`).then(
      (res) => {
        setLeaveBalance(
          res?.leaveBalance?.map((item) => {
            return {
              leaveType: item?.leaveType ?? "N/A",
              max: item?.max ?? "N/A",
              days: item?.balance ?? "N/A",
              percentage: (item?.balance / item?.max) * 100 ?? "N/A",
            };
          })
        );
        setLoading(false);
      },
      (err) => console.log(err)
    );

    // customAxios.get(`/leave?id=${EmployeeId}`).then(
    //   (res) => setLeaves(res.items),
    //   (err) => console.log(err)
    // );
  }, [selectedEmployee]);

  React.useEffect(() => {
    dispatch(
      setSubHeader(`Add ${LeaveType.replace(/[A-Z]/g, " $&").trim()} leave`)
    );
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Leaves", link: "/leaves/all" },
        { name: "Add Leave", link: "" },
      ])
    );
  }, []);

  const formData = {
    contents: [
      {
        heading: "Leave Information",
        description:
          "You are required to fill the following information to add a new Employee. Rest of the steps maybe avoided if necessary.",
        function: () => {
          return <div></div>;
        },
        fields: [
          {
            name: "employee",
            displayName: "Employee",
            type: "comboBox",
            inputType: "text",
            required: true,
            value: (formik) => {
              setSelectedEmployee(formik.values.employee);
              return formik.values.employee;
            },
            values: JSON.parse(localStorage.getItem("employees")),
            options: function (item) {
              return (
                <div className="grid grid-cols-12 justify-center items-center gap-2">
                  <span className="col-span-1">{item.id}</span>
                  <span className="col-span-5">{item.name}</span>
                  <span className="col-span-6 ">
                    {item.designation ?? "N/A"}
                  </span>
                </div>
              );
            },
          },
          {
            name: "dateStart",
            displayName: "Starts on",
            type: "dateSelect",
            default: new Date(),
            inputType: "text",
            value: (formik) => {
              dispatch(setStartDate(formik.values.dateStart));
              return formik.values.dateStart;
            },
            required: true,
          },
          {
            name: "dateEnd",
            displayName: "Ends On",
            type: "dateSelect",
            default: new Date(),
            inputType: "text",
            value: (formik) => {
              dispatch(setEndDate(formik.values.dateEnd));
              return formik.values.dateEnd;
            },
            required: true,
          },
          {
            name: "workDays",
            displayName: "Number of working days",
            type: "component",
            component: function () {
              dispatch(setIsBackDate(false));
              if (isBefore(startDate, endDate)) {
                customAxios
                  .get(
                    `/calendar/working-days-count?From=${moment(
                      startDate
                    ).format("YYYY-MM-DD")}&To=${moment(endDate).format(
                      "YYYY-MM-DD"
                    )}`
                  )
                  .then((response) => {
                    dispatch(setWorkingDays(response));
                  });
                return (
                  <div className="flex justify-center items-center">
                    <h1 className="text-orange-800">{workDays ?? 1}</h1>
                  </div>
                );
              } else if (isSameDay(startDate, endDate)) {
                customAxios
                  .get(
                    `/calendar/working-days-count?${moment(startDate).format(
                      "YYYY-MM-DD"
                    )}/${moment(endDate).format("YYYY-MM-DD")}`
                  )
                  .then((response) => {
                    dispatch(setWorkingDays(response));
                  });
                return (
                  <div className="flex justify-center items-center">
                    <h1 className="text-orange-800">{workDays ?? 1}</h1>
                  </div>
                );
              } else {
                dispatch(setIsBackDate(true));
                return (
                  <div className="flex justify-center items-center">
                    <h1 className="text-red-700">
                      You cannot select a back date
                    </h1>
                  </div>
                );
              }
            },
            required: false,
          },
          {
            name: "remarks",
            displayName: "Remarks",
            type: "textArea",
            inputType: "text",
            required: true,
          },
          {
            name: "Attachments",
            displayName: "Attachments",
            type: "fileUpload",
            inputType: "text",
            required: false,
          },
        ],
      },
    ],
    initialValues: {
      employee: "",
      leaveType: "",
      dateStart: new Date(),
      dateEnd: new Date(),
      remarks: "",
      // empDutyCycle: basics?.data?.empDutyCycle
      //   ? basics?.data?.empDutyCycle
      //   : "",
    },
    validationSchema: Yup.object().shape({
      remarks: Yup.string().required(
        "Please Enter some remarks for clarifications"
      ),
    }),
    onSubmit: async (
      values,
      { setSubmitting, setErrors, setStatus, resetForm, handleSubmit }
    ) => {
      var form_data = new FormData();
      const data = {
        LeaveTypeId: selectedLeaveType?.id,
        StartDate: moment(values.dateStart).format("YYYY-MM-DD"),
        EndDate: moment(values.dateEnd).format("YYYY-MM-DD"),
        Remarks: values.remarks,
      };
      for (var key in data) {
        form_data.append(key, data[key]);
      }

      customAxios.post("/request/leave", form_data).then(
        (res) => {
          dispatch(
            addNotification({
              id: 1,
              heading: `The Leave was added successfully`,
            })
          );

          navigate(`/leaves/view/id=${res.id}`);
        },
        (err) => {
          setIsError(true);
          setErrorMsg(err);
          console.log(err);
        }
      );
    },
  };

  return (
    <div>
      {selectedEmployee ? (
        leaveBalance?.map((item, index) => {
          if (item?.max !== "N/A") {
            return (
              <div
                key={index}
                className="flex flex-row justify-start items-start space-y-1 bg-white rounded-xl p-2 px-4 shadow-md mb-2"
              >
                <div className="flex flex-row text-sm font-bold w-full justify-between items-center">
                  <h1 className="text-sm font-medium">{item?.leaveType}</h1>
                  <p>
                    {item?.days}/{item?.max}
                  </p>
                </div>

                <div className="flex relative w-full ml-2">
                  <div className={`h-4 w-full rounded-md  bg-slate-200`} />
                  <div
                    style={{ width: `${item?.percentage}%` }}
                    className={`absolute top-0 left-0 h-4 rounded-md  bg-orange-200`}
                  />
                </div>
              </div>
            );
          }
        })
      ) : (
        <div className="flex justify-start items-center p-2 text-orange-600 bg-orange-50 rounded-xl mb-2 text-sm px-5">
          Select an employee to view their leave balances
        </div>
      )}
      <FormGenerator
        ErrorMsg={errorMsg}
        isError={isError}
        height={""}
        buttonSubmit={{ title: "Submit" }}
        formData={formData}
      />
    </div>
  );
}
