import React from "react";
import PieChart from "components/charts/pie-chart/PieChart";
import { useState, useEffect } from "react";
import customAxios from "middlewares/axios-interceptor/customAxios";

const stats = [
  {
    statusName: "Male",
    color: "#34d399",
    count: 19,
  },
  {
    statusName: "Female",
    color: "#fbbf24",
    count: 6,
  },
];

export default function Gender() {
  const [employeeDataItems, setEmployeeDataItems] = useState([])

  useEffect(() => {
      customAxios.get("/employee")
      .then((res) => {
          setEmployeeDataItems(res.items ?? [])
      })
  }, [])

  const activeEmployeeDataItems = employeeDataItems.filter((item) => item.status === "Active") ?? []

  const genderCounts = {}

  for(const object of activeEmployeeDataItems)
  {
      let gender = object.gender
      if(genderCounts.hasOwnProperty(gender))
      {
        genderCounts[gender]++;
      }
      else
      {
        genderCounts[gender] = 1;
      }
  }

  const data = {
    labels: Object.keys(genderCounts),
    datasets: [
      {
        data:  Object.values(genderCounts),
      },
    ],
  };

  return (
    <PieChart datasets={data.datasets} labels={data.labels}/>
  );
}
