import { useState, useEffect } from "react"
import DataGrid, { CONSTANTS as GridConstants } from "components/data-grid/DataGrid"
import customAxios from "middlewares/axios-interceptor/customAxios"


const LeaveTypeSettings = () => {
    let [isLoading, setIsLoading] = useState(true);
    let [data, setData] = useState([]);

    const refreshGridData = (row) => {
        setIsLoading(true)
        customAxios.get("/leave/types").then((res) => {
            setData(res);
            setIsLoading(false)
        });
    }

    useEffect(() => { refreshGridData() }, [])

    const columns = [
        {
            accessor: "id",
            Header: "Id",
            hideOnAdd: true,
            hideOnEdit: true,
            formFieldProps:
            {
                // validation: (yup) => yup.string().required("Date is required"),
                // type: "dateSelect",
                // initialValue: () => dayjs().format()
            }
        },
        {
            accessor: "name",
            Header: "Name",
            formFieldProps:
            {
                validation: (yup) => yup.string().required("Name is required"),
            }
        },
        {
            accessor: "formId",
            Header: "Form Id",
            formFieldProps:
            {
                // validation: (yup) => yup.string().required("Name is required"),
            }
        },
        // {
        //     accessor: "workStatus",
        //     Header: "WorkStatus",
        //     hideOnAdd: true,
        //     hideOnEdit: true,
        //     formFieldProps:
        //     {
        //         // validation: (yup) => yup.string().required("Name is required"),
        //     }
        // }
        // {
        //     accessor: "employeeId",
        //     Header: "Employee ID",
        //     formFieldProps:
        //     {
        //         displayName: "Employee",
        //         type: "autoComplete",
        //         initialDataSource: async () => { const res = await customAxios.get("/employee"); return res.items; },
        //         fieldProps: {
        //             optionValue: "id",
        //             fieldDisplay: (x) => `${x.name}`,
        //             optionDisplay: (x) =>
        //                 <div className="grid grid-cols-12 justify-center items-center gap-2">
        //                     <span className="col-span-1">{x.id}</span>
        //                     <span className="col-span-5">{x.name}</span>
        //                     <span className="col-span-6 ">
        //                         {x.designation ?? "N/A"}
        //                     </span>
        //                 </div>,
        //             onBlur:
        //                 ({ fieldValueOnFocus, fieldName, formValues, setFormFieldValue, formDataSources, setFormFieldDataSources }) => {
        //                     // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>TRACER>>>>>>>>>>", formDataSources)
        //                     // fetchDataSource(setInternalDataSource)
        //                     if (fieldValueOnFocus !== formValues[fieldName]) {
        //                         const route = `/employee/shifts/${formValues["employeeId"]}`
        //                         console.log(route)
        //                         customAxios
        //                             .get(route)
        //                             .then((res) => {
        //                                 setFormFieldValue("start", res[0].startOn)
        //                                 setFormFieldValue("end", res[0].endOn)
        //                                 setFormFieldValue("checkIn", res[0].startOn)
        //                                 setFormFieldValue("checkOut", res[0].endOn)
        //                                 console.log(res)
        //                             })
        //                     }
        //                 }
        //             // <div className="w-full">{`${x.id} >> ${x.name}`}</div>,
        //         }
        //     }
        // },
        // {
        //     accessor: "start",
        //     Header: "Start Time",
        //     formFieldProps:
        //     {
        //         // initialValue: "09:00"
        //     }
        // }
    ];



    const onActionBegin = (args) => {

        if (args.requestType === GridConstants.GRID_ACTION_SAVE) {
            if (args.action === GridConstants.ACTION_ADD) {
                const payload =
                {
                    ...args.entity,
                }
                console.log("SAVE >> ADD", payload)
                customAxios
                    .post("/leave/types/create", payload)
                    .then(() => { refreshGridData(); });

            }

            else if (args.action === GridConstants.ACTION_UPDATE) {

                const { formId, ...payload } = args.entity;
                if (formId) {
                    payload.formId = formId
                }
                console.log("SAVE >> UPDATE", payload)

                customAxios
                    .put("/leave/types/update", payload)
                    .then(() => { refreshGridData(); });
            }

            else if (args.action === GridConstants.ACTION_DELETE) {
                console.log("SAVE >> DELETE", args.entity)
                const entityId = args.entity.id;
                customAxios
                    .delete(`/leave/types/${entityId}/delete`)
                    .then(() => { refreshGridData() })
            }

        }
    }



    return (
        <DataGrid
            header="Leave Types"
            showHeader={true}
            cellFunction={cell => cell.render("Cell")}
            columns={columns}
            testData={data}
            setData={setData}
            allowAdd={true}
            allowEdit={true}
            allowEditRowOnDoubleClick={true}
            allowDelete={true}
            confirmOnDelete={true}
            onActionBegin={onActionBegin}
        />
    )
}

export default LeaveTypeSettings