import { PencilIcon, PlusIcon } from "@heroicons/react/solid";
import BreadCrumbs from "components/navigation/bread-crumbs/BreadCrumbs";
import {
  selectNavigationButtons,
  selectSelectedSubmenu,
  selectSubHeader,
} from "features/navigation/NavigationSlice";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";
import SecondaryButton from "ui/buttons/SecondaryButton";

export default function HeadingCard({ Buttons, BreadCrumbsData }) {
  let { Modal } = useParams();
  let selectedSubMenu = useSelector(selectSelectedSubmenu);
  let Heading = useSelector(selectSubHeader);
  let NavButtons = useSelector(selectNavigationButtons);
  return (
    <div className="col-span-12 flex flex-row px-1 justify-between items-start">
      <div className="flex flex-col space-y-2 justify-between items-start">
        <h1 className="text-2xl font-semibold">
          {Heading ?? selectedSubMenu?.title}
        </h1>
        <BreadCrumbs BreadCrumbs={BreadCrumbsData} />
      </div>
      {!Modal && (
        <div className=" flex justify-end space-x-3 ">
          {Buttons?.map((b, i) => {
            return b;
          })}
          {NavButtons?.map((b, i) => {
            return b;
          })}
        </div>
      )}
    </div>
  );
}
