export const columns = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Employee Code",
    accessor: "empCode",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Approval Type",
    accessor: "approvalType",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Applied On",
    accessor: "createdAt",
  },
];
