import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedMenu: null,
  selectedSubMenu: null,
  refreshTrigger: null,
  selectedTab: null,
  breadCrumbs: [],
  navigationButtons: [],
  subHeader: null,
};

// create slice
const menuSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setSelectedMenu: (state, action) => {
      state.selectedMenu = action.payload;
    },
    setSelectedSubMenu: (state, action) => {
      state.selectedSubMenu = action.payload;
    },
    setSubHeader: (state, action) => {
      state.subHeader = action.payload;
    },
    setBreadCrumbs: (state, action) => {
      state.breadCrumbs = action.payload;
    },
    setNavigationButtons: (state, action) => {
      state.navigationButtons = action.payload;
    },
    setRefreshTrigger: (state, action) => {
      state.refreshTrigger = Math.random();
    },
  },
});

// export actions
export const {
  setSelectedMenu,
  setSelectedSubMenu,
  setSubHeader,
  setBreadCrumbs,
  setNavigationButtons,
  setRefreshTrigger,
} = menuSlice.actions;

// export reducer
export default menuSlice.reducer;

// export selector
export const selectSelectedMenu = (state) => state.navigation.selectedMenu;
export const selectSelectedSubMenu = (state) => state.navigation.selectedSubMenu;
export const menuItems = (state) => state.navigation.menuItems;
export const selectBreadCrumbs = (state) => state.navigation.breadCrumbs;
export const selectSubHeader = (state) => state.navigation.subHeader;
export const selectRefreshTrigger = (state) => state.navigation.refreshTrigger;
export const selectSelectedSubmenu = (state) =>
  state.navigation.selectedSubMenu;
export const selectNavigationButtons = (state) =>
  state.navigation.navigationButtons;
