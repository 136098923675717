import React from "react";
import ModalLayout from "../../../../components/layout/ModalLayout";
import PrimaryButton from "../../../../ui/buttons/PrimaryButton";
import SecondaryButton from "../../../../ui/buttons/SecondaryButton";
import * as Yup from "yup";
import FormGenerator from "../../../../components/form-generator/FormGenerator";
import customAxios from "../../../../middlewares/axios-interceptor/customAxios";
import { data } from "autoprefixer";

function Confirmation({ type, data, setIsOpen }) {
  const formData = {
    contents: [
      {
        heading:
          type == "Approved"
            ? "Are you sure you want to approve the following request?"
            : "Are you sure you want to reject the following request?",
        description:
          "You are required to fill the following information to add a new Employee. Rest of the steps maybe avoided if necessary.",
        function: () => {
          return <div></div>;
        },
        fields: [
          {
            name: "remarks",
            displayName: "Remarks",
            type: "textArea",
            inputType: "text",
            required: true,
          },
        ],
      },
    ],
    initialValues: {
      remarks: "",
    },
    validationSchema: Yup.object().shape({
      remarks: Yup.string().required(
        "Please Enter some remarks for clarifications"
      ),
    }),
    onSubmit: async (
      values,
      { setSubmitting, setErrors, setStatus, resetForm, handleSubmit }
    ) => {
      console.log(data);
      const payload = {
        requestId: data.request.id,
        status: type,
        remarks: values.remarks,
      };
      console.log(values);
      customAxios.put("/request/updatestatus", payload).then((res) => {
        const employee = res.request?.employee;
        const request = res.request;
        const status = res.status;
        const document = res.request?.document;

        const message = `${employee?.commonName ?? employee?.firstName}'s ${
          document.name
        } has been ${status}`;

        alert(message);
        setIsOpen(false);
      });
    },
  };
  return (
    <ModalLayout
      width={"w-[35vw]"}
      onClose={"/approval/leaves"}
      setIsOpen={setIsOpen}
      component={<div>Please add remarks for further clarification</div>}
    >
      <div className="flex flex-col">
        <FormGenerator formData={formData} />
      </div>
    </ModalLayout>
  );
}

export default Confirmation;
