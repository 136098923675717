import React from "react";
import { useParams } from "react-router-dom";
import ViewAttendanceDate from "./view-date/ViewAttendanceDate";

export default function ViewAttendance() {
  let [date, setDate] = React.useState(useParams().date);
  let { EmployeeId } = useParams();

  React.useEffect(() => {
    
  }, [date]);

  return (
    <div>
      {EmployeeId == null && date != null ? (
        <div>ViewAttendance for EmployeeId: {EmployeeId}</div>
      ) : (
        <ViewAttendanceDate />
      )}
    </div>
  );
}
