import { Tab } from "@headlessui/react";
import ProgressBar from "components/progress-bar/ProgressBar";
import StepModal from "components/step-modal/StepModal";
import {
  setBreadCrumbs,
  setNavigationButtons,
  setSelectedSubMenu,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Addresses from "./components/Addresses";
import Documents from "./components/Documents";
import Education from "./components/Education";

import EmploymentDetails from "./components/EmploymentDetails";
import Experience from "./components/Experience";
import PersonalInfo from "./components/PersonalInfo";
import { ReviewAndCreate } from "./components/ReviewAndCreate";
import { menuItems } from "./../MenuItems";


let tabData = [
  {
    index: 1,
    name: "Personal Info",
    component: <PersonalInfo />,
  },
  {
    index: 2,
    name: "Employment Details",
    component: <EmploymentDetails />,
  },
  {
    index: 3,
    name: "Address",
    component: <Addresses />,
  },

  {
    index: 5,
    name: "Documents",
    component: <Documents />,
  },
  {
    index: 6,
    name: "Education",
    component: <Education />,
  },
  {
    index: 7,
    name: "Experience",
    component: <Experience />,
  },
  {
    index: 8,
    name: "Review",
    component: <ReviewAndCreate />,
  },
];

export default function AddEmployee() {
  let [selectedTab, setSelectedTab] = React.useState(tabData[0]);
  let [progress, setProgress] = React.useState(0);
  let { step } = useParams();
  let dispatch = useDispatch();

  React.useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);
    switch (step) {
      case "Personal Info":
        setProgress((1 / 7) * 100);
        break;
      case "Employment Details":
        setProgress((2 / 7) * 100);
        break;
      case "Experience":
        setProgress(30);
        break;
      case "Service":
        setProgress(50);
        break;
      case "Bio":
        setProgress(60);
        break;
      case "Rate":
        setProgress(70);
        break;
      case "Profile":
        setProgress(95);
        break;
      default:
        setProgress(0);
    }
  }, [step]);

  const renderPanel = () => {
    switch (step) {
      case "Personal Info":
        return <PersonalInfo />;
      case "Employment Details":
        return <EmploymentDetails />;
      case "Experience":
        return <Experience />;
      default:
        return <PersonalInfo />;
    }
  };

  React.useEffect(() => {
    dispatch(setSelectedSubMenu(menuItems[1]))
    dispatch(setSubHeader("Onboard Employee"));
    dispatch(setNavigationButtons([]));
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Recruitment", link: "/Employee/overview" },
        { name: "Onboard Employee", link: "/Employee/add-employee" },
      ])
    );
  }, []);

  return (
    <div className="flex relative flex-col space-y-2">
      <Tab.Group>
        <div className="flex flex-col space-y-2 relative">
          <div className="flex sticky top-0 z-50 flex-col justify-between items-center">
            <Tab.List className="flex flex-row w-full h-fit  z-40 sticky top-0 shadow-lg   bg-white  justify-start rounded">
              {tabData.map((tab, index) => (
                <Tab
                  key={index}
                  className={({ selected }) =>
                    `
                  ${tab.name == step
                      ? "text-gray-900 font-bold  "
                      : "text-gray-500"
                    }
                     text-center items-center  max-w-[24vw] flex flex-col  divide-y divide-slate-200 focus:outline-none   w-full p-2.5 text-sm font-medium cursor-pointer`
                  }
                  onClick={() => setSelectedTab(index)}
                >
                  <p className="text-xs text-center pb-1 font-normal text-slate-600">
                    Step {tab.index}
                  </p>
                  <p className="text-md pt-1">{tab.name}</p>
                </Tab>
              ))}
            </Tab.List>
            {/* Show Progress */}
            <ProgressBar progress={progress} />
          </div>

          <Tab.Panels className={"flex flex-col w-full h-full"}>
            {renderPanel()}
          </Tab.Panels>
        </div>
      </Tab.Group>
    </div>
  );
}
