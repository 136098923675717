import { PlusIcon } from "@heroicons/react/solid";
import GlobalLayout from "layout/GlobalLayout";
import React from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { menuItems } from "./MenuItems";

let init = JSON.parse(localStorage.getItem("init"));

export default function Index() {
  let [selected, setSelected] = React.useState(null);
  let dropDownItems = init?.groupTypes ?? [];
  let navigate = useNavigate();
  return (
    <GlobalLayout
      mainTitle={"Recruitment"}
      heading={"All Employees"}
      selectedMenu={menuItems[1]}
      breadCrumbs={[{ title: "Groups" }]}
      MenuItems={menuItems}
      buttons={[]}
    >
      <Outlet />
    </GlobalLayout>
  );
}
