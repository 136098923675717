import { Listbox, Transition } from "@headlessui/react";
import { taskAxios } from "pages/attendance/timesheet/taskAxios/taskAxios";
import { useState, Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";

const SelectTasksDropdown = ({tasks, onTaskChange}) => {
    const [selectedTask, setSelectedTask] = useState(null)

    return (
        <div>
            <div className="text-xs mb-1">Task</div>
            <Listbox
                value={selectedTask}
                onChange={(task) => {
                    setSelectedTask(task)
                    onTaskChange(task)
                }}
            >
                <Listbox.Button className="p-0.5 px-2 border border-gray-200 w-fit cursor-pointer h-8 text-xs rounded-lg flex justify-between items-center flex-row space-x-3 bg-white font-semibold hover:brightness-95">
                    <div className="text-xs">
                        {selectedTask?.activity ?? "Select Task"}
                    </div>
                    <ChevronDownIcon className="ml-2 -mr-1 h-4 w-4" aria-hidden="true" />
                </Listbox.Button>
                <Transition
                    as={"div"}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className="absolute rounded-md mt-1 z-50 w-72 h-fit max-h-56 overflow-auto  origin-bottom-right divide-y divide-gray-100  bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {
                            tasks.map((task, i) => (
                                <Listbox.Option
                                    className="cursor-pointer p-2 hover:bg-orange-100 text-xs"
                                    key={i}
                                    value={task}
                                >
                                    {`${task.task} / ${task.activity}`}
                                </Listbox.Option>
                            ))
                        }
                    </Listbox.Options>
                </Transition>
            </Listbox>
        </div>
    )
}

export default SelectTasksDropdown