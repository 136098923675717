import Widget from "components/widget/Widget";
import { differenceInHours } from "date-fns";
import customAxios from "middlewares/axios-interceptor/customAxios";
import moment from "moment";
import React from "react";

export default function UpcomingEvents() {
  let [data, setData] = React.useState([]);

  let today = new Date();
  let formattedToday = moment(today).format("YYYY-MM-DD");
  let yearEndDate = moment(today).add(1, "year").format("YYYY-MM-DD");

  async function loadUpcomingEvents() {
    let response = await customAxios.get(
      `/calendar?ScheduleType=7&from=${formattedToday}&to=${today.getFullYear()}-12-31`
    );

    setData(response.slice(0, 3));
  }

  React.useEffect(() => {
    loadUpcomingEvents();
  }, []);

  function renderDayStatus(item) {
    let date = item.dateTime;
    let isSameDay = moment(date).isSame(new Date(), "day");

    return (
      <div>
        {isSameDay ? (
          <p className="px-2 p-0.5 rounded-xl text-xs bg-orange-400 text-white">
            Celebrate Today
          </p>
        ) : (
          moment(date).startOf("day").fromNow()
        )}
      </div>
    );
  }

  return (
    <Widget
      heading={"Upcoming Birthdays"}
      component={function () {
        return (
          <div className="flex flex-row justify-end items-center">
            <div className="text-xs text-gray-500 cursor-pointer hover:text-gray-700">
              View All
            </div>
          </div>
        );
      }}
    >
      <div className="flex flex-col justify-start items-start space-y-2 ">
        {data.map((item) => {
          return (
            <div className="flex flex-row p-4 divide-x-2 divide-slate-100 justify-start items-center  shadow-md w-full rounded-xl border border-slate-100 ">
              <div className="flex  px-4 pr-4  flex-col justify-center items-center">
                <div className="text-3xl font-light">
                  {moment(item.dateTime).format("DD")}
                </div>
                <div className="text-sm text-orange-400 uppercase font-semibold">
                  {moment(item.dateTime).format("MMM")}
                </div>
              </div>
              <div className="flex flex-col px-6  justify-between items-start">
                <div className="text-xs text-gray-500 capitalize">
                  {renderDayStatus(item)}
                </div>
                <div className=" font-semibold">{item.title}</div>
                {differenceInHours(item.dateTime, new Date()) > 24 && (
                  <div className="text-xs text-gray-500 capitalize">
                    {moment(item.dateTime).format("hh:mm A")}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </Widget>
  );
}
