export const menuItems = [
  {
    title: "Timesheet",
    path: "Timesheet",
  },
  {
    title: "Employee Snapshot",
    path: "Snapshots",
  },
];
