export const menuItems = [
  {
    title: "Overview",
    path: "/Attendance/Overview",
  },
  {
    title: "Attendance",
    path: "/Attendance/All",
  },
  // {
  //   title: "Timesheet",
  //   path: "/Attendance/Timesheet",
  // },
  // {
  //   title: "Overtime",
  //   path: "/Attendance/Overtime",
  // },
  // {
  //   title: "Adjustments",
  //   path: "/Attendance/Adjustments",
  // },
  // {
  //   title: "Releases",
  //   path: "/Attendance/Releases",
  // },
  {
    title: "Sign-in Log",
    path: "/Attendance/SignInLogs",
  },
];
