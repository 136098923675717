import React, { useEffect } from "react";
import { columns } from "./columns";
import { useState } from "react";
// This File and Folder Should be in task-sheet. Shoudl Refactor. Temp Fix Is Changing this files route to the appropriate folder;
import { menuItems } from "./../../task-sheet/MenuItems";
import Widget from "components/widget/Widget";
import {
  setBreadCrumbs,
  setSelectedSubMenu,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import DataGrid from "../../../components/data-grid/DataGrid";
import moment from "moment";
import DateRangePickerMain from "components/date-picker-v2/DateRangePicker";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import { isSameDay } from "date-fns";
import EmployeeDropDown from "./components/EmployeeDropDown";
import DepartmentDropdown from "./components/DepartmentDropdown";
import { cellFunction } from "./HelperFunctions";
import TenantDropdown from "./components/TenantDropdown";
import { taskAxios } from "./taskAxios/taskAxios";
import { getHeading } from "./HelperFunctions";
import { getTenant } from "./HelperFunctions";
import { selectTaskSheet } from "features/tasksheet/auth/tasksheet.selector";
import { useSelector } from "react-redux";

export default function AttendanceTimeSheet() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [requestBody, setRequestBody] = useState({
    parentId: 0,
    depId: 0,
    empId: 0,
    fdate: startOfDay(new Date()),
    edate: endOfDay(new Date()),
    status: 0,
  })
  const username = useSelector(selectTaskSheet).userId
  const password = useSelector(selectTaskSheet).password
  const isLoggedIn = useSelector(selectTaskSheet).isLoggedIn
  const auth = {
    username: username,
    password: password
  }
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedSubMenu(menuItems[2]));
    dispatch(setSubHeader("Timesheet"));
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Attendance", link: "" },
        { name: "Timesheet", link: "/Attendance/timesheet" },
      ])
    );
  }, []);

  useEffect(() => {
    taskAxios.post("/timesheets/", requestBody, { auth: auth })
      .then((response) => {
        const sortedTimesheets = response.sort((a, b) => a.asignee.localeCompare(b.asignee))
          .map((x) => ({ // adding a date field
            ...x,
            date: x.startTime
          }))
        setData(sortedTimesheets);
        setFilteredData(sortedTimesheets);
      });
  }, [requestBody]);

  const setEDate = (date) => {
    let edate = endOfDay(date);
    setRequestBody({
      ...requestBody,
      edate: edate,
    });
  };

  const setFDate = (date) => {
    let fdate = startOfDay(date);
    setRequestBody({
      ...requestBody,
      fdate: fdate,
    });
  };

  const handleEmployeeChange = (selectedEmployee) => {
    setFilteredData(
      selectedEmployee.name === "" ? data : data.filter((task) => task.asignee === selectedEmployee.name)
    );
  };

  const handleDepartmentChange = (departmentId) => {
    setFilteredData(
      departmentId === -1 ? data : data.filter((task) => task.depId === departmentId)
    );
  };

  return (
    <div className="h-full">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Widget
          heading={getHeading(requestBody.fdate, requestBody.edate)}
        >
          <div className="w-full h-15 flex items-end space-x-3 my-2">
            <DateRangePickerMain
              onFDateChange={setFDate}
              onEDateChange={setEDate}
            />
            <div>
              <DepartmentDropdown onDepartmentChange={handleDepartmentChange} />
            </div>
            <div>
              <EmployeeDropDown onEmployeeChange={handleEmployeeChange} />
            </div>
          </div>
          <DataGrid
            showHeader={true}
            testData={filteredData}
            columns={columns}
            cellFunction={cellFunction}
            height={"h-[74vh]"}
          />
        </Widget>
      )}
      <Outlet />
    </div>
  );
}
