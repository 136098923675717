import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const allEmployeeSlice = createSlice({
  name: "allEmployees",
  initialState,
  reducers: {
    // Set Selected Tab
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
});

export const { setSelectedTab } = allEmployeeSlice.actions;

// Selectors
export const selectTab = (state) => state.allEmployees.selectedTab;
export const selectContacts = (state) => state.allEmployees.contacts;

// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default allEmployeeSlice.reducer;
