import React, { Fragment, useEffect } from "react";
import { menuItems } from "./../MenuItems";
import Widget from "components/widget/Widget";
import { useDispatch } from "react-redux";
import {
  setBreadCrumbs,
  setSelectedSubMenu,
  setSubHeader,
} from "features/navigation/NavigationSlice";

import GroupDistribution from "./charts/GroupDistributionPerDepartment";

export default function GroupsOverview() {
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedSubMenu(menuItems[0]));
    dispatch(setSubHeader("Overview"));
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Groups", link: "/groups" },
      ])
    );
  }, []);

  return (
    <div className="grid grid-cols-4 gap-2">
      <div className="col-span-3">
        <Widget heading={"Group Distribution"}>
          <div className="h-full w-[50%]  mx-auto">
            <GroupDistribution/>
          </div>
        </Widget>
      </div>
      <div className="col-span-1 h-40">
        <Widget heading={"Total Groups"}>
          <div className="text-center text-5xl">
            8
          </div>
        </Widget>
      </div>
    </div>
  );
}
