import React, { useEffect, useState, FunctionComponent, ReactNode } from "react";

type PageSectionProps = {
    header? : string,
    className? : string,
    heightTwClass? : string,
    widthTwClass? : string,
    sectionTwClass? : string,
    headerTextTwClass? : string,
    headerTwClass? : string,
    children: ReactNode
}

export const PageSection : FunctionComponent<PageSectionProps> = ({
    header = "Section Header",
    className = "",
    heightTwClass = "h-full",
    widthTwClass = "w-full",
    sectionTwClass = "",
    headerTextTwClass = "",
    headerTwClass = "",
    children 
}) =>
{
    return (
        // TODO: I HAVE ABSOLUTELY NO IDEA WHY THE ONLY WAY NESTED SECTIONS WORK WITHOUT MESSING UP 
        // THEIR CHILDREN SECTIONS IS WITH A BORDER BEING PRESENT. I JUST ADDED FOR DEBUG AND ITS FIXED
        // AND WITHOUT, ITS NOT SOOO HACKS FOR NOW I GUESS. ALSO border-0 DOES NOT WORK
        <div className={`${className} ${heightTwClass} ${widthTwClass} border-2 relative`}>
            <h3 className={`text-lg w-fit ml-4 px-2 rounded-md font-semibold absolute bg-slate-200 ${headerTwClass}`}>
                <div className={headerTextTwClass}>
                    {header}
                </div>
            </h3>
            <div className={`h-[calc(100%-1rem)] mt-4 border-2 border-solid border-slate-400 rounded-xl p-10 overflow-hidden ${sectionTwClass}`}>
                {children}
            </div>
        </div>
    )
}


export default PageSection