export const menuItems = [
  {
    title: "Overview",
    path: "/Leaves/Overview",
  },
  {
    title: "All Leaves",
    path: "/Leaves/All",
  },
];
