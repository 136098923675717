export const columns = [
  {
    accessor: "date",
    Header: "Date",
  },
  { accessor: "employeeId", Header: "Employee ID" },
  { accessor: "employeeName", Header: "Employee Name" },
  { accessor: "start", Header: "Start Time" },
  { accessor: "end", Header: "End Time" },
  { accessor: "checkIn", Header: "Check In" },
  { accessor: "checkOut", Header: "Check Out" },
  {
    accessor: "dayType",
    Header: "Schedule Type",
  },
  {
    accessor: "status",
    Header: "Status",
  },
];
