import { Tab } from "@headlessui/react";
import React from "react";
import { Link } from "react-router-dom";
import menuItems from "./MenuItems";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SideMenu() {
  return (
    <Tab.Group>
      <div className="flex p-2 bg-white rounded-md space-y-3 flex-col justify-between h-full pb-2">
        {menuItems?.map((m, i) => (
          <div className="rounded-md" key={i}>
            <h1
              className={`ml-3 text-sm  ${
                m.name == null
                  ? ""
                  : "border-b focus:outline-none border-b-black/10 p-1 font-medium"
              }`}
            >
              {m.name}
            </h1>

            <div className="flex flex-col">
              {m.menuItems.map((item, i) => (
                <Link
                  className="focus:outline-none w-full"
                  to={item.route}
                  key={i}
                >
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "flex flex-row justify-start rounded-md  text-md focus:outline-none w-full items-center space-x-4 pl-5 p-1.5  hover:cursor-pointer hover:bg-black/5"
                      )
                    }
                    key={i}
                  >
                    <img className="h-5 w-5" src={item.icon} />
                    <p>{item.name}</p>
                  </Tab>
                </Link>
              ))}
            </div>
          </div>
        ))}
        <div>
          <Link to={"/settings/main"} className="focus:outline-none w-full">
            <Tab
              className={({ selected }) =>
                classNames(
                  "flex flex-row justify-start rounded-md  text-md focus:outline-none w-full items-center space-x-4 pl-5 p-2 font-medium  hover:cursor-pointer hover:bg-black/5"
                )
              }
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/738/738853.png"
                className="w-6 h-6"
              />

              <p>Settings</p>
            </Tab>
          </Link>
        </div>
      </div>
    </Tab.Group>
  );
}
