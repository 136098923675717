import EditableDataGrid from "components/editable-data-grid/EditableDataGrid";
import Widget from "components/widget/Widget";
import {
  selectGeneratedAttendance,
  setGeneratedAttendance,
} from "features/attendance/AttendanceSlice";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { columns } from "./columns";

export default function EditGeneratedAttendance() {
  let generatedAttendance = useSelector(selectGeneratedAttendance);

  const [data, setData] = React.useState(generatedAttendance);

  return (
    <div className=" h-[50vh] overflow-auto">
      <Widget heading={"Edit Generated Attendance"}>
        <EditableDataGrid
          columns={columns}
          updateMyData={setData}
          data={data}
        />
      </Widget>
    </div>
  );
}
