import { TerminalIcon } from "@heroicons/react/outline";
import GlobalLayout from "layout/GlobalLayout";
import React from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { menuItems } from "./MenuItems";

let init = JSON.parse(localStorage.getItem("init"));

export default function Index() {
  return (
    <GlobalLayout
      mainTitle={"Attendance"}
      heading={"All Attendance"}
      selectedMenu={menuItems[1]}
      breadCrumbs={[{ title: "Attendance" }]}
      MenuItems={menuItems}
      buttons={[
        <Link
          className="px-4 p-1.5 flex flex-row shadow-sm bg-orange-400 text-white rounded-xl text-sm"
          to="/attendance/GenerateAttendance/Modal=true"
        >
          <TerminalIcon className="h-5 w-5 mr-2" />
          <button className="">Generate Attendance</button>
        </Link>,
      ]}
    >
      <Outlet />
    </GlobalLayout>
  );
}
