import Widget from "components/widget/Widget";
import React from "react";

export default function EmployeeLeaveBalance({ leaveBalanceData }) {
  return (
    <Widget heading={"Leave Balance"}>
      {leaveBalanceData?.map((item, index) => {
        if (item?.max !== "N/A") {
          return (
            <div
              key={index}
              className="flex flex-col justify-start items-start space-y-1"
            >
              <div className="flex flex-row text-sm font-bold w-full justify-between items-center">
                <h1 className="text-sm font-medium">{item?.leaveType}</h1>
                <p>
                  {item?.days}/{item?.max}
                </p>
              </div>

              <div className="flex relative w-full">
                <div className={`h-4 w-full rounded-md  bg-slate-200`} />
                <div
                  style={{ width: `${item?.percentage}%` }}
                  className={`absolute top-0 left-0 h-4 rounded-md  bg-orange-200`}
                />
              </div>
            </div>
          );
        }
      })}
    </Widget>
  );
}
