import DataGrid from "components/data-grid/DataGrid";
import Widget from "components/widget/Widget";
import {
  setBreadCrumbs,
  setSelectedSubMenu,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import customAxios from "middlewares/axios-interceptor/customAxios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { columns } from "./columns";
import { menuItems } from "./../MenuItems";
import moment from "moment";

export default function InactiveEmployees() {
  const [selectedRows, setSelectedRows] = React.useState([]);
  const handleSelection = React.useCallback((value) => {
    setSelectedRows(value);
  }, []);
  let [data, setData] = React.useState([]);
  let [loading, setLoading] = React.useState(true);

  let dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setSelectedSubMenu(menuItems[4]));
    dispatch(setSubHeader("Inactive Employees"));
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Employees", link: "/employees/overview" },
        {
          name: "Inactive Employees",
          link: "/employees/inactive-employees",
        },
      ])
    );
  }, []);

  React.useEffect(() => {
    customAxios.get("/employee").then((res) => arrangeData(res.items));
  }, []);

  async function arrangeData(items) {
    let tempData = [];
    items?.map((d) => {
      tempData.push({
        id: d.id ?? "N/A",
        name: d.name ?? "N/A",
        empCode: d.id ?? "N/A",
        identificationNumber: d.idNumber ?? "N/A",
        dateOfBirth: d.dob ?? "N/A",
        nationality: d.country ?? "N/A",
        designation: d.position?.designation?.name ?? "N/A",
        department: d.position?.department?.name ?? "N/A",
        email: d.email ?? "N/A",
        empType: d.employeeType ?? "N/A",
        status: d.status ?? "N/A",
        dateJoined: d.dateJoined ?? "N/A",
      });
    });

    localStorage.setItem("employees", JSON.stringify(tempData));

    setData(tempData.filter((d) => d.status == "Inactive"));
    setLoading(false);
  }

  function cellFunction(cell) {
    if (cell.column.id == "name") {
      return (
        <div className="flex flex-row py-1 space-x-3 items-center">
          <img
            className="h-8 w-8 rounded-full"
            src={"https://www.mydthpay.com/img/review.png"}
          />
          <Link
            to={`/employees/view/EmployeeId=${cell.row.values.empCode}&Name=${cell.row.values.name}/overview`}
            className="text-orange-600 font-medium hover:cursor-pointer hover:underline"
          >
            {cell.value}
          </Link>
        </div>
      );
    } else if (cell.column.id == "dateJoined") {
      return (
        <div className="flex flex-row space-x-3 items-center">
          {moment(cell.value).format("D MMMM YYYY")}
        </div>
      );
    } else if (cell.column.id == "status") {
      return (
        <div className="flex flex-row space-x-3 items-center">
          {cell.value == "Active" ? (
            <span className="text-green-500 font-medium">{cell.value}</span>
          ) : (
            <span className="text-red-500 font-medium">{cell.value}</span>
          )}
        </div>
      );
    }

    return cell.render("Cell");
  }

  // find index of selected row items from data array

  return (
    <div className="flex flex-col space-y-2 h-full">
      <Widget heading={"Inactive Employees"}>
        <div className="h-full">
          <DataGrid
            showHeader={true}
            search={true}
            columns={columns}
            testData={data ?? []}
            selectable={false}
            cellFunction={cellFunction}
          />
        </div>
      </Widget>
    </div>
  );
}
