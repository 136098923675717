import PieChart from "components/charts/pie-chart/PieChart";
import Widget from "components/widget/Widget";
import React from "react";

export default function EmployeeLeaveByType({ data }) {
  let ChartData = {
    labels: [],
    datasets: [
      {
        data: [],
        tension: 0,
      },
    ],
  };

  let labels = data?.map((item) => item?.leaveType?.name);
  // remove repeated labels
  ChartData.labels = [...new Set(labels)];

  // Count the number of time label repeated
  ChartData.labels.forEach((label) => {
    let count = 0;
    data.forEach((item) => {
      if (item?.leaveType?.name === label) {
        count++;
      }
    });
    ChartData.datasets[0].data.push(count);
  });

  return (
    <Widget heading={"Employee Leaves by Type"}>
      <PieChart labels={ChartData.labels} datasets={ChartData.datasets} />
    </Widget>
  );
}
