import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  setBreadCrumbs,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import { useDispatch } from "react-redux";
import AddGroupDepartment from "./add-group-department/AddGroupDepartment";

export default function AddGroup() {
  let { groupType, Modal } = useParams();
  let navigate = useNavigate();
  let dispatch = useDispatch();

  let GroupType = groupType.replace(/[A-Z]/g, " $&").trim();

  React.useEffect(() => {
    dispatch(setSubHeader(`Add ${GroupType}`));
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Groups", link: "/groups/all" },
      ])
    );
  }, []);

  const renderSwitch = () => {
    switch (groupType) {
      case "Department":
        return <AddGroupDepartment />;
      default: {
        navigate("/groups/all");
      }
    }
  };

  return <div>{renderSwitch()}</div>;
}
