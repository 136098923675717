export const menuItems = [
  {
    title: "Overview",
    path: "/Payroll/Overview",
  },
  {
    title: "Staff Payroll",
    path: "/Payroll/Staffs",
  },
  {
    title: "Payroll Reports",
    path: "/Payroll/Reports",
  },
  {
    title: "Add Allowance",
    path: "/Payroll/add-allowance",
  },
  {
    title: "Assign Allowance",
    path: "/Payroll/assign-allowance",
  },
  {
    title: "Update Allowance",
    path: "/Payroll/update-allowance",
  },
];
