import React, { useEffect } from "react";
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import { Combobox } from "@headlessui/react";
import { selectTab } from "../../features/employees/EmployeesSlice";
import { useSelector } from "react-redux";
import ComboBoxComponent from "../combo-box/ComboBoxComponent";
import Widget from "components/widget/Widget";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { useDropzone } from "react-dropzone";
import DatePickerMain from "components/date-picker-v2/DatePickerMain";
import moment from "moment";

function Index({
  formData,
  height = "h-full",
  isError,
  ErrorMsg,
  setSelectedValues,
  buttonSubmit,
}) {
  let selectedTab = useSelector(selectTab);
  const [files, setFiles] = React.useState([]);

  const thumbs = files.map((file) => (
    <div style={{ fontSize: 10 }} className="w-fit" key={file.name}>
      <div className="p-0.5 px-2 border border-gray-200">
        {/* <img src={file.preview} className="h-10 w-10" /> */}
        <p>{file.name}</p>
      </div>
    </div>
  ));

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );

      formik.setFieldValue(`documents`, acceptedFiles);
    },
  });

  const initialValues = formData.initialValues;

  const validationSchema = formData.validationSchema;

  const onSubmit = formData.onSubmit;
  // console.log("FORMIKINTI", initialValues)
  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  // console.log("FORMIKF", formik)

  useEffect(() => {
    // console.log("Form Values", formData);
    // console.log("Form Initial Values", initialValues);
  }, []);
  return (
    <section className="relative">
      <FormikProvider value={formik}>
        <div className="flex flex-col space-y-5">
          {isError && (
            <div className="bg-red-50 w-full rounded-md p-5 items-center justify-center border border-red-600  flex flex-row space-x-8">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-12 h-12 text-red-700"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="w-full flex flex-col  space-y-2 text-sm text-slate-700">
                <h1 className="text-base font-medium">{ErrorMsg.message}</h1>
                <div>
                  {ErrorMsg.payload?.policy?.length > 0 ? (
                    ErrorMsg.payload?.policy[0]?.map((policy, i) => (
                      <div
                        key={i}
                        className="flex  flex-row space-x-3 justify-start items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-2 h-2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>

                        <p>{policy}</p>
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <button
                onClick={() => {
                  isError = false;
                }}
                className="flex relative   justify-start items-start"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          )}
          <Form className="">
            <div className={`${height}  flex flex-col space-y-3`}>
              {formData.array?.length >= 1 ? formData.arrayRender() : ""}

              {formData?.contents?.map((content, i) => (
                <div className="flex flex-col space-y-3 " key={i}>
                  <Widget
                    heading={content?.heading ?? "N/A"}
                    subHeading={content?.description ?? ""}
                  >
                    <div
                      className={`grid ${content.fields.length > 4
                        ? "grid-cols-2 "
                        : "grid-cols-1"
                        } gap-3 overflow-auto`}
                    >
                      {content?.fields?.map((field, i) => {
                        return (
                          <div
                            className="flex flex-row row-span-2 justify-center items-center    gap-3 text-sm"
                            key={i}
                          >
                            <label
                              className="col-span-3 text-xs w-1/2"
                              htmlFor={field.name}
                            >
                              {field.displayName}
                              {field.required ? (
                                <span className="text-red-700 ml-1">*</span>
                              ) : (
                                ""
                              )}
                            </label>
                            <div className="w-full flex flex-col space-y-1">
                              {field?.type == "field" && (
                                <Field
                                  // required={field.required}
                                  name={field.name}
                                  type={field.inputType}
                                  className="w-full rounded-md   py-1  px-2  text-sm border border-gray-200 focus:ring-orange-400 focus:outline-none"
                                />
                              )}

                              {field?.type == "pictureUpload" && (
                                <div className="space-y-3 w-24 aspect-square group   relative justify-start items-center">
                                  <div
                                    style={{
                                      backgroundImage: field.value(formik)?.name
                                        ? `url(${URL.createObjectURL(
                                          field.value(formik)
                                        )}) `
                                        : `url('https://www.mydthpay.com/img/review.png')`,
                                    }}
                                    className="h-32 w-32 rounded-full bg-cover bg-center group-hover:opacity-50"
                                  />
                                  <div
                                    className="absolute top-10 left-10
                                 "
                                  >
                                    <input
                                      className="bg-orange-100 sticky z-50 opacity-0 h-14 w-14 border-orange-300 text-orange-800 focus:ring-0 cursor-pointer"
                                      type="file"
                                      name="image"
                                      onChange={(event) =>
                                        formik.setFieldValue(
                                          `${field?.name}`,
                                          event.target.files[0]
                                        )
                                      }
                                    />
                                    <img
                                      className=" absolute transition-all z-10 top-0 left-2 group-hover:opacity-70 opacity-10"
                                      src="https://cdn-icons-png.flaticon.com/512/4687/4687428.png"
                                      alt="camera-icon"
                                      height={32}
                                      width={32}
                                    />
                                  </div>
                                </div>
                              )}
                              {field?.type == "dateSelect" && (
                                <DatePickerMain
                                  selectedDateChild={moment(field.value(formik) ?? field.default ?? new Date()).format(
                                    "DD/MM/yyyy"
                                  )}
                                  onDateChange={(e) =>
                                    formik.setFieldValue(`${field.name}`, e)
                                  }
                                  value={field.value(formik)}
                                />
                              )}
                              {field.type == "checkBox" && (
                                <input
                                  type={"checkbox"}
                                  className="bg-orange-100 border-orange-300 text-orange-800 focus:ring-0 "
                                />
                              )}
                              {field.type == "textArea" && (
                                <Field
                                  className="text-sm rounded-md p-1 focus:outline-none border border-gray-200"
                                  component="textarea"
                                  rows="4"
                                  name={field.name}
                                ></Field>
                              )}
                              {field.type == "fileUpload" && (
                                <div className="flex flex-col space-y-2">
                                  <div
                                    {...getRootProps({ className: "dropzone" })}
                                    className="border border-gray-300 bg-gray-100 space-y-3 flex flex-col items-center justify-center  p-5"
                                  >
                                    <input {...getInputProps()} />
                                    <img
                                      className="h-14 w-14"
                                      src="https://cdn-icons-png.flaticon.com/128/3685/3685850.png"
                                      alt="file-upload-icon"
                                    />
                                    <p className="" style={{ fontSize: 10 }}>
                                      Drag 'n' drop some files here, or click to
                                      select files
                                    </p>
                                  </div>
                                  <aside className="flex flex-wrap space-x-1  w-full max-w-[90vw] overflow-auto">
                                    {thumbs}
                                  </aside>
                                </div>
                              )}
                              {field.type == "checkbox" && (
                                <input
                                  className="bg-orange-100 border-orange-300 text-orange-800 focus:ring-0 cursor-pointer"
                                  type="checkbox"
                                  value={field.value(formik)}
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      `${field.name}`,
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                              {field.type == "comboBox" && (
                                <Combobox
                                  value={field.value(formik)}
                                  onChange={(e) => {
                                    formik.setFieldValue(`${field.name}`, e);
                                    if (field.name == "department") {
                                      setSelectedValues({
                                        department: e,
                                      });
                                    }
                                  }}
                                >
                                  <ComboBoxComponent
                                    options={field.options}
                                    values={field.values}
                                  />
                                </Combobox>
                              )}

                              {field.type == "component" && (
                                <div className="flex flex-col justify-start text-start items-start text-sm space-y-2 w-full">
                                  {field.component(formik)}
                                </div>
                              )}

                              <ErrorMessage
                                name={field.name}
                                component="div"
                                className="text-red-700 text-xs pt-1"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Widget>
                </div>
              ))}
            </div>

            <div className="my-2 drop-shadow-xl border border-slate-200 z-50 w-full bg-white p-2 mb-1 rounded-xl flex justify-end">
              <PrimaryButton
                type={"submit"}
                onClick={onSubmit}
                title={buttonSubmit?.title ?? `Submit`}
              />
            </div>
          </Form>
        </div>
      </FormikProvider>
    </section>
  );
}

export default Index;
