import moment from "moment"
import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import { tenantMapper } from "./TenantMapper";

export const cellFunction = (cell) => {
  const { id } = cell.column
  switch (id) {
    case "remarks":
      return <div className="w-[200px] text-limit">{cell.value}</div>

    case "startTime":
    case "endTime":
      return (
        <div className="flex flex-row space-x-3 items-center">
          {moment(cell.value).format("HH:mm a")}
        </div>
      )

    case "timeSpent":
      return <div>{cell.value}</div>;

    case "date":
      return <div>{format(new Date(cell.value), "dd MMMM yyyy")}</div>

    default:
      return cell.render("Cell");
  }
};

export const getHeading = (fdate, edate) =>
  isSameDay(fdate, edate) ? `Timesheet for ${moment(fdate).format("D MMM YYYY")}`
    : `Timesheet from ${moment(fdate).format("D MMM YYYY")} to ${moment(edate).format("D MMM YYYY")}`

export const getTenant = (tenantId) => tenantMapper[tenantId]

const tenantId = JSON.parse(localStorage.getItem("tokenData"))?.tenantId ?? "N/A"
export const getImmediateTenant = () => getTenant(tenantId)
export const getTSHRId = hrId => `${tenantId}+${hrId}`