import { Tab } from "@headlessui/react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Tabs({ tabItems, rootPath }) {
  const location = useLocation();
  let navigate = useNavigate();
  const url = location.pathname.replace(/\s/g, "&20").replace("/overview", "");

  return (
    <div className="">
      <Tab.Group>
        <Tab.List className={"flex space-x-2 rounded-xl p-1"}>
          {tabItems.map((tabItem, i) => (
            <Tab
              onClick={() => {
                navigate(`${rootPath + tabItem.path}`);
              }}
              key={i}
              className={({ selected }) =>
                classNames(
                  " text-center capitalize focus:outline-none py-1.5 px-3 rounded-xl  text-sm  hover:cursor-pointer hover:shadow-md ",
                  selected
                    ? " bg-white text-orange-600 font-semibold shadow"
                    : "text-slate-700 border-b-transparent"
                )
              }
            >
              <p>{tabItem.title}</p>
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
    </div>
  );
}
