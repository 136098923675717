import Widget from "components/widget/Widget";
import React from "react";
import DataGrid from "../../../components/data-grid/DataGrid";
import customAxios from "../../../middlewares/axios-interceptor/customAxios";
import { useParams } from "react-router-dom";
import moment from "moment";

export default function EmployeeAttendanceList() {
  let [data, setData] = React.useState([]);
  let { EmployeeId } = useParams();

  React.useEffect(() => {
    customAxios.get(`/employee/signInLogs/` + EmployeeId).then((res) => {
      arrangeItems(res);
    });
  }, []);

  let arrangeItems = (res) => {
    let temp = [];
    res.forEach((item) => {
      temp.push({
        employeeName: item.employee?.name,
        date: moment(item.dateTime).format("MM-DD-YYYY HH:mm:ss"),
      });
    });
    setData(temp);
  };

  let cellFunction = (cell) => {
    return cell.value;
  };

  return (
    <Widget heading={"Employee Sign-Ins"}>
      <DataGrid
        testData={data}
        columns={[
          {
            Header: "Employee",
            accessor: "employeeName",
          },
          {
            Header: "Timestamp",
            accessor: "date",
          },
        ]}
        showHeader={true}
        cellFunction={cellFunction}
      />
    </Widget>
  );
}
