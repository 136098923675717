import Widget from "components/widget/Widget"
import DataGrid from "components/data-grid/DataGrid"
import { taskAxios } from "pages/attendance/timesheet/taskAxios/taskAxios"
import { useSelector } from "react-redux"
import { selectTaskSheet } from "features/tasksheet/auth/tasksheet.selector"
import { useEffect, useState } from "react"
import format from "date-fns/format"

const Snapshot = () => {
    const [snapshot, setSnapshot] = useState(null)

    const auth = {
        username: useSelector(selectTaskSheet).userId,
        password: useSelector(selectTaskSheet).password
    }

    useEffect(() => {
        taskAxios.get("/todaysattendance", {auth})
        .then(x => setSnapshot(x))
    }, [])

    const cellFunction = (cell) => {
        const {id} = cell.column
        const {value} = cell
        console.log(value);
        switch(id)
        {
            case "checkIn":
                return <div>{value ? format(new Date(value), `HH:mm:ss`) : "-"}</div>
            case "checkOut":
                return <div>{value ? format(new Date(value), `HH:mm:ss`) : "-"}</div>
            default:
                return cell.render('Cell')
        }
    }

    if(!snapshot)
    {
        return
    }

    return (
        <Widget 
            heading={"Employee Snapshot"}
        >
            <DataGrid
                showHeader={true}
                testData={snapshot}
                columns={[
                    {
                        Header: "Employee Name",
                        accessor: "employee",
                    },
                    {
                        Header: "Check in",
                        accessor: "checkIn",
                    },
                    {
                        Header: "Check out",
                        accessor: "checkOut",
                    },
                    {
                        Header: "Tea Toom Total",
                        accessor: "teaRoomTotal",
                    },
                    {
                        Header: "Personal Total",
                        accessor: "personalTotal",
                    },
                    {
                        Header: "Break Total",
                        accessor: "breakTotal",
                    },
                    {
                        Header: "Overdue Tasks",
                        accessor: "overDue",
                    },
                    {
                        Header: "Due Today",
                        accessor: "dueToday",
                    },
                    {
                        Header: "Due Tomorrow",
                        accessor: "dueTomorrow",
                    },
                    {
                        Header: "Due This week",
                        accessor: "dueThisWeek",
                    },
                ]}
                
                cellFunction={cellFunction}
                height={"h-[74vh]"}
            />
        </Widget>
    )
}

export default Snapshot