import { XIcon } from "@heroicons/react/solid";
import React from "react";
import * as Yup from "yup";
import FormGenerator from "../../../../components/form-generator/FormGenerator";

import { addDocuments } from "../../../../features/employees/EmployeesSlice";
import { useSelector, useDispatch } from "react-redux";
let contactForm = JSON.parse(sessionStorage.getItem("contactForm"));

export default function Documents({ setChildSelectedTab, selectedTab }) {
  const dispatch = useDispatch();
  let documents = [];
  const formData = {
    array: true,
    arrayRender: function () {
      return (
        <div className="flex flex-row space-x-3 text-md">
          <div>{JSON.stringify(documents)}</div>
        </div>
      );
    },
    contents: [
      {
        heading: "Documents",
        description:
          "Multiple Documents can be added for an employee. The contact types can be set in settings please click on the link below to add more contact types",
        function: () => {
          return <div></div>;
        },
        fields: [
          {
            name: "AwardType",
            displayName: "Document Type",
            type: "comboBox",
            value: (formik) => {
              return formik.values.bank;
            },
            values: [
              { id: 1, name: "Doctorate Degree (PHD)" },
              { id: 2, name: "Masters Degree" },
              { id: 2, name: "Bachelors Degree" },
              { id: 2, name: "Accounting" },
            ],
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },
          {
            name: "docNumber",
            displayName: "Document Number",
            type: "field",
            inputType: "text",
          },
          {
            name: "issuedCountry",
            displayName: "Issued Country",
            type: "field",
            inputType: "text",
          },

          {
            name: "issuedBy",
            displayName: "Issued by",
            type: "field",
            inputType: "text",
          },
          {
            name: "issuedOn",
            displayName: "Issued on",
            type: "dateSelect",
            default: new Date(),
            inputType: "text",
            value: (formik) => {
              return formik.values.dob;
            },
          },
          {
            name: "expiresOn",
            displayName: "Expires on",
            type: "dateSelect",
            default: new Date(),
            inputType: "text",
            value: (formik) => {
              return formik.values.dsob;
            },
          },
          {
            name: "remarks",
            displayName: "Remarks",
            type: "textArea",
            inputType: "text",
          },
          {
            name: "attachments",
            displayName: "Attachments",
            type: "fileUpload",
            inputType: "text",
          },
        ],
      },
    ],
    initialValues: {
      contactType: contactForm?.contactType ? contactForm?.contactType : "",
      email: contactForm?.email ? contactForm?.email : "",
      mobile: contactForm?.mobile ? contactForm?.mobile : "",
      phone: contactForm?.phone ? contactForm?.phone : "",
      isDefault: contactForm?.isDefault ? contactForm?.isDefault : "",
      name: contactForm?.name ? contactForm?.name : "",
      relationship: contactForm?.relationship ? contactForm?.relationship : "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("First name is required"),
    }),
    onSubmit: async (
      values,
      { setSubmitting, setErrors, setStatus, resetForm, handleSubmit }
    ) => {
      console.log(values);
      dispatch(addDocuments(values));
      resetForm();
    },
  };
  return (
    <div>
      <FormGenerator formData={formData} />
    </div>
  );
}
