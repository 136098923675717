import Widget from "components/widget/Widget"
import { useEffect, useState } from "react"
import AddAlowancesForm from "./AddAllowancesForm"
import customAxios from "middlewares/axios-interceptor/customAxios"
import { useDispatch } from "react-redux"
import { addNotification } from "features/notifications/NotificationSlice"
import FormWrapper from "pages/employees/view-employee/views/timesheet/components/FormWrapper"
import { setSubHeader } from "features/navigation/NavigationSlice"

const defaultFormFields = {
    // hidden
    AllowanceType: "Income",
    IsShownInPaySlip: 1,
    IsTaxApplicable: 0,
    State: 0,
    Frequency: 2,
    // not hidden
    Query: "select @Type as Allowance ,462 as Currency ,GETDATE() as [Date] ,( ((1.0 * @LateMinutes) / @TotalWorkMinutes) * @Amount) as Total)",
    Currency: 462,
    Descripton: "",
    Name: "",
    BaseAmount: 0
}

const AddAllowances = () => {
    const [formFields, setFormFields] = useState(defaultFormFields)
    const [errorMessage, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setSubHeader("Add an Allowance"))
    }, [])

    const handleFormChange = (event) => {
        setFormFields({
            ...formFields,
            [event.target.name]: event.target.value
        })
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)
        const res = await customAxios.post("/pay/create", formFields)
        const hasError = res?.response?.status ?? ""

        if(hasError)
        {
            let errorMessage = res.message
            setErrorMessage(errorMessage)
        }
        else
        {
            dispatch(addNotification({
                id: 1,
                heading: `${res.name} Added Successfully`
            }))
        }

        setLoading(false)
    }   

    return (
        <div className="w-full min-h-96 max-h-full overflow-y-auto">
            <Widget>
                <FormWrapper loading={loading} errorMessage={errorMessage}>
                    <AddAlowancesForm onChange={handleFormChange} onSubmit={handleSubmit} />
                </FormWrapper>
            </Widget>
        </div>
    )
}

export default AddAllowances