import customAxios from "middlewares/axios-interceptor/customAxios"
import { useEffect, useState } from "react"
import { endOfDay, isWithinInterval, startOfDay } from "date-fns"
import { useNavigate } from "react-router-dom"

export default function OnLeaveToday() {
    const [onLeaveToday, setOnLeaveToday] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        customAxios.get("/leave")
        .then((res) => {
            let leaves = res.items
            let onLeaveToday = []
            leaves.forEach((leaf) => {
                let today = new Date()
                let leafStart = startOfDay(new Date(leaf.from))
                let leafEnd = endOfDay(new Date(leaf.to))
                if(isWithinInterval(today, { start: leafStart, end: leafEnd })) 
                {
                    onLeaveToday.push(leaf)
                }
            }) 
            setOnLeaveToday(onLeaveToday)
        });
    }, [])

    const navigateToLeaveId = (leaveId) => {
        navigate(`/leaves/view/id=${leaveId}`);
    }

    return (
        <>
            <table>
                <caption className="text-lg border-b font-semibold p-1 pb-2 mb-2 text-left">{`Total: ${onLeaveToday.length}`}</caption>
                <tr>
                    <th>Name</th>
                    <th>Leave Type</th>
                    <th>Remarks</th>
                    <th>Status</th>
                </tr>
                {
                    onLeaveToday.map((leaf, i) => (
                        <>
                            <tr onClick={() => {navigateToLeaveId(leaf.id)}} className={(i + 1) % 2 == 0 ? "hover:bg-orange-50 cursor-pointer rounded-md" : "bg-gray-50 hover:bg-orange-50 cursor-pointer rounded-md"} key={leaf.id}>
                                <td>{leaf?.employee?.name ?? "N/A"}</td>
                                <td>{leaf?.leaveType?.name ?? "N/A"}</td>
                                <td>{leaf?.remarks ?? "N/A"}</td>
                                <td>{leaf?.status ?? "N/A"}</td>
                            </tr>
                        </>
                    ))
                }
            </table>
        </>
    )
}