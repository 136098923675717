import { XIcon } from "@heroicons/react/solid";
import React from "react";
import * as Yup from "yup";
import FormGenerator from "../../../../components/form-generator/FormGenerator";
import { addDocuments } from "../../../../features/employees/EmployeesSlice";
import { useSelector, useDispatch } from "react-redux";
let contactForm = JSON.parse(sessionStorage.getItem("contactForm"));

export default function Experience({ setChildSelectedTab, selectedTab }) {
  const dispatch = useDispatch();
  let documents = [];
  const formData = {
    array: true,
    arrayRender: function () {
      return (
        <div className="flex flex-row space-x-3 text-md">
          <div>{JSON.stringify(documents)}</div>
        </div>
      );
    },
    contents: [
      {
        heading: "Add Experience",
        description:
          "Multiple Documents can be added for an employee. The contact types can be set in settings please click on the link below to add more contact types",
        function: () => {
          return <div></div>;
        },
        fields: [
          {
            name: "docNumber",
            displayName: "Title",
            type: "field",
            inputType: "text",
            required: true,
          },
          {
            name: "AwardType",
            displayName: "Employment Type",
            type: "comboBox",
            value: (formik) => {
              return formik.values.bank;
            },
            values: [
              { id: 1, name: "Doctorate Degree (PHD)" },
              { id: 2, name: "Masters Degree" },
              { id: 2, name: "Bachelors Degree" },
              { id: 2, name: "Accounting" },
            ],
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },
          {
            name: "fieldof",
            displayName: "Company name",
            type: "field",
            inputType: "text",
            required: true,
          },
          {
            name: "docNumber",
            displayName: "Location",
            type: "field",
            inputType: "text",
            required: true,
          },
          {
            name: "dateOfBirth",
            displayName: "Start date",
            type: "dateSelect",
            default: new Date(),
            inputType: "text",
            value: (formik) => {
              return formik.values.dateOfBirth;
            },
            required: true,
          },
          {
            name: "dateOfBirth",
            displayName: "End date",
            type: "dateSelect",
            default: new Date(),
            inputType: "text",
            value: (formik) => {
              return formik.values.dateOfBirth;
            },
            required: true,
          },
          {
            name: "gr",
            displayName: "Industry",
            type: "field",
            inputType: "text",
            required: true,
          },
          {
            name: "gr",
            displayName: "Description",
            type: "textArea",
            inputType: "text",
            required: true,
          },
        ],
      },
    ],
    initialValues: {},
    validationSchema: Yup.object().shape({
      email: Yup.string().required("First name is required"),
    }),
    onSubmit: async (
      values,
      { setSubmitting, setErrors, setStatus, resetForm, handleSubmit }
    ) => {
      console.log(values);
      dispatch(addDocuments(values));
      resetForm();
    },
  };
  return (
    <div>
      <FormGenerator formData={formData} />
    </div>
  );
}
