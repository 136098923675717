import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import SideMenu from "../side-menu/SideMenu";
import { Link, Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import {
  open,
  setOpen,
  notifications,
  addNotification,
  removeNotification,
} from "../../../features/notifications/NotificationSlice";
import { motion, animatePresence, AnimatePresence } from "framer-motion";
import PrimaryButton from "../../../ui/buttons/PrimaryButton";

const navbarFunctions = [
  {
    name: "Calendar",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
        />
      </svg>
    ),
    modal: null,
    route: "/calendar/overview",
  },
  {
    name: "notifications",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-7 h-7"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
        />
      </svg>
    ),
    modal: null,
  },
  {
    name: "support",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-7 h-7"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
        />
      </svg>
    ),
  },
  {
    name: "feedback",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-7 h-7"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
        />
      </svg>
    ),
  },
];

export default function NavigationBar({ heading }) {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let [isOpen, setIsOpen] = React.useState(false);
  let [selectedNavFunction, setSelectedNavFunction] = React.useState();
  let [navFunctionOpen, setNavFunctionOpen] = React.useState(false);
  let isNotificationOpen = useSelector(open);
  let reduxNotifications = useSelector(notifications);
  let [tokenData, setTokenData] = useState();

  useEffect(() => {
    setTokenData(JSON.parse(localStorage.getItem("tokenData")));
  }, []);

  function openModal() {
    setIsOpen(true);
  }

  function NavFunctionHandler(nf) {
    setSelectedNavFunction(nf);
    setNavFunctionOpen(true);
  }

  function handleNavfunctionClose() {
    setSelectedNavFunction(null);
    setNavFunctionOpen(false);
  }

  return (
    <div
      style={{ zIndex: 999 }}
      className="z-30 flex flex-row items-center justify-between w-full bg-white text-black  shadow-sm"
    >
      {/* Menu Icon and App title */}
      <div className="flex w-4/12 flex-row -space-x-2 justify-start items-center">
        {/* <div
          onClick={() => openModal()}
          className="px-3   hover:bg-orange-100 rounded-xl py-1 hover:cursor-pointer  flex items-center"
        >
          {!isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-7 h-7 cursor-pointer  "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-7 h-7 cursor-pointer  "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12
              />
            </svg>
          )}
        </div> */}

        <Link
          to="/"
          className="flex items-center space-x-  flex-row space-x-8 ml-2 h-12  py-1 hover:cursor-pointer"
        >
          <img
            src="https://www.svgrepo.com/show/227189/orange.svg"
            className="w-8 h-8 fill-orange-600"
            alt="logo"
          />
          <h1 className="font-bold text-xl">Eches HR Portal</h1>
          <h2 className=" text-lg">{heading}</h2>
        </Link>
      </div>
      {/* Universal Search */}
      {/* <div className="flex w-6/12 text-md flex-row justify-start items-center space-x-2 p-1.5 rounded-md  active:bg-white bg-orange-200 text-orange-800">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-5 h-5"
        >
          <path
            fillRule="evenodd"
            d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z"
            clipRule="evenodd"
          />
        </svg>
        <input
          placeholder="Search for an employee, service, resource and more.."
          className="bg-inherit focus:bg-white text-black w-full focus:outline-none "
          type={"search"}
        ></input>
      </div> */}
      {/* Navbar icons and profile */}
      <div className="flex w-4/12 flex-row space-x-3  justify-end items-center">
        {/* Function Icons */}
        <div className="flex flex-row space-x-0 ">
          {navbarFunctions.map((nf, i) => (
            <Link
              key={uuidv4()}
              to={nf.route ?? "#"}
              onClick={() => {
                nf.modal ? NavFunctionHandler(nf) : console.log("ee");
              }}
              className={`${selectedNavFunction?.name === nf.name
                ? "bg-white text-black"
                : ""
                } hover:bg-orange-100 rounded-xl p-3  hover:cursor-pointer w-full h-full`}
            >
              <div
                className="h-5 w-5  flex justify-center items-center"
                key={i}
              >
                {nf.icon}
              </div>
            </Link>
          ))}
        </div>

        <Menu>
          <Menu.Button className="flex rounded-xl flex-row pl-8 text-sm text-md pr-5 p-3  py-1 hover:cursor-pointer text-left space-x-5 justify-center items-center">
            <div>
              <p className="text-base font-medium">{tokenData?.emp_dn}</p>
              <p className="text-xs">{tokenData?.tenant?.trim()}</p>
            </div>
            <img
              className="h-8 w-8 rounded-full"
              src={
                "https://i.pinimg.com/736x/b0/e2/f5/b0e2f54d141a70986beac46962394651.jpg"
              }
            />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition x-100 ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div className="absolute text-slate-700 p-5 rounded-md flex flex-row space-x-10 justify-start items-center z-50 top-14 right-2 w-96 origin-bottom-right divide-xs divide-gray-100  bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <img
                className="h-16 w-16 rounded-full border-2 border-slate-600"
                src={
                  "https://i.pinimg.com/736x/b0/e2/f5/b0e2f54d141a70986beac46962394651.jpg"
                }
              />
              <div className="flex flex-col space-y-2 text-md text-slate-700">
                <h5 className="text-xl font-medium">{tokenData?.name}</h5>
                <div className="flex flex-row divide-x divide-slate-300 justify-between items-center">
                  <Link className="pr-4" to={"Switch Division"}>
                    Switch Division
                  </Link>
                  <Link className="px-4" to={"Edit Profile"}>
                    Edit Profile
                  </Link>
                  <Link className="px-4" to={`/employees/view/EmployeeId=${JSON.parse(localStorage.getItem("tokenData"))?.emp_id}&Name=${JSON.parse(localStorage.getItem("tokenData"))?.emp_dn}/overview`}>
                    View Profile
                  </Link>
                </div>
                <div className="flex flex-row  justify-between items-center">
                  <button
                    onClick={() => {
                      localStorage.clear();
                      navigate("/auth/login");
                    }}
                  >
                    Log out
                  </button>

                  <button>Reset Password</button>
                </div>
              </div>
            </div>
          </Transition>
        </Menu>
        {/* Avatar */}
      </div>
      {/* Side Menu Modal */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 w-screen "
          onClose={() => setIsOpen(false)}
        >
          <div className="fixed inset-0  w-screen  h-screen overflow-y-auto">
            <div className="flex min-h-full w-full justify-start  text-center">
              <Dialog.Panel className=" w-[16vw] flex flex-col justify-between pt-14  z-20   transform overflow-hidden  bg-white text-left  shadow-2xl transition-all">
                <div className=" h-full">
                  <SideMenu />
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition>
      {/* Notifications */}
      <Transition appear show={isNotificationOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 w-screen "
          onClose={() => setIsOpen(false)}
        >
          <div className="fixed right-0 inset-0  w-fit  h-fit overflow-y-auto">
            <div className="flex min-h-full w-full justify-end  text-center">
              <Dialog.Panel className=" w-[16vw] flex flex-col space-y-3 justify-start pt-14  z-20 mr-3   transform overflow-hidden  text-left  transition-all">
                <AnimatePresence initial={false}>
                  {reduxNotifications.map((not, i) => (
                    <motion.div
                      positionTransition
                      initial={{ opacity: 0, y: 50, scale: 0.3 }}
                      animate={{ opacity: 1, y: 0, scale: 1 }}
                      exit={{
                        opacity: 0,
                        scale: 0.5,
                        transition: { duration: 0.2 },
                      }}
                      className="z-50 text-md bg-white drop-shadow-md border border-slate-200 p-3"
                      key={i}
                    >
                      <Link className="outline-none flex flex-col space-y-2">
                        <div className="flex flex-row justify-between items-center w-full">
                          <h1 className="text-sm font-medium">{not.heading}</h1>
                          <svg
                            onClick={() => dispatch(removeNotification(not))}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-4 h-4 text-slate-500 hover:text-orange-800"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </div>
                        <div>
                          <p className="text-slate-500">{not.description}</p>
                        </div>
                      </Link>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition>
      {/* Other Modal */}
      <Transition appear show={navFunctionOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 w-screen "
          onClose={() => handleNavfunctionClose()}
        >
          <div className="fixed inset-0  w-screen  h-screen overflow-y-auto">
            <div className="flex min-h-full w-full justify-end  text-center">
              <Dialog.Panel
                className={`${selectedNavFunction?.name == "settings"
                  ? "w-[100vw]"
                  : "w-[28vw]"
                  }  flex space-y-5 flex-col justify-between pt-16 p-5  z-20   transform overflow-hidden  bg-white text-left  shadow-2xl transition-all`}
              >
                <Dialog.Title className={"capitalize text-xl font-medium"}>
                  {selectedNavFunction?.name}
                </Dialog.Title>
                <div className=" h-full">{selectedNavFunction?.modal}</div>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
