import ModalLayout from "components/layout/ModalLayout";
import Modal from "components/modal/Modal";
import React from "react";
import { useParams } from "react-router-dom";
import FormGenerator from "components/form-generator/FormGenerator";

import AddExperience from "./modals/AddExperience";
import AddEducation from "./modals/AddEducation";
import AddContacts from "./modals/AddContacts";
import AddAddress from "./modals/AddAddress";

export default function EmployeePersonalAddModal() {
  const { AddModalType } = useParams();

  function findAddModalType(AddModalType) {
    switch (AddModalType) {
      case "Experience":
        return <AddExperience />;
      case "Education":
        return <AddEducation />;
      case "Contact":
        return <AddContacts />;
      case "Address":
        return <AddAddress />;
      default:
        return null;
    }
  }

  return (
    <Modal>
      <ModalLayout width={"w-[33vw] h-full"}>
        {findAddModalType(AddModalType)}
      </ModalLayout>
    </Modal>
  );
}
