import { useDispatch } from "react-redux"
import { setSelectedSubMenu } from "features/navigation/NavigationSlice"
import MenuItems from "../MenuItems"
import Widget from "components/widget/Widget"
import DataGrid, { CONSTANTS as GRIDCONSTANTS } from "components/data-grid/DataGrid"
import { useEffect, useState } from "react"
import { getAllowances } from "middlewares/axios-interceptor/quickEndpoints"
import { getDefaultColumns } from "global-helper-functions/GlobalHelperFunctions"
import { cellFunction } from "../SettingsHelpers"
import { postAllowances } from "middlewares/axios-interceptor/quickEndpoints"
import AddAllowancesForm from "./AddAllowanceForm"
import customAxios from "middlewares/axios-interceptor/customAxios"

const PayrollSettings = () => {
    const [allowances, setAllowances] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setSelectedSubMenu(MenuItems.find(x => x.title === "Payroll Settings")))
        getAllowances()
            .then((res) => {
                setAllowances(res)
            })
    }, [])

    const defaultColumns = getDefaultColumns(allowances)

    let columns = []





    const onActionBegin = (args) => {
        if (args.requestType === GRIDCONSTANTS.GRID_ACTION_SAVE) {
            if (args.action === GRIDCONSTANTS.ACTION_ADD) {
                console.log("GRID ADD >>> ", args.entity)
            } else if (args.action === GRIDCONSTANTS.ACTION_UPDATE) {
                console.log("GRID UPDATE >>> ", args.entity)
            }
        }
    }

    return (
        <div className="flex flex-col gap-y-1 h-full w-full overflow-auto">
            <Widget>
                <DataGrid
                    showHeader
                    columns={columns}
                    selectable={false}
                    cellFunction={cellFunction}
                    testData={allowances}
                    allowAdd
                    allowEdit
                    // editRenderProp={(isDrawerShown, row) => {}}
                    // addRenderProp={(setIsSideDrawerOpen) => <AddAllowancesForm setIsSideDrawerOpen={setIsSideDrawerOpen} setAllowances={setAllowances}/>}
                    allowDelete
                    // onDelete={(row) => 
                    //     { 
                    //         customAxios
                    //         .delete(`/pay/delete/${row.id}`)
                    //         .then(async () => await customAxios.get("/pay/allowances"))
                    //         .then(res => {setAllowances(res);})
                    //     }
                    // }
                    confirmOnDelete
                    onActionBegin={onActionBegin}
                />
            </Widget>
        </div>
    )
}

export default PayrollSettings