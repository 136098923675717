
export const columns = [
  {
    Header: "ID",
    accessor: "id",
    hideOnAdd: true,
    hideOnEdit: true,
  },
  {
    Header: "Name",
    accessor: "name",
    formFieldProps:
    {
      validation: (yup) => yup.string().required("Name is required").min(5, "Must be at least 5 charaters long"),
      required: true,
    }
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Group Type",
    accessor: "type",
    hideOnAdd: true,
    hideOnEdit: true,
  },
  {
    Header: "Owner",
    accessor: "owner",
    hideOnAdd: true,
    hideOnEdit: true,
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    hideOnAdd: true,
    hideOnEdit: true,
  },
  {
    Header: "Created On",
    accessor: "createdAt",
    hideOnEdit: true,
    hideOnAdd: true,
    // formFieldProps:
    // {
    //   type: "dateSelect"
    // }
  },
];
