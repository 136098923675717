import React from "react";
import * as Yup from "yup";
import FormGenerator from "components/form-generator/FormGenerator";
import customAxios from "middlewares/axios-interceptor/customAxios";
import { useNavigate, useParams } from "react-router-dom";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export default function AddAddress({}) {
  let navigate = useNavigate();
  let { EmployeeId } = useParams();
  const formData = {
    contents: [
      {
        heading: "Address Information",
        description:
          "Multiple Address can be added for an employee. The contact types can be set in settings please click on the link below to add more contact types",
        function: () => {
          return <div></div>;
        },
        fields: [
          {
            name: "addressType",
            displayName: "Adress Type",
            type: "comboBox",
            value: (formik) => {
              return formik.values.bank;
            },
            values: [
              { id: 1, name: "Current" },
              { id: 2, name: "Permanent" },
              { id: 2, name: "Abroad" },
            ],
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },

          {
            name: "apartmentNo",
            displayName: "Apartment Number",
            type: "field",
            inputType: "text",
            required: false,
          },
          {
            name: "building",
            displayName: "Building",
            type: "field",
            inputType: "text",
            required: false,
          },

          {
            name: "block",
            displayName: "Block",
            type: "field",
            inputType: "text",
            required: false,
          },
          {
            name: "street",
            displayName: "Street",
            type: "field",
            inputType: "text",
            required: false,
          },
          {
            name: "postalCode",
            displayName: "Postal Code",
            type: "field",
            inputType: "text",
            required: false,
          },
          {
            name: "city",
            displayName: "City",
            type: "field",
            inputType: "text",
            required: false,
          },
          {
            name: "country",
            displayName: "country",
            type: "comboBox",
            value: (formik) => {
              return formik.values.bank;
            },
            values: [
              {
                id: 1,
                name: "Maldives'",
              },
            ],
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },

          // {
          //   name: "isDefault",
          //   displayName: "Make Primary",
          //   type: "checkbox",
          //   inputType: null,
          //   value: (formik) => {
          //     return formik.values.isDefault;
          //   },
          //   required: true,
          // },
        ],
      },
    ],
    initialValues: {
      city: "",
    },
    validationSchema: Yup.object().shape({
      city: Yup.string().required("City is required"),
    }),
    onSubmit: async (
      values,
      { setSubmitting, setErrors, setStatus, resetForm, handleSubmit }
    ) => {
      let data = {
        type: values.addressType.name,
        bulding: values.building,
        appartmentNo: values.apartmentNo,
        postalCode: values.postalCode,
        street: values.street,
        block: values.block,
        city: values.city,
        country: values.country.name,
      };

      customAxios
        .post(
          `https://api.echeshr.com/api/v2/employee/${EmployeeId}/addresses`,
          data
        )
        .then((res) => navigate(-1));
    },
  };
  return (
    <div>
      <FormGenerator formData={formData} />
    </div>
  );
}
