import { Listbox, Transition } from "@headlessui/react";
import { useState, Fragment } from "react";
import { useEffect } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { taskAxios } from "../taskAxios/taskAxios";
import { useSelector } from "react-redux";
import { selectTaskSheet } from "features/tasksheet/auth/tasksheet.selector";

export default function DepartmentDropdown({ onDepartmentChange }) {
  const taskSheetUserName = useSelector(selectTaskSheet).userId
  const taskSheetPassword = useSelector(selectTaskSheet).password
  const auth = {
      username: taskSheetUserName,
      password: taskSheetPassword
  }
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState({
    name: "All Departments",
  });

  useEffect(() => {
    taskAxios.get("/department", {auth: auth})
      .then((res) => {
        let data = res;
        data.unshift({ id: -1, name: "" });
        console.log(data);
        setDepartments(data);
    });
  }, []);

  return (
    <Listbox
      value={selectedDepartment}
      onChange={(department) => {
        console.log(department);
        onDepartmentChange(department.id);
        setSelectedDepartment(department);
      }}
    >
      <Listbox.Button className="w-[150px] h-8 border-2 border-slate-400 rounded-lg flex justify-between items-center p-2 text-sm flex-row space-x-3 text-slate-800 bg-white font-semibold shadow-md hover:brightness-95">
        <div className="text-xs">
          {selectedDepartment.name === ""
            ? "All Departments"
            : selectedDepartment.name}
        </div>
        <ChevronDownIcon className="ml-2 -mr-1 h-4 w-4" aria-hidden="true" />
      </Listbox.Button>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Listbox.Options className="absolute rounded-md mt-1 z-50 w-56 h-fit max-h-56 overflow-auto  origin-bottom-right divide-y divide-gray-100  bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {departments.map((department) => {
            if (department.name === "") {
              return (
                <Listbox.Option
                  className="cursor-pointer p-2 hover:bg-orange-100 text-xs text-orange-400 font-bold"
                  key={department.id}
                  value={department}
                >
                  All Departments
                </Listbox.Option>
              );
            } else {
              return (
                <Listbox.Option
                  className="cursor-pointer p-2 hover:bg-orange-100 text-xs"
                  key={department.id}
                  value={department}
                >
                  {department.name}
                </Listbox.Option>
              );
            }
          })}
        </Listbox.Options>
      </Transition>
    </Listbox>
  );
}
