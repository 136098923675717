export const columns = [
  {
    Header: "Name",
    accessor: "asignee",
  },
  {
    Header: "Activity",
    accessor: "activity",
  },
  {
    Header: "Task",
    accessor: "task",
  },
  {
    Header: "Remarks",
    accessor: "remarks",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Start Time",
    accessor: "startTime",
  },
  {
    Header: "End Time",
    accessor: "endTime",
  },
  {
    Header: "Time Spent",
    accessor: "timeSpent",
  },
];
