import DataGrid from "components/data-grid/DataGrid";
import Widget from "components/widget/Widget";
import React from "react";

export default function EmployeeJobHistory({ Data }) {
  return (
    <Widget heading={"Employee Job History"}>
      <DataGrid
        showHeader={true}
        columns={[
          {
            Header: "Department",
            accessor: "department",
          },
          {
            Header: "Designation",
            accessor: "designation",
          },
          {
            Header: "Place of Work",
            accessor: "placeOfWork",
          },
          {
            Header: "Start Date",
            accessor: "startDate",
          },
          {
            Header: "End Date",
            accessor: "endDate",
          },
        ]}
        testData={Data}
        cellFunction={(cell) => {
          return cell?.value ?? "N" + "A";
        }}
      />
    </Widget>
  );
}
