import { PlusCircleIcon } from "@heroicons/react/outline";
import Widget from "components/widget/Widget";
import React from "react";
import { Link } from "react-router-dom";
import SecondaryButton from "ui/buttons/SecondaryButton";

export default function EmployeeContacts({ contacts }) {
  let data = [];
  let types = [];

  contacts?.map((address) => {
    types.push(address.type);
  });
  // remove repated types
  types = [...new Set(types)];

  types.map((type) => {
    data.push({
      type: type,
      phone:
        contacts?.filter(
          (contact) => contact.contactType == "Phone" && contact.type == type
        )[0]?.value ?? "N/A",
      email:
        contacts?.filter(
          (contact) => contact.contactType == "Email" && contact.type == type
        )[0]?.value ?? "N/A",
      mobile:
        contacts?.filter(
          (contact) => contact.contactType == "Mobile" && contact.type == type
        )[0]?.value ?? "N/A",
    });
  });

  let url = window.location.pathname;
  return (
    <Widget
      heading={"Contacts"}
      // component={function () {
      //   return (
      //     <div className="flex flex-col space-y-3">
      //       <SecondaryButton>
      //         <button className="text-orange-600">Edit Employee Address</button>
      //       </SecondaryButton>
      //     </div>
      //   );
      // }}
    >
      <div className="grid grid-cols-4 gap-3">
        {data?.map((address, index) => {
          return (
            <div
              className={`min-h-[30vh] ${
                address.isDefault
                  ? " shadow-md rounded-xl  divide-slate-300"
                  : "divide-slate-200 shadow-sm"
              } rounded-xl border border-slate-100 p-3 divide-y `}
              key={index}
            >
              <div className="flex flex-row justify-between pb-1 items-center">
                <h1 className="capitalize text-sm font-bold ">
                  {address.type ?? "N/A"}
                </h1>
                {address.isDefault && (
                  <div className="flex flex-row items-center space-x-1">
                    <p className="text-xs text-white px-4 py-1 bg-orange-400 rounded-xl ">
                      Default
                    </p>
                  </div>
                )}
              </div>

              <div
                className={`grid grid-rows-3 p-2 grid-flow-col gap-2 text-sm`}
              >
                {Object.keys(address).map((key, index) => {
                  if (key === "type" || key === "isDefault") {
                    return;
                  }
                  return (
                    <div className="row-span-1 flex flex-col space-y-1.5">
                      {key === "isDefault" ? (
                        <div className="capitalize text-xs text-slate-500"></div>
                      ) : (
                        <>
                          <div className="capitalize text-xs text-slate-500">
                            <p>
                              {key
                                .replace(/([A-Z])/g, " $1")
                                .replace(/^./, function (str) {
                                  return str.toUpperCase();
                                })}
                            </p>
                          </div>
                          <div className=" font-semibold">
                            <p>{address[key]}</p>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        <Link
          to={`${url}/Add/Type=Contact&Modal=true`}
          className="flex col-span-1 hover:bg-orange-50  rounded-xl w-full h-full items-center justify-center"
        >
          <div className="rounded-xl text-sm space-y-2 items-center text-orange-600 justify-center flex flex-col  p-3 ">
            <PlusCircleIcon className="h-10 w-10 " />
            <p>Add a new Contact</p>
          </div>
        </Link>
      </div>
    </Widget>
  );
}
