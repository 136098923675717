import DataGrid from "components/data-grid/DataGrid";
import Widget from "components/widget/Widget";
import {
  selectSelectedEmployees,
  setSelectedEmployees,
} from "features/groups/GroupsSlice";
import customAxios from "middlewares/axios-interceptor/customAxios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";

export default function SelectEmployees() {
  let dispatch = useDispatch();
  const selectedEmployees = useSelector(selectSelectedEmployees);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const handleSelection = React.useCallback((value) => {
    setSelectedRows(value);
  }, []);
  let [data, setData] = React.useState([]);
  let [loading, setLoading] = React.useState(true);
  let [preSelectedItems, setPreSelectedItems] = React.useState({
    0: true,
  });

  let columns = [
    {
      name: "Employee Code",
      accessor: "empCode",
    },
    {
      name: "Name",
      accessor: "name",
    },
    {
      name: "Designation",
      accessor: "designation",
    },
    {
      name: "Department",
      accessor: "department",
    },
  ];

  React.useEffect(() => {
    customAxios.get("/employee").then((res) => arrangeData(res.items));
  }, []);

  async function arrangeData(items) {
    let tempData = [];
    items?.map((d) => {
      selectedEmployees?.map((e) => {
        if (e.id == d.id) {
          setPreSelectedItems((prev) => {
            return {
              ...prev,
              [d.id]: true,
            };
          });
        }
      });

      tempData.push({
        id: d.id ?? "N/A",
        name: d.name ?? "N/A",
        empCode: d.id ?? "N/A",
        identificationNumber: d.idNumber ?? "N/A",
        dateOfBirth: d.dob ?? "N/A",
        nationality: d.country ?? "N/A",
        designation: d.designation ?? "N/A",
        department: d.department ?? "N/A",
        email: d.email ?? "N/A",
        empType: d.employeeType ?? "N/A",
        status: true ?? "N/A",
        dateJoined: d.dateJoined ?? "N/A",
      });
    });

    localStorage.setItem("employees", JSON.stringify(tempData));

    setData(tempData);
    setLoading(false);
  }

  function onSubmit() {
    dispatch(setSelectedEmployees(selectedRows.map((d) => d.original)));
  }

  function cellFunction(cell) {
    if (cell.column.id == "name") {
      return (
        <div className="flex flex-row space-x-3 items-center">
          <img
            className="h-8 w-8 rounded-full"
            src={"https://www.mydthpay.com/img/review.png"}
          />
          <Link
            to={`/employees/view/employee-id=${cell.row.values.id}&name=${cell.row.values.name}/overview`}
            className="text-orange-800 hover:cursor-pointer hover:underline"
          >
            {cell.value}
          </Link>
        </div>
      );
    }
    return cell.render("Cell");
  }

  // find index of selected row items from data array

  return (
    <div className="flex flex-col space-y-2">
      <Widget heading={"Select Employees"}>
        <div className="text-sm flex flex-row space-x-3 items-center font-semibold my-1">
          <span className="bg-orange-200 text-orange-600 p-2 rounded-full w-fit px-4 ">
            {selectedEmployees.length}
          </span>{" "}
          <p>Employees Selected</p>
        </div>
        {/* 
        <code>{JSON.stringify(selectedEmployees)}</code> */}

        <div>
          <DataGrid
            search={true}
            height={"30vh"}
            columns={columns}
            testData={data ?? []}
            selectable={false}
            cellFunction={cellFunction}
            setSelectedRows={handleSelection}
            preSelectedItems={preSelectedItems}
          />
        </div>
      </Widget>
      <div>
        <button
          className="px-6 p-1.5 w-fit text-sm border border-slate-300 flex items-center justify-center text-slate-50 bg-orange-400 font-semibold rounded-xl shadow-md hover:brightness-95"
          onClick={() => onSubmit()}
        >
          Next
        </button>
      </div>
    </div>
  );
}
