import { Doughnut } from "react-chartjs-2";
import ChartContainer from "../charts-container/ChartsContainer";
import { normaliseDatasets } from "../HelperFunctions";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables)

export default function DoughnutChart ({labels, title, className, datasets}) {
    const normalisedDatasets = normaliseDatasets(datasets);

    const dataConfig = {
        datasets: normalisedDatasets,
        labels: labels
    }
    return (
        <Doughnut data={dataConfig}/>
    )
}


DoughnutChart.defaultProps = {
    title: "",
    data: [],
    labels: [],
    datasets: []
}