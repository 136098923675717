import React from "react";
import { Outlet } from "react-router-dom";
import GlobalLayout from "layout/GlobalLayout";
import MenuItems from "./MenuItems";
import { useDispatch } from "react-redux";

export default function Index() {
  return (
    <GlobalLayout
      mainTitle={"Settings"}
      MenuItems={MenuItems}
      selectedMenu={MenuItems[0]}
    >
      <Outlet />
    </GlobalLayout>
  );
}
