import StepModal from "components/step-modal/StepModal";
import {
  setBreadCrumbs,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import React from "react";
import { useDispatch } from "react-redux";
import EditGeneratedAttendance from "./steps/edit-generated-attendance/EditGeneratedAttendance";
import SelectEmployees from "./steps/select-employees/SelectEmployees";

let tabData = [
  {
    index: 1,
    name: "Select Employees",
    component: <SelectEmployees />,
  },
  {
    index: 2,
    name: "Edit Generated Attendance",
    component: <EditGeneratedAttendance />,
  },
  {
    index: 3,
    name: "Review & Submit",
    component: <div>Review</div>,
  },
];

export default function GenerateAttendance() {
  let [selectedTab, setSelectedTab] = React.useState(tabData[0]);

  let dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setSubHeader("Generate Attendance"));
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Attendance", link: "/Attendance/overview" },
      ])
    );
  }, []);

  return (
    <div className="flex flex-col space-y-2">
      <StepModal
        TabData={tabData}
        SelectedTab={selectedTab}
        SetSelectedTab={setSelectedTab}
      />
    </div>
  );
}
