import { setBreadCrumbs } from "features/navigation/NavigationSlice";
import customAxios from "middlewares/axios-interceptor/customAxios";
import React from "react";
import { useDispatch } from "react-redux";
import { json, Outlet, useParams } from "react-router-dom";
import EmployeeDocumentExpiry from "widgets/employee/employee-card/EmployeeDocumentExpiry";
import EmployeeDocuments from "widgets/employee/employee-card/EmployeeDocuments";
import Widget from "components/widget/Widget";
import DatePickerMain from "components/date-picker-v2/DatePickerMain";
import dayjs from "dayjs";
import DataGrid from "components/data-grid/DataGrid";
import PrimaryButton from "ui/buttons/PrimaryButton";
import axios from "axios";


var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)

function CustomTextArea({ value, onChange, onBlur }) {
    return (
        <textarea
            className="h-full w-full border-none resize-none font-mono text-xs"
            spellCheck="false"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
        />
    )
}

function JsonStringifyCustom(obj) {
    return obj === undefined || obj === null ? "No Data" : JSON.stringify(obj, null, 2);
}


export default function ViewEmployeePayroll() {
    let { Name, EmployeeId } = useParams();

    let [startDate, setStartDate] = React.useState(new Date(2023, 3, 1));
    let [endDate, setEndDate] = React.useState(new Date(2023, 3, 30));


    let [selectedEmployee, setSelectedEmployee] = React.useState();
    let [scheduleLogs, setScheduleLogs] = React.useState([]);
    let [payrollParametersText, setPayrollParametersText] = React.useState("{}");
    let [payrollParametersObj, setPayrollParametersObj] = React.useState({});
    // let [attendanceSummaryText, setAttendanceSummaryText] = React.useState("{}");
    // let [attendanceSummaryObj, setAttendanceSummaryObj] = React.useState("{}");
    let [customParameters, setCustomParameters] = React.useState(
        JsonStringifyCustom(
            {
                "DaysAbsent": 0,
                "DaysSick": 0,
                "DaysAnnual": 0,
                "DaysPresent": 0,
                "MintuesLate": 0,
                "MintuesAbsent": 0,
            }
        )
    );
    let [payItemsText, setPayItemsText] = React.useState("[]");
    let [payItemsObj, setPayItemsObj] = React.useState([]);
    let [generatedPayroll, setGeneratedPayroll] = React.useState([]);
    let [generatedPayrollIsLoading, setGeneratedPayrollIsLoading] = React.useState(false);

    let dispatch = useDispatch();

    React.useEffect
        (
            () => {
                customAxios
                    .get(`https://api.echeshr.com/api/v2/employee/profile/${EmployeeId}`)
                    .then
                    (
                        (res) => { setSelectedEmployee(res) },
                        (err) => console.log(err)
                    );

                const routeGetScheduleLogs = `/employee/attendance?Page=1&Size=100&IsSortAscending=true&Id=${EmployeeId}&From=${dayjs(startDate).format("YYYY-MM-DD")}&To=${dayjs(endDate).format("YYYY-MM-DD")}`;
                customAxios
                    .get(routeGetScheduleLogs)
                    .then
                    (
                        (res) => {
                            // console.log("API RESPONSE:\n==============\n\n", res)
                            setScheduleLogs(res?.items ?? []);
                        }
                    );

            },
            []
        );


    React.useEffect(
        () => {
            console.log("UE EMP:", selectedEmployee)
            if (selectedEmployee === undefined) { return }
            scopeStuffToDate();
        },
        [selectedEmployee]
    )

    React.useEffect(
        () => {
            console.log("UE START END", startDate, endDate);
            scopeStuffToDate();
        },
        [startDate, endDate]
    )

    // NOT EXACTLY WHAT IS NEEDED >>> ONLY DO THIS AT INITIAL LOAD >>> OTHERWISE ON BLUR >>> YET TO FIGURE THIS
    React.useEffect(
        () => {
            console.log("UE AS CP PIT")
            generateCustomPayroll();
        },
        [payrollParametersObj, payItemsObj]
        // [attendanceSummaryText, customParameters, payItemsText]
    )

    // React.useEffect(() => {
    //     dispatch(
    //         setBreadCrumbs([
    //             { name: "Home", link: "/" },
    //             { name: "Employees", link: "/employees/all" },
    //             { name: `${Name}`, link: `` },
    //             { name: "Documents", link: `` },
    //         ])
    //     );
    // }, []);

    function scopeStuffToDate() {
        console.log("FUNC ScopeToDate")
        if (selectedEmployee === undefined) { return }



        customAxios
            .get(`https://api.echeshr.com/api/v2/employee/payroll-meta-info?EmployeeId=${EmployeeId}&From=${dayjs(startDate).format('YYYY-MM-DD')}&To=${dayjs(endDate).format('YYYY-MM-DD')}&ScheduleType=&DepartmentId=`)
            .then
            (
                (res) => {
                    const why = Object.keys(res.payrollParameters).reduce((accu, next) => ({ ...accu, [next.charAt(0).toUpperCase() + next.slice(1)]: res.payrollParameters[next] }), {})
                    setPayrollParametersObj(why)
                    setPayrollParametersText(JsonStringifyCustom(why))

                    setPayItemsObj(res.payrollStructure)
                    setPayItemsText(JsonStringifyCustom(res.payrollStructure))
                },
                (err) => console.log(err)
            );

        // let PayrollParameters =
        //     selectedEmployee.attendances.find(x => {
        //         return dayjs(endDate).isSameOrAfter(dayjs(x.startDate)) && dayjs(x.endDate).isSameOrAfter(dayjs(startDate))
        //     }
        //     );


        // PayrollParameters =
        //     PayrollParameters === undefined
        //         ?
        //         {
        //             EarlyArrival: 0,
        //             EarlyDeparture: 0,
        //             LateArrival: 0,
        //             LateDeparture: 0,
        //             LateMinutes: 0,
        //             OvertimeMinutes: 0,
        //             TotalDays: 1,
        //             TotalWorkingDays: 1,
        //             TotalHolidays: 0,
        //             TotalDaysOnLeave: 0,
        //             TotalWorkMinutes: 1
        //         }
        //         :
        //         {
        //             EarlyArrival: PayrollParameters.earlyArrival,
        //             EarlyDeparture: PayrollParameters.earlyDeparture,
        //             LateArrival: PayrollParameters.lateArrival,
        //             LateDeparture: PayrollParameters.lateDeparture,
        //             LateMinutes: PayrollParameters.lateMinutes,
        //             OvertimeMinutes: PayrollParameters.overtimeMinutes,
        //             TotalDays: PayrollParameters.totalDays,
        //             TotalWorkingDays: PayrollParameters.totalWorkingDays,
        //             TotalHolidays: PayrollParameters.totalHolidays,
        //             TotalDaysOnLeave: PayrollParameters.totalDaysOnLeave,
        //             TotalWorkMinutes: PayrollParameters.totalWorkMinutes
        //         }
        // // console.log("SELECTED ATTENDANCE SUMMARY:", PayrollParameters)
        // // setAttendanceSummaryObj(PayrollParameters)
        // // setAttendanceSummaryText(JsonStringifyCustom(PayrollParameters));


        // let selectedPayItems;
        // if (selectedEmployee.allowances.length > 0) {
        //     selectedPayItems =
        //         selectedEmployee.allowances.map(
        //             x => ({
        //                 id: x.allowance.Id,
        //                 name: x.allowance.name,
        //                 description: x.allowance.description,
        //                 amount: x.amount,
        //                 currency: x.currency,
        //                 isTaxApplicable: false,
        //                 isShownInPaySlip: true,
        //                 baseAmount: x.allowance.baseAmount,
        //                 allowanceType: x.allowance.allowanceType,
        //                 query: x.allowance.query
        //             })
        //         );
        // }
        // else {
        //     selectedPayItems =
        //         [
        //             {
        //                 name: "Basic Pay",
        //                 description: null,
        //                 amount: 7500,
        //                 currency: "MVR",
        //                 isTaxApplicable: false,
        //                 isShownInPaySlip: true,
        //                 baseAmount: 0,
        //                 allowanceType: "Income",
        //                 query: "declare @Formula decimal = 1.0 * (@TotalDays) / (@TotalDays) * @Amount; select \r\n\t@Type as Allowance\r\n\t,462 as Currency\r\n\t,GETDATE() as [Date]\r\n\t,@Formula as Total"
        //             }
        //         ]
        // }
        // setPayItemsObj(selectedPayItems);
        // setPayItemsText(JsonStringifyCustom(selectedPayItems));
    }

    function generateDefaultPayroll() {
        if (generatedPayrollIsLoading) { return; }

        setGeneratedPayrollIsLoading(true);

        const routeGeneratePayroll = `https://api.echeshr.com/api/v2/employee/payroll-live?EmployeeId=${EmployeeId}&From=${dayjs(startDate).format("YYYY-M-D")}&To=${dayjs(endDate).format("YYYY-M-D")}&ScheduleType=${""}&DepartmentId=${""}`
        customAxios
            .get(routeGeneratePayroll)
            .then
            (
                (res) => {
                    setGeneratedPayroll(res);
                    setGeneratedPayrollIsLoading(false);
                }
            )
            .catch
            (
                (err) => {
                    setGeneratedPayroll(err)
                }
            )
            .finally
            (
                async (x) => {
                    await new Promise(r => setTimeout(r, 1500));
                    setGeneratedPayrollIsLoading(false);
                }
            );
    }

    function generateCustomPayroll() {
        console.log("FUNCT CustomPayroll")
        // if (generatedPayrollIsLoading) { console.log("FUNCT CustomPayroll BREK"); return; }

        setGeneratedPayrollIsLoading(true);

        const routeGeneratePayroll = `https://api.echeshr.com/api/v2/employee/payroll-experimental?EmployeeId=${EmployeeId}&From=${dayjs(startDate).format("YYYY-M-D")}&To=${dayjs(endDate).format("YYYY-M-D")}&ScheduleType=${""}&DepartmentId=${""}`
        console.log("PAYROLL ROUTE", routeGeneratePayroll)
        const payload =
        {
            Parameters: {
                ...payrollParametersObj,
                ...JSON.parse(customParameters)
            },
            Allowances: [
                ...payItemsObj
            ]
        }
        // console.log(payload)

        customAxios
            .post(routeGeneratePayroll, payload)
            .then
            (
                (res) => {
                    setGeneratedPayroll(res)
                }
            )
            .catch
            (
                (err) => {
                    setGeneratedPayroll(err)
                }
            )
            .finally
            (
                async (x) => {
                    await new Promise(r => setTimeout(r, 1500));
                    setGeneratedPayrollIsLoading(false);
                }
            );

    }


    return (
        <div className="h-full w-full min-h-0 min-w-0 grid gap-2 grid-cols-3 grid-rows-6" >
            <div className="col-span-3 row-span-1">
                <Widget heading={"Payroll Settings"}>
                    <div className="flex">
                        <div>
                            <label className="font-semibold text-sm pl-1" htmlFor="">Start Date</label>
                            <DatePickerMain
                                selectedDateChild={dayjs(startDate).format("DD/MM/YYYY")}
                                value={startDate}
                                onDateChange={e => { setStartDate(e); console.log(startDate); }}
                            />
                        </div>
                        <div className="ml-10">
                            <label className="font-semibold text-sm pl-1" htmlFor="">End Date</label>
                            <DatePickerMain
                                selectedDateChild={dayjs(endDate).format("DD/MM/YYYY")}
                                value={endDate}
                                onDateChange={e => { setEndDate(e); console.log(endDate); }}
                            />
                        </div>
                        <div className="ml-auto">
                            <label className="font-semibold text-sm pl-1" htmlFor=""></label>
                            <div>
                                <PrimaryButton
                                    type={"submit"}
                                    title={"Generate Custom"}
                                    onClick={generateCustomPayroll}
                                />
                                {/* <button className="bg-orange-500 font-bold text-white p-5 rounded-lg">Generate</button> */}
                            </div>
                        </div>
                        <div className="ml-10">
                            <label className="font-semibold text-sm pl-1" htmlFor=""></label>
                            <div>
                                <PrimaryButton
                                    type={"submit"}
                                    title={"Generate Default"}
                                    onClick={generateDefaultPayroll}
                                />
                                {/* <button className="bg-orange-500 font-bold text-white p-5 rounded-lg">Generate</button> */}
                            </div>
                        </div>
                        {/* <button onClick={() => console.log(startDate)}>Startdate</button> */}
                    </div>
                </Widget>
            </div>
            <div className="col-span-1 row-span-2">
                <Widget heading={"Attendance Summary"}>
                    <CustomTextArea
                        value={payrollParametersText}
                        onChange={e => setPayrollParametersText(e.target.value)}
                        onBlur={e => payrollParametersObj(JSON.parse(e.target.value))}
                    />
                </Widget>
            </div>
            <div className="col-span-1 row-span-4">
                <Widget heading={"Pay Items"}>
                    <CustomTextArea
                        value={payItemsText}
                        onChange={e => setPayItemsText(e.target.value)}
                        onBlur={e => { setPayItemsObj(JSON.parse(e.target.value)) }}
                    />
                </Widget>
            </div>
            <div className="col-span-1 row-span-4">
                <Widget heading={"Payroll"}>
                    <div className="flex flex-col h-full w-full overflow-auto">
                        <div className="">
                            <div className="text-xl font-bold font-mono ml-4">Allowances</div>
                            <hr className="my-2" />
                            <div className="flex flex-col font-mono">
                                {
                                    payItemsObj.map(allowance =>
                                        <div className="flex items-center px-4">
                                            <div className="w-full max-w-md font-semibold">{allowance.name}</div>
                                            <div className="w-full max-w-sm text-right">{allowance.amount}</div>
                                        </div>
                                    )
                                }
                                <hr className="my-2" />
                                <div className="flex items-center px-4">
                                    <div className="w-full max-w-md font-bold">{"TOTAL ALLOWANCE"}</div>
                                    <div className="w-full max-w-sm text-right font-semibold">{payItemsObj.reduce((accu, next) => next.amount > 0 ? accu + next.amount : accu, 0.0)}</div>
                                </div>
                            </div>
                            <hr className="my-2" />
                        </div>
                        <div className="mt-20">
                            <div className="text-xl font-bold font-mono ml-4">Generated Payroll</div>
                            <hr className="my-2" />
                            {
                                generatedPayrollIsLoading
                                    ?
                                    <div className="h-full w-full bg-slate-300 rounded-lg animate-pulse"></div>
                                    :
                                    generatedPayroll.constructor === Array
                                        ?
                                        <div className="flex flex-col font-mono">
                                            {
                                                generatedPayroll.map(x =>
                                                    <div className="flex items-center px-4">
                                                        <div className="w-full max-w-md font-semibold">{x.allowance}</div>
                                                        <div className="w-full max-w-sm text-right">{x.total.toFixed(4)}</div>
                                                    </div>
                                                )
                                            }
                                            <hr className="my-2" />
                                            <div className="flex items-center px-4">
                                                <div className="w-full max-w-md font-bold">{"GROSS PAY"}</div>
                                                <div className="w-full max-w-sm text-right font-semibold">{generatedPayroll.reduce((accu, next) => accu + next.total, 0.0).toFixed(4)}</div>
                                            </div>
                                        </div>
                                        :
                                        <div className="font-mono text-xs whitespace-pre-wrap px-4">
                                            {JsonStringifyCustom(generatedPayroll)}
                                        </div>

                            }
                            <hr className="my-2" />
                        </div>
                    </div>
                </Widget>
            </div>
            <div className="col-span-1 row-span-2">
                <Widget heading={"Custom Params"}>
                    <CustomTextArea
                        value={customParameters}
                        onChange={e => setCustomParameters(e.target.value)}
                        onBlur={generateCustomPayroll}
                    />
                </Widget>
            </div>
            <div className="col-span-3 row-span-1">
                <Widget heading={"Attendance Logs"}>
                    <div className="h-full overflow-auto">
                        <DataGrid
                            showHeader={true}
                            search={true}
                            columns={[
                                {
                                    accessor: "date",
                                    Header: "Date",
                                },
                                { accessor: "employeeId", Header: "Employee ID" },
                                { accessor: "employeeName", Header: "Employee Name" },
                                { accessor: "start", Header: "Start Time" },
                                { accessor: "end", Header: "End Time" },
                                { accessor: "checkIn", Header: "Check In" },
                                { accessor: "checkOut", Header: "Check Out" },
                                {
                                    accessor: "dayType",
                                    Header: "Schedule Type",
                                },
                                {
                                    accessor: "status",
                                    Header: "Status",
                                },
                            ]}
                            testData={scheduleLogs ?? []}
                            selectable={false}
                            cellFunction={(cell) => {
                                return cell.render("Cell");
                            }}
                        />
                    </div>
                </Widget>
            </div>
        </div>
    );
}