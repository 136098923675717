import * as Yup from "yup"
import { postAllowances } from "middlewares/axios-interceptor/quickEndpoints"

export const formData = {
    contents: [
        {
            heading: "Add an Allowance",
            fields: [
                {
                    name: "name",
                    displayName: "Name",
                    type: "field",
                    inputType: "text",
                    required: true
                },
                {
                    name: "description",
                    displayName: "Description",
                    type: "field",
                    inputType: "text",
                    required: false
                },
                {
                    name: "amount",
                    displayName: "Amount",
                    type: "field",
                    inputType: "number",
                    required: true
                },
                {
                    name: "currency",
                    displayName: "Currency",
                    type: "field",
                    inputType: "text",
                    required: true
                },
                {
                    name: "baseAmount",
                    displayName: "Base Amount",
                    type: "field",
                    inputType: "number",
                    required: true
                },
                {
                    name: "allowanceType",
                    displayName: "Allowance Type",
                    type: "field",
                    inputType: "text",
                    required: true
                },
                {
                    name: "query",
                    displayName: "Query",
                    type: "field",
                    inputType: "text",
                },
            ]
        }
    ],
    initialValues: {
        name: "",
        description: "",
        currency: "MVR",
        allowanceType: "",
    },
    validationSchema: Yup.object().shape({
        name: Yup.string().required("Required"),
        description: Yup.string(),
        amount: Yup.number().required("Required"),
        currency: Yup.string().required("Required"),
        baseAmount: Yup.number().required("Required"),
        allowanceType: Yup.string().required("Required"),
        query: Yup.string()
    }),
    onSubmit: async (values) => {
        values.isTaxApplicable = true
        values.isShownInPaySlip = true
        values.query = "select @Type as Allowance ,462 as Currency ,GETDATE() as [Date] ,( ((1.0 * @LateMinutes) / @TotalWorkMinutes) * @Amount) as Total)"
        await postAllowances(values)
    }
}