import { useDispatch } from "react-redux"
import { setSelectedSubMenu } from "features/navigation/NavigationSlice"
import MenuItems from "../MenuItems"
import Widget from "components/widget/Widget"
import { useEffect } from "react"

const CalendarSettings = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setSelectedSubMenu(MenuItems.find(x => x.title === "Calendar Settings")))
    }, [])

    return (
        <div className="flex flex-col gap-y-1 h-full w-full overflow-auto">
            <Widget></Widget>
        </div>
    )
}

export default CalendarSettings