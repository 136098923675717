import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import "./index.css";
import { PageRoutes } from "routes/routes";
import { BrowserRouter } from "react-router-dom";

const container = document.getElementById("root");
const root = createRoot(container);
// store.dispatch(fetchEmployees());
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <PageRoutes />
    </BrowserRouter>
  </Provider>
);
