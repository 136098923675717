import React from "react";
import * as Yup from "yup";
import FormGenerator from "../../../../components/form-generator/FormGenerator";
import {
  addPersonal,
  selectPersonal,
} from "../../../../features/employees/EmployeesSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

let countries = JSON.parse(localStorage.getItem("countries"));
let variables = JSON.parse(localStorage.getItem("variables")) ?? [];
let suffix = variables?.filter((x) => x.key == "Title");
let MaritalStatuses = variables?.filter((x) => x.key == "MaritalStatus");
let Banks = variables?.filter((x) => x.key == "Bank");
let genders = variables?.filter((x) => x.key == "Gender");

export default function PersonalInfo({ progress }) {
  let personal = useSelector(selectPersonal);
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const formData = {
    contents: [
      {
        heading: "Personal Information",
        description: "Something about adding personal Information",
        function: () => {
          return <div></div>;
        },
        fields: [
          {
            name: "profilePicture",
            displayName: "Profile Picture",
            type: "pictureUpload",
            value: (formik) => {
              return formik.values.profilePicture;
            },
          },
          {
            name: "firstName",
            displayName: "First Name",
            type: "field",
            inputType: "text",
            required: true,
          },
          {
            name: "middleName",
            displayName: "Middle Name",
            type: "field",
            inputType: "text",
          },
          {
            name: "lastName",
            displayName: "Last Name",
            type: "field",
            inputType: "text",
            required: true,
          },
          {
            name: "suffix",
            displayName: "Suffix",
            type: "comboBox",
            inputType: "text",
            required: true,
            value: (formik) => {
              return formik.values.suffix;
            },
            values: suffix,
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span>{item.name}</span>
                </div>
              );
            },
          },
          {
            name: "gender",
            displayName: "Gender",
            type: "comboBox",
            inputType: "text",
            required: true,
            value: (formik) => {
              return formik.values.gender;
            },
            values: genders,
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span>{item.name}</span>
                </div>
              );
            },
          },
          {
            name: "nationality",
            displayName: "Nationality",
            type: "comboBox",
            value: (formik) => {
              return formik.values.nationality;
            },
            values: countries ?? [
              { id: 1, name: "Maldives" },
              { id: 2, name: "China" },
              { id: 2, name: "Russia" },
            ],
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <img className="w-6 h-5 col-span-2" src={item.flag} />
                  <span className="col-span-9">{item.demonym}</span>
                </div>
              );
            },
          },
          {
            name: "nationalIdentificationNumber",
            displayName: "NIC/Passport Number",
            type: "field",
            inputType: "text",
            required: true,
          },
          {
            name: "dateOfBirth",
            displayName: "Date of Birth",
            type: "dateSelect",
            default: new Date(),
            inputType: "text",
            value: (formik) => {
              return formik.values.dateOfBirth;
            },
            required: true,
          },

          {
            name: "maritalStatus",
            displayName: "Marital Status",
            type: "comboBox",
            inputType: "text",
            required: true,
            value: (formik) => {
              return formik.values.maritalStatus;
            },
            values: MaritalStatuses,
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-10">{item?.name}</span>
                </div>
              );
            },
          },
        ],
      },
      {
        heading: "Banking Information",
        description:
          "Only the primary bank account to what the salary will be deposited",
        fields: [
          {
            name: "bankName",
            displayName: "Bank",
            type: "comboBox",
            value: (formik) => {
              return formik.values.bankName;
            },
            values: Banks,
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },
          {
            name: "bankAccountName",
            displayName: "Account Name",
            type: "field",
            inputType: "text",
            required: true,
          },
          {
            name: "bankAccountNumber",
            displayName: "Account Number",
            type: "field",
            inputType: "text",
            required: true,
          },
        ],
      },
      {
        heading: "Create A User",
        description:
          "Provide the following info to create a user for the employee",
        fields: [
          {
            name: "user_email",
            displayName: "User Email",
            type: "field",
            inputType: "text",
            required: false,
          },
        ],
      },
    ],
    initialValues: {
      profilePicture: personal?.data?.profilePicture ?? "",
      firstName: personal?.data?.firstName ?? "",
      middleName: personal?.data?.middleName ?? "",
      lastName: personal?.data?.lastName ?? "",
      suffix: personal?.data?.suffix ?? {},
      gender: personal?.data?.gender ?? {},
      nationalIdentificationNumber:
        personal?.data?.nationalIdentificationNumber ?? "",
      dateOfBirth: personal?.data?.dateOfBirth ?? "",
      nationality: personal?.data?.nationality ?? {},
      maritalStatus: personal?.data?.maritalStatus ?? {},
      bankName: personal?.data?.bankName ?? {},
      bankAccountName: personal?.data?.bankAccountName ?? "",
      bankAccountNumber: personal?.data?.bankAccountNumber ?? "",
    },
    validationSchema: Yup.object().shape({
      profilePicture: Yup.string().required("Profile Picture is required"),
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      suffix: Yup.object().required("Suffix is required"),
      middleName: Yup.string().required("Middle Name is required"),
      gender: Yup.object().required("Gender is required"),
      nationality: Yup.object().required("Nationality is required"),
    }),
    onSubmit: async (
      values,
      { setSubmitting, setErrors, setStatus, resetForm, handleSubmit }
    ) => {
      navigate("/Employees/Onboarding/Employment Details");
      dispatch(addPersonal(values));
      // resetForm();
      // dispatch(setSelectedTab(Steps[4]));
    },
  };

  return (
    <div className="pb-20 ">
      <FormGenerator height="h-fit " formData={formData} />
    </div>
  );
}
