import { setSelectedEmployee } from "features/employees/EmployeesSlice";
import {
  setBreadCrumbs,
  setNavigationButtons,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import TabLayout from "layout/TabLayout";
import customAxios from "middlewares/axios-interceptor/customAxios";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";
import SecondaryButton from "ui/buttons/SecondaryButton";
import ViewEmployeeDocuments from "./views/documents/ViewEmployeeDocuments";

let tabItems = [
  {
    title: "overview",
    path: "/overview",
  },
  {
    title: "personal",
    path: "/personal",
  },
  {
    title: "leaves",
    path: "/leaves",
  },
  {
    title: "Payroll",
    path: "/payroll",
  },
  {
    title: "Timesheet",
    path: "/Timesheet",
  },
  {
    title: "Task Sheet",
    path: "/TaskSheet",
  },
  {
    title: "Attendance",
    path: "/Attendance",
  },
  {
    title: "documents",
    path: "/documents",
  },
  {
    title: "loans",
    path: "/loans",
  },
  {
    title: "Employment",
    path: "/jobs",
  },

  // {
  //   title: "employment",
  //   component: <div>employment</div>,
  //   path: "/employment",
  // },

  // {
  //   title: "benefits",
  //   component: <div>benefits</div>,
  //   path: "/benefits",
  // },
  // {
  //   title: "payroll",
  //   component: <div>payroll</div>,
  //   path: "/payroll",
  // },

  // {
  //   title: "performance",
  //   component: <div>performance</div>,
  //   path: "/performance",
  // },
  // {
  //   title: "Groups",
  //   component: <div>training</div>,
  //   path: "/Groups",
  // },
];
export default function ViewEmployee() {
  let dispatch = useDispatch();
  let { Name, EmployeeId } = useParams();
  let [empData, setEmpData] = React.useState({});

  React.useEffect(() => {
    customAxios.get(`/employee/profile/${EmployeeId}`).then(
      (res) => dispatch(setSelectedEmployee(res)),
      (err) => console.log(err)
    );
  }, []);

  React.useEffect(() => {
    dispatch(setSubHeader(Name));
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Employees", link: "/employees/all" },
        { name: `View ${Name}`, link: `` },
      ])
    );
    dispatch(
      setNavigationButtons([
        <SecondaryButton>
          <Link
            to={`/employees/view/EmployeeId=${EmployeeId}&Name=${Name}/ResetPassword`}
          >
            Reset Password
          </Link>
        </SecondaryButton>,
      ])
    );
  }, []);

  return (
    <TabLayout
      rootPath={`/employees/view/EmployeeId=${EmployeeId}&Name=${Name}`}
      tabItems={tabItems}
    />
  );
}
