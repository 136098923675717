export const columns = [
  // {
  //   Header: "ID",
  //   accessor: "id",
  // },
  {
    Header: "Leave Type",
    accessor: "leaveType",
  },
  {
    Header: "Employee Name",
    accessor: "name",
  },
  {
    Header: "Employee Id",
    accessor: "empCode",
  },

  {
    Header: "Start",
    accessor: "startDate",
  },
  {
    Header: "End",
    accessor: "endDate",
  },
  {
    Header: "Working Days",
    accessor: "effectiveQty",
  },
  {
    Header: "Total Days",
    accessor: "totalDays",
  },
  {
    Header: "Remarks",
    accessor: "remarks",
    maxWidth: 100,
    minWidth: 20,
    width: 50,
  },
  {
    Header: "Status",
    accessor: "status",
  },
  // {
  //   Header: "Documents",
  //   accessor: "documents",
  // },
  {
    Header: "Applied On",
    accessor: "createdAt",
  },
];
