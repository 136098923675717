import DataGrid from "components/data-grid/DataGrid";
import DatePickerMain from "components/date-picker-v2/DatePickerMain";
import DatePickerV2 from "components/date-picker-v2/DatePickerV2";
import DateRangePickerMain from "components/date-picker-v2/DateRangePicker";
import Widget from "components/widget/Widget";
import { startOfYear } from "date-fns";
import {
  setBreadCrumbs,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import customAxios from "middlewares/axios-interceptor/customAxios";
import moment from "moment";
import DataTable from "pages/employees/view/views/leaves/data-grid/DataGrid";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

export default function ViewAttendanceDate() {
  let navigate = useNavigate();
  let { EmployeeId } = useParams();
  let [date, setDate] = React.useState(useParams().date);
  let [data, setData] = React.useState([]);
  let [rowData, setRowData] = React.useState([]);
  let [loading, setLoading] = React.useState(false);
  let from = startOfYear(new Date()).toISOString();
  let to = new Date("2022-12-25").toISOString();
  from = moment(from).format("YYYY-MM-DD");
  to = moment(to).format("YYYY-MM-DD");

  let dispatch = useDispatch();

  React.useEffect(() => {
    customAxios
      .get(
        `/attendance?From=${moment(date)
          .startOf("D")
          .format("YYYY-MM-DD")}&To=${moment(date)
          .endOf("D")
          .format("YYYY-MM-DD")}`
      )
      .then((res) => {
        setData(res.items);
        arrangeRowData(res.items);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    dispatch(setSubHeader(`Attendance on ${moment(date).format("LL")}`));
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Attendance", link: "/Attendance/All" },
        { name: "View", link: "" },
        {
          name: moment(date).format("LL"),
        },
      ])
    );
  }, [date]);

  function arrangeRowData(data) {
    let temp = [];
    data.map((item) => {
      let arrayItem = {
        employee: item.employee,
        designation: item.employee?.position?.name,
        department: item.department ?? "N/A",
        date: item.date,
        checkIn: item.checkIn,
        checkOut: item.checkOut,
        dayType: item?.scheduleType?.name,
        start: item?.start,
        end: item?.end,
      };
      temp.push(arrayItem);
    });
    setRowData(temp);
  }

  function cellFunction(cell) {
    if (cell.column.id === "employee") {
      return (
        <div
          onClick={() =>
            navigate(
              "/Attendance/ViewAttendanceOn/date=" +
                date +
                "&EmployeeId=" +
                cell.row.original.employee?.id
            )
          }
          className="flex items-center w-34 hover:bg-slate-200 rounded-full"
        >
          <img
            src={
              cell.row.original.employee?.avatar
                ? cell.row.original.employee?.avatar
                : "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50"
            }
            alt="avatar"
            className="w-8 h-8 rounded-full mr-2"
          />
          <div className="text-sm text-start">
            <p className="font-semibold">{cell.row.original.employee?.name}</p>
            <p className="text-xs text-gray-500">
              {cell.row.original.employee?.department ?? "N/A"} -{" "}
              {cell.row.original.employee?.position?.name ?? "N/A"} -{" "}
              {cell.row.original.employee?.id}
            </p>
          </div>
        </div>
      );
    }
    if (cell.column.id === "date") {
      return (
        <div className="flex text-center items-center w-34">
          <p className="text-center w-full">
            {moment(cell.row.original.date).format("DD MMM YYYY")}
          </p>
        </div>
      );
    }
    return cell.render("Cell");
  }

  return (
    <Widget
      heading={"Attendance"}
      subHeading={
        "View individual employee attendance details by clicking on the employee"
      }
    >
      <DatePickerMain
        selectedDateChild={moment(date).format("DD/MM/yyyy")}
        onDateChange={setDate}
      />
      <DataGrid
        showHeader={true}
        columns={[
          {
            Header: "Employee",
            accessor: "employee",
          },
          {
            Header: "Check In",
            accessor: "checkIn",
          },
          {
            Header: "Check Out",
            accessor: "checkOut",
          },
          {
            Header: "Day Type",
            accessor: "dayType",
          },
          {
            Header: "Start",
            accessor: "start",
          },
          {
            Header: "End",
            accessor: "end",
          },
          {
            Header: "Date",
            accessor: "date",
          },
        ]}
        testData={rowData}
        cellFunction={cellFunction}
        loading={loading}
        alignRow=""
        alignHeader={"s"}
      />
    </Widget>
  );
}
