import React from "react";
import Formik from "formik";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import Widget from "components/widget/Widget";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { alertClasses } from "@mui/material";

export default function FormCreator({
  initialValues,
  validationSchema,
  onSubmit,
  data,
}) {
  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  return (
    <div className="h-full">
      <FormikProvider value={formik}>
        <Form>
          {data?.map((d, i) => {
            return (
              <Widget key={i} heading={d.heading}>
                <div className="grid-rows-2 grid gap-2">
                  {d.fields.map((f, i) => {
                    return (
                      <div className="flex flex-col space-y-1.5">
                        <Field
                          name={f.name}
                          type={f.type}
                          component={f.component}
                          defaultValue={f.value}
                          className=" w-96 p-1 rounded-xl  focus:outline-none focus:ring-orange-500 pl-2 text-sm border border-slate-300"
                          placeholder={f.placeholder}
                        />
                        <ErrorMessage
                          name={f.name}
                          component="div"
                          style={{ fontSize: 11 }}
                          className="text-red-700 ml-2"
                        />
                      </div>
                    );
                  })}
                </div>
              </Widget>
            );
          })}

          <div className="mt-2">
            <PrimaryButton onClick={formik.submitForm} title={`Next`} />
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
}
