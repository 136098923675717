import DataGrid from "components/data-grid/DataGrid";
import Widget from "components/widget/Widget";
import React from "react";
import moment from "moment";

const columns = [
  {
    Header: "Leave Type",
    accessor: "leaveType",
  },

  {
    Header: "Start",
    accessor: "startDate",
  },
  {
    Header: "End",
    accessor: "endDate",
  },
  {
    Header: "Working Days",
    accessor: "effectiveQty",
  },
  {
    Header: "Remarks",
    accessor: "remarks",
    maxWidth: 100,
    minWidth: 20,
    width: 50,
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Applied On",
    accessor: "createdAt",
  },
];

export default function EmployeeLeaveList({ data }) {
  let [gridData, setGridData] = React.useState([]);

  React.useEffect(() => {
    let tempData = [];
    data.map((d) => {
      tempData.push({
        id: d.id,
        name: d.employee?.name,
        empCode: d.employeeId,
        leaveTypeId: d.leaveTypeId,
        leaveType: d.leaveType?.name,
        startDate: moment(d.from).format("D MMMM yyyy"),
        endDate: moment(d.to).format("D MMMM yyyy"),
        effectiveQty: Math.round(Math.abs(d.totalDays)),
        remarks: d.remarks,
        status: d.status ?? "Pending",
        documents: [],
        createdAt: moment(d.createdAt).format("D MMMM yyyy"),
      });
    });
    setGridData(tempData);
  }, [data]);

  function cellFunction(cell) {
    if (cell.column.id == "remarks") {
      return <div className="w-[200px] text-limit">{cell.value}</div>;
    } else if (cell.column.id == "status") {
      console.log(cell.value)
      return (
        <div className="flex justify-start items-center text-center ">
          <p
            style={{ backgroundColor: cell.value == "approved" ? "#22c55e" : cell.value == "rejected" ? "#f52c2c" : cell.value == "closed" ? "#808080" : "#f59d40" }}
            className="py-0.4 w-20 px-5 rounded-full text-white flex items-center justify-center"
          >
            {cell.value ?? "N/A"}
          </p>
        </div>
      );
    } else if (cell.column.id == "documents") {
      return (
        <div className="flex flex-col space-y-1 justify-start">
          {cell.value?.map((document, i) => (
            <div key={i}>
              <div className="text-orange-800 hover:underline cursor-pointer">
                {document.name}
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return cell.render("Cell");
    }
  }

  return (
    <Widget heading={"Employee Leaves"}>
      <DataGrid
        showHeader={true}
        cellFunction={cellFunction}
        columns={columns}
        testData={gridData}
        path={"/Leaves/View/id="}
      />
    </Widget>
  );
}
