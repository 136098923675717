import Widget from "components/widget/Widget";
import React from "react";
import SecondaryButton from "ui/buttons/SecondaryButton";
import { useNavigate } from "react-router-dom";

export default function EmployeeLeaveDetails({ data }) {
  let navigate = useNavigate();
  let url = window.location.pathname;

  return (
    <Widget heading={"Employee Leave Details"}>
      <div className="p-3">
        <div className="grid grid-rows-4 grid-flow-col gap-4 text-sm">
          {Object.keys(data).map((key, index) => {
            return (
              <div className="row-span-1 flex flex-col space-y-1.5">
                <div className="capitalize text-xs">
                  <p>
                    {key
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                  </p>
                </div>
                <div className=" font-semibold">
                  <p>{data[key]}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Widget>
  );
}
