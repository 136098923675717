import React, { Fragment, useEffect, useState } from "react";
import { PlusIcon, XIcon } from "@heroicons/react/solid";
import { columns } from "./columns";
import DataTable, { CONSTANTS as GridConstants } from "../../../components/data-grid/DataGrid";
import customAxios from "../../../middlewares/axios-interceptor/customAxios";
import GlobalLayout from "layout/GlobalLayout";
import DropDownButton from "ui/buttons/DropDownButton";
import {
  setBreadCrumbs,
  setSelectedSubMenu,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import { useDispatch } from "react-redux";
import { menuItems } from "./../MenuItems";
import Widget from "components/widget/Widget";
import PrimaryButton from "ui/buttons/PrimaryButton";
import FormGenerator from "components/form-generator/FormGenerator";
import { setOpen } from "features/notifications/NotificationSlice";
import SideDrawer from "components/side-drawer/SideDrawer";
import { v4 as uuidv4 } from 'uuid';
import Addresses from "pages/employees/add-employee/components/Addresses";
import * as Yup from "yup";




let init = JSON.parse(localStorage.getItem("init"));

export default function GroupDepartment() {
  const [open, setIsOpen] = React.useState(false);
  let [data, setData] = React.useState([]);
  let [loading, setLoading] = React.useState(false);
  // let [formData, setFormData] = React.useState({});
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedSubMenu(menuItems[2]))
    dispatch(setSubHeader(menuItems[2].title))
    customAxios.get("/group/type/department").then((res) => {
      setData(res);
      console.log("DATA", data)
    });
  }, []);


  let dropDownItems = init?.groupTypes ?? [];
  let [isVisibleAddDeparmentModal, setIsVisibleAddDeparmentModal] = useState(false);

  function cellFunction(cell) {
    return cell.render("Cell");
  }


  // const EditRenderProp = (setIsSideDrawerOpen, row) => {
  //   const formDataTemplate = {
  //     contents: [
  //       {
  //         heading: "Edit Department",
  //         description:
  //           "Edit Department Information",
  //         // function: () => {
  //         //   return <div></div>;
  //         // },
  //         fields: [
  //           {
  //             name: "name",
  //             displayName: "Name",
  //             type: "field",
  //             inputType: "text",
  //             required: true,
  //             value: (formik) => {
  //               // setSelectedEmployee(formik.values.employee);
  //               return formik.values.name;
  //             }
  //           },
  //           {
  //             name: "description",
  //             displayName: "Description",
  //             type: "field",
  //             inputType: "text",
  //             required: true,
  //             value: (formik) => {
  //               // setSelectedEmployee(formik.values.employee);
  //               return formik.values.description;
  //             }
  //           }
  //         ]
  //       }
  //     ],
  //     initialValues: {
  //       name: row.name,
  //       description: row.description
  //     },
  //     // validationSchema: Yup.object().shape({
  //     //   remarks: Yup.string().required(
  //     //     "Please Enter some remarks for clarifications"
  //     //   ),
  //     // }),
  //     // TODO: NEED TO CHANGE HOW values is passed to the function below, without upsetting setSubmitting and stuff
  //     // ALSO REBASE FROM MASTER
  //     onSubmit: async (
  //       values,
  //       { setSubmitting, setErrors, setStatus, resetForm, handleSubmit },
  //     ) => {
  //       console.log("DATA GRID EDIT SUBMIT");
  //       const payload =
  //       {
  //         ...row,
  //         ...values,
  //       }

  //       // const { owner: _, ...refinedPayload } = payload;

  //       console.log(payload);
  //       customAxios
  //         .put("/group/update", payload)
  //         .then(() => {
  //           customAxios.get("/group/type/department").then((res) => {
  //             setData(res);
  //           });
  //         });
  //       setIsSideDrawerOpen(false);
  //     }
  //   }

  //   return (
  //     <div key={uuidv4()}>
  //       <FormGenerator
  //         // ErrorMsg={errorMsg}
  //         // isError={isError}
  //         height={""}
  //         buttonSubmit={{ title: `Submit` }}
  //         formData={formDataTemplate}
  //       />
  //     </div>
  //   );
  // }


  // const AddRenderProp = (setIsSideDrawerOpen) => {
  //   const formDataTemplate = {
  //     contents: [
  //       {
  //         heading: "Add Department",
  //         description:
  //           "Complete the following Info",
  //         // function: () => {
  //         //   return <div></div>;
  //         // },
  //         fields: [
  //           {
  //             name: "name",
  //             displayName: "Name",
  //             type: "field",
  //             inputType: "text",
  //             required: true,
  //             value: (formik) => {
  //               // setSelectedEmployee(formik.values.employee);
  //               return formik.values.name;
  //             }
  //           },
  //           {
  //             name: "description",
  //             displayName: "Description",
  //             type: "field",
  //             inputType: "text",
  //             required: true,
  //             value: (formik) => {
  //               // setSelectedEmployee(formik.values.employee);
  //               return formik.values.description;
  //             }
  //           }
  //         ]
  //       }
  //     ],
  //     initialValues: {
  //       name: "",
  //       description: ""
  //     },
  //     // validationSchema: Yup.object().shape({
  //     //   remarks: Yup.string().required(
  //     //     "Please Enter some remarks for clarifications"
  //     //   ),
  //     // }),
  //     // TODO: NEED TO CHANGE HOW values is passed to the function below, without upsetting setSubmitting and stuff
  //     // ALSO REBASE FROM MASTER
  //     onSubmit: async (
  //       values,
  //       { setSubmitting, setErrors, setStatus, resetForm, handleSubmit }
  //     ) => {
  //       console.log("DATA GRID ADD SUBMIT");
  //       const payload =
  //       {
  //         ...values,
  //         type: "Department"
  //       }

  //       console.log(payload)

  //       customAxios
  //         .post("/group/create", payload)
  //         .then(() => {
  //           customAxios.get("/group/type/department").then((res) => {
  //             setData(res);
  //           });
  //         });
  //       setIsSideDrawerOpen(false);
  //     }
  //   }

  //   return (
  //     <div key={uuidv4()}>
  //       <FormGenerator
  //         // ErrorMsg={errorMsg}
  //         // isError={isError}
  //         height={""}
  //         buttonSubmit={{ title: `Submit` }}
  //         formData={formDataTemplate}
  //       />
  //     </div>
  //   );
  // }


  const refreshGridData = (row) => {
    customAxios.get("/group/type/department").then((res) => {
      setData(res);
    });
  }

  const onActionBegin = (args) => {

    console.log("ONACTIONBEGIN EXT");
    if (args.requestType === GridConstants.GRID_ACTION_SAVE) {

      if (args.action === GridConstants.ACTION_ADD) {

        const payload =
        {
          ...args.entity,
          type: "Department"
        }
        console.log("SAVE>ADD", payload)
        customAxios
          .post("/group/create", payload)
          .then(() => { refreshGridData(); });

      }

      else if (args.action === GridConstants.ACTION_UPDATE) {


        console.log("DATA GRID EDIT SUBMIT", args);

        // OLD GRID EDIT VER
        // const { policies: _, ...payload } = args.entity;

        // console.log("SAVE>UPDATE", payload)
        // // const { owner: _, ...refinedPayload } = payload;

        // console.log(payload);
        // customAxios
        //   .put("/group/update", payload)
        //   .then(() => {
        //     customAxios.get("/group/type/department").then((res) => {
        //       setData(res);
        //     });
        //   });

        const { policies: _, ...payload } = args.entity;

        console.log("SAVE >> UPDATE", payload)
        // const { owner: _, ...refinedPayload } = payload;

        console.log(payload);
        customAxios
          .put("/group/update", payload)
          .then(() => {
            customAxios.get("/group/type/department")
              .then(refreshGridData);
          });

      }

      else if (args.action === GridConstants.ACTION_DELETE) {

        console.log("SAVE>DELETE")
        const entityId = args.entity.id;
        customAxios
          // .delete("/group/delete", { data: payload })
          .delete(`/group/delete/${entityId}`)
          .then(() => {
            customAxios.get("/group/type/department")
              .then((res) => {
                setData(res);
              });
          });

      }

    }

  }




  return (
    <div className="h-full">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="flex flex-col h-full w-full">
          <Widget heading={"Departments"}>
            <DataTable
              header="Departments"
              showHeader={true}
              cellFunction={cell => cell.render("Cell")}
              columns={columns}
              testData={data}
              setData={setData}
              allowAdd={true}
              createUpdateColumns={columns}
              // createUpdateColumns={
              //   [
              //     {
              //       accessor: "name",
              //       Header: "Name",
              //       required: true,
              //       validation: Yup.string().required("Name is required").min(5, "Must be at least 5 charaters long"),
              //     },
              //     {
              //       accessor: "description",
              //       Header: "Description",
              //       required: false,
              //     },
              //     {
              //       accessor: "createdAt",
              //       Header: "Created At",
              //       type: "dateSelect",
              //       hideOnEdit: true
              //     }
              //   ]
              // }
              // addRenderProp={AddRenderProp}
              allowEdit={true}
              // editRenderProp={EditRenderProp}
              allowEditRowOnDoubleClick={true}
              allowDelete={true}
              confirmOnDelete={true}
              onActionBegin={onActionBegin}
            />
          </Widget>
        </div>
      )}
    </div>
  );
}
