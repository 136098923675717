import StepModal from "components/step-modal/StepModal";
import { setBreadCrumbs } from "features/navigation/NavigationSlice";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import LoanInformation from "./steps/loan-information/LoanInformation";
import PayStructure from "./steps/pay-structure/PayStructure";

let tabData = [
  {
    index: 1,
    name: "Loan Information",
    component: <LoanInformation />,
  },
  {
    index: 2,
    name: "Pay Structure",
    component: <PayStructure />,
  },
  {
    index: 3,
    name: "Contract",
    component: <p>s</p>,
  },
  {
    index: 4,
    name: "Review & Submit",
    component: <p>ss</p>,
  },
];

export default function AddEmployeeLoans() {
  let [selectedTab, setSelectedTab] = React.useState(tabData[0]);
  let dispatch = useDispatch();
  let { Name } = useParams();

  React.useEffect(() => {
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Employees", link: "/employees/all" },
        { name: `${Name}`, link: `` },
        { name: "Loans", link: `` },
        { name: "Add Loan", link: `` },
      ])
    );
  }, []);

  return (
    <div className="flex flex-col space-y-2">
      <StepModal
        TabData={tabData}
        SelectedTab={selectedTab}
        SetSelectedTab={setSelectedTab}
      />
    </div>
  );
}
