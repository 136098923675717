import React, { Fragment, useEffect } from "react";
import { columns } from "./columns";
import DataTable from "../../../components/data-grid/DataGrid";
import customAxios from "../../../middlewares/axios-interceptor/customAxios";
import { menuItems } from "./../MenuItems";
import Widget from "components/widget/Widget";
import {
  setBreadCrumbs,
  setSelectedSubMenu,
  setSubHeader,
} from "features/navigation/NavigationSlice";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import moment from "moment";
import { Popover } from "@headlessui/react";

export default function AllGroups() {
  let [data, setData] = React.useState([]);
  let [loading, setLoading] = React.useState(true);
  let [tempColumns, setTempColumns] = React.useState([]);
  let navigate = useNavigate();
  let currentMonth = moment().format("YYYY-MM-DD");
  let startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
  let endOfMonth = moment().endOf("month").format("YYYY-MM-DD");

  let dispatch = useDispatch();

  function generatteColumns() {
    console.log("generatteColumns");
    tempColumns = [
      {
        Header: "_",
        accessor: "employee",
      },
      // {
      //   Header: <p className="px-2 rounded-full bg-green-400">P</p>,
      //   accessor: "present",
      // },
      {
        Header: moment(new Date()).format("MMMM"),
        columns: [],
      },
    ];

    //Insert array after a specific index
    function insertAfter(arr, index, newItem) {
      return [...arr.slice(0, index), newItem, ...arr.slice(index)];
    }

    let summary = data.map((x) => x.summary);

    console.log(data[0]);
    console.log(summary);

    let startDate = moment().startOf("month");
    let endDate = moment().endOf("month");
    // return all the dates between the start and end dates
    let allDates = [];
    let currentDate = startDate;
    while (currentDate <= endDate) {
      allDates.push(currentDate.format("DD"));
      currentDate = moment(currentDate).add(1, "days");
    }

    allDates.map((d) => {
      // check if the date is a weekend
      let isWeekend =
        moment(d, "DD").isoWeekday() == 5 || moment(d, "DD").isoWeekday() == 6;

      tempColumns[tempColumns.length - 1].columns.push({
        Header: isWeekend ? (
          <p
            onClick={() =>
              navigate(
                `/Attendance/viewAttendanceOn/date=${moment().format(
                  "YYYY-MM"
                )}-${d}&EmployeeId=null`
              )
            }
            className="text-orange-600 rounded-full hover:bg-slate-200"
          >
            {d}
          </p>
        ) : (
          <p
            onClick={() =>
              navigate(
                `/Attendance/viewAttendanceOn/date=${moment().format(
                  "YYYY-MM"
                )}-${d}&EmployeeId=null`
              )
            }
            className="text-slate-600 rounded-full hover:bg-slate-200"
          >
            {d}
          </p>
        ),
        accessor: d,
        orientation: "vertical",
      });
    });

    return tempColumns;
  }

  function renderLegend(log) {
    return (
      <Popover className="relative">
        <Popover.Button
          className={
            "outline-orange-200 hover:outline hover:outline-slate-200 flex items-center justify-center rounded-full w-5 h-5 "
          }
        >
          <div className="flex  items-center justify-center">
            <div
              style={{
                backgroundColor: log.scheduleColor,
              }}
              className={`w-4 h-4 rounded-full `}
            ></div>
          </div>
        </Popover.Button>

        <Popover.Panel className="absolute z-50  aspect-video top-0 right-0  text-left text-xs xw bg-white border border-slate-200 rounded-md shadow-sm p-2 w-80">
          <div className="grid grid-cols-12">
            <div className="flex flex-col col-span-6 text-left space-y-1.5 items-start font-semibold justify-start">
              <p>Check In</p>
              <p>Check Out</p>
              <p>Early</p>
              <p>Overtime</p>
              <p>Hours</p>
              <p>Shift</p>
            </div>
            <div className="flex flex-col col-span-1 text-left space-y-1.5 items-start font-semibold justify-start">
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
            </div>
            <div className="flex flex-col col-span-5 text-left space-y-1.5 items-start  justify-start">
              <p>
                {moment(log.checkIn, "hh:mm:ss").format("hh:mm A") ?? "N/A"}
              </p>
              <p>
                {moment(log.checkOut ?? "17:00:00", "hh:mm:ss").format(
                  "hh:mm A"
                ) ?? "N/A"}
              </p>
              <p>
                {moment(log.early, "hh:mm:ss").format("mm") + " mins" ?? "N/A"}
              </p>
              <p>
                {moment(log.late, "hh:mm:ss").format("mm") + " mins" ?? "N/A"}
              </p>
              <p>
                {
                  // find total hours from check in and check out
                  moment(log.checkIn ?? "17:00:00", "hh:mm:ss")
                    .diff(moment(log.checkOut, "hh:mm:ss"), "hours", true)
                    .toFixed(2) + " hrs" ?? "N/A"
                }
              </p>
              <p>
                {moment(log.start ?? "09:00:00", "hh:mm:ss").format("hh:mm A") +
                  " - " +
                  moment(log.end ?? "17:00:00", "hh:mm:ss").format("hh:mm A")}
              </p>
            </div>
          </div>

          <img src="/solutions.jpg" alt="" />
        </Popover.Panel>
      </Popover>
    );
  }

  let employees = JSON.parse(localStorage.getItem("employees"));

  React.useEffect(() => {
    customAxios
      .get(`/attendance/summary?from=${startOfMonth}&to=${endOfMonth}`)
      .then((res) => {
        setData(res);
        arrangeData(res);
      });
  }, []);

  async function arrangeData(data) {
    console.log(data);
    let tempData = [];
    await data.map((data) => {
      let temp = {
        employee: {
          // id: data.id,
          name: data.name,
          // department: data.department,
          // designation: data.designation,
          avatar: "https://www.mydthpay.com/img/review.png",
        },
        // others: data.others,
      };

      // data.summary.forEach((element) => {
      //   temp[Object.keys(element)[0]] = Object.values(element)[0];
      // });

      data.logs.map((attendance) => {
        temp[moment(attendance.date).format("DD")] = renderLegend(attendance);
      });

      tempData.push(temp);
    });

    setData(tempData);
    setLoading(false);
  }

  let statuses = ["null"];

  useEffect(() => {
    dispatch(setSelectedSubMenu(menuItems[1]));
    dispatch(setSubHeader("Attendance"));
    dispatch(
      setBreadCrumbs([
        { name: "Home", link: "/" },
        { name: "Attendance", link: "/Attendance/overview" },
      ])
    );
    setLoading(false);
  }, []);

  function cellFunction(cell) {
    if (cell.column.id === "employee") {
      return (
        <div className="flex items-center w-64 rounded-full hover:bg-slate-200">
          <img
            src={
              cell.row.original.employee?.avatar
                ? cell.row.original.employee?.avatar
                : "https://i.pravatar.cc/150?img=1"
            }
            alt="avatar"
            className="w-8 h-8 rounded-full mr-2"
          />
          <div className="text-sm">
            <p className="font-semibold text-start">
              {cell.row.original.employee?.name}
            </p>
            <p className="text-xs text-gray-500">
              {cell.row.original.employee?.department} -{" "}
              {cell.row.original.employee?.designation} -{" "}
              {cell.row.original.employee?.id}
            </p>
          </div>
        </div>
      );
    }
    return cell.render("Cell");
  }

  return (
    <div className="h-full">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Widget>
          <DataTable
            showHeader={true}
            cellFunction={cellFunction}
            columns={generatteColumns()}
            testData={data}
            alignRow="center"
            alignHeader={"center"}
          />
        </Widget>
      )}
      <Outlet className="w-screen h-screen" />
    </div>
  );
}
