import React from "react";
import { Outlet } from "react-router-dom";
import Tabs from "./navigation/Tabs";

export default function TabLayout({ tabItems, rootPath }) {
  return (
    <div className="flex h-full flex-col space-y-2 ">
      <Tabs rootPath={rootPath} tabItems={tabItems} />
      <Outlet className="" />
    </div>
  );
}
