export const columns = [
  {
    Header: "Employee",
    accessor: "employee",
  },
  {
    Header: "Department",
    accessor: "department",
  },
  {
    Header: "Statistics",
    columns: [
      {
        Header: "Present",
        accessor: "daysWorked",
      },
      {
        Header: "Absent",
        accessor: "daysAbsent",
      },
      {
        Header: "On Leave",
        accessor: "daysLeave",
      },
      {
        Header: "Days Holiday",
        accessor: "daysHoliday",
      },
      {
        Header: "Days Paid Leave",
        accessor: "daysPaidLeave",
      },
      {
        Header: "Days Unpaid Leave",
        accessor: "daysUnpaidLeave",
      },
      {
        Header: "Total Work Hours",
        accessor: "totalWorkHours",
      },
      {
        Header: "Total Late Hours",
        accessor: "totalLateHours",
      },
    ],
  },
  {
    Header: "Payables",
    columns: [
      {
        Header: "Basic",
        accessor: "basic",
      },
      {
        Header: "HRA",
        accessor: "hra",
      },
      {
        Header: "Conveyance",
        accessor: "conveyance",
      },
      {
        Header: "Medical",
        accessor: "medical",
      },
      {
        Header: "Special Allowance",
        accessor: "specialAllowance",
      },
      {
        Header: "Other Allowance",
        accessor: "otherAllowance",
      },
      {
        Header: "Total",
        accessor: "totalPayable",
      },
    ],
  },

  {
    Header: "Deductions",
    columns: [
      {
        Header: "PF",
        accessor: "pf",
      },
      {
        Header: "ESI",
        accessor: "esi",
      },
      {
        Header: "TDS",
        accessor: "tds",
      },
      {
        Header: "Other Deductions",
        accessor: "otherDeductions",
      },
      {
        Header: "Total",
        accessor: "totalDeductions",
      },
    ],
  },
  {
    Header: "Net Pay",
    accessor: "netPay",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
