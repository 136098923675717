import { PlusIcon } from "@heroicons/react/solid";
import GlobalLayout from "layout/GlobalLayout";
import React from "react";
import { Outlet } from "react-router-dom";
import DropDownButton from "ui/buttons/DropDownButton";
import { menuItems } from "./MenuItems";

let init = JSON.parse(localStorage.getItem("init"));

export default function Index() {
  let [selected, setSelected] = React.useState(null);
  let dropDownItems = init?.groupTypes ?? [];
  return (
    <GlobalLayout
      mainTitle={"Groups"}
      heading={"All Groups"}
      selectedMenu={menuItems[1]}
      breadCrumbs={[{ title: "Groups" }]}
      MenuItems={menuItems}
    // buttons={[
    //   <DropDownButton
    //     setSelected={setSelected}
    //     path="/Groups/AddGroup/Modal=true&groupType="
    //     title={"Add Group"}
    //     items={dropDownItems}
    //     icon={<PlusIcon className="h-4 w-4" />}
    //   />,
    // ]}
    >
      <Outlet />
    </GlobalLayout>
  );
}
