import PieChart from "components/charts/pie-chart/PieChart";

const data = {
    labels: ["HR", "Software Development", "Marketing", "Solutions", "Admin"],
    datasets: [
      {
        data: [2, 2, 2, 1, 1],
      },
    ],
  };

  export default function GroupDistribution () {
    return (
        <PieChart labels={data.labels} datasets={data.datasets} />
    )
  }