import BarChart from "components/charts/bar-chart/barChart";
import customAxios from "middlewares/axios-interceptor/customAxios";
import { useEffect } from "react";
import { useState } from "react";

export default function Department({ handleEmployeeCount }) {
  const [employeeDataItems, setEmployeeDataItems] = useState([]);

    useEffect(() => {
        customAxios.get("/employee")
        .then((res) => {
            let activeEmployeeDataItems = res.items.filter((item) => item.status === "Active") ?? []
            handleEmployeeCount(activeEmployeeDataItems.length)
            setEmployeeDataItems(activeEmployeeDataItems)
        })
    }, [])

    const departmentCounts = {}
    console.log(employeeDataItems)

    for(const object of employeeDataItems)
    {
        let departmentOfObject = object.position?.department ?? "N/A"
        if(departmentCounts.hasOwnProperty(departmentOfObject))
        {
            departmentCounts[departmentOfObject]++;
        }
        else
        {
            departmentCounts[departmentOfObject] = 1;
        }
    }

  const data = {
    labels: Object.keys(departmentCounts),
    datasets: [
      {
        data: Object.values(departmentCounts),
      },
    ],
  };
  return <BarChart labels={data.labels} datasets={data.datasets} />;
}
