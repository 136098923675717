import ModalLayout from "components/layout/ModalLayout";
import Modal from "components/modal/Modal";
import { addNotification } from "features/notifications/NotificationSlice";
import React from "react";
import * as Yup from "yup";
import FormGenerator from "components/form-generator/FormGenerator";
import customAxios from "../../../../../../middlewares/axios-interceptor/customAxios";
import { useParams } from "react-router-dom";

let countries = JSON.parse(localStorage.getItem("countries"));
let variables = JSON.parse(localStorage.getItem("variables"));
let genders = variables?.filter((x) => x.key == "Gender");
let suffix = variables?.filter((x) => x.key == "Title");
let MaritalStatuses = variables?.filter((x) => x.key == "MaritalStatus");
let Banks = variables?.filter((x) => x.key == "Bank");

export default function EditEmployeePersonalInfo() {
  let { EmployeeId } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [formData, setFormData] = React.useState<any>({
    contents: [
      {
        heading: "Personal Information",
        description: "Something about adding personal Information",
        function: () => {
          return <div></div>;
        },
        fields: [
          {
            name: "avatar",
            displayName: "Profile Picture",
            type: "pictureUpload",
            value: (formik) => {
              return formik.values.profilePicture;
            },
          },
          {
            name: "firstName",
            displayName: "First Name",
            type: "field",
            inputType: "text",
            required: true,
            value: "test",
          },
          {
            name: "middleName",
            displayName: "Middle Name",
            type: "field",
            inputType: "text",
          },
          {
            name: "lastName",
            displayName: "Last Name",
            type: "field",
            inputType: "text",
            required: true,
          },
          {
            name: "suffix",
            displayName: "Suffix",
            type: "comboBox",
            inputType: "text",
            required: true,
            value: (formik) => {
              return formik.values.suffix;
            },
            values: suffix,
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span>{item.name}</span>
                </div>
              );
            },
          },
          {
            name: "gender",
            displayName: "Gender",
            type: "comboBox",
            inputType: "text",
            required: true,
            value: (formik) => {
              return formik.values.gender;
            },
            values: genders,
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span>{item.name}</span>
                </div>
              );
            },
          },
          {
            name: "nationality",
            displayName: "Nationality",
            type: "comboBox",
            value: (formik) => {
              return formik.values.nationality;
            },
            values: countries ?? [
              { id: 1, name: "Maldives" },
              { id: 2, name: "China" },
              { id: 2, name: "Russia" },
            ],
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <img className="w-6 h-5 col-span-2" src={item.flag} />
                  <span className="col-span-9">{item.demonym}</span>
                </div>
              );
            },
          },
          {
            name: "nationalIdentificationNumber",
            displayName: "NIC/Passport Number",
            type: "field",
            inputType: "text",
            required: true,
          },
          {
            name: "dateOfBirth",
            displayName: "Date of Birth",
            type: "dateSelect",
            default: new Date(),
            inputType: "text",
            value: (formik) => {
              return formik.values.dateOfBirth;
            },
            required: true,
          },

          {
            name: "maritalStatus",
            displayName: "Marital Status",
            type: "comboBox",
            inputType: "text",
            required: true,
            value: (formik) => {
              return formik.values.maritalStatus;
            },
            values: MaritalStatuses,
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-10">{item?.name}</span>
                </div>
              );
            },
          },
        ] as any,
      },
    ],
    initialValues: {} as Employee,
    validationSchema: Yup.object().shape({}),
    onSubmit: async (
      values,
      { setSubmitting, setErrors, setStatus, resetForm, handleSubmit }
    ) => {
      setLoading(true);
      const payload = getChangedValues(values, formData.initialValues);
      await saveInformation(payload);
      setLoading(false);
      // dispatch(addPersonal(values));
      // resetForm();
      // dispatch(setSelectedTab(Steps[4]));
    },
  });

  //Get changed values from form
  const getChangedValues = (values, initialValues) => {
    let patches = [];
    for (let key in values) {
      if (values[key] !== initialValues[key]) {
        patches.push({
          path: `/${key}`,
          from: initialValues[key],
          value: values[key],
          op: "replace",
        });
      }
    }

    console.log(patches);
    return patches;
  };

  async function saveInformation(payload) {
    await customAxios
      .patch(
        "https://api.echeshr.com/api/v2/employee/update/" + EmployeeId,
        payload
      )
      .catch((res) => {
        console.log(res);
      });
  }

  function loadPersonalInfo() {
    customAxios.get("/employee/find/" + EmployeeId).then((res) => {
      formData.initialValues = res;
      console.log(formData.initialValues);
      setLoading(false);
    });
  }

  React.useEffect(() => {
    loadPersonalInfo();
  }, []);

  return (
    <Modal>
      <ModalLayout heading={"Edit Employee Details"} width={"w-[33vw]"}>
        {!loading ? (
          <FormGenerator
            ErrorMsg={"Something went wrong"}
            isError={false}
            formData={formData}
          />
        ) : (
          <div>Loading</div>
        )}
      </ModalLayout>
    </Modal>
  );
}
