import React, { useState } from "react";
import {
  selectPersonal,
  selectTab,
  setSelectedTab,
} from "../../../../features/employees/EmployeesSlice";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment/moment";
import Spinner from "../../../../components/spinner/Spinner";
import customAxios from "../../../../middlewares/axios-interceptor/customAxios";
import Widget from "components/widget/Widget";

export function ReviewAndCreate() {
  let [isSubmit, setIsSubmit] = React.useState(false);
  let dispatch = useDispatch();
  let personalInfo = useSelector(
    (state) => state.employees.addEmployee.personal.data
  );
  let selectedTab = useSelector(selectTab);

  return (
    <div>
      {!isSubmit && (
        <div className="grid grid-cols-3 gap-3">
          <Widget
            heading={"Personal Information"}
            component={function () {
              return <div className="flex flex-col space-y-2">Edit</div>;
            }}
          >
            <div className="flex flex-col space-y-2">
              {Object.keys(personalInfo).map((key) => {
                return (
                  <div className="flex flex-row justify-between space-x-2">
                    <p className="text-sm font-medium text-gray-500 capitalize">
                      {/* put space between camelcase */}
                      {key !== "profilePicture" ? (
                        <p> {key.replace(/([A-Z])/g, " $1")} </p>
                      ) : (
                        <p>Profile Picture</p>
                      )}
                    </p>

                    {typeof personalInfo[key] === "object" ? (
                      <div>
                        {key === "profilePicture" ? (
                          <div
                            style={{
                              backgroundImage: personalInfo[key]
                                ? `url(${URL.createObjectURL(
                                    personalInfo[key]
                                  )}) `
                                : `url('https://www.mydthpay.com/img/review.png')`,
                            }}
                            className="h-32 w-32 border mb-5 border-slate-300 rounded-full bg-cover bg-center group-hover:opacity-50"
                          />
                        ) : (
                          <div className="text-base text-black">
                            {personalInfo[key].name ?? "N/A"}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="text-base text-black">
                        {personalInfo[key]}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </Widget>
          <Widget heading={"Employment Details"} />
          <Widget heading={"Addresses"} />
          <Widget heading={"Documents"} />
          <Widget heading={"Education"} />
          <Widget heading={"Experience"} />
        </div>
      )}
      {isSubmit && (
        <div>
          <div className="h-[70vh] flex flex-col space-y-5">
            <h1 className=" text-orange-800 font-medium">Adding Employee...</h1>
            <div className="flex flex-col space-y-2">
              <div className="flex flex-row space-x-5 text-md">
                <Spinner />
                <p>Adding Basic Information</p>
              </div>
              <div className="flex flex-row space-x-5 text-md">
                <Spinner />
                <p>Adding Contacts 1/3</p>
              </div>
              <div className="flex flex-row space-x-5 text-md">
                <Spinner />
                <p>Adding Addresses 1/3</p>
              </div>
              <div className="flex flex-row space-x-5 text-md">
                <Spinner />
                <p>Adding Documents 1/3</p>
              </div>
              <div className="flex flex-row space-x-5 text-md">
                <Spinner />
                <p>Completed</p>
              </div>
            </div>
          </div>

          <div className="flex flex-row  justify-start border-t border-y-gray-100 pt-4 space-x-3 items-center">
            <button
              className={`px-4 p-1.5 text-md hover:bg-orange-500 bg-orange-600 font-medium text-white`}
              type="button"
            >
              Go Back To Employees
            </button>

            <button
              className="px-4 p-1.5 text-md font-medium text-orange-800 hover:bg-orange-100 bg-white border border-orange-600"
              type="submit"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
